import { Flex, Box, Text, Stack } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const LeftlineColumn1 = styled(Box)((props) => ({
  width: "5%",
  display: "none",
  padding: "30px 0px 0 0px",
  [`@media ${device.tabletM}`]: {
    display: "none",
  },
}));

export const LeftlineColumn = styled(Box)((props) => ({
  display: "none",
  padding: "30px 0px 0px",
  height: "100%",
  zIndex: "11",
  marginLeft: "-30px",
  position: "relative",
  top: "90px",
  "@media screen and (max-width:1199px)": {
    marginLeft: "-16px",
  },
  [`@media ${device.tabletM}`]: {
    width: "17%",
    display: "block",
    position: "sticky",
  },
}));

export const LeftPersonal = styled(Text)((props) => ({
  fontSize: "15px",
  padding: "0px 50px",
  marginBottom: "20px",
  fontFamily: "Poppins",
  color: "#fff",
  fontWeight: "600",
  [`@media ${device.tablet}`]: {
    color: "#000",
  },
  [`@media ${device.tabletM}`]: {
    color: "#fff",
  },
}));

export const iconWrap = styled(Flex)((props) => ({
  padding: "10px 50px",
  // marginTop: "15px",
}));

export const LeftMenuTitle = styled(Text)((props) => ({
  fontSize: "14px",
  paddingLeft: "10px",
  fontFamily: "Poppins",
  color: "#B9B9B9",
}));

export const LeftlineRow = styled(Box)((props) => ({
  margin: "20px 0px",
  borderTop: "1px solid #262626",
  width: "90%",
}));

export const CancelBtn1 = styled(Box)((props) => ({
  fontSize: "18px",
  color: "#fff",
  cursor: "pointer",
  textAlign: "end",
  [`@media ${device.tablet}`]: {
    color: "#000",
  },
}));
