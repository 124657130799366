import {
  Flex,
  Container,
  SimpleGrid,
  GridItem,
  Box,
  VStack,
  Link,
  Stack,
  Image,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Heading from "./Heading/Heading";
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import AppDownload from "./AppDownload/AppDownload";
import Text from "./SubHeading/SubHeading";
import Testimonial from "./Testimonial/Testimonial";
import Accordian from "./Accordion/Accordian";
import MarqueLoop from "./Marque Loop/MarqueLoop";
import PriceBox from "./Price Component/PriceBox";
import WhatsincludedCard from "./WhatsIncluded Card/WhatsincludedCard";
import LinkButton from "./Button/LinkButton";
import ContactForm from "./Form/ContactForm";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";

import VideoBanner from "./VideoBanner/VideoBanner";
import BlogSection from "./Blog/BlogSection";

const accoData = [
  {
    id: 1,
    title: "HOW DO I ARRANGE A CONSULTATION?",
    description:
      "Please fill in the form below and a member of the nutrition team will be in contact with you in the next 24 hours.",
  },
  {
    id: 2,
    title: "HOW LONG IS A NUTRITION PLAN?",
    description:
      "Each plan is developed to run for 12 weeks - However if you need a longer or shorter plan, you can discuss with the coach",
  },
  {
    id: 3,
    title: "WHAT IS AN IN-BODY ANALYSIS?",
    description:
      "This is a machine that we use in Perpetua to measure your weight, muscle mass, body fat and much more. This allow us to get an accurate reading of your starting point.",
  },
  {
    id: 3,
    title: "WHAT IS THE PRICING?",
    description:
      "Our 12 week program starts at 300 euros. If you need any additional personal training on top of this, we will arrange a price plan for you. ",
  },
];
const accoData2 = [
  {
    id: 1,
    title: "HOW IS THE PROGRAM DELIVERED?",
    description:
      "The nutrition program will be delivered through our training app where you can easily follow the plan and log your daily stats",
  },
  {
    id: 2,
    title: "HOW OFTEN DO I GIVE FEEDBACK?",
    description:
      "We would ask that a client logs there food intake daily so we can get an accurate measure of your nutrition.",
  },
  {
    id: 3,
    title: "WHEN IS THE WEEKLY CHECK IN?",
    description:
      "This will be decided between you and your coach to find a time that suits.",
  },
  {
    id: 4,
    title: "HOW MUCH CONTACT DO I HAVE WITH THE COACH?",
    description:
      "You can contact the coach through the training app or pop them an email if you have any questions.",
  },
];
const accoData3 = [
  {
    id: 1,
    title: "CAN I CONTINUE WITH THE PROGRAM?",
    description:
      "Yes of course and we would encourage this. After you complete your initial program, we would sit down with the client and go over the results together. Once this is complete, you can decide on your goal and work with the coach. However, if you are happy with your results and want a break from the program, this is no problem.",
  },
];

const metaTitle = "Nutrition Coaching Dublin | Perpetua Fitness";
const metaDesc =
  "Nutrition coaching in Dublin with Perpetua Fitness. Learn how to balance your goals, lifestyle and nutrition for best results and enjoyment.";

function Nutrition(props) {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* <InnerBanner 
        backgroundColor='black' 
        backgroundImage={{sm:'url(./images/nutrition_m.jpg)', md:'url(./images/nutrition.jpg)'}} 
        subtitle='PERPETUA NUTRITION'
        title='NUTRITION COACHING AND PROGRAMMING'
        subtitleRight='The accountability and guidance you need'
        issubtextpara={false} 
        buttontitle="Enquire now"
        Link="/contact-us"
        issubtitleRight={true} 
        width={{sm:"100%"}}
        height={{ sm:'450px', md:'500px', lg:'550px', xl:'767px'}}
        ButtonModalmarginTop={{sm:'10px', md:'0'}}
        videoModal='https://www.youtube.com/embed/WeL4yfMoleY'
        /> */}

      <VideoBanner
        videobanner="../video/Nutrition-banner.mp4"
        title="NUTRITION COACHING DUBLIN"
        subtitle={<Box>PERPETUA NUTRITION</Box>}
        isButton={true}
        issubtextpara={false}
        buttontitle="Enquire now"
        margin="0px 0 0"
        Link="/contact-us"
        videoModal="https://www.youtube.com/embed/WeL4yfMoleY"
        targetBlank={false}
        modalButtontitle="View The Experience"
        subtitleRight={
          <Box display="inline-block">
            The accountability and guidance you need
          </Box>
        }
        issubtitleRight={true}
      />

      {/*  */}
      <Flex backgroundColor="black" padding="55px 0px 0px">
        <Container maxW="container.xl">
          <MarqueLoop></MarqueLoop>
        </Container>
      </Flex>
      <Flex
        backgroundColor="black"
        padding={{ sm: "100px 0px 50px", md: "100px 0px" }}
      >
        <Container maxW="container.xl">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            textAlign={{ base: "left", lg: "left" }}
            alignItems="center"
          >
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                currency="€"
                priceValue="99"
                time="/8 Week Group Challenge"
                description="Learn how to balance your goals, lifestyle and nutrition for best results and enjoyment in our 8 Week Group Nutrition Challenge starting in January!"
                buttonText="Sign up now"
                link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107856"
                issubHeading={true}
                width={{ base: "100%", sm: "90%" }}
                border="2px solid"
                borderColor="Red"
                buttonPading="20px 25px"
                targetBlank={true}
                subdescription="Starting January 15th 2024"
                subheading="Starting January 15th 2024"
              ></PriceBox>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <Heading
                title="WHATS INCLUDED "
                variant="small"
                color="white"
                margin="0px 0px 30px"
                padding={{ sm: "0px 0px 0px 0px", md: "0px 0px 0px 31px" }}
                textAlign={{ sm: "center", ms: "left" }}
              ></Heading>
              <SimpleGrid
                columns={{ base: "1", lg: "2" }}
                spacing="0px"
                className="_whatsinclude_box_body _whatsinclude_box_body2"
              >
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    // Imguri='/images/gym.svg'
                    title="In-Body Analysis"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    //spacing={props.padding}
                    //Link='/contact-us'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg'
                    title="Individualised nutrition plan"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    //spacing={props.padding}
                    //Link='/contact-us'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg'
                    title="24/7 support via whatsapp group chat"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    //spacing={props.padding}
                    //Link='/contact-us'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg'
                    title="Nutrition guidebook & shopping list"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    //spacing={props.padding}
                    //Link='/contact-us'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg'
                    title="Weekly check in & accountability"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    //spacing={props.padding}
                    //Link='/contact-us'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>
      {/*  */}

      {/* <Flex flexDirection='column' padding={{sm:'60px 0px ', md:'90px 0px 100px', lg:'120px 0px 150px'  }}>
        <Container maxW='container.xl'>
          <Heading 
            as="h3" 
            // title="Community and movement is at the heart of everything we do here at Perpetua.
            // We live for intelligent programming, functional fitness and having a good time together while sweating it out."
            title="AVAILABLE AT OUR WINDMILL LANE FACILITY"
            variant='medium'
            textAlign="center"
            lineHeight="1"
            width={{lg:'100%', xl:'100%'}}
            padding={{sm:'0px 0px 50px', md:'0px 0px 100px' }}
          >
          </Heading>

          <SimpleGrid 
            columns={{sm:'1',md:'2'}} 
            columnGap={10} 
            rowGap={2} 
            width="full" 
          >
              <GridItem>
                <ClassCard
                    title='GUIDANCE'
                    subtitle={false}
                    bg="url('/images/nutrition-box1.jpg')"
                    link="/about"
                    isButton={false}
                    marginBottom='30px'
                    transform="uppercase"
                    minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                />
              </GridItem>
              <GridItem mt={{base:'20px', sm:'40px', xl:'84px'}}>
                <ClassCard
                    title='RESULTS'
                    subtitle={false}
                    bg="url('/images/nutrition-box2.jpg')"
                    link="/about"
                    isButton={false}
                    marginBottom='30px'
                    transform="uppercase"
                    minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                />
              </GridItem>
          </SimpleGrid>

        </Container>
      </Flex> */}

      <Flex
        /* flexDirection='column'*/ padding={{
          base: "50px 0px ",
          md: "110px 0px 100px",
        }}
        position="relative"
        /* _after={{position:'absolute',content:'""',width:'554px', height:'464px', top:'0',left:'0',background:'LightGrey',zIndex:'-1'}}
      _before={{position:'absolute',content:'""',width:'554px', height:'464px', top:'auto', bottom:'0',right:'0',background:'LightGrey',zIndex:'-1',margin:'auto'}} */
      >
        <Container maxW="container.xl">
          <Testimonial
            headingWidth={{ sm: "97%", md: "90%", lg: "87%", xl: "69%" }}
            iconUri="/images/comment-quote-outline.svg"
            isname={false}
            title="“I CANT BELIEVE THE RESULTS I GOT. THE PLAN WAS SO EASY TO FOLLOW AND THE ACCOUNTABILITY WAS EXACTLY WHAT I NEEDED. I CAN NOW SAY FOR THE FIRST TIME IN MY LIFE THAT I UNDERSTAND HOW TO EAT AND BE HEALTHY”"
          />
        </Container>
      </Flex>

      <Box
        padding={{ sm: "50px 0px 40px", md: "80px 0px 80px" }}
        backgroundColor="black"
      >
        <Container maxW="container.xl">
          <Heading
            variant="extralarge"
            title="HOW DOES THE NUTRITION PROGRAM WORK?"
            margin={{ sm: "0px auto 50px", md: "0px auto 86px" }}
            width={{ base: "90%", md: "60%" }}
            textAlign="center"
            lineHeight="1"
            color="white"
          />
          <SimpleGrid
            columns={{ sm: "1", md: "3" }}
            spacing={5}
            textAlign="center"
            justifyContent="center"
          >
            <GridItem>
              <Heading title="01" color="Red" textAlign="center" />
              <Heading
                title="ARRANGE A CONSULTATION"
                color="white"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="Before you begin the program, you arrange a consultation where they will do an in-body analysis with you, find out about your training and eating habits and explain the process."
                color="white"
                padding="15px 20px"
              />
            </GridItem>
            <GridItem>
              <Heading title="02" color="Red" textAlign="center" />
              <Heading
                title="START YOUR PROGRAM"
                color="white"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="When you receive your program, you will track your daily intake through our online program platform and give feedback. This is essential for our coaches to monitor your habits and see what changes are needed."
                padding="15px 20px"
                color="white"
              />
            </GridItem>
            <GridItem>
              <Heading title="03" color="Red" textAlign="center" />
              <Heading
                title="WEEKLY CHECK-INS"
                color="white"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="Each week, the coach will analyse your feedback and progress - From they will decide what changes need to be made on the current plan and provide detailed feedback on the data you have provided."
                color="white"
                padding="15px 20px"
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      {/* <Flex padding={{sm:'60px 0px 20px', md:'100px 0px'}}>
            <Container maxW='container.xl'>
                <Heading title='HOW DOES THE NUTRITION PROGRAM WORK?' textAlign='center' width='70%' margin={{sm:'0px auto 50px', md:'0px auto 60px',}} lineHeight='1'></Heading>
                <SimpleGrid columns={{sm:'1',md:'3'}} spacing={10} >
                    <Box textAlign={'left'} p=' 110px 30px 30px' boxShadow={'0px 10px 80px 5px rgba(0, 0, 0, 0.13)'} position='relative' overflow={'hidden'}>
                        <Box width={'110px'} height='110px'  position='absolute' 
                        left={'-15px'} top='-15px' borderRadius={'100%'} background='Red' textAlign={'center'} color='white' paddingLeft={'13px'} paddingTop='5px'
                        >
                            <Heading title='01' color='white' lineHeight='110px'></Heading>
                        </Box>
                        <Heading title='ARRANGE A CONSULTATION' variant='smallmid' margin='0px 0px 20px'></Heading>
                        <Text title='Before you begin the program, you arrange a consultation where they will do an in-body analysis
                         with you, find out about your training and eating habits and explain the process.'></Text>
                        
                    </Box>
                    <Box textAlign={'left'} p=' 110px 30px 30px' boxShadow={'0px 10px 80px 5px rgba(0, 0, 0, 0.13)'} position='relative' overflow={'hidden'}>
                        <Box width={'110px'} height='110px'  position='absolute' 
                        left={'-15px'} top='-15px' borderRadius={'100%'} background='Red' textAlign={'center'} color='white' paddingLeft={'13px'} paddingTop='5px'
                        >
                            <Heading title='02' color='white' lineHeight='110px'></Heading>
                        </Box>
                        <Heading title='START YOUR PROGRAM' variant='smallmid' margin='0px 0px 20px'></Heading>
                        <Text title='When you receive your program, you will track your daily intake through our online program platform and give feedback.
                         This is essential for our coaches to monitor your habits and see what changes are needed.'></Text>
                        
                    </Box>
                    <Box textAlign={'left'} p=' 110px 30px 30px' boxShadow={'0px 10px 80px 5px rgba(0, 0, 0, 0.13)'} position='relative' overflow={'hidden'}>
                        <Box width={'110px'} height='110px'  position='absolute' 
                        left={'-15px'} top='-15px' borderRadius={'100%'} background='Red' textAlign={'center'} color='white' paddingLeft={'13px'} paddingTop='5px'
                        >
                            <Heading title='03' color='white' lineHeight='110px'></Heading>
                        </Box>
                        <Heading title='WEEKLY CHECK-INS' variant='smallmid' margin='0px 0px 20px'></Heading>
                        <Text title='Each week, the coach will analysis your feedback and progress - From there there will decide what changes need to be 
                        made on the current plan and provide detailed feedback on the data you have provided.'></Text>
                        
                    </Box>

                </SimpleGrid>
            </Container>
      </Flex>   */}

      {/* <Flex 
        flexDirection='row' 
        padding={{sm:'50px 0px ', md:'50px 0 110px' }} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width='58%' backgroundColor='LightGrey' marginRight='-145px' padding='200px 300px 200px 200px' >
            <Heading title='PROGRAMMED BY CERTIFIED COACHES' variant="extralarge" lineHeight='1' margin='0px 0px 60px'></Heading>
            <Text 
              title='These aren’t just made up nutrition programs. These are professional certified coaches with years of experience and dedicated to helping you get results.'
              margin='0px 0px 20px'
            >
                  
            </Text>
            <Text title='When you join start a nutrition program, you will get the accountability and guidance you need to understand how to fuel your body for training and also get the results you desire.'></Text>
        </Box>
        <Box 
        width='50%' 
        backgroundImage='url(./images/nutri-tion-box1.jpg)' 
        height='558px'
        backgroundRepeat='no-repeat'
        backgroundPosition='center'
        backgroundSize='cover'
        ></Box>

      </Flex>

      <Flex 
        flexDirection='row' 
        padding={{base:'150px 0px 0px ', sm:'310px 0px 0px' }} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width='50%' textAlign='right'>
            <Box 
            backgroundImage='url(./images/sweat-bg2.png)' 
            width='551px' height='671' 
            display='inline-block' 
            backgroundRepeat='no-repeat'
            backgroundPosition='center'
            backgroundSize='cover'
            position='relative'
            zIndex='1'
            top="-80px"
            >
            </Box>
        </Box>
        <Box width='58%' backgroundColor='LightGrey' marginLeft='-145px' padding='180px 200px' >
            <Heading title='SINCE 2014' variant="extrasmall" lineHeight='1' margin='0px 0px 60px'></Heading>
            <Heading title='THOUSANDS OF CLIENTS HAVE EARNED RESULTS WITH OUR PROGRAMS' variant="extralarge" lineHeight='1' margin='0px 0px 60px'></Heading>
            <Text title='You can expect the best experience from the moment you start your nutrition program. You will start with a
             consultation before receiving your program and get getting weekly feedback from our coaches on what changes you need to make. '></Text>
        </Box>
      </Flex> */}

      {/* <Flex 
        flexDirection={{sm: "column", md: "row"}}
        padding={{sm:'50px 0px ', md:'30px 0px 0' }} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Flex width={{sm: "100%", md:"50%"}} backgroundColor='LightGrey' padding={{sm: "60px 0 35px", md: "200px 0"}} position="relative" justifyContent="flex-end"
        _after={{position:'absolute',content:'""',width:'200px', height:'100%', top:'0',left:'100%',background:'LightGrey',zIndex:'-1', display:{sm:"none", md:"block"}}}
        
        > <Box width={{md: "100%", xl:"630px"}}  padding="0 15px">
            <Heading title='PROGRAMMED BY CERTIFIED COACHES' variant="extralarge" lineHeight='1' margin={{sm:'0px 0px 25px', md:'0px 0px 40px', lg:'0px 0px 60px'}} />
            <Text 
              title='These aren’t just made up nutrition programs. These are professional certified coaches with years of experience and dedicated to helping you get results.'
              margin='0px 0px 20px'
            />
            <Text title='When you join start a nutrition program, you will get the accountability and guidance you need to understand how to fuel your body for training and also get the results you desire.' />
         </Box>
        </Flex>
        <Box 
        width={{sm: "100%", md:"50%"}}
        backgroundImage='url(./images/sweat-bg1.jpg)' 
        height={{sm: "300", md: '558px'}}
        backgroundRepeat='no-repeat'
        backgroundPosition='center'
        backgroundSize='cover'
        ></Box>

      </Flex>

      <Flex 
        flexDirection={{sm: 'column-reverse', md: "row"}}
        padding={{sm:'0 0px 35px ', md:'70px 0px 0px', md:'150px 0px 0px' }}
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width={{sm: '100%', md: "50%"}} textAlign='right'>
            <Box 
            backgroundImage='url(./images/sweat-bg2.png)' 
            width={{md:"100%" , lg: '551px'}} height={{sm: "350px", md: '671'}} 
            display={{sm:"block", md: 'inline-block' }}
            backgroundRepeat='no-repeat'
            backgroundPosition='top'
            backgroundSize='cover'
            position='relative'
            zIndex='1'
            top={{sm: "0", md: "-80px"}}
            >
            </Box>
        </Box>
        <Box width={{sm: '100%', md: "58%"}} backgroundColor='LightGrey' marginLeft={{sm: "0", md: '-145px'}} padding={{sm: "30px 20px 30px", md: "70px 100px 60px 187px", xl:  '110px 100px 100px 190px'}}   >
          <Heading title='SINCE 2014' variant="extrasmall" lineHeight='1' margin={{sm:'0px 0px 25px', md:'0px 0px 40px', lg:'0px 0px 60px'}}></Heading>
          <Heading title='THOUSANDS OF CLIENTS HAVE EARNED RESULTS WITH OUR PROGRAMS' variant="extralarge" lineHeight='1' margin={{sm:'0px 0px 25px', md:'0px 0px 40px', lg:'0px 0px 60px'}}></Heading>
          <Text title='You can expect the best experience from the moment you start your nutrition program. You will start with a consultation before receiving your program and get getting weekly feedback from our coaches on what changes you need to make. '></Text>
            
        </Box>

      </Flex> */}

      {/* <TabComponent /> */}

      <Box
        padding={{ sm: "50px 0px 30px", md: "80px 0px" }}
        background={"#eeeef1"}
      >
        <Container maxW="container.xl">
          <Flex
            margin="auto"
            textAlign={"center"}
            width={{ sm: "100%", md: "50%" }}
            flexDirection="column"
            paddingBottom="30px"
          >
            <Heading
              lineHeight={"1"}
              variant="large"
              title="Frequently Asked Questions"
              marginBottom="30px"
            />
            <Box mb="40px">
              <Text title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie " />
            </Box>
          </Flex>

          <Flex flexDirection={{ sm: "column", md: "row" }}>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="BEFORE STARTING"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="DURING THE PROGRAM"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData2} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="AFTER COMPLETING THE PROGRAM"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData3} />
            </Box>
          </Flex>
        </Container>
      </Box>
      <BlogSection catSlug="nutrition" />
      <AppDownload
        bg="url('/images/App-section-bg.webp')"
        heading="Your Daily Dose of Online Workouts Is Live"
        para="The Best Online Training Platform"
        imageUri="/images/iphone.webp"
        appstore="/images/app-store.png"
        googleplay="/images/google-play.png"
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
        alt="iphone - Nutrition Coaching Dublin"
      />
      {/*  */}

      <VStack
        backgroundColor="black"
        padding={{ sm: "20px 0px 50px", md: "66px 0px 96px" }}
        borderBottom="1px solid #232323"
      >
        <Container
          display="flex"
          maxW="container.xl"
          flexDirection={{ base: "column", lg: "row" }}
          alignItems="top"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
        >
          <Box
            w={{ base: "100%", lg: "50%" }}
            textAlign={{ base: "center", lg: "left" }}
            marginBottom={{ base: "50px", lg: "0" }}
          >
            <Heading
              title="LOOKING FOR MORE INFORMATION"
              variant="extralarge"
              lineHeight="1"
              margin="0px 0px 40px"
              color="white"
            ></Heading>
            <Heading
              title="Chat with a member of the team "
              variant="smallmid"
              lineHeight="1"
              margin="0px 0px 30px"
              color="white"
            ></Heading>
            <Link
              href="/contact-us"
              textDecoration="none"
              display="inline-block"
              _hover={{ textDecoration: "none" }}
            >
              <LinkButton
                title="Enquire Now"
                Imguri="/images/red_arrow.svg"
                border="2px solid"
                borderColor="Red"
                padding="20px 25px"
                color="White"
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ base: "100%", lg: "50%" }}>
            <ContactForm pageName="Nutrition"></ContactForm>
          </Box>
        </Container>
      </VStack>
    </>
  );
}

export default Nutrition;
