import React from "react";
import {
  Box,
  GridItem,
  Container,
  SimpleGrid,
  Flex,
  Text as PText,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Center,
} from "@chakra-ui/react";
import LandingBanner from "./LandingPage/LandingBanner/LandingBanner";
import ClassCard from "./Classcard/ClassCard";
import MetaTitle from "./MetaTitle";
import Heading from "./Heading/Heading";
import { NavLink } from "react-router-dom";
import Text from "./SubHeading/SubHeading";
import BlogSection from "./Blog/BlogSection";
import Button from "./Button/CustomeButton";

const banners = [
  {
    heading1: "",
    heading2: "Fitness Classes Dublin",
    heading3: "Perpetua Fitness | Gym, Fitness Classes & CrossFit in Dublin",
    description:
      "Discover your ultimate destination at Perpetua Fitness Dublin. With state-of-the-art facilities and a passionate team of trainers, we're here to support your fitness journey.",
    Link1: "/timetable",
    Link2: "/contact-us",
    modalButtontitle: "View Gym",
  },
];

function NewStudios() {
  const metaTitle = "Fitness Classes Dublin | Perpetua Fitness";
  const metaDescription =
    "Explore the best fitness classes in Dublin at Perpetua Fitness. From HIIT to Yoga, discover classes that elevate your fitness journey.";
  return (
    <Box backgroundColor="#000">
      <MetaTitle title={metaTitle} content={metaDescription} />
      <LandingBanner banners={banners} />

      <Container maxW="container.xl">
        <Heading
          title="Our Classes"
          as="h2"
          variant="extralarge"
          textAlign="center"
          color="#fff"
          padding="50px 0 0"
        />
        <SimpleGrid
          columns={{ sm: "1", md: "2", xl: "3" }}
          columnGap={5}
          rowGap={5}
          width="full"
          padding={{
            sm: "30px 20px 30px",
            md: "50px 0 50px",
            xl: "80px 0 40px",
          }}
        >
          <GridItem
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.7s"
          >
            <ClassCard
              title="Tread & Shred Classes"
              subtitle="Our signature Tread & Shred class is a group fitness experience that is unmatched in Ireland. It is
                            tailored towards complete beginners as well as professional athletes.
                            You’ll alternate between treadmill and floor exercises focused on strength, power, and endurance.
                            You’ll burn calories and get stronger and faster every class.
                            Each class is different with a mix between upper, lower and full body days so you never get bored.
                            If you’re a newbie to Tread & Shred or need a little refresher, watch this short video so you know
                            what to expect ahead of your first class."
              bg="url('/images/TreadAndShread/WarmUp.jpg')"
              isButton={false}
              marginBottom="30px"
              transform="uppercase"
              minHeight={{
                sm: "500px",
                md: "450px",
                xl: "500px",
                xxl: "600px",
              }}
              linkBg="/treadandshred"
            />
          </GridItem>
          <GridItem
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.9s"
          >
            <ClassCard
              title="Rythm Ride Classes"
              subtitle="MORE THAN A SPIN CLASS. AN EXPERIENCE. FULLY EQUIPPED WITH KEISER M3I BIKES AND THE BEST
                            LIGHTING AND SOUND SYSTEM IN IRELAND. OUR RIDE45, AND RHYTHM RIDE CLASSES OFFER
                            SOMETHING FOR EVERYONE."
              bg="url('/images/RhythmRide/Beat.jpg')"
              isButton={false}
              margin={{
                sm: "30px 0 0",
                md: "60px 0px 0px",
                xl: "60px 0px 0px",
              }}
              marginBottom="30px"
              transform="uppercase"
              minHeight={{
                sm: "500px",
                md: "450px",
                xl: "500px",
                xxl: "600px",
              }}
              linkBg="/rhythmride"
            />
          </GridItem>
          <GridItem
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.9s"
          >
            <ClassCard
              title="Ride45 Classes"
              subtitle="RIDE45 is for everyone, the class scales based on the gears you select,so you're in
                            total control of the difficuly. The useof the Keiser Group app shows the
                            participants how far they have travelled and incorporates some group
                            challenges,so if you're a competitive or data-driven person, this is could be further
                            to your liking. You also have the option of staying off the screen, so don't let that
                            beter you."
              bg="url('/images/Ride45/Metric.jpg')"
              isButton={false}
              margin={{
                sm: "30px 0px 0px",
                md: "0px",
                lg: "0",
                xl: "120px 0px 0px",
              }}
              marginBottom="30px"
              transform="uppercase"
              minHeight={{
                sm: "500px",
                md: "450px",
                xl: "500px",
                xxl: "600px",
              }}
              linkBg="/ride45"
            />
          </GridItem>
          <GridItem
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.7s"
          >
            <ClassCard
              title="Rumble Boxing"
              subtitle="We’ve ripped up the boxing rulebook to bring you a fast-paced workout that improves your strength,
                            endurance and power all while working up a sweat to some big beats.
                            Rumble is a group fitness experience where you’ll alternate between the treadmill and boxing so
                            lace up your trainers and get your gloves on!
                            Get ready to learn some legendary combinations mixed in with running intervals.
                            If you’re a newbie to Rumble or need a little refresher, watch this short video so you know what to
                            expect ahead of your first class."
              bg="url('/images/Rumble/Cooldown.jpg')"
              isButton={false}
              margin={{ sm: "30px 0px 0px", md: "60px 0 0", xl: "0" }}
              marginBottom="30px"
              transform="uppercase"
              minHeight={{
                sm: "500px",
                md: "450px",
                xl: "500px",
                xxl: "600px",
              }}
              linkBg="/rumble-boxing"
            />
          </GridItem>
          <GridItem
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.9s"
          >
            <ClassCard
              title="Rumble - 12 Rounds"
              subtitle="OUR FIRST CLASS CONCEPT THAT FOCUSES SOLELY ON IMPROVING YOUR BOXING SKILLS – OUR 12
                            ROUND CLASS IS A GROUP FITNESS EXPERIENCE WHERE YOU WILL GO THROUGH 12 ROUNDS OF
                            SKILLS DRILLS ON OUR AQUA BAGS WITH THE GOAL OF BECOMING A BETTER BOXER."
              bg="url('/images/Rumble/BagWork.jpg')"
              isButton={false}
              margin={{ xl: "60px 0px 0px", md: "0px", sm: "30px 0px 0px" }}
              marginBottom="30px"
              transform="uppercase"
              minHeight={{
                sm: "500px",
                md: "450px",
                xl: "500px",
                xxl: "600px",
              }}
              linkBg="/rumble-12-rounds"
            />
          </GridItem>
        </SimpleGrid>
      </Container>
      <Box
        padding={{ sm: "20px 0 0", md: "20px 0px 20px" }}
        backgroundColor="black"
      >
        <Container maxW="container.xl">
          <Heading
            variant="extralarge"
            as="h2"
            title="First Timers"
            margin={{ sm: "0px auto 20px", md: "0px auto 30px" }}
            width={{ base: "90%", md: "60%" }}
            textAlign="center"
            lineHeight="1"
            color="white"
          />
          <Text
            title="Joining Perpetua Fitness is your first step towards a new and exciting fitness journey in Dublin. We make it easy for newcomers to get started and feel right at home from day one. Here’s how you can jump into the action:"
            color="white"
            textAlign="center"
            padding="0 0 20px"
          />
          <SimpleGrid
            columns={{ sm: "1", md: "3" }}
            spacing={5}
            textAlign="center"
            justifyContent="center"
          >
            <GridItem>
              <Heading title="01" color="Red" textAlign="center" />
              <Heading
                title="Choose Your Class"
                color="white"
                variant="small"
                padding="15px 20px"
              />
              <Flex flexWrap="wrap">
                <NavLink to="/treadandshred">
                  <PText
                    _hover={{ color: "Red" }}
                    color="white"
                    padding="0 5px"
                    fontFamily="Poppins"
                  >
                    Tread & Shred,
                  </PText>
                </NavLink>
                <NavLink to="/rumble-boxing">
                  <PText
                    _hover={{ color: "Red" }}
                    color="white"
                    padding="0 5px"
                    fontFamily="Poppins"
                  >
                    Rumble Boxing
                  </PText>
                </NavLink>
                <Text title=" or " color="white" padding="0 5px" />
                <NavLink to="/rhythmride">
                  <PText
                    _hover={{ color: "Red" }}
                    color="white"
                    padding="0 5px"
                    fontFamily="Poppins"
                  >
                    Rhythm Ride Classes
                  </PText>
                </NavLink>
              </Flex>
            </GridItem>
            <GridItem>
              <Heading title="02" color="Red" textAlign="center" />
              <Heading
                title="Register"
                color="white"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="Sign up for your chosen class easily online or visit us at our studio. Our team is here to help you with the process and answer any questions you might have."
                padding="15px 20px"
                color="white"
              />
            </GridItem>
            <GridItem>
              <Heading title="03" color="Red" textAlign="center" />
              <Heading
                title="Get Ready"
                color="white"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="All you need is comfortable workout gear and a water bottle. We'll take care of the rest, from providing top-notch equipment to creating an uplifting workout environment."
                color="white"
                padding="15px 20px"
              />
            </GridItem>
          </SimpleGrid>
          <Center padding="30px 0 0">
            <NavLink to="/first-timers">
              <Button title="first timers" color="#fff" />
            </NavLink>
          </Center>
        </Container>
      </Box>
      <Box
        padding={{ sm: "50px 20px 30px", md: "50px 0 50px", lg: "80px 0" }}
        backgroundColor="black"
      >
        <Container maxW="container.xl">
          <SimpleGrid
            columns={{ sm: "1", md: "2" }}
            columnGap={5}
            rowGap={5}
            width="full"
          >
            <GridItem>
              <Heading
                variant="extralarge"
                as="h2"
                title="Studio Timetable"
                margin={{ sm: "0px auto 20px", md: "0px auto 30px" }}
                width={{ base: "90%" }}
                textAlign="center"
                lineHeight="1"
                color="white"
              />
              <Text
                title="Check out our studio timetable for the latest class schedules. Plan your workouts with ease and never miss a beat at Perpetua Fitness."
                color="white"
                textAlign="center"
              />
              <Center padding="30px 0 40px">
                <NavLink to="/timetable">
                  <Button title="Studio Timetable" color="#fff" />
                </NavLink>
              </Center>
            </GridItem>
            <GridItem
              borderLeft={{ md: "1px solid #282828" }}
              paddingLeft={{ md: "30px" }}
            >
              <Heading
                variant="extralarge"
                as="h2"
                title="Book now"
                margin={{ sm: "0px auto 20px", md: "0px auto 30px" }}
                width={{ base: "90%", md: "60%" }}
                textAlign="center"
                lineHeight="1"
                color="white"
              />
              <Text
                title="Ready to dive into your next workout? Book your class now and take the first step towards a fitter, healthier you. Looking to become a member, join here today."
                color="white"
                textAlign="center"
              />
              <Center padding="30px 0 40px">
                <NavLink to="/timetable">
                  <Button title="Book now" color="#fff" />
                </NavLink>
              </Center>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>
      <Flex
        p={{ sm: "50px 10px", md: "80px 0px", xl: "100px 0" }}
        backgroundColor="#fff"
      >
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.lg",
            lg: "container.lg",
            xl: "container.xl",
          }}
        >
          <Stack direction={{ sm: "column", md: "row" }} spacing="24px">
            <Box width={{ sm: "100%", md: "50%" }}>
              <Box
                paddingLeft="30px"
                position={"relative"}
                _after={{
                  position: "absolute",
                  content: '""',
                  width: "3px",
                  height: "100%",
                  top: "0",
                  left: "0",
                  background: "Red",
                }}
              >
                <Heading
                  title="FREQUENTLY ASKED QUESTIONS"
                  as="h2"
                  lineHeight="1"
                  padding="0px 0px 30px"
                  margin="0px auto"
                ></Heading>
                <Text
                  title="Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us."
                  width="80%"
                ></Text>
              </Box>
            </Box>
            <Box width={{ sm: "100%", md: "50%" }}>
              <Accordion
                defaultIndex={[0]}
                allowMultiple
                className="Accor_item _black_acording  white_acording"
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="What Should I Bring to My First Class?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="For your first class, we recommend bringing comfortable workout clothing, athletic shoes, a water bottle, and a towel. If you’re attending a specialty class, like Rumble Boxing, we provide all the necessary equipment, such as gloves. Please read up on each class before arriving."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Do I Need Previous Fitness Experience to Join a Class?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="No, you don’t need any prior fitness experience to join our classes. We offer a range of classes suitable for all fitness levels, from beginners to advanced athletes. Our coaches are here to guide you through each session, ensuring you perform exercises safely and effectively."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="How Early Should I Arrive Before My Class Starts?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="We suggest arriving at least 15 minutes before your class starts. This gives you enough time to check in, change (if needed), and familiarize yourself with our facilities."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="How Do I Choose the Right Class for Me?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Our class descriptions and fitness advisors are here to help you select the perfect class. Consider what you enjoy doing and your fitness goals. Whether it’s increasing strength, improving cardiovascular health, or stress relief, we have a class that aligns with your needs."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Are Showers Available?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>
      <Box backgroundColor="#fff">
        <BlogSection catSlug="personal-training" />
      </Box>
    </Box>
  );
}

export default NewStudios;
