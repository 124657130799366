import { Flex, Button, Box, Link } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const FilterWrapper = styled(Flex)(props => ({
    flexDirection: 'column',
    padding: '90px 0',
    "@media screen and (max-width:767px)": {
        padding:'20px 20px',
    }
}))

export const ButtonWrapper = styled(Flex)( props => ({
flexDirection:'row',
gap:'15px',
flexWrap:' wrap',
margin:'0px auto',
justifyContent:'center',
padding:'40px 13%',

"@media screen and (max-width:1800px)": {
    padding:'40px 8%',
  },

"@media screen and (max-width:1680px)": {
    padding:'40px 1%',
  }, 
  "@media screen and (max-width:767px)": {
    gap:'0px',
    padding:'0px',
    justifyContent:'flex-start',
    background: '#ffffff',
    boxShadow:' 0px 0px 61px -29px rgba(0,0,0,0.40)',
  },
"@media screen and (max-width:480px)": {
    padding:"0px 10px"
},
  
}))

export const FilterButon = styled(Button)(props => ({

    background: 'transparent',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontFamily: 'BlenderProBold',
    color: 'black',
    textTransform: ' uppercase',
    border: '2px solid #D12C41',
    borderRadius: '50px',
    height: '50px',
    minWidth: '115px',
    ":hover&": {
        background: '#D12C41',
        color: 'white',
    },

    "@media screen and (max-width:767px)": {
        width:'100%',
        borderRadius:'0px',
        border:'0px',
        borderBottom:'1px solid #e9e9e9',
        padding:'0px 30px',
        height:'50px',
        justifyContent:'flex-start',
        fontSize:'20px',
    }, 

    "@media screen and (max-width:480px)": {
        fontSize: '16px',
        padding: '0px 10px',
        minWidth: '100px',
    }

}))

export const TeamWrapper = styled(Flex)(props => ({
    flexDirection: 'row',
    gap: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '40px 10%',
    flexWrap: ' wrap',

    "@media screen and (max-width:1800px)": {
        padding: '40px 8%',
    },

    "@media screen and (max-width:1680px)": {
        padding: '40px 1%',
    },

    "@media screen and (max-width:1440px)": {
        gap: '15px',
    },
}))


export const TeamCardWrapper = styled(Flex)(props => ({
    flexDirection: 'column',
    transition: 'all 0.2s ease-in-out',
    position: 'relative',
    ":after": {
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        backgroundColor: '#d12c418c',
        content: '""',
        zIndex: '0',
        opacity: '0',
        transition: 'all 0.2s ease-in-out',
    },
    ":hover": {
        ":after": {
            opacity: '1',
            transition: 'all 0.2s ease-in-out',
        },
        ".LinkWraper": {
            visibility: 'visible',
            transition: 'all 0.2s ease-in-out',
            zIndex: '1'
        },

        ".TeamCardContent": {
            marginBottom: '60px',
            transition: 'all 0.2s ease-in-out',
            position: 'relative',
            zIndex: '1',
        }

    },
}))


export const TeamCard = styled(Link)(props => ({
    width: '329px',
    height: '380px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'end',
    transition: 'all 0.2s ease-in-out',

    "@media screen and (max-width:1440px)": {
        width: '300px',
    },

    "@media screen and (max-width:1280px)": {
        width: '329px',
    },

    "@media screen and (max-width:1199px)": {
        width: '300px',
    },

    "@media screen and (max-width:991px)": {
        width: '350px',
    },

    "@media screen and (max-width:767px)": {
        width: '300px',
    },

    "@media screen and (max-width:640px)": {
        width: '220px',
    },
    "@media screen and (max-width:480px)": {
        width: '350px',
    },
    "@media screen and (max-width:400px)": {
        width: '300px',
    },
}))

export const TeamCardContent = styled(Box)(props => ({
    textDecoration: 'none',
    padding: '20px',
    width: '100%',
    marginBottom: '0px',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out',
    "p": {
        display: 'inline-block',
        padding: '3px 5px'
    }
}))

export const LinkWraper = styled(Box)(props => ({
    position: 'absolute',
    bottom: '0px',
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    padding: '10px 0px',
    background: '#000',
    visibility: 'hidden',
    ":hover": {
        background: '#D12C41',
        transition: 'all 0.2s ease-in-out',
    },
    "button": {
        ":hover": {
            color: 'white',
        }
    }
}))

export const SmallWraper = styled(Box)( props => ({
    padding: '0px 13%',

    ".-small-dropdown-button":{
        background:' #000',color:' #fff',padding:'10px 15px',fontSize:'16px',borderRadius:'0',
        ":hover":{
            background:'#D12C41',
        },
        "span":{
            color:'#fff',
            fontSize:'20px'
        }
},

    "@media screen and (max-width:767px)": {
       padding:'0px',

       ".-small-dropdown-button":{
        height:'50px'
    }
    },
}))
