import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Career from "../component/Career";


const CareerPage = () => {
  return (
    <MainWrapper>
      <Career />
    </MainWrapper>

  );
};

export default CareerPage;
