import { Flex, Box, Text, Image, Button } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const image = styled(Image)((props) => ({
  width: "45px",
  height: "45px",
  borderRadius: "22px",
  "@media screen and (max-width:1367px)": {
    width: "35px",
    height: "35px",
  },
}));

export const peopleTitle = styled(Text)((props) => ({
  fontSize: "12px",
  color: "#fff",
  fontFamily: "Poppins",
  fontWeight: "600",
  "@media screen and (max-width:1367px)": {
    fontSize: "10px",
  },
}));
export const ConnectBtn = styled(Button)((props) => ({
  //padding: "12px 2px",
  width: "100px",
  borderRadius: "8px",
  backgroundColor: "#D12C41",
  color: "#fff",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontWeight: "500",
  marginLeft: "30px",
  ":hover": {
    color: "#D12C41",
  },
  "@media screen and (max-width:1367px)": {
    width: "70px",
    fontSize: "10px",
  },
}));
export const UnFollowBtn = styled(Button)((props) => ({
  //padding: "12px 2px",
  width: "100px",
  borderRadius: "8px",
  backgroundColor: "grey.200",
  color: "#D12C41",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontWeight: "500",
  marginLeft: "30px",
  ":hover": {
    color: "#fff",
    backgroundColor: "#D12C41",
  },
  "@media screen and (max-width:1367px)": {
    width: "70px",
    fontSize: "10px",
  },
}));
export const following = styled(Text)((props) => ({
  color: "#B9B9B9",
  fontSize: "10px",
  fontFamily: "Poppins",
  fontWeight: "300",
  "@media screen and (max-width:1367px)": {
    fontSize: "9px",
  },
}));

export const connectWrap = styled(Flex)((props) => ({
  alignItems: "center",
  padding: "0",
  marginTop: "20px",
  marginBottom: "20px",
  justifyContent: "space-between",
  [`@media ${device.tablet}`]: {
    justifyContent: "space-between",
  },
}));
