import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import CrossFit from "../component/CrossFit";


const CrossFitPage = () => {
  return (
    <MainWrapper>
      <CrossFit />
    </MainWrapper>

  );
};

export default CrossFitPage;
