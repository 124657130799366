import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Cancellation from "../component/Cancellation";


const CancellationPage = () => {
  return (
    <MainWrapper>
      <Cancellation />
    </MainWrapper>

  );
};

export default CancellationPage;
