import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AuthUser() {
  const navigate = useNavigate();

  const getToken = () => {
    const userToken = localStorage.getItem("forum_token");
    return userToken;
  };
  const getUser = () => {
    const userString = localStorage.getItem("forum_user");
    const user_detail = userString ? JSON?.parse(userString) : null;
    return user_detail;
  };
  const getUserImage = () => {
    const userImage = localStorage.getItem("user_image");
    return userImage;
  };
  const getUserId = () => {
    const user_Id:any = localStorage.getItem("forum_user_id");
    return user_Id;
  };
  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());
  const [userImage, setUserImage] = useState(getUserImage());
  const [userId, setUserId] = useState<number>(getUserId());
  const saveToken = (token) => {
    localStorage.setItem("forum_token", token);
    setToken(token);
  };
  const saveImage = (image) => {
    localStorage.setItem("user_image", image);
    console.log(image);
    setUserImage(image);
  };
  const saveUser = (user) => {
    localStorage.setItem("forum_user", JSON.stringify(user));
    localStorage.setItem("forum_user_id", user?.id);
    setUser(user);
    setUserId(user?.id);
  };
  const logout = () => {
    saveUser("");
    localStorage.clear();
    navigate("/");
  };
  const api = axios.create({
    baseURL: process.env.REACT_APP_FORUM_API_BASE_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const apiFormData = axios.create({
    baseURL: process.env.REACT_APP_FORUM_API_BASE_URL,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  const Encrypt = (data) => {
    let encoded = btoa("userId=" + data);
    return encoded;
  };
  const Decrypt = (data) => {
    if(data){
      try {
        let decoded = atob(data);
        return decoded.slice(7);
      } catch (error) {
        navigate("/forum/profile/not/found");
      }
    }
   
  };

  return {
    setToken: saveToken,
    setUser: saveUser,
    setImage: saveImage,
    userImage,
    token,
    user,
    userId,
    getToken,
    api,
    apiFormData,
    logout,
    Encrypt,
    Decrypt,
  };
}
