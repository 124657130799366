import {
  Flex,
  Box,
  Container,
  InputGroup,
  SimpleGrid,
  Spinner,
  Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Heading from "./Heading/Heading";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
/* import Input from '../component/Input Type/InputType'; */
import { SearchIcon } from "@chakra-ui/icons";
import LinkButton from "./Button/LinkButton";
import * as Style from "./Style/Blog.style";
import BlogCard from "./Blog/BlogCard";
import axios from "axios";
import moment from "moment";
import Text from "./SubHeading/SubHeading";
import MetaTitle from "./MetaTitle";

function BlogListing() {
  const [catList, setCatList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { category_id } = useParams();
  const { coach_slug } = useParams();
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const [catMetaTitle, setCatMetaTitle] = useState("Blog | Perpetua Fitness");
  const [catMetaDesc, setCatMetaDesc] = useState("");

  const queryStr = window.location.search;
  const params = new URLSearchParams(queryStr);

  const location = useLocation();
  const splitLocation = location.pathname.split("/");

  const page_no = params.get("page");
  var pageNumber = 1;
  if (page_no !== null) {
    pageNumber = page_no;
  }

  const searchBy = params.get("by");

  const handleBlog = () => {
    var blogListUrl = "";
    if (pageNumber == 1) {
      blogListUrl = "/blog";
    } else {
      blogListUrl = "/blog?page=" + pageNumber;
    }
    window.history.pushState({}, undefined, blogListUrl);

    setIsLoading(true);
    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/all",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        page: pageNumber,
      },
    })
      .then((response) => {
        console.log(response.data);
        setBlogList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/categories",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        setCatList(response.data.categories);
      })
      .catch((error) => {
        console.log(error);
      });

    if (splitLocation.includes("category")) {
      blogsByCat(category_id);
    } else if (splitLocation.includes("by-coach")) {
      blogByCoach(coach_slug);
    } else if (splitLocation.includes("search")) {
      reloadSearch();
    } else {
      handleBlog();
    }
  }, []);

  const blogsByCat = (category_id) => {
    var blogListCatUrl = "";
    if (pageNumber == 1) {
      blogListCatUrl = "/blog/category/" + category_id;
    } else {
      blogListCatUrl = "/blog/category/" + category_id + "?page=" + pageNumber;
    }
    window.history.pushState({}, undefined, blogListCatUrl);

    setIsLoading(true);

    axios({
      method: "get",
      url:
        process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/categories/single",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        slug: category_id,
      },
    })
      .then((response) => {
        console.log(response.data.data);
        setCatMetaTitle(response.data.data.meta_title);
        setCatMetaDesc(response.data.data.meta_description);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/filter",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        page: pageNumber,
        category_by_id: category_id,
      },
    })
      .then((response) => {
        console.log("====================================");
        console.log("kkkkkkkkkkkkkkkkk");
        console.log("====================================");
        console.log(response.data);
        setBlogList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const blogByCoach = (coach_slug) => {
    var bloglistby_coach = "";
    if (pageNumber === 1) {
      bloglistby_coach = "/blog/by-coach/" + coach_slug;
    } else {
      bloglistby_coach = "/blog/by-coach/" + coach_slug + "?page=" + pageNumber;
    }
    window.history.pushState({}, undefined, bloglistby_coach);

    setIsLoading(true);

    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/filter",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        page: pageNumber,
        coach_slug: coach_slug,
      },
    })
      .then((response) => {
        console.log(response.data);
        setBlogList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const allBlogs = () => {
    navigate("/blog");
    window.location.reload(false);
  };

  const handleClick = (category_id) => () => {
    navigate("/blog/category/" + category_id);
    window.location.reload(false);
  };

  const handlePage = (number) => () => {
    if (splitLocation.includes("category")) {
      console.log("category");

      var blogListCatUrl = "";
      if (number === 1) {
        blogListCatUrl = "/blog/category/" + category_id;
      } else {
        blogListCatUrl = "/blog/category/" + category_id + "?page=" + number;
      }
      window.history.pushState({}, undefined, blogListCatUrl);

      setIsLoading(true);
      axios({
        method: "get",
        url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/filter",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: {
          page: number,
          category_by_id: category_id,
        },
      })
        .then((response) => {
          console.log(response.data);
          setBlogList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else if (splitLocation.includes("by-coach")) {
      var bloglistby_coach = "";
      if (number == 1) {
        bloglistby_coach = "/blog/by-coach/" + coach_slug;
      } else {
        bloglistby_coach = "/blog/by-coach/" + coach_slug + "?page=" + number;
      }

      window.history.pushState({}, undefined, bloglistby_coach);

      setIsLoading(true);
      axios({
        method: "get",
        url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/filter",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: {
          page: number,
          coach_slug: coach_slug,
        },
      })
        .then((response) => {
          console.log(response.data);
          setBlogList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else if (splitLocation.includes("search")) {
      var blogSearchUrl = "";
      if (number == 1) {
        blogSearchUrl = "/blog/search?by=" + searchBy;
      } else {
        blogSearchUrl = "/blog/search?by=" + searchBy + "&page=" + number;
      }
      window.history.pushState({}, undefined, blogSearchUrl);

      setIsLoading(true);
      axios({
        method: "get",
        url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/filter",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: {
          page: number,
          search_by: searchBy,
        },
      })
        .then((response) => {
          console.log(response.data);
          setBlogList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      console.log("blog");
      var blogListUrl = "";
      if (number == 1) {
        blogListUrl = "/blog";
      } else {
        blogListUrl = "/blog?page=" + number;
      }
      window.history.pushState({}, undefined, blogListUrl);

      setIsLoading(true);
      axios({
        method: "get",
        url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/all",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: {
          page: number,
        },
      })
        .then((response) => {
          console.log(response.data);
          setBlogList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const reloadSearch = () => {
    var blogSearchUrl = "";
    if (pageNumber == 1) {
      blogSearchUrl = "/blog/search?by=" + searchBy;
    } else {
      blogSearchUrl = "/blog/search?by=" + searchBy + "&page=" + pageNumber;
    }
    window.history.pushState({}, undefined, blogSearchUrl);

    setSearch(searchBy);
    setIsLoading(true);
    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/filter",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        page: pageNumber,
        search_by: searchBy,
      },
    })
      .then((response) => {
        console.log(response.data);
        setBlogList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/blog/search?by=" + search);
      window.location.reload(false);
    }
  };
  const handleSearch = (event) => {
    navigate("/blog/search?by=" + search);
    window.location.reload(false);
  };

  return (
    <>
      <MetaTitle title={catMetaTitle} content={catMetaDesc} />
      <Flex minH={{ sm: "200px", md: "none" }} background={"black"}>
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.md",
            lg: "container.lg",
            xl: "container.xl",
          }}
          padding={{ sm: "50px 15px", md: "50px 0px", lg: "100px 0px" }}
        >
          <Style.BlogSearchInfo>
            <Style.PerpetuaBlogContent>
              <Heading
                title="PERPETUA BLOG"
                lineHeight="1"
                color="white"
                variant="medium"
                cfSize={{ sm: "sm", md: "md", lg: "lg" }}
                width={{ md: "70%" }}
                margin="0px auto"
                as="h1"
              ></Heading>
            </Style.PerpetuaBlogContent>
            <Style.BlogContentSearch>
              <InputGroup>
                <Input
                  onKeyDown={handleKeyDown}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  _placeholder="search"
                  border="0"
                  color="white"
                  fontSize="16px"
                  borderBottom="1px solid #545454"
                  borderRadius="0"
                  className="searchInput"
                ></Input>

                <SearchIcon
                  onClick={handleSearch}
                  className="SearchIcon"
                  color="white"
                  position="absolute"
                  right="8px"
                  top="0"
                  bottom="0"
                  margin="auto"
                  cursor="pointer"
                  zIndex="55"
                />
              </InputGroup>
            </Style.BlogContentSearch>
          </Style.BlogSearchInfo>
        </Container>
      </Flex>

      <Flex minH={{ sm: "500px" }} position="relative">
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.md",
            lg: "container.lg",
            xl: "container.xl",
          }}
          padding={{ sm: "0px 15px 20px", md: "10px 0px", lg: "50px 0px" }}
        >
          <Style.BlogCategoryListingcontent>
            {isLoading && (
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                className="loaderPanel"
              >
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="#d12c41"
                  size="lg"
                />
              </Flex>
            )}
            {/*  :  */}
            <>
              <Style.BlogCategorySearchInfo>
                <Style.BlogCategorySearchBy>
                  <Heading
                    title="SEARCH BY"
                    lineHeight="1"
                    variant="extrasmall"
                    cfSize={{ sm: "xs", md: "sm2" }}
                    margin="0px auto"
                  ></Heading>
                  <Heading
                    title="CATEGORY"
                    lineHeight="1"
                    variant="medium"
                    cfSize={{ sm: "sm", md: "md", lg: "lg" }}
                    margin="0px auto"
                  ></Heading>
                </Style.BlogCategorySearchBy>

                <Box>
                  <Style.BlogCategoryUnorderedList>
                    <Style.BlogCategoryListItems>
                      <Style.BlogCategoryLink
                        className="catList"
                        onClick={allBlogs}
                      >
                        <Style.BlogCategoryIcon />
                        <Text
                          title="All"
                          lineHeight="1"
                          variant="extrasmall"
                          margin="0px auto"
                        ></Text>
                      </Style.BlogCategoryLink>
                    </Style.BlogCategoryListItems>

                    {catList.map((item, index) => {
                      return (
                        <Style.BlogCategoryListItems key={index}>
                          <Style.BlogCategoryLink
                            className="catList"
                            onClick={handleClick(item.slug)}
                          >
                            <Style.BlogCategoryIcon />
                            <Text
                              title={item.title}
                              lineHeight="1"
                              variant="extrasmall"
                              margin="0px auto"
                            ></Text>
                          </Style.BlogCategoryLink>
                        </Style.BlogCategoryListItems>
                      );
                    })}
                  </Style.BlogCategoryUnorderedList>
                </Box>
              </Style.BlogCategorySearchInfo>
              <Box
                textAlign="Left"
                width={{ sm: "100%", md: "100%", lg: "75%" }}
              >
                {blogList.status === true ? (
                  <>
                    <SimpleGrid
                      columns={{ sm: "1", md: "2", xl: "3" }}
                      spacing={6}
                    >
                      {blogList.data.data.map((item, index) => {
                        return (
                          <BlogCard
                            key={index}
                            uri={
                              item.image_path != "null"
                                ? item.image_path
                                : "url('/images/no-image.jpg')"
                            }
                            alt={item.image_tag}
                            height="260px"
                            date={moment(item.created_at).format(
                              "MMMM Do YYYY"
                            )}
                            title={item.title}
                            tag={item.category.title}
                            link={"/blog/" + item.slug}
                            taglink={"/blog/category/" + item.category.slug}
                          />
                        );
                      })}
                    </SimpleGrid>
                    <Flex
                      paddingTop="30px"
                      textAlign="center"
                      justifyContent="space-between"
                    >
                      {blogList.data.prev_page_url != null && (
                        <LinkButton
                          onClick={handlePage(blogList.data.current_page - 1)}
                          title="Previous"
                          flexDirection="row-reverse"
                          /* Imguri='/images/small-arrow.svg' */ padding="0px"
                        />
                      )}
                      {blogList.data.next_page_url != null && (
                        <LinkButton
                          onClick={handlePage(blogList.data.current_page + 1)}
                          title="Next"
                          /* Imguri='/images/small-arrow.svg' */ padding="0px"
                        />
                      )}
                    </Flex>
                  </>
                ) : (
                  <Heading
                    title="No data found"
                    lineHeight="1"
                    variant="extrasmall"
                    cfSize={{ sm: "xs", md: "sm2" }}
                    margin="0px auto"
                  ></Heading>
                )}
              </Box>
            </>
          </Style.BlogCategoryListingcontent>
        </Container>
      </Flex>
    </>
  );
}

export default BlogListing;
