import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Corporate from "../component/Corporate";


const CorporatePage = () => {
  return (
    <MainWrapper>
      <Corporate />
    </MainWrapper>

  );
};

export default CorporatePage;
