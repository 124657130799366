import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import LennoxstreetLocation from "../component/LennoxstreetLocation";


const LennoxstreetLocationPage = () => {
  return (
    <MainWrapper>
      <LennoxstreetLocation />
    </MainWrapper>

  );
};

export default LennoxstreetLocationPage;
