import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import ForumRegister from "../../component/Forum/ForumRegister";



const ForumRegisterPage = () => {
  return (

    <MainWrapper>
      <ForumRegister />
    </MainWrapper>

  );
};

export default ForumRegisterPage;