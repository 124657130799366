import { Button, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react'
const DeleteModal = ({ setShowDelete, showDelete, title, deleteFunction }) => {
    return (
        <Modal
            isCentered
            onClose={() => setShowDelete(false)}
            isOpen={showDelete}
            motionPreset="slideInBottom"
        >
            <ModalOverlay />
            <ModalContent
                backgroundColor="#282828!important"
                boxShadow="0 0 20px rgba(255,255,255,0.2)"
                paddingY="10px"
            >
                <ModalHeader
                    fontWeight="400"
                    fontSize={{ base: "20px", md: "26px" }}
                    textAlign="center"
                    color="#fff"
                >
                    {title}
                </ModalHeader>
                <ModalFooter paddingBottom="20px" justifyContent="center">
                    <Button
                        paddingX="30px"
                        colorScheme="gray"
                        fontWeight="400"
                        fontSize={{ base: "16px", md: "18px" }}
                        mr={3}
                        onClick={() => setShowDelete(false)}
                    >
                        No
                    </Button>
                    <Button
                        paddingX="30px"
                        colorScheme="red"
                        fontSize={{ base: "16px", md: "18px" }}
                        fontWeight="400"
                        // variant="ghost"
                        onClick={() => {
                            deleteFunction();
                        }}
                    >
                        Yes
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DeleteModal