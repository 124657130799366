import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import FeedCategories from "../../component/Forum/FeedCategories";



const FeedCategoriesPage = () => {
  return (

    <MainWrapper>
      <FeedCategories />
    </MainWrapper>

  );
};

export default FeedCategoriesPage;
