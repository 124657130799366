import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Page404 from "../component/Page404";


const Page404Page = () => {
  return (
    <MainWrapper>
      <Page404 />
    </MainWrapper>

  );
};

export default Page404Page;
