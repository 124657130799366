import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import StudioClassDescriptions from "../component/StudioClassDescriptions";


const StudioClassDescriptionsPage = () => {
  return (
    <MainWrapper>
      <StudioClassDescriptions />
    </MainWrapper>

  );
};

export default StudioClassDescriptionsPage;
