
import React, { useEffect, useRef } from "react";
import {Box, Container, Modal, ModalOverlay,ModalContent,ModalBody,ModalCloseButton, useDisclosure,Link } from '@chakra-ui/react';
import Button from '../Button/CustomeButton';
import Heading from '../Heading/Heading';
import Text from'../SubHeading/SubHeading';
import {defaultCaptionProps}from'../Banner/Caption.type';
import VideoCard from '../VideoCard/VideoCard';
import * as Style from './VideoBanner.style';
import LazyLoad from 'react-lazy-load';
import { NavLink } from 'react-router-dom';

function VideoBanner(props) {
  
  const videoEl = useRef(null);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

 
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };
  return (
    <>
        <Style.VideoBanner
            className={props.class}
            alignItems={"center"}
            justifyContent={"center"}
            height={{ sm:'100vh', md:'383px', lg:'495px', xl:'600px', xxl:'720px'}}
            display='flex'
            flexDirection='column'
            position='relative'
          >
            <Container maxW='container.2xl' p={{sm:'0 20px', md:"0 70px", lg:"0 130px"}} >
                 
                <Style.CaptionBox
                    maxW={props.maxW ? props.maxW : {sm:'100%', md:'700px'}} 
                    minWidth={{sm:'100%', md:'500px'}} 
                    paddingTop={"40px"} 
                    paddingBottom={"60px"}  
                    textAlign={props.textAlign}
                    textTransform="uppercase"
                    margin={props.align}
                    className='wow animate fadeInUp'
                    >

                    {props.isSubtext &&<Heading as='small' cfSize={{sm:'xs' , md:'sm'}} variant={props.SubtextVariant ? props.SubtextVariant : "smallmid"} m={"0"} p={"0"} color={props.color} title={props.subtitle} fontFamily='BlenderProBold' width={props.SubtextWidth}></Heading>}
                    {props.isHeading &&<Heading as='h1' cfSize={{ sm:'md' , md:'lg' , lg:'xl', xl:'xxl'}} color={props.color} title={props.title} lineHeight="1" padding="0px 0px 5px" variant="largheading" width={props.width}></Heading>}
                    {props.isSubHeading &&<Heading as='h1' color={props.color} title={props.SubHeading} lineHeight="1" padding="0px 0px 5px" variant="largheading"></Heading>}

                    {props.issubtextpara &&<Text as='strong' fontSize={{base:'xs',md:"sm"}} m={"0"} display='block'  color={props.color} title={props.subtextpara}  fontFamily='BlenderProBold'></Text>}
                    {props.isButton && <Style.ButtonGrup>
                         <NavLink to={props.Link} isExternal={props.targetBlank}>
                            <Button margin={props.margin}  title={props.buttontitle}/> 
                         </NavLink>
                         <Style.ButtonModal onClick={onOpen} color='white'>{props.modalButtontitle}</Style.ButtonModal>
                        </Style.ButtonGrup>
                    }
                    {props.isButton2 && <Box> <Link href={props.Link2} onClick={() => handleClickScroll("privateHireCorporate")} > <Button margin={props.marginTop}  title={props.buttontitle2}/> </Link></Box>}
                    
                    {props.issubtitleRight && <Heading as='p' cfSize={{sm:'xs' , md:'sm'}} variant="smallmid" m={"0"} p={"0"} color={props.color} title={props.subtitleRight} fontFamily='BlenderProBold'></Heading>}
                    <Box marginLeft={{md:'10px'}} marginTop={props.ButtonModalmarginTop}>
                        <Modal isOpen={isOpen} onClose={onClose} size={{sm:'sm', md:'3xl'}} >
                          <ModalOverlay />
                          <ModalContent marginTop='100px'>
                              {/* <ModalHeader>Modal Title</ModalHeader> */}
                              <ModalCloseButton/>
                              <ModalBody >
                                  <LazyLoad offset={300}>
                                     <VideoCard src={props.videoModal} width={{sm:'100%', md:'700px'}}   margin={{sm:'30px 3px', md:'30px 9px'}} maxHeight='540px'></VideoCard>
                                  </LazyLoad>
                              </ModalBody>
                          </ModalContent>
                        </Modal>
                    </Box>

                </Style.CaptionBox>
                
            </Container>
            <Style.VideoBox>
                <video
                style={{ maxWidth: "100%",width: "100%", margin: "0 auto", objectFit:'cover',objectPosition:'center top',flex: '1 1 0%', }}
                playsInline
                loop
                muted
                controls={false}
                alt="All the devices"
                src={props.videobanner}
                ref={videoEl}
                />
            </Style.VideoBox>
        </Style.VideoBanner>
    </>
  )
}
export default  VideoBanner;
VideoBanner.defaultProps = defaultCaptionProps
