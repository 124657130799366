import React from "react";
import { Box, Container } from "@chakra-ui/react";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import CancellationForm from "./Form/CancellationForm";
import MetaTitle from "./MetaTitle";

function Cancellation() {
  return (
    <>
      <MetaTitle title="Cancellation | Perpetua Fitness" />
      <Box padding={{ sm: "50px 15px", md: "50px 15px" }}>
        <Container maxW="container.xl">
          <Box>
            <Text title="We’re sorry you’re taking a break!" />
            <Text
              title="Please fill in the form below to cancel your contract. As you already know, we require 60 days notice to cancel a contract early, any payments due from the date this form is completed will still be processed."
              padding="5px 0"
            />
            <Text
              title="As a gym, we hope you’ll keep exercising so I’m going to going to send you ten workouts in a follow-up email for you to try at home."
              padding="5px 0"
            />
            <Text title="Hope to see you again very soon!" padding="5px 0" />
          </Box>
          <Box paddingTop="30px">
            <Heading
              title="Please cancel my contract:-"
              color="#d12c41"
              variant="small"
              padding="10px 0"
            />
            <Heading
              title="Please fill in the below:"
              variant="smallmid"
              padding="10px"
            />
            <CancellationForm />
          </Box>
        </Container>
      </Box>
    </>
  );
}
export default Cancellation;
