
import React, {lazy, Suspense, useState} from 'react';
import {
    FormControl,
    Stack,
    Input,
    Select,
    Textarea,
    Flex,
    Box
} from '@chakra-ui/react'

/*  import Input from '../Input Type/InputType'  */
import Button from '../Button/CustomeButton';
/* import Textarea from '../Textarea/Textarea'; */
import axios from 'axios';
import Text from '../SubHeading/SubHeading';
import Heading from '../Heading/Heading';
const ReCAPTCHA = lazy(()=>import('react-google-recaptcha')) ;

function JointodayForm(props) {

    const [successFrom, setsuccessFrom] = useState('');
    const [nameError, setnameError] = useState('');
    const [phoneError, setphoneError] = useState('');
    const [emailError, setemailError] = useState('');
    const [subjectError, setsubjectError] = useState('');
    const [messageError, setmessageError] = useState('');
    const [btnDisable, setBtnDisable] = useState(false);

    const [isCaptcha, setIsCaptcha] = useState(null);
    const [errorCaptcha, setErrorCaptcha] = useState('');

    const getReCaptchaVal = (value) => {
        setIsCaptcha(value);
        setErrorCaptcha('');
    }

    const [contactForm, setContactForm] = useState({
        name: "",
        email: "",
        phone: "",
        option: "Select option",
        message: "",

    });
    const handelInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setContactForm({ ...contactForm, [name]: value })
    }
    const handelSubmit = (e) => {
        e.preventDefault();
        setsuccessFrom('');
        setBtnDisable(true);

        if (isCaptcha === null) {
            setBtnDisable(false);
            setsuccessFrom('');
            setErrorCaptcha('Please verify that you are not a robot.');
            return false;
        }

        axios.post(process.env.REACT_APP_ADMIN_API_BASE_URL + '/inner-page-contact', {
            name: contactForm.name,
            email: contactForm.email,
            subject: contactForm.option,
            phone_no: contactForm.phone,
            message: contactForm.message,
            page_name: contactForm.option
        })
            .then((response) => {
                setBtnDisable(false);
                console.log(response.data.success);
                setsuccessFrom(response.data.success);
                window.grecaptcha.reset();
                contactForm.name = "";
                contactForm.email = "";
                contactForm.message = "";
                contactForm.phone = "";
            })
            .catch((error) => {
                setBtnDisable(false);
                setsuccessFrom('');
                window.grecaptcha.reset();
                console.log("eeeeeeee");
                console.log(error);
                console.log(error.response.data.error.message[0]);

                if (error.response.data.error.name[0]) {
                    setnameError(error.response.data.error.name[0]);
                } else {
                    setnameError('');
                }
                if (error.response.data.error.email[0]) {
                    setemailError(error.response.data.error.email[0]);
                } else {
                    setemailError('');
                }
                if (error.response.data.error.subject[0]) {
                    setsubjectError(error.response.data.error.subject[0]);
                } else {
                    setsubjectError('');
                }
                if (error.response.data.error.subject[0]) {
                    setphoneError(error.response.data.error.subject[0]);
                } else {
                    setphoneError('');
                }
                if (error.response.data.error.message[0]) {
                    setmessageError(error.response.data.error.message[0]);
                } else {
                    setmessageError('');
                }

                console.log(nameError);

            });

    }

    return (
        <form action="" onSubmit={handelSubmit} className="innerContact">
            <FormControl isRequired width='100%' p={{ base: '30px 20px', md: '30px 20px' }} m='0px auto' border='2px solid' borderColor='Red' borderRadius='20px'>
                <Heading title='Please fill in the form below' variant='smallmid' margin='0px 0px 15px' lineHeight='0.8'color='#fff'></Heading>    
                <Stack direction={['column']} spacing='24px' position='relative'>
                    <Input placeholder="Name *" _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding:'0 !important' }} border='0' borderBottom='2px solid #252525' height='50px' borderRadius='0px' color='white' fontSize='xs' name="name" value={contactForm.name} onChange={handelInput} />

                    {/* <FormErrorMessage>Error message show here</FormErrorMessage> */}
                </Stack>
                <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                    <Input placeholder="Email *" type='email' _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding:'0 !important'  }} border='0' borderBottom='2px solid #252525' height='50px' borderRadius='0px' color='white' fontSize='xs' name="email" value={contactForm.email} onChange={handelInput} />
                    {/* <FormErrorMessage>Error message show here</FormErrorMessage> */}
                </Stack>
                <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                    <Input placeholder="PHONE NUMBER *" type='number' _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase' , padding:'0 !important' }} border='0' borderBottom='2px solid #252525' height='50px' borderRadius='0px' color='white' fontSize='xs' name="phone" value={contactForm.phone} onChange={handelInput} />
                    {/* <FormErrorMessage>Error message show here</FormErrorMessage> */}
                </Stack>
                <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                    <Select placeholder="Select option *" _placeholder={{ color: "black" , padding:'0 !important' }} borderRadius='0' height='50px' textColor='white' fontSize='16px' textTransform='uppercase' name="option" border='0' borderBottom='2px solid #252525' value={contactForm.option} onChange={handelInput} >
                        <option style={{ color: 'black' }} value='CrossFit'>CrossFit</option>
                        <option style={{ color: 'black' }} value='JustGym'>JustGym</option>
                        <option style={{ color: 'black' }} value='PersonalTraining'>Personal Training</option>
                        <option style={{ color: 'black' }} value='Hyrox'>Hyrox </option>
                        <option style={{ color: 'black' }} value='Corporate'>Corporate</option>
                    </Select>

                </Stack>

                <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                    <Textarea placeholder="GENERAL COMMENTS *" _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding:'0 !important'  }} border='0' borderBottom='2px solid #252525' height='100px' borderRadius='0px' color='white' fontSize='xs' name="message" value={contactForm.message} onChange={handelInput}></Textarea>
                    {/* <FormErrorMessage>Error message show here</FormErrorMessage> */}
                </Stack>
                <Stack direction={{ sm: 'column', xl: 'row' }} spacing='24px' position='relative' marginTop='25px' justifyContent='space-between' alignItems='start' marginBottom='20px'>
                    <Suspense fallback={"Loading.."}>
                        <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={getReCaptchaVal} className='captcha' theme='dark' />
                    </Suspense>
                    <Button btnDisable={btnDisable} submit="submit" title='Submit' width='auto'></Button>
                </Stack>
                <Text title='By submitting, you agree to share your contact information with Perpetua Fitness and receive
                promotional email from Perpetua.' color='Deepgre' lineHeight='1.2' margin='0px' marginBottom='15px'></Text>

                {errorCaptcha &&  <Text title={errorCaptcha} fontSize="14px" color="red"/>}
                <Flex justifyContent="center" margin="30px 0 0"> {successFrom && <Text title={successFrom} fontSize="16px" color="green" />}</Flex>


            </FormControl>
        </form>
    );
}

export default JointodayForm;
