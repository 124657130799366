import React from "react";
import GuestList from "./LandingPage/GuestList";

const Landingpage = () => {
  return (
    <div>
      <GuestList />
    </div>
  );
};

export default Landingpage;
