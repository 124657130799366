import Resizer from "react-image-file-resizer";
const ImageResize = (file:File,size:number,imageType:string) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      "WEBP",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      imageType
    );
  });
export default ImageResize;
