import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import ResidentialServices from "../component/ResidentialServices";


const ResidentialServicesPage = () => {
  return (
    <MainWrapper>
      <ResidentialServices />
    </MainWrapper>

  );
};

export default ResidentialServicesPage;
