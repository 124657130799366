import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from '@chakra-ui/react';
import { ListItem, UnorderedList, Box, IconButton } from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

function MenuList(props) {
  const menuLists = [
    {
      title: "LOCATIONS",
      link: "/windmill-lane",
      targetBlank: false,
      submenu: [
        {
          title: "Windmill Lane",
          link: "/windmill-lane",
          targetBlank: false,
        },
        {
          title: "Lennox Street",
          link: "/lennox-street",
          targetBlank: false,
        },

      ],
    },
    {
      title: "STUDIOS",
      link: "/fitness-classes-dublin",
      targetBlank: false,
      submenu: [
        {
          title: "Tread & Shred",
          link: "/treadandshred",
          targetBlank: false,
        },
        {
          title: "Rhythm Ride",
          link: "/rhythmride",
          targetBlank: false,
        },
        {
          title: "Ride 45",
          link: "/ride45",
          targetBlank: false,
        },
        {
          title: "RUMBLE",
          link: "/rumble-boxing",
          targetBlank: false,
        },
        {
          title: "Rumble – 12 Rounds",
          link: "/rumble-12-rounds",
          targetBlank: false,
        },
        {
          title: "First Timers",
          link: "/first-timers",
          targetBlank: false,
        },
        {
          title: "TIMETABLE",
          link: "/timetable",
          targetBlank: false,
        },
        {
          title: "PRICING",
          link: "/pricing",
          targetBlank: false,
        },
      ],
    },
    {
      title: "CROSSFIT",
      link: "/crossfit-dublin",
      targetBlank: false,
      submenu: [
        {
          title: "GET STARTED",
          link: "/crossfit-dublin",
          targetBlank: false,
        },
        {
          title: "TIMETABLE",
          link: "/timetable",
          targetBlank: false,
        },
        {
          title: "PRICING",
          link: "/pricing",
          targetBlank: false,
        },
      ],
    },
    {
      title: "JUSTGYM",
      link: "/luxury-gym-dublin-city-centre",
      targetBlank: false,
      submenu: [
        {
          title: "Get Started",
          link: "/luxury-gym-dublin-city-centre",
          targetBlank: false,
        },
        {
          title: "PRICING",
          link: "/pricing",
          targetBlank: false,
        },
      ],
    },
    {
      title: "PERSONAL TRAINING",
      link: "/personal-training-dublin",
      targetBlank: false,
      submenu: [
        {
          title: "PERSONAL TRAINING",
          link: "/personal-training-dublin",
          targetBlank: false,
        },
        {
          title: "NUTRITION COACHING",
          link: "/nutrition-coaching-dublin",
          targetBlank: false,
        },
        {
          title: "Golf",
          link: "/golf-fitness-training-dublin",
          targetBlank: false,
        },
        {
          title: "Pricing",
          link: "/pricing",
          targetBlank: false,
        },

      ],
    },
    {
      title: "LADIES",
      link: "/ladies-gym-dublin",
      targetBlank: false,
      submenu: [
        {
          title: "Ladies Group",
          link: "/ladies-gym-dublin",
          targetBlank: false,
        },
        {
          title: "PRE/POST NATAL",
          link: "/pregnancy-exercise-dublin",
          targetBlank: false,
        },
        {
          title: "PRICING",
          link: "/pricing",
          targetBlank: false,
        },
      ],
    },
    {
      title: "HYROX",
      link: "/hyrox-dublin",
      targetBlank: false,
      submenu: [
        {
          title: "GET STARTED",
          link: "/hyrox-dublin",
          targetBlank: false,
        },
        {
          title: "TIMETABLE",
          link: "/timetable",
          targetBlank: false,
        },
        {
          title: "PRICING",
          link: "/pricing",
          targetBlank: false,
        },
      ],
    },
    {
      title: "ONLINE TRAINING",
      link: "/online-gym-programming",
      targetBlank: false,
      submenu: [
        {
          title: "ONLINE PROGRAMING",
          link: "online-gym-programming",
          targetBlank: false,
        },
        {
          title: "PERPETUA TRAINING",
          link: "https://perpetuatraining.com/",
          targetBlank: true,
        },
      ],
    },
    {
      title: "MEDIA",
      // link: "https://www.youtube.com/@perpetuafitness",
      link: "/blog",
      targetBlank: false,
      submenu: [
        {
          title: "PODCAST",
          link: "/podcast",
          targetBlank: false,
        },
        {
          title: "MOVEMENT LIBRARY",
          link: "https://youtube.com/@perpetuafitness",
          targetBlank: true,
        },
        {
          title: "BLOG",
          link: "/blog",
          targetBlank: false,
        },
      ],
    },
  ];

  const [menuId, setMenuId] = useState(null);

  const handelSubmenu = (str) => () => {
    if (menuId == str) {
      setMenuId(null);
    } else {
      setMenuId(str);
    }


  };

  const location = useLocation();
  let token = localStorage.getItem("forum_token");
  return (
    <>
      <UnorderedList p="0" m="0" className="menu-body">
        {menuLists.map((item, index) => {
          return (
            <ListItem
              key={index}
              listStyleType="none"
              fontSize={{ base: "18px", md: "18px", lg: "14px", xl: "18px" }}
              display={{ md: "block", lg: "inline-block" }}
              marginBottom={{ sm: "15px", md: "0px" }}
              padding={{ sm: "10px 0px", lg: "0px 3px", xl: "0px 5px" }}
            >
              <Box className="menuDdBtn">
                {
                  item.targetBlank == true ?
                    <Link
                      target={item.targetBlank ? "_blank" : ""}

                      className="nav-link"
                      onClick={props.closeDrawer}
                      href={item.link}
                    >
                      {item.title}
                    </Link>
                    : item.link == "" ?
                      <Link
                        className="nav-link"
                        onClick={props.closeDrawer}
                        to={item.link}
                      >
                        {item.title}
                      </Link>
                      :
                      <NavLink
                        target={item.targetBlank ? "_blank" : ""}

                        className="nav-link"
                        onClick={props.closeDrawer}
                        to={item.link}
                      >
                        {item.title}
                      </NavLink>
                }

                <IconButton
                  onClick={handelSubmenu(index)}
                  className="ddIBtn"
                  aria-label="Search database"
                  icon={<ChevronDownIcon />}
                />
              </Box>
              <Box
                className={
                  index == menuId ? "menuDropdown open" : "menuDropdown "
                }
              >
                {item.submenu.map((subitem, subindex) => {
                  return (
                    subitem.targetBlank == true ?
                      <Link
                        target={subitem.targetBlank ? "_blank" : ""}
                        key={"sb" + subindex}
                        className="nav-link"
                        onClick={props.closeDrawer}
                        href={subitem.link}
                      >
                        {subitem.title}
                      </Link>
                      :
                      <NavLink
                        target={subitem.targetBlank ? "_blank" : ""}
                        key={"sb" + subindex}
                        className="nav-link"
                        onClick={props.closeDrawer}
                        to={subitem.link}
                      >
                        {subitem.title}
                      </NavLink>

                  );
                })}
              </Box>
            </ListItem>
          );
        })}
        <ListItem
          listStyleType="none"
          fontSize={{ base: "18px", md: "18px", lg: "14px", xl: "18px" }}
          display={{ md: "block", lg: "inline-block" }}
          marginBottom={{ sm: "15px", md: "0px" }}
          padding={{ sm: "10px 0px", lg: "0px 3px", xl: "0px 5px" }}
        >
          <Box className="menuDdBtn">
            <NavLink
              color="#fff"
              className={`nav-link ${location.pathname.includes("/forum") ? 'active' : ''}`}
              to={token ? `/forum/feeds` : "/forum/login"}
            >
              FORUM
            </NavLink>
          </Box>
        </ListItem>
      </UnorderedList>
    </>
  );
}
export default MenuList;