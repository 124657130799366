import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import ResetPassword from "../../component/Forum/ResetPassword";

const ResetPasswordPage = () => {
  return (
    <MainWrapper>
      <ResetPassword />
    </MainWrapper>
  );
};

export default ResetPasswordPage;
