import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import JustGym from "../component/JustGym";


const JustGymPage = () => {
  return (
    <MainWrapper>
      <JustGym />
    </MainWrapper>

  );
};

export default JustGymPage;
