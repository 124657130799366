import React from "react";
import { Helmet } from "react-helmet";

function MetaTitle(props) {
  return (
    <>
      <Helmet>
        {props.title && <title>{props.title}</title>}
        {props.content && <meta name="description" content={props.content} />}
        {props.metaTitle ? (
          <meta property="og:title" content={props.metaTitle} />
        ) : (
          <meta property="og:title" content={props.title} />
        )}
        {props.content && (
          <meta property="og:description" content={props.content} />
        )}
      </Helmet>
    </>
  );
}
export default MetaTitle;
