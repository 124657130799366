import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import UnbrokinLanding from "../../component/LandingPage/UnbrokinLanding";

const UnbrokinLandingPage = () => {
  return (
    <MainWrapper>
      <UnbrokinLanding />
    </MainWrapper>
  );
};

export default UnbrokinLandingPage;
