import React from "react";
import Heading from "./Heading/Heading";

function AboutPage() {
  return (
    <>
      <Heading></Heading>
    </>
  );
}

export default AboutPage;
