import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import LadiesGroup from "../component/LadiesGroup";


const LadiesGroupPage = () => {
  return (
    <MainWrapper>
      <LadiesGroup />
    </MainWrapper>

  );
};

export default LadiesGroupPage;
