import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Center,
  Button,
} from "@chakra-ui/react";
import * as Style from "./Modal.style";
import AuthUser from "../../services/Auth";
import ImageResize from "./ImageResize";
import ImageBox from "./ImageBox";
import DeleteModal from "./DeleteModal";
function CoverModal(props) {
  const { apiFormData, api } = AuthUser();
  const [imagesrc, setimagesrc] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const submit = async () => {
    try {
      setIsLoading(true);
      const Image =
        imagesrc?.size > 400000
          ? await ImageResize(imagesrc, 1024, "file")
          : imagesrc;
      var formData = new FormData();
      formData.append("image", Image);
      apiFormData
        .post("/profile/cover-image-upload", formData)
        .then((res) => {
          if (res.data.success) {
            setMessage("Profile Cover Updated successfully");
            setTimeout(() => {
              props.onClose();
              setimagesrc("");
              setMessage("");
              props.refresh();
            }, 1000);
          } else {
            setMessage(res.data.message[0]);
          }
        })
        .catch((err) => {
          setMessage(err.response.data.message[0]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteImage = () => {
    api
      .delete("/profile/cover-image-remove")
      .then((res) => {
        if (res?.data?.success) {
          setShowDelete(false);
          setMessage(res?.data?.message);
          setTimeout(() => {
            props.onClose();
            setimagesrc("");
            setMessage("");
            props.refresh();
          }, 1000);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={() => {
          props.onClose();
          setimagesrc("");
          setMessage("");
        }}
        size={{ sm: "lg", md: "6xl" }}
      >
        <ModalOverlay />
        <Style.ModalForumContent>
          <Style.ModalForumBody>
            <Style.createWrap>
              <Box />
              <Text color="#fff" style={{ fontFamily: "Poppins" }}>
                {props?.image ? "Change " : "Add "} Cover Image
              </Text>
              <Style.CancelBtn
                color="#fff!important"
                className="bi bi-x-lg"
                onClick={() => {
                  props.onClose();
                  setimagesrc("");
                  setMessage("");
                }}
              />
            </Style.createWrap>
            <Box
              justifyContent="center"
              padding={{ sm: "15px 15px", md: "15px 30px", lg: "40px" }}
            >
              <Flex position="relative" minH="305px" justifyContent="center">
                <ImageBox
                  image={imagesrc}
                  setImage={setimagesrc}
                  setMessage={setMessage}
                  type="cover"
                  width="600px"
                />
              </Flex>
              <Text
                fontSize="14px"
                fontFamily="Poppins"
                color={
                  message == "Profile Cover Updated successfully"
                    ? "#5cb85c"
                    : "Red"
                }
                textAlign={"center"}
                padding="10px 5px 5px "
                minH="36px"
              >
                {message}
              </Text>
              <Center paddingTop="10px">
                {props?.image && (
                  <Button
                    onClick={() => setShowDelete(true)}
                    color="#000"
                    _hover={{ opacity: 0.7 }}
                    _focused={{ backgroundColor: "#fff" }}
                    fontFamily="Poppins"
                    cursor="pointer"
                    bgColor="#fff"
                    fontWeight="500"
                    fontSize="18px"
                    marginRight="10px"
                    paddingY={{ base: "22px", lg: "25px" }}
                    colorScheme="gray"
                  >
                    <Box className="bi bi-trash3"></Box>
                  </Button>
                )}
                <Button
                  onClick={submit}
                  color="#fff"
                  _hover={{ opacity: 0.7 }}
                  fontFamily="Poppins"
                  cursor="pointer"
                  bgColor="Red"
                  fontWeight="500"
                  fontSize="18px"
                  paddingY={{ base: "22px", lg: "25px" }}
                  width="227px"
                  marginX="10px"
                  colorScheme="Red"
                  isDisabled={isLoading}
                >
                  Save
                </Button>
              </Center>
            </Box>
          </Style.ModalForumBody>
        </Style.ModalForumContent>
      </Modal>
      <DeleteModal
        title="Are you sure want to delete your cover image?"
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        deleteFunction={deleteImage}
      />
    </>
  );
}

export default CoverModal;
