import React from "react";
import { Heading, } from "@chakra-ui/react";
import Posts from "./Posts";
import CreatePost from "./CreatePost";

const PostWrapper = ({ data, onToggle, refresh, showCreate }: { data: any, onToggle: Function, refresh: Function, showCreate?: boolean }) => {
    return (
        <>
            {showCreate && <CreatePost
                refresh={refresh}
                onToggle={onToggle}
            />}

            {data.length <= 0 ? (
                <Heading textAlign="center" marginTop="50px">
                    No posts
                </Heading>
            ) : data.map((item, index) => (
                <Posts data={item} key={index} refresh={refresh} />
            ))
            }

        </>
    )
};

export default PostWrapper;
