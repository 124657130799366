import React, {useState, useEffect, lazy, Suspense} from 'react';
import {
    FormControl,
    FormLabel,
    Stack,
    Select,
    Input,
    Textarea,
    Checkbox,
    Flex
} from '@chakra-ui/react'

import Button from '../Button/CustomeButton';
import Text from '../SubHeading/SubHeading';
import axios from 'axios';
const ReCAPTCHA = lazy(()=>import('react-google-recaptcha')) ;

const ContactDetails = [
    {
        id: '1',
        contactName: 'Email'
    },
    {
        id: '2',
        contactName: 'Phone'
    },
    {
        id: '3',
        contactName: 'Post'
    },
    {
        id: '4',
        contactName: 'SMS'
    }

]
const InbodyForm = (props) => {
    const [ContactDetail, setContactDetail] = useState(ContactDetails);
    const [checked, setChecked] = useState([]);
    useEffect(() => {
        contactForm.contactMethod = checked.join(', ');
    }, [checked]);
    const handleCheckAllChange = (e) => {

        if (e.target.checked) {
            const allDetails = ContactDetail.map((c) => c.contactName);
            setChecked(allDetails);
        } else {
            setChecked([]);
        }
    };

    const handleContactChange = (e) => {
        if (e.target.checked) {
            setChecked([...checked, e.target.value]);
        } else {
            setChecked(checked.filter((item) => item !== e.target.value));
        }

    };

    // form submit
    const [successFrom, setsuccessFrom] = useState('');
    const [errorFrom, seterrorFrom] = useState('');
    const [nameError, setnameError] = useState('');
    const [phoneError, setphoneError] = useState('');
    const [emailError, setemailError] = useState('');
    const [subjectError, setsubjectError] = useState('');
    const [messageError, setmessageError] = useState('');
    const [contactMethodError, setcontactMethodError] = useState('');
    const [btnDisable, setBtnDisable] = useState(false);


    const [isCaptcha, setIsCaptcha] = useState(null);
    const [errorCaptcha, setErrorCaptcha] = useState('');

    const getReCaptchaVal = (value) => {
        setIsCaptcha(value);
        setErrorCaptcha('');
    }


    const [contactForm, setContactForm] = useState({
        name: "",
        email: "",
        phone: "",
        option: "InBody",
        message: "",
        contactMethod: ""
    });
    const handelInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setContactForm({ ...contactForm, [name]: value })
    }
    const handelSubmit = (e) => {
        e.preventDefault();
        console.log(contactForm);
        setsuccessFrom('');
        seterrorFrom('');
        setBtnDisable(true);

        if (isCaptcha === null) {
            setBtnDisable(false);
            setsuccessFrom('');
            setErrorCaptcha('Please verify that you are not a robot.');
            return false;
        }

        axios.post(process.env.REACT_APP_ADMIN_API_BASE_URL + '/inbodycontactform', {
            name: contactForm.name,
            email: contactForm.email,
            subject: contactForm.option,
            phone_no: contactForm.phone,
            message: contactForm.message,
            connect_with_you: contactForm.contactMethod

        })
            .then((response) => {
                setBtnDisable(false);
                console.log(response.data.success);
                setsuccessFrom(response.data.success);
                console.log(response.data.error);
                seterrorFrom(response.data.error);
                window.grecaptcha.reset();
                contactForm.name = "";
                contactForm.email = "";
                contactForm.message = "";
                contactForm.phone = "";
                contactForm.contactMethod = "";

            })
            .catch((error) => {
                setBtnDisable(false);
                setsuccessFrom('');
                seterrorFrom('');
                window.grecaptcha.reset();
                console.log(error);
                console.log(error.response.data.error.message[0]);

                if (error.response.data.error.name[0]) {
                    setnameError(error.response.data.error.name[0]);
                } else {
                    setnameError('');
                }
                if (error.response.data.error.email[0]) {
                    setemailError(error.response.data.error.email[0]);
                } else {
                    setemailError('');
                }
                if (error.response.data.error.subject[0]) {
                    setsubjectError(error.response.data.error.subject[0]);
                } else {
                    setsubjectError('');
                }
                if (error.response.data.error.phone[0]) {
                    setphoneError(error.response.data.error.phone[0]);
                } else {
                    setphoneError('');
                }
                if (error.response.data.error.message[0]) {
                    setmessageError(error.response.data.error.message[0]);
                } else {
                    setmessageError('');
                }
                if (error.response.data.error.contactMethod[0]) {
                    setcontactMethodError(error.response.data.error.contactMethod[0]);
                } else {
                    setcontactMethodError('');
                }

                console.log(nameError);

            });

    }


    return (
        <>
            <form className='innerContact inbody-form' onSubmit={handelSubmit} width='100%'>
                <FormControl isRequired >

                    <Stack direction={['column']} spacing='24px' position='relative'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            position='relative'
                            marginBottom='-34px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'

                        >Name</FormLabel>
                        <Input _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' borderRadius='0px' color='black' fontSize='18px' margin='0px 0px 0px 0px' name="name" value={contactForm.name} onChange={handelInput} />
                    </Stack>


                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            position='relative'
                            marginBottom='-34px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'

                        >Email</FormLabel>
                        <Input type='email' _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' borderRadius='0px' color='black' fontSize='18px' margin='0px 0px 0px 0px' name="email" value={contactForm.email} onChange={handelInput} />
                    </Stack>

                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            position='relative'
                            marginBottom='-34px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'

                        >Phone</FormLabel>
                        <Input type='number' _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' borderRadius='0px' color='black' fontSize='18px' margin='0px 0px 0px 0px' name="phone" value={contactForm.phone} onChange={handelInput} />
                    </Stack>

                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Select placeholder='Select option' borderRadius='0' height='50px' color='black' fontSize='18px' textTransform='uppercase' name="option" value={contactForm.option} onChange={handelInput} >
                            <option style={{ color: 'black' }} value='Tread & Shred' padding='8px 0px'>Tread & Shred</option>
                            <option style={{ color: 'black' }} value='Ride'>Ride</option>
                            <option style={{ color: 'black' }} value='Rumble'>Rumble</option>
                            <option style={{ color: 'black' }} value='CrossFit'>CrossFit</option>
                            <option style={{ color: 'black' }} value='Personal Training'>Personal Training</option>
                            <option style={{ color: 'black' }} value='JustGym'>JustGym</option>
                            <option style={{ color: 'black' }} value='Online Program'>Online Program</option>
                            <option style={{ color: 'black' }} value='Perpetua Anywhere'>Perpetua Anywhere</option>
                            <option style={{ color: 'black' }} value='Post Natal Training'>Post Natal Training</option>
                            <option style={{ color: 'black' }} value='Nutrition'>Nutrition</option>
                            <option style={{ color: 'black' }} value='Ladies Group'>Ladies Group</option>
                            <option style={{ color: 'black' }} value='Hyrox'>Hyrox </option>
                            <option style={{ color: 'black' }} value='Careers'>Careers</option>
                            <option style={{ color: 'black' }} value='Corporate'>Corporate</option>
                            <option style={{ color: 'black' }} value='Private Hire'>Private Hire</option>
                            <option style={{ color: 'black' }} value='Mojo'>Mojo</option>

                        </Select>
                    </Stack>

                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            position='relative'
                            marginBottom='-34px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'

                        >Your Message</FormLabel>
                        <Textarea
                            _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }}
                            height='120px' borderRadius='0px' border='1px solid #000' color='black' fontSize='18px' margin='0px 0px 0px 0px' name="message" value={contactForm.message} onChange={handelInput}></Textarea>
                        {/* {messageError && <FormErrorMessage>{messageError}</FormErrorMessage>} */}
                    </Stack>
                    {/* <Stack direction={['column']} spacing='24px'  position='relative' marginTop='25px' textAlign='left'>
                        <Text as='span' title='From time to time we may wish to contact you about products and services which we feel may be of interest to you. If you are happy to receive this information please tick the relevant boxes below.' fontSize='14px'></Text>
                    </Stack>

                    <Stack direction={{sm:'row',md:'row'}} spacing={5}  position='relative' marginTop='25px'>
                        <Checkbox colorScheme='red'>
                            <Text fontSize='14px' title='Select All'></Text>
                        </Checkbox>
                        <Checkbox colorScheme='red'>
                            <Text fontSize='14px' title='Email'></Text>
                        </Checkbox>
                        <Checkbox colorScheme='red'>
                            <Text fontSize='14px' title='Phone'></Text>
                        </Checkbox>
                        <Checkbox colorScheme='red'>
                            <Text fontSize='14px' title='Post'></Text>
                        </Checkbox>
                        <Checkbox colorScheme='red'>
                            <Text fontSize='14px' title='SMS'></Text>
                        </Checkbox>
                    </Stack> */}
                    {/* <Stack direction={['column']} spacing='24px'  position='relative' marginTop='25px' textAlign='left'>
                        <Text as='span' title='By submitting this enquiry you acknowledge and agree that your personal data will be processed per our Privacy Policy.' fontSize='12px' ></Text>
                    </Stack> */}

                    <Stack direction={{ sm: 'row', md: 'column', lg: 'row' }} spacing={{ md: '25px', lg: '30px', xl: '50px' }} marginTop='25px' justifyContent='space-between'>
                        <Suspense fallback={"Loading.."}>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={getReCaptchaVal} className='captcha' />
                        </Suspense>
                        <Button btnDisable={btnDisable} submit="submit" title='Contact' width='208px' margin='30px 0px 0px' color='black'></Button>
                    </Stack>
                    <Flex justifyContent="left"> 
                    {errorCaptcha &&  <Text title={errorCaptcha} fontSize="14px" color="red"/>}
                    </Flex>

                    <Flex justifyContent="center" margin="30px 0 0"> {successFrom && <Text title={successFrom} fontSize="16px" color="green" />}</Flex>
                    <Flex justifyContent="center" margin="30px 0 0"> {errorFrom && <Text title={errorFrom} fontSize="16px" color="Red" />}</Flex>

                    
                </FormControl>
            </form>


        </>
    )
};

export default InbodyForm;
