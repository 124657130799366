import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Show,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import * as Style from "./Modal.style";
import { FormButton } from "./LoginRegister/Inputs";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ImageResize from "./ImageResize";
import ImageBox from "./ImageBox";
import AuthUser from "../../services/Auth";
import { GetCategories } from "../../services/FeedService";
const CreatePostModal = (props) => {
  const { apiFormData } = AuthUser();
  const { categories } = GetCategories();
  const [description, setDescription] = useState(
    props?.isEdit
      ? props?.data?.description
        ? props?.data?.description
        : ""
      : ""
  );
  const [image, setImage] = useState(
    props?.isEdit ? props?.data?.feed_image : ""
  );
  const [isOnlyMe, setIsOnlyMe] = useState(0);
  const [categoryID, setCategoryID] = useState(
    props?.isEdit ? props?.data?.category?.id : ""
  );
  const [categoryName, setCategoryName] = useState(
    props?.isEdit ? props?.data?.category?.title : ""
  );
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(props.isEdit ? props.isEdit : false);
  const [imgStatus, setImgStatus] = useState("none");
  useEffect(() => {
    if (image !== props?.data?.feed_image) {
      if (image) {
        setImgStatus(props?.data?.feed_image ? "update" : "new");
      } else {
        setImgStatus("delete");
      }
    }
  }, [image]);
  const submit = async () => {
    try {
      const Image =
        image?.size > 400000 ? await ImageResize(image, 1024, "file") : image;
      var formData = new FormData();
      formData.append("body", description);
      formData.append("category_id", categoryID);
      formData.append("image", Image);
      formData.append("is_only_me", isOnlyMe);
      if ((!description && !Image) || !categoryID) {
        if (!description && !Image) {
          setMessage("Image or Description Field is required");
        } else {
          setMessage("category Field is required");
        }
      } else {
        setIsLoading(true);
        apiFormData
          .post("/feeds/create", formData)
          .then((res) => {
            if (res.data.success) {
              setMessage("Post created successfully");
              setTimeout(() => {
                props?.refresh();
                props?.onClose();
                setImage("");
                setDescription("");
                setCategoryID("");
                setMessage("");
              }, 1000);
            }
          })
          .catch((err) => {
            setMessage(err.response.data.message[0]);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updatePost = async () => {
    try {
      var formData = new FormData();
      formData.append("body", description);
      formData.append("category_id", categoryID);
      if (imgStatus === "update" || imgStatus === "new") {
        const Image =
          image?.size > 400000 ? await ImageResize(image, 1024, "file") : image;
        formData.append("image", Image);
      }
      formData.append("is_only_me", isOnlyMe);
      formData.append("img_status", imgStatus);
      setIsLoading(true);
      apiFormData
        .post("/feeds/update?feed_id=" + props?.postId, formData)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            setMessage("Post Updated successfully");
            setTimeout(() => {
              props?.refresh();
              props?.onClose();
              // setDescription("");
              // setCategoryID("");
              // setMessage("");
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
          //  setMessage(err.response.data.message[0]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
        if (!props.isEdit) {
          setImage("");
          setDescription("");
          setMessage("");
          setCategoryID("");
        }
      }}
      size={{ sm: "sm", md: "xl", lg: "6xl" }}
    >
      <ModalOverlay />
      <Style.ModalForumContent>
        <Style.ModalForumBody>
          <Style.createWrap>
            <Box />
            <Text color="#fff" style={{ fontFamily: "Poppins" }}>
              {props.isEdit ? "Edit " : "Create a "} post
            </Text>
            <Style.CancelBtn
              color="#fff!important"
              className="bi bi-x-lg"
              onClick={() => {
                props.onClose();
                if (!props.isEdit) {
                  setImage("");
                  setDescription("");
                  setMessage("");
                  setCategoryID("");
                }
              }}
            />
          </Style.createWrap>
          <Flex
            justifyContent="space-between"
            direction={{ base: "column", md: "row" }}
            padding={{ sm: "15px 15px", md: "15px 30px", lg: "40px" }}
          >
            <Show below="md">
              {!props?.shared && (
                <ImageBox
                  image={image}
                  update={update}
                  setUpdate={setUpdate}
                  setImage={setImage}
                  setMessage={setMessage}
                />
              )}
            </Show>
            <Box width={{ base: "100%", md: props?.shared ? "100%" : "57%" }}>
              <Style.whatDoTextArea
                resize="none"
                padding="15px"
                onChange={(e) => {
                  setDescription(e.target.value);
                  setMessage("");
                }}
                value={description}
                placeholder={
                  props.isEdit
                    ? "Add a description"
                    : "What do you have in mind ?"
                }
              />
              <Flex
                flexWrap="wrap"
                paddingTop="20px"
                justifyContent="space-between"
              >
                <Box width="100%">
                  <Menu autoSelect="false" matchWidth="true" width="100%">
                    <MenuButton
                      as={Button}
                      display="flex"
                      justifyContent="space-between"
                      borderRadius="5px"
                      textAlign="start"
                      _hover={{
                        color: "#fff",
                        backgroundColor: "#444!important",
                      }}
                      width="100%"
                      fontWeight="500"
                      fontFamily="poppins"
                      fontSize="16px"
                      cursor="pointer"
                      p="10px"
                      color="#fff"
                      backgroundColor="#444!important"
                      textTransform="capitalize"
                      rightIcon={<ChevronDownIcon />}
                    >
                      {categoryID ? categoryName : "Select Category *"}{" "}
                    </MenuButton>
                    <MenuList color="#fff" backgroundColor="#444" width="100%">
                      {categories?.map((item, index) => (
                        <MenuItem
                          width="100%"
                          as="option"
                          fontFamily="poppins"
                          textTransform="capitalize!important"
                          key={index}
                          cursor="pointer"
                          _focus={{ color: "#fff", backgroundColor: "#777" }}
                          _hover={{ color: "#fff", backgroundColor: "#777" }}
                          onClick={() => {
                            setCategoryID(item.id);
                            setCategoryName(item.title);
                            if (message === "category Field is required")
                              setMessage("");
                          }}
                        >
                          {item.title}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                  <Flex marginY="15px">
                    <Flex
                      cursor="pointer"
                      backgroundColor={isOnlyMe === 0 ? "Red" : "#444"}
                      color="#fff"
                      borderRadius="20px"
                      padding="5px 15px"
                      marginRight="10px"
                      onClick={() => {
                        setIsOnlyMe(0);
                      }}
                    >
                      <Box className="bi-people" marginRight="5px" />
                      <Text>Everyone</Text>
                    </Flex>
                    <Flex
                      cursor="pointer"
                      backgroundColor={isOnlyMe === 1 ? "Red" : "#444"}
                      color="#fff"
                      borderRadius="20px"
                      padding="5px 15px"
                      onClick={() => {
                        setIsOnlyMe(1);
                      }}
                    >
                      <Box className="bi-person" marginRight="5px" />
                      <Text>Only me</Text>
                    </Flex>
                  </Flex>
                  {message && (
                    <Text
                      fontSize="14px"
                      fontFamily="Poppins"
                      color={
                        message == "Post created successfully" ||
                        message == "Post Updated successfully"
                          ? "#5cb85c"
                          : "Red"
                      }
                      textAlign={"center"}
                      padding="5px "
                    >
                      {message}
                    </Text>
                  )}
                  <FormButton
                    onClick={props.isEdit ? updatePost : submit}
                    backgroundColor="Red"
                    name={props.isEdit ? "Finished Editing" : "Create"}
                    isDisabled={isLoading}
                  />
                </Box>
              </Flex>
            </Box>
            <Show above="md">
              {!props?.shared && (
                <ImageBox
                  image={image}
                  update={update}
                  setUpdate={setUpdate}
                  setImage={setImage}
                  setMessage={setMessage}
                />
              )}
            </Show>
          </Flex>
        </Style.ModalForumBody>
      </Style.ModalForumContent>
    </Modal>
  );
};

export default CreatePostModal;
