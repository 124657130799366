import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import * as Style from "./YourIntro.style";
import { useNavigate } from "react-router-dom";
import ReadMoreText from "./ReadMoreText";

function YourIntro({ data, isEdit }) {
  const navigate = useNavigate();
  const IntroData = [
    {
      id: 1,
      icon: "bi bi-geo-alt-fill",
      introTitle: "Lives In",
      introValue: data?.live_in,
    },
    {
      id: 2,
      icon: "bi bi-layers",
      introTitle: "Favourite Workout",
      introValue: data?.favourite_workout,
    },
    {
      id: 3,
      icon: "bi bi-person-arms-up",
      introTitle: "Height",
      introValue: data?.height ? data?.height + " cm" : null,
    },
    {
      id: 4,
      icon: "bi bi-person-standing",
      introTitle: "Weight",
      introValue: data?.weight ? data?.weight + " lb" : null,
    },
    {
      id: 5,
      icon: "bi bi-rss",
      introTitle: "Followed By",
      introValue: `${data?.followed_by} People`,
    },
  ];
  return (
    <Box>
      <Style.rightPeopleWrap>
        <Style.rightPeople>Intro</Style.rightPeople>
        {isEdit && (
          <Style.friendsTitle
            cursor="pointer"
            onClick={() => {
              navigate("/forum/profile-edit", { state: { secondTab: true } });
            }}
          >
            Edit
          </Style.friendsTitle>
        )}
      </Style.rightPeopleWrap>
      <Box>
        {IntroData.map((item, index) => {
          return (
            <Flex alignItems="center" key={index} paddingTop="10px">
              <Box className={item.icon} style={{ color: "#fff" }} />
              <Box
                style={{
                  fontWeight: "300",
                  paddingLeft: "10px",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  color: "#fff",
                }}
              >
                <span style={{ fontWeight: "700" }}>{item.introTitle}</span>{" "}
                <ReadMoreText maxlength={35}>
                  {item?.introValue ? item?.introValue : "N/A"}
                </ReadMoreText>
              </Box>
            </Flex>
          );
        })}
      </Box>
    </Box>
  );
}

export default YourIntro;
