import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import PrivacyPolicy from "../component/PrivacyPolicy";


const PrivacyPolicyPage = () => {
  return (
    <MainWrapper>
      <PrivacyPolicy />
    </MainWrapper>

  );
};

export default PrivacyPolicyPage;
