import React from "react";
import { Container, Flex, SimpleGrid, GridItem } from "@chakra-ui/react";
import ClassCard from "./Classcard/ClassCard";
import MetaTitle from "./MetaTitle";

function FirstTimers() {
  const metaTitle = "First timers | Perpetua Fitness";

  return (
    <>
      <MetaTitle title={metaTitle} />

      <Container
        maxW="container.xl"
        padding={{ sm: "50px 0 30px", md: "50px 0" }}
      >
        <SimpleGrid
          columns={{ sm: "1", md: "2", xl: "3" }}
          columnGap={4}
          rowGap={2}
          width="full"
          padding={{ sm: "0px 20px", md: "0px 30px" }}
        >
          <GridItem
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.7s"
          >
            <ClassCard
              title="Tread & Shred"
              subtitle=""
              bg="url('/images/TreadAndShread/WarmUp.webp')"
              isButton={false}
              marginBottom="30px"
              transform="uppercase"
              minHeight={{ sm: "300px", md: "450px", lg: "500px" }}
              linkBg="/treadandshred-first-timers"
            />
          </GridItem>
          <GridItem
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.9s"
          >
            <ClassCard
              title="Rumble Boxing "
              subtitle=""
              bg="url('/images/Rumble/Cooldown.webp')"
              isButton={false}
              marginBottom="30px"
              transform="uppercase"
              minHeight={{ sm: "300px", md: "450px", lg: "500px" }}
              linkBg="/rumble-first-timers"
            />
          </GridItem>
          <GridItem
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.9s"
          >
            <ClassCard
              title="Rhythm Ride & Ride 45"
              subtitle=""
              bg="url('/images/RhythmRide/Playlist.webp')"
              isButton={false}
              marginBottom="30px"
              transform="uppercase"
              minHeight={{ sm: "300px", md: "450px", lg: "500px" }}
              linkBg="/ride-first-timers"
            />
          </GridItem>
        </SimpleGrid>
      </Container>
    </>
  );
}

export default FirstTimers;
