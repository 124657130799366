import React from "react";
import * as Style from "./Location.style";
import LandingBanner from "./LandingBanner/LandingBanner";
import {
  Box,
  Container,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Heading from "../Heading/Heading";
import Text from "../SubHeading/SubHeading";
import ContentboxCard from "./ContentBox/ContentboxCard";
import VideoCard from "../VideoCard/VideoCard";
import OutStudio from "../OutStudio/OutStudio";
import MetaTitle from ".././MetaTitle";
import LazyLoad from "react-lazy-load";

const banners = [
  {
    heading1: "GYM DUBLIN 2",
    heading2: "Windmill Lane",
    heading3:
      "CROSSFIT DUBLIN, 2 WINDMILL LANE,WINDMILL QUARTER,DUBLIN 2.D02 KX66",
    Link1: "/timetable",
    Link2: "/contact-us",
    modalButtontitle: "View Gym",
  },
];

const classbox = [
  {
    image: "./images/crossfit.png",
    heading: "CrossFit",
    description:
      "At CrossFit Dublin, we're here to provide the best coaching and programming all while surrounding yourself with our CrossFit Community. With a CrossFit membership you have unlimited access to all of our CrossFit classes. We have over 60 classes spread over 7 days a week which you can attend.",
    link: "/crossfit-dublin",
  },
  {
    image: "./images/Hyrox.png",
    heading: "HYROX",
    description:
      "Here at Perpetua Fitness, we will provide the coaching and program that any individual can follow in class to prepare and race for a Hyrox event. We will host 3 classes a week for you to join and every 8 weeks we will host a Hyrox simulation event for individuals to join to test their progress and race capacity.",
    link: "/hyrox-dublin",
  },
  {
    image: "./images/tread-banner.jpg",
    heading: "Tread & Shred",
    description:
      "Intelligent programming, world class coaches, & the perfect beats. Our signature tread & shred class is a group fitness experience unmatched in Ireland. You’ll alternate between treadmill and floor exercises that are focused on strength, power, and endurance. You’ll burn calories and get stronger and faster every class. ",
    link: "/treadandshred",
  },
  {
    image: "./images/ride.jpg",
    heading: "RIDE",
    description:
      "More than a spin class. An experience. Fully equipped with Keiser m3i bikes and the best lighting and sound system in Ireland. Our ride45, and rhythm ride classes offer something for everyone.",
    link: "/rhythmride",
  },
  {
    image: "./images/Justgym-banner.jpg",
    heading: "JustGYM",
    description:
      "Train your way in our JustGYM space where no detail is too small. Our state-of-the-art fitness facility has everything you need to enhance your session and achieve your goals.",
    link: "/luxury-gym-dublin-city-centre",
  },
];

const aminitibox = [
  {
    icon: "./images/sport-bag.png",
    heading: "DIRTY CLOTHES BAG",
    description: "Free bags to keep your sweaty clothes in",
    alt: "gym portobello - Dirty clothes bag",
  },
  {
    icon: "./images/wifi.png",
    heading: "WI-FI",
    description: "Free Wi-Fi for our guests, connect to PerpetuaGuest",
    alt: "gym portobello - Wi Fi",
  },
  {
    icon: "./images/changing-room.png",
    heading: "CHANGING ROOMS",
    description: "Separate men’s and women’s changing areas.",
    alt: "gym portobello - Changing rooms",
  },
  {
    icon: "./images/shake.png",
    heading: "FUEL BAR",
    description:
      "Delicious, protein-packed shakes that come in chocolate, fruit, vanilla and more. Coffee, water and snacks too.",
    alt: "gym portobello - Fuel bar",
  },
  {
    icon: "./images/toiletries.png",
    heading: "GROOMING PRODUCTS",
    description:
      "All the toiletry bells and whistles including shampoo, conditioner, face wash, body wash, body lotion, tampons and deodorant. * *Some items may not be available at all locations.",
    alt: "gym portobello - Grooming products",
  },
  {
    icon: "./images/towel.png",
    heading: "COMPLIMENTARY TOWELS",
    description:
      "Mini towels for class and lovely big towels for showers after class.",
    alt: "gym portobello - Complimentary towels",
  },
  {
    icon: "./images/store.png",
    heading: "SHOP",
    description:
      "Perpetua’s latest and greatest clothing collection available for purchase. Tanks,tees, shorts, pants, jackets, hats, socks and more...check it out.",
    alt: "gym portobello - Shop",
  },
  {
    icon: "./images/public-toilet.png",
    heading: "RESTROOMS",
    description: "Fully functioning restrooms.",
    alt: "gym portobello - Restrooms",
  },
  {
    icon: "./images/lockers.png",
    heading: "LOCKERS",
    description: "Secure lockers that work by you choosing a four digit code.",
    alt: "gym portobello - Lockers",
  },
  {
    icon: "./images/shower.png",
    heading: "HOT SHOWERS",
    description:
      "High pressure, private showers with fabulous soap, shampoo, and conditioner.",
    alt: "gym portobello - Hot showers",
  },
];

const metaTitle =
  "Gym Dublin 2, City Centre | Perpetua Fitness - Windmill Lane";
const metaDesc =
  "Perpetua Fitness - Windmill Lane, Dublin 2, the space that celebrates the process of training, hard work and accomplishment. Make your move.";

const Location1 = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      <Style.LocationBody>
        <LandingBanner banners={banners} />
        <Box
          background="#000"
          padding={{ lg: "100px 0px", md: "50px 0px", sm: "50px 0px" }}
        >
          <Container maxW="container.xl">
            <Stack
              direction={{ sm: "column", md: "column", lg: "row" }}
              spacing="24px"
            >
              <Box w={{ md: "100%", lg: "50%" }}>
                <Heading
                  title="COACHING. COMMUNITY. PASSION."
                  variant="large"
                  color="#fff"
                  width="70%"
                  lineHeight="1"
                />
              </Box>
              <Box
                w={{ md: "100%", lg: "50%" }}
                padding={{ lg: "20px 0px", md: "8px 0px" }}
              >
                <Text
                  title="Windmill Lane, the space that celebrates the process of training, hard work and accomplishment."
                  as="p"
                  fontSize="18px"
                  color="#fff"
                  margin="0px 0px 10px"
                  fontWeight="300"
                />
                <Text
                  title="Train in our CrossFit and HYROX classes, sweat it out in Tread & Shred and RIDE or train your way in JustGYM. We've got it all."
                  as="p"
                  fontSize="18px"
                  color="#fff"
                  fontWeight="300"
                />
              </Box>
            </Stack>
          </Container>
        </Box>

        <ContentboxCard classbox={classbox} />
        <OutStudio aminitibox={aminitibox} />

        <Box
          background="#000"
          padding={{ lg: "100px 0px", md: "50px 0px", sm: "50px 0px" }}
          borderTop="1px solid #232323"
          borderBottom=" 1px solid #232323"
        >
          <Container maxW="container.xl">
            <Stack direction={{ sm: "column", md: "row" }} spacing="20px">
              <Box width={{ sm: "100%", md: "40%" }}>
                <Heading
                  title="WINDMILL LANE OPENING HOURS:"
                  variant="medium"
                  color="#fff"
                  width="70%"
                  lineHeight="1"
                  margin="0px 0px 20px"
                />
                <Text
                  title="MONDAY - THURSDAY: 5:30AM - 9:00PM"
                  as="p"
                  fontSize="18px"
                  color="#fff"
                  margin="0px 0px 10px"
                  fontWeight="300"
                />
                <Text
                  title="FRIDAY: 5:30AM - 8:00PM"
                  as="p"
                  fontSize="18px"
                  color="#fff"
                  margin="0px 0px 10px"
                  fontWeight="300"
                />
                <Text
                  title="SATURDAY & SUNDAY: 8:00AM - 2:00PM"
                  as="p"
                  fontSize="18px"
                  color="#fff"
                  margin="0px 0px 10px"
                  fontWeight="300"
                />
                <Style.ButtonModal
                  onClick={onOpen}
                  color="white"
                  marginTop="20px"
                >
                  View Gym
                </Style.ButtonModal>
              </Box>
              <Box width={{ sm: "100%", md: "60%" }}>
                <LazyLoad offset={300}>
                  <Box
                    className="selector"
                    width={{ sm: "100%", md: "100%" }}
                    as="iframe"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2381.8681113257567!2d-6.247753987632494!3d53.34561707217348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670e92845faed9%3A0x4997b5eaf658ec21!2sPerpetua%20Fitness%20-%20Windmill%20Lane!5e0!3m2!1sen!2sie!4v1703240367661!5m2!1sen!2sie"
                  ></Box>
                </LazyLoad>
              </Box>
              <Box
                marginLeft={{ md: "10px" }}
                marginTop={props.ButtonModalmarginTop}
              >
                <Modal
                  isOpen={isOpen}
                  onClose={onClose}
                  size={{ sm: "sm", md: "3xl" }}
                >
                  <ModalOverlay />
                  <ModalContent marginTop="100px">
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>
                      <VideoCard
                        src="https://www.youtube.com/embed/2ukYI8KIwUk"
                        width={{ sm: "100%", md: "700px" }}
                        margin={{ sm: "30px 3px", md: "30px 9px" }}
                        maxHeight="540px"
                      ></VideoCard>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Box>
            </Stack>
          </Container>
        </Box>
      </Style.LocationBody>
    </>
  );
};

export default Location1;
