import React from 'react';
import { Box, Flex, Text, Container, Link } from '@chakra-ui/react';
import Button from './Button/CustomeButton';
import AppDownload from './AppDownload/AppDownload';
import ClassListing from './ClassListing/ClassListing';
import Heading from './Heading/Heading';
import MetaTitle from './MetaTitle'
import VideoSection from './VideoSection/VideoSection';
import * as Style from './Banner.style';

function Home() {

  const metaTitle = "Perpetua Fitness | Gym, Fitness Classes & CrossFit in Dublin"
  const metaDesc = "The fitness experience you won't forget. Community and movement is at the heart of everything we do here at Perpetua Fitness. Make your move."

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />


      <Style.LandindBanner backgroundImage={{ sm: 'url(./images/home-mobile.webp)', md: 'url(./images/home-desktop.webp)' }}>
        <Container maxW='container.xl'>

          <Style.BannerWrapper>
            <Style.ButtonWrapper>

              <Flex direction={{ md: 'row', sm: 'column' }} gap='15px' width='100%'
                marginTop={{ md: '0', sm: '20px' }} justifyContent={{ md: 'center' }} alignItems={{ sm: 'center' }} >
                <Style.Button href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107884' isExternal>Sign up Now</Style.Button>
                <Style.Button href='/unbroken-powered-by-whoop'>Find out more</Style.Button>
              </Flex>
            </Style.ButtonWrapper>
          </Style.BannerWrapper>
        </Container>
      </Style.LandindBanner>

      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        background={"black"}
      >
        <Container maxW='container.xl'>
          <Box
            width="90%"
            p={{ base: '20px 0px', sm: '30px 0px', md: '50px 0px' }}
            textAlign={"center"}
            color={"white"}
            textTransform="uppercase"
            m={"auto"}
          >
            <Heading
              color="white"
              variant="medium"
              title="Community and movement is at the heart of everything we do here at Perpetua. We live for intelligent programming, functional fitness and having a good time together."
              ineHeight='1'
              lineHeight={{ sm: ".9", md: "1.3" }}
              className='wow animate fadeInUp'
            >
            </Heading>
            <Text
              className='wow animate fadeInUp'
              fontSize={{ sm: "16px", md: "24px" }}
              as="p"
              fontFamily='Poppins'
              marginTop='20px'
              marginBottom='20px'
              color='white'
              fontWeight='300'
            >
              <Text as="b">Make</Text> Your Move</Text>
            <Link href="/timetable">
              <Button title='BOOK A CLASS' className='wow animate fadeInUp' />
            </Link>
          </Box>
        </Container>
      </Flex>

      <ClassListing />
      <AppDownload
        bg="url('/images/App-section-bg.webp')"
        heading='Your Daily Dose of Online Workouts Is Live'
        para='Perpetua Online Programming'
        imageUri='/images/iphone.webp'
        appstore='/images/app-store.png'
        googleplay='/images/google-play.png'
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
        alt="iphone - perpetua fitness"
      />

      <VideoSection heading='Check out'
        headingSub='our studios' video1='https://youtube.com/embed/2ukYI8KIwUk?si=xs2r3j2jyf_Io-uW'
        video2='https://youtube.com/embed/wuxj2I_lYc0?si=zTjaSl4eJ5cWedbo' smallHeading='Windmill Lane' smallHeading2='Lennox Street' midHeading='' midHeading2=''
        borderB='1px solid #232323' borderT='1px solid #232323'
      />
    </>
  );
}

export default Home;
