import { Flex, Box, Link } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { NavLink } from "react-router-dom";


export const LandindBanner = styled(Box)(props => ({
    padding: 0,
    margin: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    "@media screen and (max-width:991px)": {
        backgroundPosition: 'Center'
    },
    "@media screen and (max-width:767px)": {
        backgroundPosition: 'top'
    },

}))
export const BannerWrapper = styled(Box)(props => ({
    position: 'relative',
    height: '700px',

    "@media screen and (max-width:1440px)": {
        height: '650px',
    },

    "@media screen and (max-width:1366px)": {
        height: '600px',
    },
    "@media screen and (max-width:1199px)": {
        height: '500px',
    },
    "@media screen and (max-width:991px)": {
        height: '375px',
    },
    "@media screen and (max-width:767px)": {
        height: '665px',
    },
    "@media screen and (max-width:480px)": {
        height: '80vh',
    },
    "@media screen and (max-width:400px)": {
        height: '80vh',
    },

}))
export const ButtonWrapper = styled(Flex)(props => ({
    padding: 0,
    //margin: '0 auto',
    position: 'absolute',
    // top: 0,
    // right: 0,
    bottom: '30px',
    left: '0px',
    width: '100%',
    alignItems: 'center',
    textAlign: "center",
    justifyContent: 'center',
    gap: 20,
    //marginTop: '130px',

    //flexDirection: 'column',

    "@media screen and (max-width:767px)": {
        bottom: '20px',
        alignItems: 'center',
        right: '0',
        textAlign: 'center',
        paddingLeft: '0',
        gap: '10px'
    },
    "@media screen and (max-width:480px)": {
        bottom: '20%',
    },
    "@media screen and (max-width:1199px)": {
        bottom: '15px',

    },
}))

export const captionwrapper = styled(Flex)(props => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '15px',
    flexDirection: 'column',

    "@media screen and (max-width:767px)": {
        alignItems: 'center',
        textAlign: 'center'
    },
}))


export const Button = styled(NavLink)(props => ({
    padding: 0,
    margin: 0,
    marginRight: '20px',
    padding: '9px 20px',
    color: '#fff',
    border: '2px solid #d12c41',
    width: '100%',
    maxWidth: '208px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 20,
    borderRadius: 300,
    '&:hover': {
        backgroundColor: '#d12c41',
        color: '#fff'
    },
    "@media screen and (max-width:480px)": {
        fontSize: 17,
    },
}))