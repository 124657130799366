import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import TeamDetails from "../component/TeamDetails";


const TeamDetailsPage = () => {
  return (
    <MainWrapper>
      <TeamDetails />
    </MainWrapper>

  );
};

export default TeamDetailsPage;
