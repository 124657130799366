import { Box, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
export const LoginRegisterBox = styled(Flex)((props) => ({
  padding: "40px",
  "@media screen and (max-width:1600px)": {
    padding: "25px",
  },
  "@media screen and (max-width:1366px)": {
    padding: "20px",
  },
  "@media screen and (max-width:992px)": {
    padding: "40px",
  },
  "@media screen and (max-width:768px)": {
    padding: "40px",
  },
  "@media screen and (max-width:552px)": {
    justifyContent: "center",
    padding: "40px 16px",
  },
  "@media screen and (max-width:416px)": {
    padding: "40px 0px",
  },
}));
export const FormBox = styled(Box)((props) => ({
  width: "550px",
  backgroundColor: "rgba(225,225,225,0.8)",
  borderRadius: "80px",
  height: "fit-content",
  padding: "40px 60px 30px",
  boxSizing: "border-box",
  "@media screen and (max-width:1600px)": {
    width: "500px",
    padding: "35px 55px 30px",
  },
  "@media screen and (max-width:1440px)": {
    borderRadius: "70px",
    width: "475px",
    padding: "30px 50px",
  },
  "@media screen and (max-width:1366px)": {
    borderRadius: "65px",
    width: "450px",
  },
  "@media screen and (max-width:1200px)": {
    borderRadius: "60px",
    width: "400px",
    padding: "25px 40px",
  },
  "@media screen and (max-width:552px)": {
    borderRadius: "50px",
  },
  "@media screen and (max-width:475px)": {
    width: "100%",
    padding: "25px 20px 10px",
    borderRadius: "40px",
  },
}));

export const FormLogo = styled(Flex)((props) => ({
  justifyContent: "center",
  paddingTop: "80px",
  paddingBottom: "40px",
  "@media screen and (max-width:1600px)": {
    paddingTop: "60px",
    paddingBottom: "30px",
  },
  "@media screen and (max-width:1440px)": {
    paddingTop: "50px",
    paddingBottom: "25px",
  },
  "@media screen and (max-width:1366px)": {
    paddingTop: "40px",
    paddingBottom: "20px",
  },
  "@media screen and (max-width:475px)": {
    padding: "16px 0px",
  },
}));
export const FormFooter = styled(Flex)((props) => ({
  width: "100%",
  paddingTop: "80px",
  maxHeight: "30px",
  justifyContent: "center",
  alignItems: "center",
  "@media screen and (max-width:1600px)": {
    paddingTop: "60px",
  },
  "@media screen and (max-width:1440px)": {
    paddingTop: "50px",
  },
  "@media screen and (max-width:1366px)": {
    paddingTop: "40px",
  },
  "@media screen and (max-width:1200px)": {
    paddingTop: "35px",
  },
  "@media screen and (max-width:475px)": {
    padding: "16px 0px",
  },
}));
