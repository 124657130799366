import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Golf from "../component/Golf";


const GolfPage = () => {
  return (
    <MainWrapper>
      <Golf />
    </MainWrapper>

  );
};

export default GolfPage;
