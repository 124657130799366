import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import RumbleLanding from "../component/RumbleLanding";


const RumbleLandingPage = () => {
  return (
    <MainWrapper>
      <RumbleLanding />
    </MainWrapper>

  );
};

export default RumbleLandingPage;
