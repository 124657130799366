import { Flex, Box, Text } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const createIconWrap = styled(Flex)((props) => ({
  //direction: "column",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0px 20px",
  [`@media ${device.tablet}`]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
export const threeLine = styled(Box)((props) => ({
  fontSize: "22px",
  color: "white",
  paddingRight: "5px",
}));
export const createPostWrap = styled(Box)((props) => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  padding: "0px 0 20px",
  [`@media ${device.tablet}`]: {
    display: "none",
    padding: "0px",
  },
}));
export const createPostWrap1 = styled(Box)((props) => ({
  alignItems: "center",
  display: "none",
  padding: "30px 0 0",
  [`@media ${device.tablet}`]: {
    display: "flex",
    padding: "0",
  },
}));

export const plusIcon = styled(Box)((props) => ({
  backgroundColor: "#D12C41",
  color: "#fff",
  padding: "5px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  fontSize: "30px",
  borderRadius: "25px",
  cursor: "pointer",
  // "@media screen and (max-width:1366px)": {
  //     fontSize: '22px',
  // },
  "@media screen and (max-width:1440px)": {
    fontSize: "22px",
  },
}));

export const create = styled(Text)((props) => ({
  fontSize: "14px",
  color: "#fff",
  fontFamily: "Poppins",
  fontWeight: "400",
  paddingRight: "15px",
  paddingLeft: "5px",
  [`@media ${device.tabletM}`]: {
    paddingRight: "10px",
  },
  "@media screen and (max-width:1366px)": {
    fontSize: "10px",
  },
  "@media screen and (max-width:767px)": {
    fontSize: "14px",
  },
}));
export const whatVideoWrap = styled(Flex)((props) => ({
  backgroundColor: "#282828",
  width: "100%",
  maxWidth: "590px",
  padding: "12px 10px",
  borderRadius: "8px",
  alignItems: "center",
  // [`@media ${device.desktopL}`]: {
  //     width: "100%",
  //     maxWidth: "590px",
  // },
  // "@media screen and (max-width:1780px)": {
  //     width: "100%",
  //     maxWidth: "590px",
  // },
}));
export const whatVideobacground = styled(Box)((props) => ({
  backgroundColor: "#DCDCDC",
  width: "100%",
  textAlign: "center",
  borderRadius: "8px",
  padding: "8px 10px ",
  [`@media ${device.tablet}`]: {
    padding: "8px 40px ",
  },
}));
export const whatText = styled(Text)((props) => ({
  fontSize: "14px",
  color: "#000",
  fontFamily: "Poppins",
  fontWeight: "500",
  "@media screen and (max-width:992px)": {
    fontSize: "10px",
  },
}));
