import * as Style1 from "./Modal.style";
import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  Flex,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ModalFooter,
} from "@chakra-ui/react";
import * as Style from "./Posts.style";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ReadMoreText from "./ReadMoreText";
import AuthUser from "../../services/Auth";
import { ChevronDownIcon } from "@chakra-ui/icons";
function SharePostModal({ isOpen, onClose, data, postId, refresh }) {
  const { api, user, userImage, Encrypt } = AuthUser();
  const navigate = useNavigate();
  const [isOnlyMe, setIsOnlyMe] = useState(0);
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const submit = async () => {
    setIsLoading(true);

    let feedId = data?.shared_feed ? data?.shared_feed?.id : postId;
    console.log(feedId);
    api
      .post("/feeds/share", {
        shared_feed_id: feedId,
        body: description,
        is_only_me: isOnlyMe,
      })
      .then((res) => {
        if (res.data.success) {
          setMessage("Post shared successfully");
          setTimeout(() => {
            onClose();
            setDescription("");
            setMessage("");
            refresh();
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        setMessage(err.response.data.message[0]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size={{ sm: "lg", md: "4xl" }}
      maxHeight="85vh"
    >
      <ModalOverlay />
      <Style1.ModalForumContent>
        <Style1.ModalForumBody
          borderBottomLeftRadius="0!important"
          borderBottomRightRadius="0!important"
        >
          <Style1.createWrap>
            <Box />
            <Text color="#fff" style={{ fontFamily: "Poppins" }}>
              Share post
            </Text>
            <Style1.CancelBtn
              color="#fff!important"
              className="bi bi-x-lg"
              onClick={() => {
                onClose();
              }}
            />
          </Style1.createWrap>
          <Box
            justifyContent="center"
            maxHeight="80vh"
            overflowY="scroll"
            style={{
              " :-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none" /* IE and Edge */,
              "scrollbar-width": "none",
            }}
            padding="0"
          >
            <Flex
              style={{
                justifyContent: "space-between",
                padding: "15px 20px 10px",
              }}
            >
              <Flex style={{ alignItems: "center", width: "100%" }}>
                <Style.image2
                  alt="image"
                  src={
                    userImage
                      ? userImage
                      : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${user?.name}`
                  }
                />
                <Box paddingX="10px" width="100%">
                  <Style.PostsTitle
                    _hover={{
                      cursor: "pointer",
                      textDecor: "underline",
                    }}
                    onClick={() => {
                      navigate("/forum/profile/" + Encrypt(data?.user_id));
                    }}
                  >
                    {user?.name}
                  </Style.PostsTitle>
                  <Style.Postsactive>
                    <Menu autoSelect="false" matchWidth="true">
                      <MenuButton
                        as={Button}
                        display="flex"
                        justifyContent="space-between"
                        borderRadius="5px"
                        textAlign="start"
                        _hover={{
                          color: "#fff",
                          backgroundColor: "#444!important",
                        }}
                        width="110px"
                        height="30px"
                        fontWeight="500"
                        fontFamily="poppins"
                        fontSize="12px"
                        cursor="pointer"
                        p="5px 10px"
                        color="#fff"
                        backgroundColor="#444!important"
                        textTransform="capitalize"
                        rightIcon={<ChevronDownIcon />}
                      >
                        <Box
                          display="inline-block"
                          className={`bi-${
                            isOnlyMe === 0 ? "people" : "person"
                          }`}
                          marginRight="5px"
                        />
                        <Text display="inline-block">
                          {isOnlyMe === 0 ? "Everyone" : "Only me"}
                        </Text>
                      </MenuButton>
                      <MenuList
                        color="#fff"
                        backgroundColor="#444"
                        minWidth="110px"
                      >
                        <MenuItem
                          width="100%"
                          as={Flex}
                          fontFamily="poppins"
                          textTransform="capitalize!important"
                          cursor="pointer"
                          _focus={{
                            color: "#fff",
                            backgroundColor: "#777",
                          }}
                          _hover={{
                            color: "#fff",
                            backgroundColor: "#777",
                          }}
                          onClick={() => {
                            setIsOnlyMe(0);
                          }}
                        >
                          <Box className="bi-people" marginRight="5px" />
                          <Text>Everyone</Text>
                        </MenuItem>
                        <MenuItem
                          width="100%"
                          as={Flex}
                          fontFamily="poppins"
                          textTransform="capitalize!important"
                          cursor="pointer"
                          _focus={{
                            color: "#fff",
                            backgroundColor: "#777",
                          }}
                          _hover={{
                            color: "#fff",
                            backgroundColor: "#777",
                          }}
                          onClick={() => {
                            setIsOnlyMe(1);
                          }}
                        >
                          <Box className="bi-person" marginRight="5px" />
                          <Text>Only me</Text>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Style.Postsactive>
                </Box>
              </Flex>
            </Flex>
            <Box padding="0 20px 15px">
              <Style.AddComment
                paddingLeft="20px!important"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                placeholder="Add a description..."
              />
            </Box>
            <Box
              margin="0 20px 10px"
              border="1px solid #808080"
              borderRadius="8px"
            >
              {data?.shared_feed ? (
                <>
                  {data?.shared_feed?.feed_image && (
                    <Style.image1
                      borderTopLeftRadius="8px"
                      borderTopRightRadius="8px"
                      marginBottom="10px"
                      src={data?.shared_feed?.feed_image}
                      alt=""
                    />
                  )}
                  <Style.PostsTitleWrap marginBottom="10px">
                    <Flex paddingTop="5px">
                      <Style.image
                        _hover={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(
                            "/forum/profile/" +
                              Encrypt(data?.shared_feed?.user_id)
                          );
                        }}
                        alt="image"
                        src={
                          data?.shared_feed?.user?.image
                            ? data?.shared_feed?.user?.image
                            : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${data?.shared_feed?.user?.name}`
                        }
                      />
                      <Box style={{ paddingLeft: "10px" }}>
                        <Style.PostsTitle
                          _hover={{
                            cursor: "pointer",
                            textDecor: "underline",
                          }}
                          onClick={() => {
                            navigate(
                              "/forum/profile/" +
                                Encrypt(data?.shared_feed?.user_id)
                            );
                          }}
                        >
                          {data?.shared_feed?.user?.name}
                        </Style.PostsTitle>
                        <Style.Postsactive>
                          {moment(
                            new Date(data?.shared_feed?.created_at)
                          ).fromNow()}{" "}
                          <Text
                            color="Red"
                            cursor="pointer"
                            onClick={() =>
                              navigate(
                                "/forum/feeds/category/" +
                                  data?.shared_feed?.category?.title?.toLowerCase()
                              )
                            }
                            as="span"
                          >
                            #{data?.shared_feed?.category?.title}
                          </Text>
                        </Style.Postsactive>
                      </Box>
                    </Flex>
                  </Style.PostsTitleWrap>
                  {data?.shared_feed?.description && (
                    <Box padding="0 20px 10px" fontSize="14px">
                      <ReadMoreText maxlength={200}>
                        {data?.shared_feed?.description}
                      </ReadMoreText>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {data?.feed_image && (
                    <Style.image1
                      borderTopLeftRadius="8px"
                      borderTopRightRadius="8px"
                      marginBottom="10px"
                      src={data?.feed_image}
                      alt=""
                    />
                  )}
                  <Style.PostsTitleWrap marginBottom="10px">
                    <Flex paddingTop="5px">
                      <Style.image
                        _hover={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/forum/profile/" + Encrypt(data?.user_id));
                        }}
                        alt="image"
                        src={
                          data?.user?.image
                            ? data?.user?.image
                            : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${data?.user?.name}`
                        }
                      />
                      <Box style={{ paddingLeft: "10px" }}>
                        <Style.PostsTitle
                          _hover={{
                            cursor: "pointer",
                            textDecor: "underline",
                          }}
                          onClick={() => {
                            navigate(
                              "/forum/profile/" + Encrypt(data?.user_id)
                            );
                          }}
                        >
                          {data?.user?.name}
                        </Style.PostsTitle>
                        <Style.Postsactive>
                          {moment(new Date(data?.created_at)).fromNow()}{" "}
                          <Text
                            color="Red"
                            cursor="pointer"
                            onClick={() =>
                              navigate(
                                "/forum/feeds/category/" +
                                  data?.category?.title?.toLowerCase()
                              )
                            }
                            as="span"
                          >
                            #{data?.category?.title}
                          </Text>
                        </Style.Postsactive>
                      </Box>
                    </Flex>
                  </Style.PostsTitleWrap>
                  {data?.description && (
                    <Box padding="0 20px 10px" fontSize="14px">
                      <ReadMoreText maxlength={200}>
                        {data?.description}
                      </ReadMoreText>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Style1.ModalForumBody>
        <Style1.ModalForumFooter width="100%" backgroundColor="#282828">
          <Flex
            width="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding="5px 20px 20px"
          >
            <Text
              fontSize="14px"
              fontFamily="Poppins"
              color={message == "Post shared successfully" ? "#5cb85c" : "Red"}
              textAlign={"center"}
              padding="5px "
            >
              {message}
            </Text>
            <Button
              onClick={submit}
              color="#fff"
              _hover={{ opacity: 0.7 }}
              fontFamily="Poppins"
              cursor="pointer"
              bgColor="Red"
              fontWeight="500"
              fontSize="18px"
              paddingY={{ base: "22px", lg: "25px" }}
              width="227px"
              marginX="10px"
              colorScheme="Red"
              isDisabled={isLoading}
            >
              Share
            </Button>
          </Flex>
        </Style1.ModalForumFooter>
      </Style1.ModalForumContent>
    </Modal>
  );
}

export default SharePostModal;
