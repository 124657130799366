import { Box, useDisclosure } from "@chakra-ui/react";
import React from "react";
import * as Style from "./ForumProfile.style";
import ImagePopup from "./Gallery/ImagePopup";
const FriendCard = (props) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    props?.data?.file_path && (
      <>
        <Box>
          <Style.FriendGallery
            _hover={{
              cursor: "pointer",
              borderRadius: "12px",
            }}
            onClick={onToggle}
            transition="0.6s all ease-in-out"
            alt="image"
            src={props?.data?.file_path}
          />
        </Box>
        <ImagePopup
          isOpen={isOpen}
          onClose={onClose}
          data={props.data.file_path}
        />
      </>
    )
  );
};

export default FriendCard;
