import { useState } from "react";
import axios from "axios";
import { parseGallery } from "../parser/parseGallery";
import { Gallery } from "../type/Gallery";
import {useParams } from "react-router-dom";
import AuthUser from "./Auth";
type ErrorResponse = {
  statusCode: number;
  message: string;
};
type GalleryServiceType = {
  data: Gallery[];
  loader: boolean;
  error: ErrorResponse | null;
  fetchData: Function;
};
const GalleryService = (): GalleryServiceType => {
  const {Decrypt } = AuthUser()
  const { id } = useParams()
  const [data, setData] = useState<Gallery[]>([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const fetchData = async (): Promise<void> => {
    const token = localStorage.getItem("forum_token");
    try {
      await axios({
        method: "get",
        url: process.env.REACT_APP_FORUM_API_BASE_URL+"/feeds/gallery",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: { user_id: Decrypt(id) },
      })
        .then((response) => {
          console.log("ggggggg-----", response.data.images);

          const data = parseGallery(response.data.images);
          if (response.status == 200) {
            setData(data);
          }
        })
        .catch((error) => {
          setError({
            statusCode: error.response.status,
            message: error.response.statusText,
          });
        });
    } catch (error) {
      setError({
        statusCode: 502,
        message: "Something went worng!!",
      });
    } finally {
      setLoader(false);
    }
  };
  return { data, loader, error, fetchData };
};

export default GalleryService;
