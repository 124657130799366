
import React, {useRef } from "react";
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import * as Style from './CorporateCard.style';
import { CorporateContentProps,defaultCorporateContentProps } from './CorporateCard.types';
import {Box} from '@chakra-ui/react';
import{Link} from 'react-router-dom';
import Button from '../Button/CustomeButton';

const CorporateContent  = ( props ) => {
  const ref = useRef(null);
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
  <> 
    <Style.CorporateContent  {...props}>
        <Heading title={props.Heading} color={props.color}></Heading>
        <Text title={props.paragraph} color={props.color}></Text>
        
        {props.list && <Style.CorporateOrderlist>
          {props.list.map((item, index) => {
                return (
                  <Style.CorporatelistItem key={index} color={props.color}>{item}</Style.CorporatelistItem >
            )})}
          </Style.CorporateOrderlist>
        }
        <Box display={{md:'flex'}} marginTop='15px'> <Link onClick={() => handleClickScroll("privateHireCorporate")} ><Button title='Book A Consult' color={props.btnColor}/> </Link></Box>
    </Style.CorporateContent>
  </>
  );
}
export default CorporateContent ;
CorporateContent.propTypes = CorporateContentProps;
CorporateContent.defaultProps = defaultCorporateContentProps