import React, { useEffect } from "react";
import {
  Box,
  Container,
  GridItem,
  SimpleGrid,
  Image,
  VStack,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import InnerBanner from "./InnerBanner/InnerBanner";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import ContactForm from "./Form/ContactForm";
import LinkButton from "./Button/LinkButton";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";

function ResidentialServices() {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title="Residential Services | Perpetua Fitness" />
      <InnerBanner
        backgroundColor="black"
        backgroundImage={{
          sm: "url(./images/residential-service_m.jpg)",
          md: "url(./images/residential-service.jpg)",
        }}
        isSubtext={false}
        title="Residential Service Offerings"
        subtextpara="IRELAND'S # 1 HEALTH & WELLBEING PROVIDER"
        issubtextpara={true}
        isButton={false}
        issubtitleRight={false}
        width={{ sm: "100%" }}
        height={{ sm: "100vh", md: "500px", lg: "550px", xl: "767px" }}
      />

      <Box padding={{ sm: "50px 10px 0", md: "80px 10px 30px" }}>
        <Container maxW="container.xl">
          <Box textAlign="center">
            <Heading
              title="WHO ARE WE?"
              color="black"
              padding={{ sm: "0 0 10px", md: "0" }}
            />
            <Text
              title="Perpetua is Dublin's first premium fitness brand. We offer a comprehensive wellness experience in our two locations and online via
                            our Perpetua anywhere mobile app. We offer fitness and wellness solutions for the hybrid world and are thrilled to create bespoke corporate wellness packages for companies of all sizes."
              color="black"
              width={{ md: "85%" }}
              margin="0 auto"
            />
          </Box>
          <SimpleGrid
            columns={{ sm: "1", md: "3" }}
            columnGap={10}
            rowGap={2}
            width="full"
            padding="50px 10px"
          >
            <GridItem padding="5px 0">
              <Heading title="PASSION" color="#D12C41" variant="small" />
              <Text
                title="Our passion is to create a safe and effective training environment with a supportive community of like-minded individuals. Our coaches are full time professionals who have dedicated their lives to helping others through health and fitness."
                color="black"
              />
            </GridItem>
            <GridItem padding="5px 0">
              <Heading title="EXPERIENCE" color="#D12C41" variant="small" />
              <Text
                title="We’ve built an environment where doing the right thing on a daily basis is rewarded. A place where we help each other as a group become better, stronger, more educated and more tolerant people by training with professional systems and support."
                color="black"
              />
            </GridItem>
            <GridItem padding="5px 0">
              <Heading title="COMMUNITY" color="#D12C41" variant="small" />
              <Text
                title="We pride ourselves on the community we have built across the last decade. You are not just joining a gym. You become part of a community here at Perpetua. It is a place to 
                            connect and make new friends. You will find yourself with a new social and support network that will help you on your continued path to greater health and happiness."
                color="black"
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Box
        backgroundImage="url(./images/mid-bg.jpg)"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <Container maxW={{ md: "container.lg", xl: "container.xl" }}>
          <SimpleGrid
            columns={{ sm: "1", md: "2" }}
            spacing={{ sm: "20px", md: "50px" }}
            width="full"
            padding={{ sm: "50px 10px", md: "80px 0px" }}
          >
            <GridItem
              position={{ md: "sticky" }}
              top="100px"
              height={{ sm: "auto", md: "200px" }}
            >
              <Heading title="RESIDENT PERKS" color="white" />
            </GridItem>
            <GridItem paddingLeft={{ sm: "0", md: "20px" }}>
              <Box>
                <Heading
                  title="EXCLUSIVE PERPETUA DISCOUNTS"
                  color="#D12C41"
                  variant="medium"
                  padding="5px 0px 0px"
                />
                <Text
                  title="Including discounts on studio classes, JustGYM memberships, physio appointments and more!"
                  color="white"
                  padding="10px 10px 10px 0"
                />
              </Box>
              <Box>
                <Heading
                  title="PERPETUA ANY WHERE"
                  color="#D12C41"
                  variant="medium"
                  padding="5px 0px 0px"
                />
                <Text
                  title="Built for our hybrid world, Perpetua Anywhere is an app available on all devices, encompassing your health, fitness and wellness needs from training at home or in the gym to customising your own nutrition."
                  color="white"
                  padding="10px 10px 10px 0"
                />
              </Box>
              <Box>
                <Heading
                  title="EVENTS & WORKSHOPS"
                  color="#D12C41"
                  variant="medium"
                  padding="5px 0px 0px"
                />
                <Text
                  title="With specialists in the health, fitness & wellbeing industry"
                  color="white"
                  padding="10px 10px 10px 0"
                />
              </Box>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Box padding={{ sm: "50px 0", md: "80px 0" }}>
        <Container maxW={{ md: "container.lg", xl: "container.xl" }}>
          <SimpleGrid
            columns={{ sm: "1", md: "2" }}
            spacing="50px"
            width="full"
          >
            <GridItem>
              <Heading
                title="PERPETUA DISCOUNTS"
                variant="large"
                lineHeight="0.8"
                padding="0 0 0px"
              />
              <Heading
                title="Exclusive to residential partners "
                color="#D12C41"
                variant="extrasmall"
                padding="10px 0"
                width="90%"
              />
              <UnorderedList
                listStyleType="none"
                fontFamily="Poppins"
                marginTop="15px"
                padding="0px"
                marginInlineStart="0px"
              >
                <ListItem padding="5px 0" display="flex">
                  <Image
                    src="./images/Vector.png"
                    width="13px"
                    height="13px"
                    marginTop="5px"
                    marginRight="7px"
                  />{" "}
                  10% off all Studio Credit Packs including - RIDE, TREAD &
                  SHRED, RUMBLE & HYROX
                </ListItem>
                <ListItem padding="5px 0" display="flex">
                  <Image
                    src="./images/Vector.png"
                    width="13px"
                    height="13px"
                    marginTop="5px"
                    marginRight="7px"
                  />{" "}
                  10% JustGYM and CrossFit Memberships
                </ListItem>
                <ListItem padding="5px 0" display="flex">
                  <Image
                    src="./images/Vector.png"
                    width="13px"
                    height="13px"
                    marginTop="5px"
                    marginRight="7px"
                  />{" "}
                  10% off Personal Training
                </ListItem>
                <ListItem padding="5px 0" display="flex">
                  <Image
                    src="./images/Vector.png"
                    width="13px"
                    height="13px"
                    marginTop="5px"
                    marginRight="7px"
                  />{" "}
                  10% Nutrition Packages and Body Composition Analysis
                </ListItem>
                <ListItem padding="5px 0" display="flex">
                  <Image
                    src="./images/Vector.png"
                    width="13px"
                    height="13px"
                    marginTop="5px"
                    marginRight="7px"
                  />{" "}
                  10% Off Physio Therapy
                </ListItem>
              </UnorderedList>
            </GridItem>
            <GridItem>
              <Image
                src="./images/PERPETUA-DISCOUNTS.jpg"
                className="wow animate fadeInUp"
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <VStack
        backgroundColor="black"
        padding={{ sm: "30px 0px", md: "96px 0px" }}
        borderBottom="1px solid #232323"
      >
        <Container
          display="flex"
          maxW={{ md: "container.lg", xl: "container.xl" }}
          flexDirection={{ base: "column", lg: "row" }}
          alignItems="top"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
        >
          <Box
            w={{ md: "100%", lg: "50%" }}
            textAlign={{ base: "center", lg: "left" }}
            marginBottom={{ base: "50px", lg: "0" }}
          >
            <Heading
              title="LOOKING FOR MORE INFORMATION"
              variant="extralarge"
              lineHeight="1"
              margin="0px 0px 40px"
              color="white"
            ></Heading>
            <Heading
              title="Chat with a member of the team"
              variant="smallmid"
              lineHeight="1"
              margin="0px 0px 30px"
              color="white"
            ></Heading>
            <Link
              href="/contact-us"
              textDecoration="none"
              display="inline-block"
              _hover={{ textDecoration: "none" }}
            >
              <LinkButton
                title="BOOK A CALL"
                Imguri="/images/red_arrow.svg"
                border="2px solid"
                borderColor="Red"
                padding="20px 25px"
                color="White"
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ md: "100%", lg: "50%" }}>
            <ContactForm pageName="Residential"></ContactForm>
          </Box>
        </Container>
      </VStack>
    </>
  );
}

export default ResidentialServices;
