import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AuthUser from "./Auth";
import { parseCategories } from "../parser/parserFeed";
import type { CategoriesType, RandomUserType } from "../type/Feed";
import { parseFollowing, parseRandomUser } from "../parser/parseProfile";
export const GetPosts = () => {
  const { api, Decrypt } = AuthUser()
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { id } = useParams()
  const fetchData = () => {
    setLoader(true)
    api.get(
      "/feeds/all",
      {
        params: { user_id: id ? Decrypt(id) : null },
      })
      .then((res: any) => {
        if (res?.data?.success) {
          setData(res?.data?.feeds);
        } else {
          setData([]);
        }
        console.log("jhgjhgjhg", res.data);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  return { data, loader, fetchData };
};

export const GetSinglePost = () => {
  const { api, Decrypt } = AuthUser()
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { id } = useParams()
  const fetchData = () => {
    setLoader(true)
    api
      .get("/feeds/details", { params: { feed_id: Decrypt(id) } })
      .then((res: any) => {
        if (res?.data?.success) {
          setData(res?.data?.
            post);
        } else {
          setData([]);
        }
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  return { data, loader, fetchData };
}
export const LikeUnlike = () => {
  const { api } = AuthUser()
  const likeHandler = (id: number) => {
    api
      .post(
        "/like/create",
        { feed_id: id },
      )
      .then(() => {
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const unLikeHandler = (id: number) => {
    api
      .post(
        "/like/remove",
        { feed_id: id },

      )
      .then(() => {
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  return { likeHandler, unLikeHandler }
}
export const GetCategories = () => {
  const { api } = AuthUser()
  const [loader, setLoader] = useState(true);
  const [categories, setCategories] = useState<Array<CategoriesType>>([]);

  const fetchData = () => {
    api.get("/feeds/categories")
      .then((res: any) => {
        if (res?.data?.success) {
          const data = parseCategories(res?.data?.categories);
          setCategories(data);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  }


  useEffect(() => {
    fetchData();
  }, []);


  return { categories }


};
export const GetLikeList = (id: number) => {
  const { api } = AuthUser()
  const [likeList, setLikeList] = useState<Array<RandomUserType>>([]);
  const [loader, setLoader] = useState(true);

  const fetchData = () => {
    setLoader(true);
    api
      .get("/like/list", {
        params: { feed_id: id },
      })
      .then((res) => {
        if (res.data.success) {
          const data = parseFollowing(res?.data?.data)
          setLikeList(data);
        }
      })
      .catch((err) => {
        console.log("user", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }


  useEffect(() => {
    fetchData();
  }, []);


  return { loader, likeList }


};
export const GetRandomusers = () => {
  const { api } = AuthUser()
  const [friendSuggestion, setFriendSuggestion] = useState<Array<RandomUserType>>([]);
  const fetchData = () => {
    api.get("/profile/all",
      {
        params: { page_num: "100" },
      })
      .then((res: any) => {
        if (res?.data?.success) {
          const data = parseRandomUser(res?.data?.data?.data);
          const mayYouKnow = data?.filter(
            (item) => item?.is_followed === false
          );
          setFriendSuggestion(mayYouKnow);
        }
        console.log(res?.data?.data?.data);
      })
      .catch((err: any) => {
        console.log(err);
      });

  };
  useEffect(() => {
    fetchData()
  }, [])

  return { friendSuggestion }

};











