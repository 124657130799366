import type { CategoriesType} from "../type/Feed";
export const parseCategories = (data: any): Array<CategoriesType> => {
  if (!data) return [];
  return data.map((item : any) => {
    return {
        id:item.id,
        title:item.title,
        slug:item.slug
    } 
  })
};

