import React from "react";
import * as Style from "./Career.style";
import { Container, SimpleGrid, VStack, Box } from "@chakra-ui/react";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import MetaTitle from "./MetaTitle";
import OnlineApplication from "./Form/OnlineApplication";

function Career() {
  return (
    <>
      <MetaTitle title="Online Application | Perpetua Fitness" />
      <Style.Wrapper>
        <Style.Header>
          <Container maxW="container.xl">
            <Text
              title="We are a team of Fitness creating a global impact"
              as="p"
              color="#fff"
              width={{ sm: "100%", md: "80%" }}
              className="wow animate fadeInUp"
              margin="0px auto"
            ></Text>
            <Heading
              title="Want to Join Us"
              as="h2"
              color="#fff"
              margin="0px 0px 25px"
              className="wow animate fadeInUp"
            ></Heading>
          </Container>
        </Style.Header>
        <Container maxW="container.xl">
          <OnlineApplication />
        </Container>
      </Style.Wrapper>
    </>
  );
}

export default Career;
