import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Ride45 from "../component/Ride45";


const Ride45Page = () => {
  return (
    <MainWrapper>
      <Ride45 />
    </MainWrapper>

  );
};

export default Ride45Page;
