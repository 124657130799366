import React, { useEffect } from "react";
import InnerBanner from "./InnerBanner/InnerBanner";
import * as Style from "./Corporate.style";
import {
  Container,
  SimpleGrid,
  VStack,
  Box,
  GridItem,
  Image,
  Link,
  Button,
} from "@chakra-ui/react";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import CorporateCard from "./CorporateCard/CorporateCard";
import CorporateContent from "./CorporateCard/CorporateContent";
import CorporateimageBox from "./CorporateCard/CorporateimageBox";
import CorporateimageCard from "./CorporateCard/CorporateimageCard";
import PrivateHireAndCorporateForm from "./Form/PrivateHireAndCorporateForm";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";
import VideoCard from "./VideoCard/VideoCard";
import { Helmet } from "react-helmet";
import VideoBanner from "./VideoBanner/VideoBanner";

function Corporate() {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  let CorporateimageBoxcontent = [
    {
      Heading: "PRIVATE CLASSES",
      paragraph:
        "Fancy your own private class ? Host your own group or corporate class in our RIDE or SWEAT studios. You can hire our studios to schedule a weekly or monthly private class for your employees. The Perpetua team can assist with any special requirements and will provide any of our in house instructors upon request",
      list: [
        // "Bundles available starting at 50 credits",
        // "Our team will provide usage reports and insights on a weekly, monthly, or quarterly basis"
        "Step outside the office and take your pick between Tread & Shred, RIDE or Rumble.",
        "Exclusive add ons incl. shakes and water available to enhance your event. Special in house lighting, visuals and music playlists available upon request",
      ],
    },
  ];

  let CorporateCardcontent = [
    {
      Heading: "SHARED CREDIT POOL",
      paragraph:
        "An easy, low-lift way to invest in your people! Set up a shared credit pool that your employees can use to book into our studio classes. This offers your team members flexibility and autonomy and is our most popular corporate perk by far!",
      list: [
        "Exclusive add ons available to create your desired event",
        "Special in house lighting, visuals and music playlists available upon request",
      ],
    },
  ];

  let CorporateimageCardcontent = [
    {
      Heading: "PERPETUA ANYWHERE",
      paragraph:
        "Is your team spread out across the world working remotely? Keep everyone moving with Perpetua Anywhere, Ireland’s number 1 health and fitness app. Perpetua Anywhere offers a wide range of classes including HIIT workouts, mindful meditation, RIDE classes, and more.",
    },
    {
      Heading: "EMPLOYEE GIFTING ",
      paragraph:
        "Celebrate work anniversaries or a job well-done by giving the gift of fitness! We offer corporate gift vouchers as well as bespoke gift packages to include in your employee recognition programs.",
    },
  ];
  const scrollToBottom = () => {
    const maxScroll =
      document.documentElement.scrollHeight - window.innerHeight - 700;
    const scrollPosition = Math.min(
      maxScroll,
      document.documentElement.scrollHeight
    );

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet
        script={[
          {
            innerHTML: `window.lintrk('track', {
              conversion_id: 15193380
            });`,
          },
        ]}
      />

      <MetaTitle title="Corporate Wellness & Fitness - Dublin | Perpetua Fitness" />

      <VideoBanner
        videobanner="../video/video-corporat.mp4"
        title="TRAIN WITH YOUR TEAM"
        isSubtext={false}
        isButton={false}
        issubtextpara={true}
        subtextpara="WORK FRIENDS TO PERPETUA TO MOVE AND CONNECT TOGETHER!"
        isButton2={true}
        buttontitle2="Book A Consult"
        margin="0px 0 0"
        videoModal="https://www.youtube.com/embed/t3x47LUlTog"
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={false}
        marginTop="20px 0px 0px"
      />
      <Style.CorporateOffering>
        <Container maxW="container.xl">
          <Text title="PERPETUA @ WORK" as="small" color="#fff"></Text>
          <Heading
            title="CORPORATE WELLNESS"
            as="h2"
            color="#fff"
            margin="0px 0px 25px"
            className="wow animate fadeInUp"
          ></Heading>
          <Text
            title="Offer your employees and clients a premium fitness experience at Perpetua! We can create a specially-curated package for your company to support your team members both physically and mentally. Let’s work together to create a full-circle employee wellness experience!"
            as="p"
            color="#fff"
            width={{ sm: "100%", md: "80%" }}
            className="wow animate fadeInUp"
            margin="0px auto"
          ></Text>
        </Container>
      </Style.CorporateOffering>
      <InnerBanner
        backgroundColor="black"
        backgroundImage={{
          sm: "url(./images/CorporateWellnessWeeksmall.webp)",
          md: "url(./images/CorporateWellnessWeek.webp)",
        }}
        title={
          <>
            Corporate <Box color="Red">Wellness Week</Box>{" "}
          </>
        }
        subtextpara="At Perpetua Fitness"
        issubtextpara={true}
        isButton={false}
        isButton2={false}
        issubtitleRight={false}
        isSubtext={false}
        //buttontitle2="Book A Consult"
        width={{ sm: "100%", md: "100%" }}
        margin="30px 0 0"
        // Link2="/join-today"
      />
      <Box
        backgroundColor="#000"
        padding={{ sm: "50px 10px", md: "50px 20px" }}
      >
        <Container maxW="container.xl">
          <Text
            title="APRIL 22-26 2024"
            textAlign="center"
            fontSize="18px"
            color="#fff"
          ></Text>
          <Heading
            title="From meeting rooms to studios, our Corporate Wellness Week is COMING! "
            variant="small"
            color="#fff"
            textAlign="center"
            padding="10px 0 0"
          ></Heading>
        </Container>
      </Box>
      <Container
        maxW="container.xl"
        padding={{
          sm: "50px 20px",
          md: "50px 20px",
          lg: "50px 35px",
          xl: "100px 20px 0",
        }}
      >
        <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
          <GridItem>
            <Image
              src="/images/corporateWelkness1.webp"
              height="340px"
              width={{ sm: "100%", md: "500px" }}
            />
            <Heading
              title="BRING A COLLEAGUE TO CLASS FOR FREE!"
              variant="large"
              lineHeight="0.9"
              padding="15px 0 0"
            />
            <Text
              title="During Corporate Wellness Week, we are opening exclusive spots in our studio classes for you and your colleagues for FREE in Windmill Lane & Lennox Street! It’s time to show them your go-to spot outside the office morning, lunch and evening."
              padding="20px 0 5px"
            />
            <Text
              title={
                <Box>
                  Make sure you both have an account with us under your work
                  email. Contact us at{" "}
                  <Link
                    href="mailto:hello@perpetua.ie"
                    style={{ color: "#D12C41" }}
                  >
                    hello@perpetua.ie
                  </Link>{" "}
                  with subject line ‘Corporate Wellness Week’ and provide your
                  colleagues email address. Let us know what class you’d both
                  like to attend (subject to availability).
                </Box>
              }
              padding="0px 0 20px"
            />
            <Heading
              title="BOOKING OPENS THURSDAY 18TH APRIL."
              variant="small"
            />
          </GridItem>
          <GridItem>
            <Image
              src="/images/corporateWelkness2.webp"
              width={{ sm: "100%", md: "500px" }}
              height="340px"
            />
            <Heading
              title="GET 30% OFF A STUDIO PRIVATE HIRE!"
              variant="large"
              lineHeight="0.9"
              padding="15px 0 0"
            />
            <Text
              title={
                <Box>
                  Book a Studio Private hire in one of our Studios Classes -
                  Tread & Shred, RIDE, HYROX or Rumble. <br />
                  Our expert trainers curate customised fitness classes to
                  suitable your teams' level, needs and availability. Foster
                  camaraderie and teamwork through group exercises, boosting
                  morale and productivity in the office.{" "}
                </Box>
              }
              padding="20px 0"
            />
            <Heading
              title="GET IN TOUCH BELOW FOR MORE INFORMATION!"
              variant="smallmid"
              padding="0 0 20px"
            />
            <Button
              backgroundColor="transparent"
              padding="22px 30px"
              textTransform="uppercase"
              fontFamily="Poppins"
              fontSize="16px"
              cursor="pointer"
              color="#000"
              border="2px solid #D12C41"
              borderRadius="50px"
              fontWeight="500"
              _hover={{ backgroundColor: "Red" }}
              onClick={scrollToBottom}
            >
              FIND OUT MORE
            </Button>
            <Heading
              title="GET 30% OFF THE BOOKING WHEN YOU BOOK WITH US BEFORE 26TH APRIL.*"
              lineHeight="0.9"
              variant="small"
              padding="20px 0 10px"
            />
            <Heading
              title="Class must take place before 31st May."
              variant="small"
            />
          </GridItem>
        </SimpleGrid>
      </Container>

      <CorporateCard bg="url('/images/corpotare1.jpg')">
        <CorporateContent
          width={{ md: "55%", lg: "60%", xl: "50%" }}
          Heading={CorporateCardcontent[0].Heading}
          paragraph={CorporateCardcontent[0].paragraph}
          list={CorporateCardcontent[0].list}
          btnColor="#000"
        ></CorporateContent>
      </CorporateCard>

      <CorporateimageBox bg="url('/images/corporate-bg.jpg')">
        <CorporateContent
          width={{ md: "55%", lg: "60%", xl: "50%" }}
          Heading={CorporateimageBoxcontent[0].Heading}
          paragraph={CorporateimageBoxcontent[0].paragraph}
          list={CorporateimageBoxcontent[0].list}
          color="#fff"
        ></CorporateContent>
      </CorporateimageBox>

      <Container
        maxW="container.xl"
        padding={{
          sm: "50px 20px",
          md: "50px 20px",
          lg: "50px 35px",
          xl: "100px 20px",
        }}
      >
        <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10}>
          <CorporateimageCard
            Heading={CorporateimageCardcontent[0].Heading}
            paragraph={CorporateimageCardcontent[0].paragraph}
            btnColor="#000"
          >
            <Style.Corporateimage backgroundImage="url('/images/corporate4.jpg')"></Style.Corporateimage>
          </CorporateimageCard>

          <CorporateimageCard
            Heading={CorporateimageCardcontent[1].Heading}
            paragraph={CorporateimageCardcontent[1].paragraph}
            btnColor="#000"
          >
            <Style.Corporateimage backgroundImage="url('/images/corporate5.jpg')"></Style.Corporateimage>
          </CorporateimageCard>
        </SimpleGrid>
      </Container>
      <Style.VideoSection>
        <Container maxW="container.xl">
          <Style.VideoWrapper>
            <VideoCard
              src="https://www.youtube.com/embed/6AfQQs0neAU?si=8nefcVpsH9ZAlfrZ"
              width={{ sm: "100%", md: "800px" }}
              maxHeight={{ sm: "420px", md: "600px" }}
            ></VideoCard>
          </Style.VideoWrapper>
        </Container>
      </Style.VideoSection>

      <VStack
        backgroundColor="black"
        padding={{ sm: "50px 0px", md: "96px 0px" }}
        borderBottom="1px solid #232323"
      >
        <Container
          display="flex"
          maxW="container.xl"
          flexDirection={{ sm: "column", md: "row" }}
          alignItems="top"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
        >
          <Box
            w={{ sm: "100%", md: "50%", lg: "40%" }}
            textAlign={{ sm: "center", md: "left" }}
            marginBottom={{ base: "50px", lg: "0" }}
            position={{ sm: "relative", md: "sticky" }}
            top={{ sm: "0px", md: "100px" }}
            height={{ sm: "auto", md: "500px" }}
          >
            <Heading
              title="LOOKING FOR MORE INFORMATION"
              variant="extralarge"
              lineHeight="1"
              margin="0px 0px 40px"
              color="white"
            ></Heading>
            <Heading
              title="The sky is the limit! We can create a bespoke package that suits the needs of your team. We are here to help – so get in touch and let’s get moving!"
              variant="smallheading"
              lineHeight="1.4"
              margin="0px 0px 30px"
              color="white"
            ></Heading>
          </Box>
          <Box w={{ sm: "100%", md: "50%", lg: "60%" }}>
            <PrivateHireAndCorporateForm />
          </Box>
        </Container>
      </VStack>
    </>
  );
}

export default Corporate;
