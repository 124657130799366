import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Membership from "../component/Membership";


const MembershipPage = () => {
  return (
    <MainWrapper>
      <Membership />
    </MainWrapper>

  );
};

export default MembershipPage;
