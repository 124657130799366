import { Flex,Box, Image, UnorderedList, ListItem, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,Link,Tab} from "@chakra-ui/react";
import Heading from "../../component/Heading/Heading";
import Text from "../../component/SubHeading/SubHeading";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const PageBanner= styled(Link)(props => ({
display:'Flex',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',    
padding:'0',
margin:'0',
backgroundPosition:'center',
backgroundRepeat:'no-repeat',
backgroundSize:'cover', 
height:'820px',
"@media screen and (max-width:1366px)": {
    height:'600px',
},
"@media screen and (max-width:1199px)": {
    height:'500px',
},
"@media screen and (max-width:767px)": {
    height:'450px',
},
"@media screen and (max-width:480px)": {
    height:'400px',
},
"@media screen and (max-width:400px)": {
    height:'400px',
},
 
}))

export const CaptionWrapper= styled(Flex)(props => ({
    padding:'0',
    margin:'0',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',   
    height:'600px',
    "@media screen and (max-width:1366px)": {
        height:'600px',
    },
    "@media screen and (max-width:1199px)": {
        height:'500px',
    },
    "@media screen and (max-width:767px)": {
        height:'450px',
    },
    "@media screen and (max-width:480px)": {
        height:'400px',
    },
}))

export const Caption= styled(Flex)(props => ({
    padding:'0',
    margin:'0',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',   
}))

export const CenterContent= styled(Box)(props => ({
    padding:'30px 0px',
    margin:'0',
    textAlign:'center', 
    position:'relative',
    [`@media ${device.tabletS}`]: {
        padding:'20px 0px',
    },
}))

export const CenterContentWrapper= styled(Flex)(props => ({
    padding:'0',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',  
    gap:'20px' ,
}))

export const CenterContentHeader= styled(Flex)(props => ({
    padding:'0',
    margin:'0',
    alignItems:'start',
    justifyContent:'center',
    flexDirection:'column',  
    gap:'15px' ,
}))

export const h2= styled(Heading)(props => ({
    lineHeight:'1',
    position:'relative',
    zIndex:1
}))

export const p= styled(Text)(props => ({
    margin:'0',
    width:'80%',
    margin:'0px auto',
    [`@media ${device.mobileS}`]: {
        width:'100%',
    },
}))

export const bold= styled(Text)(props => ({
    margin:'0',
    margin:'0px auto',
    fontWeight:'bold',
    textTransform:'uppercase'
}))

export const midContent= styled(Box)(props => ({
    margin:'0',
}))

export const midcontentWrapper= styled(Flex)(props => ({
    paddingTop: '30px',
    alignItems:'flex-start',
    justifyContent:'space-between',
    flexDirection:'row',  
    gap:'30px' ,
    [`@media ${device.tabletS}`]: {
        flexDirection:'column', 
    },
}))

export const imageBox= styled(Box)(props => ({
    alignItems:'center',
    width:'50%',
    minHeight:'400px',
    [`@media ${device.tabletS}`]: {
        width:'100%',
        minHeight:'auto',
    },
}))

export const image= styled(Image)(props => ({
   objectFit:'cover',
   objectPosition:'center',
   width:'100%',
   height:'100%',
    
}))

export const contentBox= styled(Box)(props => ({
    alignItems:'center',
    width:'50%',
    [`@media ${device.tabletS}`]: {
        width:'100%',
    },
}))

export const description= styled(Text)(props => ({
    padding:'0',
    margin:'0',
}))

export const linkWrapper= styled(Flex)(props => ({
    padding:'0',
    margin:'0',
}))

export const link= styled(Link)(props => ({
    padding:'0',
    margin:'0',
    color:'#000',
    fontFamily:' Poppins',
    paddingLeft:'5px',
    textDecoration:'underline'
}))

export const pointList= styled(UnorderedList)(props => ({
    padding:'0',
    margin:'0',
    fontFamily:' Poppins',
    marginTop:'0px',
    paddingLeft:'20px',
}))

export const pointlistItem= styled(ListItem)(props => ({
    padding:'0',
    margin:'0',
    listStyle:'disc',
    marginBottom:'8px',
    fontSize:'16px',
    fontWeight:'500' ,
    [`@media ${device.mobileS}`]: {
        fontSize:'14px',
    },

}))

export const faqSection= styled(Box)(props => ({
padding:'80px 0px',
}))

export const faqBox= styled(Accordion)(props => ({

}))

export const faqItem= styled(AccordionItem)(props => ({
    border:'0',
    borderBottom:'1px solid #d8d8d8',

}))
export const itemButton= styled(AccordionButton)(props => ({
justifyContent:'space-between',
border:'0',
padding:'20px 10px',
"h2":{
    fontFamily:' Poppins',
    fontWeight:'500',
    textTransform:'capitalize',
},
[`@media ${device.tabletS}`]: {
    textAlign:'left',
},
}))

export const itemIcon= styled(AccordionIcon)(props => ({
fontSize:'18px'
}))

export const itemArea= styled(AccordionPanel)(props => ({

}))

export const pageButton= styled(Link)(props => ({
    display:'inline-flex',
    alignItems:'center',
    justifyContent:'center',
    padding:0,
    padding:'9px 30px',
    color:'#000',
    border:'2px solid #d12c41',
    textAlign:'center',
    textTransform:'uppercase',
    fontSize:20,
    borderRadius:300,
    '&:hover':{
        backgroundColor:'#d12c41',
        color:'#fff'
    }
}))

export const pageButton2= styled(Link)(props => ({
    display:'inline-flex',
    alignItems:'center',
    justifyContent:'center',
    padding:0,
    padding:'9px 30px',
    border:'2px solid #d12c41',
    textAlign:'center',
    textTransform:'uppercase',
    fontSize:20,
    borderRadius:300,
    '&:hover':{
        backgroundColor:'#d12c41',
        color:'#fff'
    }
}))


export const ResolutionPacks= styled(Box)(props => ({
    
}))

export const ResolutionPacksWrapper= styled(Flex)(props => ({
    alignItems:'center',justifyContent:'center',gap:'30px',flexDirection:'column', flexWrap:'wrap',
}))
export const Packs= styled(Flex)(props => ({
    flexDirection:'row',alignItems:'center',justifyContent:'center',gap:'10px',flexWrap:'wrap', 
    [`@media ${device.tabletS}`]: {
        flexDirection:'column',
    },
}))
export const ResolutionPacksbox= styled(Flex)(props => ({
    alignItems:'center',justifyContent:'center',gap:'10px',flexDirection:'row', flexWrap:'wrap', width:'32%',border:'2px solid red',borderRadius:'10px',minHeight:' 223px',
    
    "@media screen and (max-width:1199px)": {
        width:'47%',
    },
    "@media screen and (max-width:767px)": {
        width:'100%',
    }
    
}))
export const PacksboxHeader= styled(Flex)(props => ({
    flexDirection:'column',alignItems:'start', textAlign:'left', lineHeight:'1'
}))
export const PacksboxInner= styled(Flex)(props => ({
    alignItems:'start',justifyContent:'space-between',gap:'10px',flexDirection:'row', flexWrap:'wrap',
}))
export const Packsboxcontent= styled(Flex)(props => ({
    alignItems:'start',justifyContent:'center',gap:'10px',flexDirection:'column', flexWrap:'wrap', width:' 63%',minHeight:' 223px',
    
}))



export const LogoIcon= styled(Image)(props => ({
    objectFit:'cover',
    objectPosition:'center',
    width:'100%',
    height:'100%',
     
 }))

export const SubText= styled(Box)(props => ({
display:'flex',
alignItems:'flex-start',
justifyContent:'flex-start',
gap:5,
alignSelf:'flex-start'

}))

export const ContentSection= styled(Box)(props => ({
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    alignSelf:'center',
    background:'#FFE5E5',
    position:'relative',
    padding:'65px 0px'
}))

export const LineTop= styled(faqBox)(props => ({
    display:'Flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',    
    padding:'0',
    margin:'0',
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    width:'372px', 
    height:'544px',
    position:'absolute',
    top:0,
    left:0,


    "@media screen and (max-width:1199px)": {
        display:'none'
    }
}))

export const Linebottom= styled(Box)(props => ({
    display:'Flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',    
    padding:'0',
    margin:'0',
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    width:'562px', 
    height:'484px',
    position:'absolute',
    bottom:0,
    right:0,

    "@media screen and (max-width:1199px)": {
        display:'none'
    }

}))
export const WaveGraphics= styled(Box)(props => ({
    display:'Flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',    
    padding:'0',
    margin:'0',
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    width:'100%', 
    height:'18px',
    position:'absolute',
    top:-12,
    left:0,
    overflow:'hidden'

}))
export const WaveGraphics2= styled(Box)(props => ({
    display:'Flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',    
    padding:'0',
    margin:'0',
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    width:'100%', 
    height:'18px',
    position:'absolute',
    top:-5,
    left:0,
    overflow:'hidden'

}))

export const TabItem= styled(Tab)(props => ({
    border:'2px solid #F06776',
    fontFamily:' Poppins',
    fontSize:'16px ',
    color:'#000',
    fontWeight:500,
    padding:'8px 15px',
    marginRight:10

}))

export const TabContent= styled(Box)(props => ({
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    alignSelf:'center',
    position:'relative',
    width:'90%',
    margin:'0px auto',
    borderRadius:' 20px',
    border: '2px dashed rgba(240, 103, 118, 0.53)',
    marginTop:30,
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'contain',

    "@media screen and (max-width:575px)": {
        width:'100%',
    },

}))

export const highlight= styled(Box)(props => ({
    display:'Flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',    
    padding:'0',
    margin:'0',
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    width:'354px', 
    height:'100px',
    position:'absolute',
    top:-10,
    left:0,
    right:0,
    margin:'auto',
    overflow:'hidden',

    "@media screen and (max-width:1199px)": {
        width:' 174px',height:'48px'
    },

}))


export const Calenter= styled(Box)(props => ({
    display:'Flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',    
    padding:'0',
    margin:'0',
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    width:'354px', 
    height:'100px',
    position:'absolute',
    top:35,
    left:0,
    right:0,
    margin:'auto',
    overflow:'hidden',

}))

export const VideoSection= styled(Flex)(props => ({
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'row', 
    flexWrap:'wrap',
    gap:40,
}))

export const VideoContent= styled(Flex)(props => ({
    alignItems:'flex-end',
    justifyContent:'flex-end',
    flexDirection:'column', 
    flexWrap:'wrap',
    width:'30%',
    overflow:'hidden',
    "@media screen and (max-width:767px)": {
        width:'100%',
        alignItems:'center',
        justifyContent:'center',
    },
}))

export const VideoBox= styled(Flex)(props => ({
    width:'60%',
    borderRadius:10,
    overflow:'hidden'
}))


export const VideoBoxContent= styled(Flex)(props => ({
    alignItems:'flex-start',
    justifyContent:'flex-start',
    flexDirection:'column', 
    flexWrap:'wrap',
    width:'50%',
    "@media screen and (max-width:767px)": {
        width:'100%',
    },
}))