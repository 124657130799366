import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Podcast from "../component/Podcast";


const PodcastPage = () => {
  return (
    <MainWrapper>
      <Podcast />
    </MainWrapper>

  );
};

export default PodcastPage;
