import {
  Box,
  Container,
  Flex,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Image,
  Img,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import Button from "./Button/CustomeButton";
import { Link } from "react-router-dom";
import PricingWraper from "./Pricing/PricingWraper";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";
import InnerBanner from "./InnerBanner/InnerBanner";

function Pricing() {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title="Pricing | Perpetua Fitness" />

      <InnerBanner
        class="pricing-page-banner"
        backgroundColor="black"
        backgroundImage={{
          sm: "url(./images/pricingMembershipSaleSmall.webp)",
          md: "url(./images/pricingMembershipSale.webp)",
        }}
        isHeading={false}
        issubtextpara={false}
        isButton={false}
        issubtitleRight={false}
        isSubtext={false}
        width={{ sm: "90%", md: "70%", lg: "80%", xl: "100%" }}
        // height={{ sm: '450px', md: '300px', lg: '500px', xl: '500px' }}
      ></InnerBanner>

      <PricingWraper />

      <Box
        backgroundColor="#EEEEF1"
        p={{ sm: "40px 0px", md: "70px 0px" }}
        textAlign="center"
      >
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.lg",
            lg: "container.lg",
            xl: "container.xl",
          }}
          borderBottom="1px solid #C8C4C4"
          paddingBottom="36px"
        >
          <Heading
            title="THE PERPETUA GUARANTEE"
            lineHeight="1"
            padding="0px 0px 15px"
            margin="0px auto"
            variant="small"
          ></Heading>
          <Text
            margin="0 auto"
            title="At Perpetua, we know exactly what it takes for our clients to get real, sustainable results that will translate to both inside and outside the gym. We have a wide variety of options for you to choose from and these are available for all fitness levels."
            width="80%"
          ></Text>
        </Container>
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.lg",
            lg: "container.lg",
            xl: "container.xl",
          }}
          paddingTop="36px"
        >
          <Heading
            title="QUESTIONS?"
            lineHeight="1"
            padding="0px 0px 15px"
            margin="0px auto"
            variant="small"
          ></Heading>
          <Text
            mmargin="0 auto"
            title="Feel free to email us at hello@perpetua.ie"
          ></Text>
        </Container>
      </Box>

      <Flex p={{ sm: "50px 0px", md: "150px 0px" }}>
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.lg",
            lg: "container.lg",
            xl: "container.xl",
          }}
        >
          <Stack direction={{ sm: "column", md: "row" }} spacing="24px">
            <Box width={{ sm: "100%", md: "50%" }}>
              <Box
                paddingLeft="30px"
                position={"relative"}
                _after={{
                  position: "absolute",
                  content: '""',
                  width: "3px",
                  height: "100%",
                  top: "0",
                  left: "0",
                  background: "Red",
                }}
              >
                <Heading
                  title="FREQUENTLY ASKED QUESTIONS"
                  lineHeight="1"
                  padding="0px 0px 30px"
                  margin="0px auto"
                ></Heading>
                <Text
                  title="Questions about the classes or memberships? We got you. If you need more information, please feel free to contact us hello@perpetua.ie"
                  width="80%"
                ></Text>
              </Box>
            </Box>
            <Box width={{ sm: "100%", md: "50%" }}>
              <Accordion
                defaultIndex={[null]}
                allowMultiple
                className="Accor_item _black_acording  white_acording"
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="WHAT KIND OF MEMBERSHIPS DO YOU OFFER?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="You can purchase memberships for CrossFit, Hyrox, Studios and our JustGym area. You can find out the details on our pricing page."
                      margin="0px 0px 20px"
                    ></Text>
                    <Text
                      title="If a membership does not suit, we also offer class packs which you can use for our studio classes."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="WHERE AT THE GYMS LOCATED?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="We have two Perpetua locations conveniently located in Dublin City. We have a location in Windmill Lane where we offer CrossFit, Studios, JustGym and Personal Training and Lennox Street studio has studio classes and personal training options. "
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="WHAT CAN I FIND CLASS SCHEDULES?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Schedules are unique to each location. Please visit our timetable page or download the Perpetua Fitness app to see the most up to date schedule. (***Add the link for the timetable and app here)"
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="DO YOU OFFER FREE TRIAL CLASSES?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Looking to try us out first? Try 3 studio classes with us for 33 euro or if you would like to try CrossFit, please email CrossFit@perpetua.ie to arrange a meeting with the head coach."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="DO YOU OFFER DROP INS?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Yes, we offer drop-ins for our CrossFit classes or JustGym for 25 euros a session or you can purchase one studio credit for 23 euros."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <Box background={"black"} borderBottom="1px solid #333">
        <Container
          maxW={{ md: "container.md", xl: "container.xl" }}
          p={{ sm: "40px 15px", md: "150px 0px" }}
        >
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing="24px"
            className="LocationContent"
          >
            <Box width={{ sm: "100%", md: "50%" }}>
              <Heading
                title="PERPETUA"
                as="h2"
                variant="extralarge"
                lineHeight="1"
                color="white"
              ></Heading>
              <Heading
                title="2 Locations."
                as="h2"
                variant="extralarge"
                margin="0px 0px 15px"
                lineHeight="1"
                color="white"
              ></Heading>
            </Box>
            <Box width={{ sm: "100%", md: "50%" }}>
              <Box
                paddingBottom={"50px"}
                borderBottom="1px solid"
                borderBottomColor={"#D9D9D9"}
              >
                <Heading
                  title="Perpetua Fitness Windmill Lane"
                  as="h3"
                  variant="medium"
                  margin="0px 0px 30px"
                  lineHeight="0.7"
                  color="white"
                ></Heading>
                <Stack paddingTop="15px" gridRowGap={"10px"}>
                  <HStack spacing="10px" alignItems="top">
                    <Box as="span" paddingTop="5px">
                      <Image src="../images/map-white.svg"></Image>
                    </Box>
                    <Text
                      as="span"
                      title={
                        <Box lineHeight="1.5">
                          CrossFit Dublin,
                          <br />
                          2 Windmill Lane,
                          <br />
                          Windmill Quarter,
                          <br />
                          Dublin 2.
                          <br />
                          D02 kX66
                        </Box>
                      }
                      width={{ base: "100%" }}
                      fontFamily="'BlenderProBold', sans-serif"
                      textTransform="inherit"
                      fontSize="xs2"
                      lineHeight="1.2"
                      color="white"
                    >
                      {" "}
                    </Text>
                  </HStack>
                  <HStack spacing="10px" alignItems="center">
                    <Box as="span" paddingTop="5px">
                      <Image src="../images/phone-white.svg"></Image>
                    </Box>
                    <Text
                      as="span"
                      title="086 8279903"
                      width={{ base: "100%" }}
                      fontFamily="'BlenderProBold', sans-serif"
                      textTransform="inherit"
                      fontSize="xs2"
                      lineHeight="1.2"
                      color="white"
                    >
                      {" "}
                    </Text>
                  </HStack>
                  <HStack spacing="10px" alignItems="center">
                    <Box as="span" paddingTop="5px">
                      <Image
                        src="../images/email-open.svg"
                        width="33px"
                      ></Image>
                    </Box>
                    <Text
                      as="span"
                      title="windmilllane@perpetua.ie"
                      width={{ base: "100%" }}
                      fontFamily="'BlenderProBold', sans-serif"
                      textTransform="inherit"
                      fontSize="xs2"
                      lineHeight="1.2"
                      color="white"
                    >
                      {" "}
                    </Text>
                  </HStack>
                  <Link to="/contact-us">
                    <Button title="Contact" color="white" />
                  </Link>
                </Stack>
              </Box>
              <Box paddingTop={"50px"}>
                <Heading
                  title="Perpetua Fitness Lennox Street"
                  as="h3"
                  variant="medium"
                  margin="0px 0px 30px"
                  lineHeight="0.7"
                  color="white"
                ></Heading>
                <Stack paddingTop="15px" gridRowGap={"10px"}>
                  <HStack spacing="10px" alignItems="top">
                    <Box as="span" paddingTop="5px">
                      <Image src="../images/map-white.svg"></Image>
                    </Box>
                    <Text
                      as="span"
                      title={
                        <Box>
                          The Lennox Building,
                          <br />
                          Lennox St,
                          <br />
                          Saint Kevin's,
                          <br />
                          Dublin 2<br />
                          D02 FK02
                          <br />
                        </Box>
                      }
                      width={{ base: "100%" }}
                      fontFamily="'BlenderProBold', sans-serif"
                      textTransform="inherit"
                      fontSize="xs2"
                      lineHeight="1.2"
                      color="white"
                    >
                      {" "}
                    </Text>
                  </HStack>
                  <HStack spacing="10px" alignItems="center">
                    <Box as="span" paddingTop="5px">
                      <Image src="../images/phone-white.svg"></Image>
                    </Box>
                    <Text
                      as="span"
                      title="086 0136562"
                      width={{ base: "100%" }}
                      fontFamily="'BlenderProBold', sans-serif"
                      textTransform="inherit"
                      fontSize="xs2"
                      lineHeight="1.2"
                      color="white"
                    >
                      {" "}
                    </Text>
                  </HStack>
                  <HStack spacing="10px" alignItems="center">
                    <Box as="span" paddingTop="5px">
                      <Image
                        src="../images/email-open.svg"
                        width="33px"
                      ></Image>
                    </Box>
                    <Text
                      as="span"
                      title="lennox@perpetua.ie"
                      width={{ base: "100%" }}
                      fontFamily="'BlenderProBold', sans-serif"
                      textTransform="inherit"
                      fontSize="xs2"
                      lineHeight="1.2"
                      color="white"
                    >
                      {" "}
                    </Text>
                  </HStack>
                  <Link to="/contact-us">
                    <Button title="Contact" color="white" />
                  </Link>
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Pricing;
