import React, { useState } from "react";
import { Text, Center, Input, Flex } from "@chakra-ui/react";
import LoginRegisterWrapper from "../../component/Forum/LoginRegister/LoginRegisterWrapper";
import { FormButton } from "../../component/Forum/LoginRegister/Inputs";
import OtpInput from 'react-otp-input';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';

const OTPVerification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { email } = location.state || {};
    //console.log('email...........', email);
    const [otp, setOTP] = useState('');
    const [otpError, setOtpError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const verifyOTP = () => {

        if (!otp) {
            if (!otp) {
                setOtpError('Otp field is required');
            } else {
                setOtpError("");
            }
        } else {
            setIsLoading(true);
            try {
                axios
                    .post(process.env.REACT_APP_FORUM_API_BASE_URL + "/reset-password-otp-check",
                        {
                            email: email,
                            token: otp
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                            },
                        })
                    .then((response) => {
                        console.log("response--------", response);
                        if (response.data.success == true) {
                            console.log('OTP matched successfully');
                            navigate("/forum/reset-password", { state: { email: email, otp: otp } });
                        } else {
                            console.log(response.data.message);
                            setOtpError(response.data.message);
                        }

                    })
            } catch (error) {
                console.error("Error:", error);
                if (error?.response?.data?.message) {
                    setOtpError(error?.response?.data?.message[0]);
                } else {
                    setOtpError("");
                }
            } finally {
                setIsLoading(false);
            }
        }
    }
    const resentOtp = () => {
        try {
            axios
                .post(process.env.REACT_APP_FORUM_API_BASE_URL + "/forgot-password",
                    {
                        email: email,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    })
                .then((response) => {
                    console.log("response--------", response);
                    if (response?.data?.success == false) {
                        setOtpError(response.data.message);
                    } else {
                        setOtpError('Resent otp successfully');
                        setTimeout(() => {
                            setOtpError('');
                        }, 2000);
                    }
                })
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <LoginRegisterWrapper name="Verify OTP">
            <OtpInput
                value={otp}
                onChange={setOTP}
                numInputs={6}
                inputStyle={{ width: "45px", margin: "0 auto", fontFamily: "Poppins", outline: "none", cursor: "pointer", borderRadius: "5px" }}
                inputType='number'
                renderInput={(props) => <input {...props} />}
            />
            <Text
                fontSize={{ base: "12px", md: "14px" }}
                fontFamily="Poppins"
                color="Red"
                margin="15px 5px 10px"
                minH={{ base: "18px", md: "21px" }}
            >
                {otpError}
            </Text>
            <FormButton onClick={verifyOTP} name="Verify OTP" isDisabled={isLoading} />
            <Center>
                <Text
                    fontFamily="Poppins"
                    color="blackAlpha.700"
                    fontSize={{ base: "13px", md: "14px" }}
                    paddingRight="5px"
                >
                    Don't send otp?
                </Text>
                <Text
                    fontFamily="Poppins"
                    color="Red"
                    fontSize={{ base: "13px", md: "14px" }}
                    paddingRight="5px"
                    onClick={resentOtp}
                    cursor='pointer'
                >
                    Resend otp
                </Text>
            </Center>
        </LoginRegisterWrapper>
    )
}

export default OTPVerification;