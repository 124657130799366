import React, { useEffect, useRef } from 'react'
import { Box, Container, Text } from '@chakra-ui/react';
import * as Style from './LandingStyle.style';


const ResolutionLandingpage = (props) => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <>
      <Style.PageBanner backgroundImage={{ sm: 'url(./images/landing-page-banner.webp)', md: 'url(./images/landing-page-banner.webp)' }} ></Style.PageBanner>
      <Style.midContent backgroundColor="#000" padding={{ md: '80px 0px', sm: '20px 0px' }} borderTop='1px solid #212121'>
        <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
          <Style.midcontentWrapper>
            <Style.imageBox>
              <video
                style={{ maxWidth: "300px", width: "100%", margin: "0 auto", objectFit: 'cover', objectPosition: "top center", }}
                playsInline
                loop
                muted
                controls
                alt="All the devices"
                src="../images/landing-video.mp4"
                ref={videoEl}
              />
            </Style.imageBox>
            <Style.contentBox>
              <Style.h2 variant='large' title='Your New Years Resolutions are within reach.' marginBottom='20px' color="#fff" />
              <Style.h2 variant='small' title='Get Studio Credits for as low as €14 or regret it.' marginBottom='20px' color="#fff" />
            </Style.contentBox>
          </Style.midcontentWrapper>
        </Container>
      </Style.midContent>

      <Style.ResolutionPacks padding={{ md: '80px 0px', sm: '20px 0px' }} borderTop='1px solid #212121' background='#000'>
        <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
          <Style.ResolutionPacksWrapper>

            <Style.PacksboxHeader textAlign='left' padding='15p 0px' paddingLeft='15px'>
              <Style.h2 variant='extralarge' title='Resolution Pack' color="#fff" />
            </Style.PacksboxHeader>
            <Style.Packs width='100%'>

              <Style.ResolutionPacksbox width='100%' borderTop='1px solid #212121'>
                <Style.PacksboxHeader textAlign='left' padding='15p 0px' paddingLeft='15px'>
                  <Style.h2 variant='large' title=' €95' color="#fff" />
                </Style.PacksboxHeader>
                <Style.Packsboxcontent borderLeft='1px solid  #3d3d3d' padding='15px' >
                  <Style.PacksboxHeader textAlign='center'>
                    <Style.h2 variant='smallmid' title='Resolution 5 Pack' color="#fff" width='100%' margin='0px' />
                  </Style.PacksboxHeader>
                  <Style.pointList margin='0px'>
                    <Style.pointlistItem color="#fff">30 Day expiry </Style.pointlistItem>
                    <Style.pointlistItem color="#fff">€19 per credit </Style.pointlistItem>
                  </Style.pointList>
                  <Style.pageButton2 href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107760' target='_blank' color="#fff">Buy Now</Style.pageButton2>
                </Style.Packsboxcontent>
              </Style.ResolutionPacksbox>

              <Style.ResolutionPacksbox width='100%' borderTop='1px solid #212121'>
                <Style.PacksboxHeader textAlign='left' padding='15p 0px' paddingLeft='15px'>
                  <Style.h2 variant='large' title=' €175' color="#fff" />
                </Style.PacksboxHeader>
                <Style.Packsboxcontent borderLeft='1px solid  #3d3d3d' padding='15px' >
                  <Style.PacksboxHeader textAlign='center'>
                    <Style.h2 variant='smallmid' title='Resolution 10 Pack' color="#fff" width='100%' margin='0px' />
                  </Style.PacksboxHeader>
                  <Style.pointList margin='0px'>
                    <Style.pointlistItem color="#fff">45 Day expiry</Style.pointlistItem>
                    <Style.pointlistItem color="#fff">€17.50 per credit</Style.pointlistItem>
                  </Style.pointList>
                  <Style.pageButton2 href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107761' target='_blank' color="#fff">Buy Now</Style.pageButton2>
                </Style.Packsboxcontent>
              </Style.ResolutionPacksbox>

              <Style.ResolutionPacksbox width='100%' borderTop='1px solid #212121'>
                <Style.PacksboxHeader textAlign='left' padding='15p 0px' paddingLeft='15px'>
                  <Style.h2 variant='large' title=' €240' color="#fff" />
                </Style.PacksboxHeader>
                <Style.Packsboxcontent borderLeft='1px solid  #3d3d3d' padding='15px' >
                  <Style.PacksboxHeader textAlign='center'>
                    <Style.h2 variant='smallmid' title='Resolution 15 Pack' color="#fff" width='100%' margin='0px' />
                  </Style.PacksboxHeader>
                  <Style.pointList margin='0px'>
                    <Style.pointlistItem color="#fff">2 Month expiry  </Style.pointlistItem>
                    <Style.pointlistItem color="#fff">€16 per credit </Style.pointlistItem>
                  </Style.pointList>
                  <Style.pageButton2 href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107762' target='_blank' color="#fff">Buy Now</Style.pageButton2>
                </Style.Packsboxcontent>
              </Style.ResolutionPacksbox>

              <Style.ResolutionPacksbox width='100%' borderTop='1px solid #212121'>
                <Style.PacksboxHeader textAlign='left' padding='15p 0px' paddingLeft='15px'>
                  <Style.h2 variant='large' title=' €300' color="#fff" />
                </Style.PacksboxHeader>
                <Style.Packsboxcontent borderLeft='1px solid  #3d3d3d' padding='15px' >
                  <Style.PacksboxHeader textAlign='center'>
                    <Style.h2 variant='smallmid' title='Resolution 20 Pack' color="#fff" width='100%' margin='0px' />
                  </Style.PacksboxHeader>
                  <Style.pointList margin='0px'>
                    <Style.pointlistItem color="#fff">3 Month expiry  </Style.pointlistItem>
                    <Style.pointlistItem color="#fff">€15 per credit </Style.pointlistItem>
                  </Style.pointList>
                  <Style.pageButton2 href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107763' target='_blank' color="#fff">Buy Now</Style.pageButton2>
                </Style.Packsboxcontent>
              </Style.ResolutionPacksbox>

              <Style.ResolutionPacksbox width='100%' borderTop='1px solid #212121'>
                <Style.PacksboxHeader textAlign='left' padding='15p 0px' paddingLeft='15px'>
                  <Style.h2 variant='large' title=' €420' color="#fff" />
                </Style.PacksboxHeader>
                <Style.Packsboxcontent borderLeft='1px solid  #3d3d3d' padding='15px' >
                  <Style.PacksboxHeader textAlign='center'>
                    <Style.h2 variant='smallmid' title='Resolution 30 Pack' color="#fff" width='100%' margin='0px' />
                  </Style.PacksboxHeader>
                  <Style.pointList margin='0px'>
                    <Style.pointlistItem color="#fff">3 Month expiry  </Style.pointlistItem>
                    <Style.pointlistItem color="#fff">€14 per credit </Style.pointlistItem>
                  </Style.pointList>
                  <Style.pageButton2 href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107857' target='_blank' color="#fff">Buy Now</Style.pageButton2>
                </Style.Packsboxcontent>
              </Style.ResolutionPacksbox>
            </Style.Packs>
          </Style.ResolutionPacksWrapper>
        </Container>
      </Style.ResolutionPacks>

      <Style.CenterContent>
        <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
          <Style.CenterContentWrapper>
            <Style.CenterContentHeader width='100%'>
              <Style.h2 variant='medium' title='TERMS & CONDITIONS' />
              <Text fontFamily='Poppins' fontSize='18px' marginBottom='10px' marginTop='20px' fontWeight='700' textAlign='left'>Kickstart your 2024 goals with Perpetua’s Resolution Packs! </Text>
            </Style.CenterContentHeader>
            <Style.pointList margin='0px' width='100%' textAlign='left' padding='0'>
              <Style.pointlistItem >Resolution 5 Pack - €95 (30 day expiry) (€19/credit)</Style.pointlistItem>
              <Style.pointlistItem >Resolution 10 Pack - €175 (45 day expiry) (€17.50/credit) </Style.pointlistItem>
              <Style.pointlistItem >Resolution 15 Pack - €240 (2 month expiry) (€16/credit) </Style.pointlistItem>
              <Style.pointlistItem >Resolution 20-Pack - €300 (3 month expiry) (€15 / credit) </Style.pointlistItem>
              <Style.pointlistItem >Resolution 30-Pack – €420 (3 month expiry) (€14/credit) </Style.pointlistItem>
            </Style.pointList>
            <Box textAlign='left' width='100%' >
              <Text fontFamily='Poppins' fontSize='14px' marginBottom='10px' fontWeight='400' >Credits activate as of purchase date. For example, if you purchase a Resolution 5 Pack on December 29th 2023, the expiry date is January 28th 2024. </Text>
              <Text fontFamily='Poppins' fontSize='14px' marginBottom='10px' fontWeight='400' >Resolution Pack Credits valid to use in both Windmill Lane in Lennox Street in Tread & Shred, RIDE, Rumble & HYROX.  </Text>
              <Text fontFamily='Poppins' fontSize='14px' marginBottom='10px' fontWeight='400' >Standard Studio Credit T&Cs apply. No pauses or extensions & credits are non-transferable.</Text>
            </Box>
          </Style.CenterContentWrapper>
        </Container>
      </Style.CenterContent>
    </>

  )
}

export default ResolutionLandingpage