import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import TermsConditions from "../component/TermsConditions";


const TermsConditionsPage = () => {
  return (
    <MainWrapper>
      <TermsConditions />
    </MainWrapper>

  );
};

export default TermsConditionsPage;
