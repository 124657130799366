import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import * as Style from "./PeopleYouMayKnow.style";
import { useNavigate } from "react-router-dom";
import { Followers } from "../../services/ProfileService";
import AuthUser from "../../services/Auth";
import type { RandomUserType } from "../../type/Feed";
function PeopleYouMayKnow({ data, isShow }: { data: RandomUserType, isShow?: boolean }) {
  const { Encrypt } = AuthUser();
  const [is_followed, setis_followed] = useState(true);
  const { createFollower, removeFollower } = Followers();
  useEffect(() => {
    if (data?.is_followed !== undefined) {
      setis_followed(data?.is_followed);
    }
  }, [data]);
  const navigate = useNavigate();
  return (
    <Style.connectWrap>
      <Flex alignItems="center">
        <Style.image
          alt="image"
          _hover={{
            cursor: "pointer",
          }}
          objectFit="cover"
          onClick={() => {
            navigate("/forum/profile/" + Encrypt(data?.id));
          }}
          src={
            data?.profile_image
              ? data?.profile_image
              : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${data?.name}`
          }
        />
        <Box style={{ padding: "0px 10px" }}>
          <Style.peopleTitle
            _hover={{
              cursor: "pointer",
              textDecor: "underline",
            }}
            onClick={() => {
              navigate("/forum/profile/" + Encrypt(data?.id));
            }}
          >
            {data?.name}
          </Style.peopleTitle>
        </Box>
      </Flex>
      {isShow && (
        <>
          {is_followed ? (
            <Style.UnFollowBtn
              onClick={() => {
                removeFollower(data?.id);
                setis_followed(!is_followed);
              }}
            >
              Unfollow
            </Style.UnFollowBtn>
          ) : (
            <Style.ConnectBtn
              onClick={() => {
                createFollower(data?.id);
                setis_followed(!is_followed);
              }}
            >
              Follow
            </Style.ConnectBtn>
          )}
        </>
      )}
    </Style.connectWrap>
  );
}

export default PeopleYouMayKnow;
