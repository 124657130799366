import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import * as Style from "./Posts.style";

import SingleComment from "./SingleComment";
import type { ChildCommentType, commentType } from "../../type/Feed";

const ChildComment = ({ data, getCommentList, commentCount, setCommentCount }: ChildCommentType) => {
  const [showAllReply, setShowAllReply] = useState(false);
  const [commentList, setCommentList] = useState<Array<commentType>>([]);
  const ShowAllReplyToggler = () => {
    setShowAllReply(!showAllReply);
  };
  useEffect(() => {
    setCommentList(data);
  }, [data]);
  return (
    <>
      {commentList?.map(
        (item, index) =>
          (index < 1 || showAllReply) && (
            <Flex marginTop="10px" key={index}>
              <SingleComment
                item={item}
                index={index}
                commentList={commentList}
                setCommentList={setCommentList}
                getCommentList={getCommentList}
                isChild={true}
                commentCount={commentCount}
                setCommentCount={setCommentCount}
              />
            </Flex>
          )
      )}
      {commentList?.length > 1 && (
        <Style.moreCommentWrap onClick={ShowAllReplyToggler}>
          <Style.chevronDownIcon className={`bi bi-chevron-down`} />
          <Box style={{ paddingLeft: "10px" }}>
            <Style.moreComment>
              {showAllReply ? "Less " : "More "} replies
            </Style.moreComment>
          </Box>
        </Style.moreCommentWrap>
      )}
    </>
  );
};

export default ChildComment;
