import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import ValentinesTakeover from "../../component/LandingPage/ValentinesTakeover";

const ValentinesTakeoverPage = () => {
  return (
    <MainWrapper>
      <ValentinesTakeover />
    </MainWrapper>
  );
};

export default ValentinesTakeoverPage;
