import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Flex,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import * as Style from "./ForumProfile.style";
import LeftSideMenu from "./LeftSideMenu";
import PeopleYouMayKnow from "./PeopleYouMayKnow";
import { useNavigate } from "react-router-dom";
import { GetPosts, GetRandomusers, } from "../../services/FeedService";
import PostWrapper from "./PostWrapper";

const Feeds = () => {
  const navigate = useNavigate();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [seeAllSuggestion, setSeeAllSuggestion] = useState(false);
  const { data: FeedList, fetchData: getFeeds, loader } = GetPosts();

  const seeAllSuggestionHandler = () => {
    setSeeAllSuggestion(!seeAllSuggestion);
  };
  const { friendSuggestion } = GetRandomusers();

  useEffect(() => {
    const token = localStorage.getItem("forum_token");
    if (token) {
      getFeeds();
    } else {
      navigate("/forum/login");
    }
  }, [navigate]);

  return (
    <Style.forumProfilebg>
      <Container
        maxW={{ sm: "Container.sm", md: "container.xl", xl: "100%" }}
        padding={{ xl: "0px 30px 50px" }}
      >
        <Flex direction={{ sm: "column", md: "row" }}>
          <LeftSideMenu isOpen={isOpen} onClose={onClose} />
          <Box width={{ lg: "83%" }} borderLeft={{ lg: "1px solid #262626" }}>

            {loader ? (
              <Flex
                justifyContent="center"
                align="center"
                width="100%"
                bg={"#000"}
                minH="60vh"
              >
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="#333"
                  color="#ccc"
                  size="lg"
                />
              </Flex>
            ) : <Flex direction={{ sm: "column", md: "row" }}>
              <Box
                width={{ md: "60%", lg: "70%" }}
                padding={{
                  sm: "30px 0 0",
                  lg: "30px 20px",
                  xl: "30px 95px 0",
                }}
              >

                <PostWrapper data={FeedList} showCreate={true} refresh={getFeeds} onToggle={onToggle} />
              </Box>
              <Style.ForumRightSideWrap>
                {friendSuggestion?.length > 0 && (
                  <Box padding={{ sm: "0px 15px 0px", md: "0px 20px 0px" }}>
                    <Style.rightSeeAllWrap>
                      <Style.rightPeople>
                        People you may know
                      </Style.rightPeople>
                      {friendSuggestion?.length > 6 && (
                        <Style.rightSeeAll
                          cursor="pointer"
                          onClick={() => {
                            seeAllSuggestionHandler();
                          }}
                        >
                          {seeAllSuggestion ? "See less" : "See all"}
                        </Style.rightSeeAll>
                      )}
                    </Style.rightSeeAllWrap>
                    {friendSuggestion?.map(
                      (item, index) =>
                        (index < 6 || seeAllSuggestion) && (
                          <PeopleYouMayKnow
                            key={index}
                            data={item}
                            isShow={true}
                          />
                        )
                    )}
                  </Box>
                )}
              </Style.ForumRightSideWrap>
            </Flex>}

          </Box>
        </Flex>
      </Container >
    </Style.forumProfilebg >
  );
};

export default Feeds;
