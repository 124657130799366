import React from "react";
import Heading from "./Heading/Heading";
import { Flex } from "@chakra-ui/react";
import MetaTitle from "./MetaTitle";

function Shop(props) {
  return (
    <>
      <MetaTitle title="Shop | Perpetua Fitness" />
      <Flex
        minHeight={{ sm: "auto", md: "768px" }}
        padding="100px 0px"
        alignItems="center"
        justifyContent="center"
      >
        <Heading as="h1" title="Coming Soon" className="wow fadeInUp"></Heading>
      </Flex>
    </>
  );
}

export default Shop;
