import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Iconic from "../component/Iconic";


const IconicPage = () => {
  return (
    <MainWrapper>
      <Iconic />
    </MainWrapper>

  );
};

export default IconicPage;
