import { Box, Container, Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import LeftSideMenu from "../LeftSideMenu";
import { MainWrapper } from "../../MainWrapper";




type ConditionalWrapType = {
  children: JSX.Element;
};
export const ForumWrapper = ({ children }: ConditionalWrapType): JSX.Element => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  return (

    <MainWrapper >
      <Container
        maxW={{ sm: "Container.sm", md: "container.xl", xl: "100%" }}
        padding={{ xl: "0px 30px 50px" }}
        bg={"#000"}
        borderTop="1px solid #262626"
      >
        <Flex direction={{ sm: "column", md: "row" }}>
          <LeftSideMenu isOpen={isOpen} onClose={onClose} />
          <Box
            className="bi bi-filter-left"
            _hover={{ cursor: "pointer" }}
            display={{ lg: "none" }}
            onClick={onToggle}
            fontSize="22px"
            color="white"
            paddingLeft="5px"
            paddingTop="10px"
          />
          <Box
            width={{ sm: "100%", lg: "83%" }}
            borderLeft={{ lg: "1px solid #262626" }}
            padding={{ sm: "50px 10px", md: "50px 30px 50px" }}
          >
            {children}
          </Box>
        </Flex>
      </Container>
    </MainWrapper>
    
  );
};
