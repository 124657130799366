import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import TemporarilyLanding from "../component/TemporarilyLanding";


const TemporarilyLandingPage = () => {
  return (
    <MainWrapper>
      <TemporarilyLanding />
    </MainWrapper>

  );
};

export default TemporarilyLandingPage;
