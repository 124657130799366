import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import OurStudios from "../component/OurStudios";


const OurStudiosPage = () => {
  return (
    <MainWrapper>
      <OurStudios />
    </MainWrapper>

  );
};

export default OurStudiosPage;
