import React, { useEffect, useState } from "react";
import { Box, Flex, Textarea, } from "@chakra-ui/react";
import * as Style from "./Posts.style";
import AuthUser from "../../services/Auth";
import SingleComment from "./SingleComment";
import ChildComment from "./ChildComment";
import { BiSend } from "react-icons/bi";
import type { commentType } from "../../type/Feed";
const Comments = ({ postId, type, commentCount, setCommentCount }: { postId: number, type?: string, commentCount: number, setCommentCount: Function }) => {
  const { userImage, user, api } = AuthUser();
  const [showAllComment, setShowAllComment] = useState(false);

  const [commentList, setCommentList] = useState<Array<commentType>>([]);
  const [comment, setComment] = useState("");
  const ShowAllCommentToggler = () => {
    setShowAllComment(!showAllComment);
  };
  const getCommentList = () => {
    api
      .get("/comments/list", { params: { feed_id: postId } })
      .then((res: any) => {
        if (res?.data?.success) {
          setCommentList(res?.data?.data);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const AddComment = (id: number) => {
    api
      .post("/comments/create", { feed_id: id, comment: comment })
      .then((res: any) => {
        if (res?.data?.success) {
          const finalComment: any = [
            ...commentList,
            {
              id: res?.data?.data?.id,
              comment: res?.data?.data?.comment,
              created_at: new Date(),
              user: {
                id: res?.data?.data?.user_id,
                name: user?.name,
                profile_image: userImage,
              },
            },
          ];
          setCommentList(finalComment);
          setCommentCount(commentCount + 1)
        }
        console.log(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });

    setComment("");
  };
  useEffect(() => {
    getCommentList();
  }, [postId]);
  return (
    <>
      {commentList?.length > 0 && (
        <Style.linerow
          marginX="20px!important">
          {commentList?.map(
            (item, index: number) =>
              (index < 1 || showAllComment) && (
                <Flex padding="15px 5px 0" key={index}>
                  <SingleComment
                    item={item}
                    index={index}
                    commentList={commentList}
                    setCommentList={setCommentList}
                    getCommentList={getCommentList}
                    commentCount={commentCount} setCommentCount={setCommentCount}
                  >
                    <ChildComment
                      data={item?.child_comments}
                      getCommentList={getCommentList}
                      commentCount={commentCount} setCommentCount={setCommentCount}
                    />
                  </SingleComment>
                </Flex>
              )
          )}
          {commentList?.length > 1 && (
            <Style.moreCommentWrap onClick={ShowAllCommentToggler}>
              <Style.chevronDownIcon className={`bi bi-chevron-down`} />
              <Box style={{ paddingLeft: "10px" }}>
                <Style.moreComment>
                  {showAllComment ? "Less " : "More "} Comments
                </Style.moreComment>
              </Box>
            </Style.moreCommentWrap>
          )}
        </Style.linerow>
      )}

      <Flex

        justifyContent="space-between"
        padding="15px 5px 10px"
        marginX="20px"
        borderTop={commentList?.length == 0 ? "1px solid #808080" : "none"}
      >
        <Flex style={{ alignItems: "center", width: "100%" }}>
          <Style.image2
            alt="image"
            src={
              userImage
                ? userImage
                : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${user?.name}`
            }
          />
          {type == "full" ? (
            <Box
              marginLeft="10px"
              backgroundColor="#444"
              borderRadius="10px"
              width="100%"
            >
              <Textarea
                onChange={(e: { target: { value: string } }) => setComment(e.target.value)}
                value={comment}
                minHeight="0"
                color="#fff"
                _placeholder={{
                  padding: "0",
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
                //  _focusVisible="none"
                style={{
                  fontSize: "14px",
                  border: "none",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                  resize: "none",
                  overflow: "hidden",
                }}
                placeholder="write a comment..."
              // onInput={(e) => {
              //   e.target.style.height = "auto"; // Reset the height to auto
              //   e.target.style.height = `${e.target.scrollHeight}px`; // Set the height to the scroll height
              // }}
              />
              <Box
                // onClick={comment?.trim() !== "" ? AddComment : undefined}
                padding="0 10px 5px 0"
                fontSize="20px"
                display="flex"
                justifyContent="flex-end"
              >
                <BiSend
                  cursor={comment?.trim() !== "" ? "pointer" : "not-allowed"}
                  color={comment?.trim() !== "" ? "#ccc" : "#444"}
                />
              </Box>
            </Box>
          ) : (
            <Box paddingX="10px" width="100%">
              <Style.AddComment
                paddingLeft="20px!important"
                onChange={(e: { target: { value: string } }) => setComment(e.target.value)}
                value={comment}
                placeholder="Add Comment..."
              />
            </Box>
          )}
        </Flex>
        {type != "full" && (
          <Style.AddBtn onClick={() => AddComment(postId)}>
            Add
          </Style.AddBtn>
        )}
      </Flex>
    </>
  );
};

export default Comments;
