import React from "react";
import {
  Box,
  Container,
  Flex,
  GridItem,
  SimpleGrid,
  Image,
  Link,
  HStack,
} from "@chakra-ui/react";
import * as Style from "./Style/Page404.style";
import InnerBanner from "./InnerBanner/InnerBanner";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import Button from "./Button/CustomeButton";
import MetaTitle from "./MetaTitle";
function Page404() {
  return (
    <>
      <MetaTitle title="404 | Perpetua Fitness" />
      <InnerBanner
        backgroundColor="black"
        backgroundImage="url(./images/perpetua-404.jpg)"
        title="Sorry, Page not found "
        buttontitle="Go Home"
        Link="/"
        issubtextpara={false}
        isButton={true}
        isSubtext={false}
        issubtitleRight={false}
        width={{ sm: "100%", md: "80%" }}
        height={{ sm: "450px", md: "500px", lg: "550px", xl: "767px" }}
      />
      <Style.Page404Info>
        <Container maxW="container.lg">
          <Heading
            title="you’re either misspelling the URL or requesting a page that's no longer here."
            variant="extralarge"
            lineHeight="1"
          />

          <Text
            title="Let's go Home Page and try from here."
            padding="40px 0px"
          />
          <Link href="/">
            <Button title="Go home" color="black" />
          </Link>
        </Container>
      </Style.Page404Info>
      <Box>
        <SimpleGrid columns={{ sm: "1", md: "2" }} width="full">
          <Style.Page404iPhoneInfo>
            <Image
              src="./images/iphone.webp"
              margin="0 auto"
              paddingTop={{ md: "15px", xl: "0px" }}
              maxW={{ sm: "75%", md: "65%", xl: "55%" }}
            />
          </Style.Page404iPhoneInfo>

          <Style.Page404OnlineInfo>
            <Heading
              as={"h2"}
              textTransform="uppercase"
              margin={{ lg: "15px 0px ", xl: "25px 0px" }}
              variant="large"
              title="YOUR DAILY DOSE OF ONLINE WORKOUTS IS LIVE"
            ></Heading>
            <Text
              title="The Best Online Training Platform"
              padding="30px 0px"
            />
            <HStack
              spacing="10px"
              justifyContent="center"
              padding={{ sm: "0px 10px", md: "40px 10px 0px" }}
            >
              <Box>
                <Link
                  href="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
                  target="_blank"
                  _hover={{ textDecoration: "none" }}
                >
                  <Image
                    alt="image"
                    src="/images/app-store.png"
                    className="button-image"
                  />
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
                  target="_blank"
                  _hover={{ textDecoration: "none" }}
                >
                  <Image
                    alt="image"
                    src="/images/google-play.png"
                    className="button-image"
                    width={{ sm: "" }}
                  />
                </Link>
              </Box>
            </HStack>
          </Style.Page404OnlineInfo>
        </SimpleGrid>
      </Box>

      <Style.Page404ShopInfo
        backgroundImage="url('/images/team.jpg')"
        className="shopSection"
      >
        <Style.Page404ShopContent className="shop_now">
          <Style.Page404Shop>
            <Text
              title="Perpetua Shop"
              color="#fff"
              textAlign="left"
              fontSize="sm2"
              paddingBottom="15px"
              textTransform="uppercase"
              className="wow fadeInUp"
            ></Text>
            <Heading
              color="#fff"
              lineHeight={"1"}
              textAlign={{ sm: "left", md: "left" }}
              as="h2"
              textTransform="uppercase"
              marginBottom="30px"
              title="Check out our Latest Drops"
              variant="extralarge"
              className="wow fadeInUp"
              width={{ md: "60%" }}
            ></Heading>
            <Link href="/contact-us">
              <Button
                title="Contact Us"
                color="#fff"
                className="wow fadeInUp"
              />
            </Link>
          </Style.Page404Shop>
        </Style.Page404ShopContent>
      </Style.Page404ShopInfo>
    </>
  );
}

export default Page404;
