import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Landingpage from "../component/Landingpage";


const LandingPage = () => {
  return (
    <MainWrapper>
      <Landingpage />
    </MainWrapper>

  );
};

export default LandingPage;
