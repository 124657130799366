import React from "react";
import {
  Flex,
  Box,
  Heading,
  Container,
  Stack,
  Text,
  Image,
  UnorderedList,
  ListItem,
  SimpleGrid,
  GridItem,
  Link,
} from "@chakra-ui/react";
import Commoncard from "./Application/CommonCard";
import { CheckIcon } from "@chakra-ui/icons";
import MetaTitle from "./MetaTitle";
function Application() {
  return (
    <>
      <MetaTitle title="Application | Perpetua Fitness" />
      <Container
        maxW="container.xl"
        padding={{ sm: "50px 5px 0px 10px", xl: "95px 0px 60px" }}
      >
        <Flex flexDirection={{ sm: "column", md: "row" }}>
          <Box
            w={{ sm: "100%", md: "50%" }}
            h={{ sm: "auto" }}
            /*position={{sm:"relative" , md: 'sticky'}}*/ top={{
              sm: "0",
              md: "100px",
            }}
            paddingLeft={{ sm: "20px", md: "30px" }}
            paddingBottom={{ sm: "30", md: "10px" }}
          >
            <Box marginTop={{ lg: "50px" }}>
              <Heading fontSize="18px" color="black" lineHeight="1">
                OPEN POSITION
              </Heading>
              <Heading
                fontSize={{ sm: "30px", md: "50px" }}
                color="black"
                lineHeight="1"
              >
                FRONT DESK REP
              </Heading>
            </Box>
            <Box marginTop={{ lg: "80px" }}>
              <UnorderedList
                paddingBottom="30px"
                m="10px 0px 0px"
                width={{ base: "90%", sm: "90%", md: "70%" }}
                listStyleType="none"
                color="black"
                fontFamily="Poppins"
                fontWeight="300"
              >
                <ListItem
                  borderBottom="1px solid #DFDFDF"
                  padding="10px 0px"
                  position="relative"
                  paddingLeft="30px"
                  _after={{
                    w: "10px",
                    h: "10px",
                    position: "absolute",
                    content: '""',
                    top: "15px",
                    left: "0",
                    background: "Red",
                    borderRadius: "50px",
                  }}
                >
                  <Heading fontSize="20px" fontWeight="500">
                    FULL-TIME / PART-TIME
                  </Heading>
                  <Text as="p"> Part-Time </Text>
                </ListItem>
                <ListItem
                  borderBottom="1px solid #DFDFDF"
                  padding="10px 0px"
                  position="relative"
                  paddingLeft="30px"
                  _after={{
                    w: "10px",
                    h: "10px",
                    position: "absolute",
                    content: '""',
                    top: "15px",
                    left: "0",
                    background: "Red",
                    borderRadius: "50px",
                  }}
                >
                  <Heading fontSize="20px" fontWeight="500">
                    LOCATION
                  </Heading>
                  <Text as="p">Dublin City</Text>
                </ListItem>
                <ListItem
                  borderBottom="1px solid #DFDFDF"
                  padding="10px 0px"
                  position="relative"
                  paddingLeft="30px"
                  _after={{
                    w: "10px",
                    h: "10px",
                    position: "absolute",
                    content: '""',
                    top: "15px",
                    left: "0",
                    background: "Red",
                    borderRadius: "50px",
                  }}
                >
                  <Heading fontSize="20px" fontWeight="500">
                    PERKS
                  </Heading>
                  <Text as="p">
                    {" "}
                    Flexible working hours, Company events & social hours and
                    Gym and Studio access ***
                  </Text>
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
          <Box w={{ sm: "100%", md: "45%" }}>
            <Stack>
              <Image src="/images/application1.png"></Image>
              <Image src="/images/application2.png"></Image>
            </Stack>
          </Box>
        </Flex>
      </Container>

      <Text
        width={{ sm: "90%", md: "90%" }}
        borderTop="1px solid #DFDFDF"
        padding={{ md: "0px 0px 20px" }}
        margin="0 auto"
        marginTop={{ sm: "30px", md: "30px", lg: "0" }}
        marginBottom={{ sm: "20px", md: "0px" }}
      />
      {/* //////// */}
      <Box
        paddingBottom={{ sm: "0", md: "20px", lg: "0" }}
        paddingTop={{ lg: "30px" }}
      >
        <Container maxW="container.xl">
          <Flex>
            <SimpleGrid
              columns={{ sm: "1", md: "2" }}
              columnGap={0}
              rowGap={2}
              width="full"
            >
              <GridItem
                borderRight={{ sm: "0", md: "2px solid #DFDFDF" }}
                position="relative"
              >
                <Text
                  width="25px"
                  marginTop="8px"
                  top="0"
                  right="0"
                  height="1"
                  position="absolute"
                  marginRight="10px"
                  borderTop={{ sm: "0", md: "1px solid black" }}
                ></Text>
                <Box
                  width="15px"
                  height="15px"
                  borderRadius="50px"
                  boxShadow={{
                    sm: "0",
                    md: "0px 0px 0px 5px rgba(209,44,65,0.3)",
                  }}
                  top="0"
                  right="-9px"
                  position="absolute"
                  backgroundColor={{ sm: "", md: "Red" }}
                ></Box>
                <Box
                  paddingLeft={{ sm: "7px", md: "0" }}
                  paddingBottom={{ sm: "20px", md: "0" }}
                >
                  <Heading
                    as="h2"
                    textAlign={{ sm: "left", md: "right" }}
                    fontSize={{ sm: "30px", md: "36px", lg: "50" }}
                    lineHeight="1"
                    width="90%"
                    padding={{ sm: "0px 0px 16px", md: "0px 5px" }}
                    marginTop={{ md: "-15px" }}
                  >
                    WHO WE ARE
                  </Heading>

                  <Text
                    as="p"
                    width="90%"
                    fontFamily="poppins"
                    textAlign={{ sm: "left", md: "right" }}
                    padding={{ md: "20px 5px 10px" }}
                  >
                    At PERPETUA, we are obsessed with coaching and providing an
                    experience that each member will continue to talk about. We
                    eat, drink, and sleep coaching. The job of our coaches is to
                    provide the best experience to our members every single day.
                    Early alarms and late nights don't phase us – we are focused
                    and committed.{" "}
                  </Text>
                  <Text
                    as="p"
                    width="90%"
                    fontFamily="poppins"
                    textAlign={{ sm: "left", md: "right" }}
                    padding={{ md: "10px 5px" }}
                  >
                    In an industry plagued with mediocrity, we strive to set a
                    higher bar. Simply put we are professionals, and we are
                    investing heavily in progressing our coaches to new heights.
                  </Text>
                  <Text
                    as="p"
                    width="90%"
                    fontFamily="poppins"
                    textAlign={{ sm: "left", md: "right" }}
                    padding={{ md: "10px 5px" }}
                  >
                    Through a culture of excellence, embracing what it means to
                    represent yourself in the best possible way, and a dynamic
                    development framework we arm our coaches with the tools and
                    resources they need to excel and evolve.
                  </Text>
                </Box>
              </GridItem>
            </SimpleGrid>
          </Flex>

          <Flex>
            <SimpleGrid
              columns={{ sm: "1", md: "2" }}
              columnGap={0}
              rowGap={2}
              width="full"
            >
              <GridItem></GridItem>
              <GridItem
                borderLeft={{ sm: "0", md: "2px solid #DFDFDF" }}
                position="relative"
              >
                <Text
                  width="25px"
                  marginTop="8px"
                  top="0"
                  left="0"
                  height="1"
                  position="absolute"
                  marginLeft="10px"
                  borderTop={{ sm: "0", md: "1px solid black" }}
                ></Text>
                <Box
                  width="15px"
                  height="15px"
                  borderRadius="50px"
                  boxShadow={{
                    sm: "0",
                    md: "0px 0px 0px 5px rgba(209,44,65,0.3)",
                  }}
                  top="0"
                  left="-8px"
                  position="absolute"
                  backgroundColor={{ sm: "", md: "Red" }}
                ></Box>
                <Box paddingLeft={{ sm: "7px", md: "40px" }}>
                  <Heading
                    as="h2"
                    fontSize={{ sm: "30px", md: "36px", lg: "50px" }}
                    textAlign={{ sm: "left", md: "left" }}
                    lineHeight="1"
                    width={{ sm: "100%", md: "90%" }}
                    marginTop={{ md: "-20px" }}
                    paddingBottom={{ sm: "15px", md: "25px" }}
                    paddingLeft={{ md: "24px" }}
                  >
                    Join our Front of House Team!
                  </Heading>
                  <Text
                    as="p"
                    width="90%"
                    fontFamily="poppins"
                    textAlign="left"
                    paddingLeft={{ sm: "0", md: "25px" }}
                    paddingBottom={{ sm: "10px", md: "20px" }}
                  >
                    The Front of House team are the first line of contact at all
                    Perpetua studios. As such, they are well-versed in all
                    aspects of our various concepts. Front of House team members
                    are key to providing superior customer service and assisting
                    clients with class selections, bookings, and questions.
                  </Text>
                  <Text
                    as="p"
                    width="90%"
                    fontFamily="poppins"
                    textAlign="left"
                    paddingLeft={{ sm: "0", md: "25px" }}
                    paddingBottom={{ sm: "10px", md: "20px" }}
                  >
                    We are seeking individuals who are personable and
                    professional with strong communication skills. You should
                    have a passion for fitness and wellbeing and a positive
                    attitude! This role is a great introduction into the company
                    with areas to grow if you are successful. This position does
                    involve some weekends, with a combination of morning,
                    daytime and evening shifts.
                  </Text>
                </Box>
              </GridItem>
            </SimpleGrid>
          </Flex>
          <Flex>
            <SimpleGrid
              columns={{ sm: "1", md: "2" }}
              columnGap={0}
              rowGap={2}
              width="full"
            >
              <GridItem
                borderRight={{ sm: "0", md: "2px solid #DFDFDF" }}
                position="relative"
              >
                <Text
                  width="25px"
                  marginTop="8px"
                  top="0"
                  right="0"
                  height="1"
                  position="absolute"
                  marginRight="10px"
                  borderTop={{ sm: "0", md: "1px solid black" }}
                ></Text>
                <Box
                  width="15px"
                  borderRadius="50px"
                  boxShadow={{
                    sm: "0",
                    md: "0px 0px 0px 5px rgba(209,44,65,0.3)",
                  }}
                  top="0"
                  right="-9px"
                  height="15px"
                  position="absolute"
                  backgroundColor={{ sm: "", md: "Red" }}
                ></Box>
                <Box paddingLeft={{ sm: "0px", md: "0" }}>
                  <Heading
                    as="h2"
                    textAlign={{ sm: "left", md: "right" }}
                    fontSize={{ sm: "30px", md: "36px", lg: "50px" }}
                    lineHeight="1"
                    width="90%"
                    marginTop={{ sm: "10px", md: "-15px" }}
                    paddingBottom={{ sm: "20px", md: "20px" }}
                  >
                    Front of House Responsibilities
                  </Heading>

                  <UnorderedList
                    textAlign={{ sm: "left", md: "right" }}
                    paddingRight={{ md: "65px" }}
                    m="0px"
                    width={{ base: "90%", sm: "100%" }}
                    listStyleType="none"
                    color="black"
                    fontFamily="Poppins"
                    fontWeight="normal"
                  >
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Guest Experience – Have the capacity to offer each and
                      every guest a first-class customer experience.{" "}
                      <Box>
                        <CheckIcon
                          marginRight={{ sm: "10px", md: "0" }}
                          marginLeft="10px"
                          color="#D3D3D3"
                        />
                      </Box>
                    </ListItem>

                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Developing and Sustaining Interpersonal Connections -
                      Front of House must be able to establish and sustain
                      long-lasting cooperative and constructive working
                      relationships with both clients and staff.
                      <Box>
                        {" "}
                        <CheckIcon
                          marginRight={{ sm: "10px", md: "0" }}
                          marginLeft="10px"
                          color="#D3D3D3"
                        />
                      </Box>
                    </ListItem>
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex", md: "block" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Welcome, help, and check in clients{" "}
                      <CheckIcon
                        marginRight={{ sm: "10px", md: "0" }}
                        marginLeft="7px"
                        color="#D3D3D3"
                      />
                    </ListItem>
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Administration – replying to emails and phone calls in a
                      timely, professional, friendly manner.{" "}
                      <Box>
                        {" "}
                        <CheckIcon
                          marginRight={{ sm: "10px", md: "0" }}
                          marginLeft="10px"
                          color="#D3D3D3"
                        />{" "}
                      </Box>
                    </ListItem>
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Utilizing our booking system to interact with guest
                      accounts, check guests into classes, troubleshoot any
                      account issues (we will train you!){" "}
                      <Box>
                        {" "}
                        <CheckIcon
                          marginRight={{ sm: "10px", md: "0" }}
                          marginLeft="10px"
                          color="#D3D3D3"
                        />{" "}
                      </Box>
                    </ListItem>
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Responsible for supporting and participating in the
                      execution of studio operations and functions within the
                      studio.{" "}
                      <Box>
                        {" "}
                        <CheckIcon
                          marginRight={{ sm: "10px", md: "0" }}
                          marginLeft="10px"
                          color="#D3D3D3"
                        />{" "}
                      </Box>
                    </ListItem>
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Maintain a spotless and pleasant environment at all times
                      by helping with studio cleaning, restocking, and
                      maintenance as necessary.{" "}
                      <Box>
                        {" "}
                        <CheckIcon
                          marginRight={{ sm: "10px", md: "0" }}
                          marginLeft="10px"
                          color="#D3D3D3"
                        />
                      </Box>
                    </ListItem>
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Guide clients with regards to the latest studio
                      promotions, membership discounts and/or special events{" "}
                      <Box>
                        {" "}
                        <CheckIcon
                          marginRight={{ sm: "10px", md: "0" }}
                          marginLeft="7px"
                          color="#D3D3D3"
                        />
                      </Box>
                    </ListItem>
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex", md: "block" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Opening and closing the facility{" "}
                      <CheckIcon
                        marginRight={{ sm: "10px", md: "0" }}
                        marginLeft="5px"
                        color="#D3D3D3"
                      />
                    </ListItem>
                  </UnorderedList>
                </Box>
              </GridItem>
            </SimpleGrid>
          </Flex>

          <Flex>
            <SimpleGrid
              columns={{ sm: "1", md: "2" }}
              columnGap={0}
              rowGap={2}
              width="full"
            >
              <GridItem></GridItem>
              <GridItem
                position="relative"
                _after={{
                  position: "absolute",
                  content: '""',
                  width: { md: "2px" },
                  height: "84%",
                  top: "0",
                  left: "0",
                  background: "#DFDFDF",
                }}
              >
                <Text
                  width="25px"
                  marginTop="8px"
                  top="0"
                  left="0"
                  height="1"
                  position="absolute"
                  marginLeft="10px"
                  borderTop={{ sm: "0", md: "1px solid black" }}
                ></Text>
                <Box
                  width="15px"
                  height="15px"
                  zIndex="1"
                  borderRadius="50px"
                  boxShadow={{
                    sm: "0",
                    md: "0px 0px 0px 5px rgba(209,44,65,0.3)",
                  }}
                  top="0"
                  left="-8px"
                  position="absolute"
                  backgroundColor={{ sm: "", md: "Red" }}
                ></Box>
                <Box paddingLeft={{ sm: "7px", md: "40px" }}>
                  <Heading
                    as="h2"
                    fontSize={{ sm: "30px", md: "36px", lg: "50px" }}
                    textAlign={{ sm: "left", md: "left" }}
                    lineHeight="1"
                    width={{ sm: "100%", md: "90%" }}
                    marginTop={{ sm: "20px", md: "-20px" }}
                    paddingBottom={{ sm: "15px", md: "25px" }}
                    paddingLeft={{ md: "24px" }}
                  >
                    Minimum Requirements
                  </Heading>

                  <UnorderedList
                    textAlign="left"
                    paddingLeft={{ md: "22px" }}
                    paddingBottom="30px"
                    m="0px"
                    width={{ base: "90%", sm: "100%" }}
                    listStyleType="none"
                    color="black"
                    fontFamily="Poppins"
                    fontWeight="normal"
                  >
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />
                      Strong customer service skills.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" /> Ability
                      to maintain regular, consistent and reliable attendance in
                      the workplace.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" /> Outgoing,
                      friendly, and enjoys interacting with people.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" /> Exhibits
                      enthusiasm for the studio and for the job.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />
                      Must be a patient, courteous listener, able to show
                      empathy.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />{" "}
                      Responding to clients request with a can-do attitude.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />
                      Cooperative manner with an optimistic attitude.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />
                      Ability to prioritize and work within a fast-paced
                      environment.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />
                      Be meticulous and take pride in the studio's look and
                      cleanliness.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />
                      To maintain efficiency and effectiveness, work as a
                      cohesive team with all Perpetua employees.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />
                      Maintain a professional appearance and behaviour.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />
                      Communicate clearly and effectively.
                    </ListItem>
                    <ListItem padding="5px 0px" display="flex">
                      <CheckIcon marginRight="10px" color="#D3D3D3" />
                      Candidates must be at least 18 years or older to apply.
                    </ListItem>
                  </UnorderedList>
                </Box>
              </GridItem>
            </SimpleGrid>
          </Flex>
          <Flex
            paddingBottom={{ sm: "20px", md: "70px" }}
            marginTop={{ md: "-90px" }}
          >
            <SimpleGrid
              columns={{ sm: "1", md: "2" }}
              columnGap={0}
              rowGap={2}
              width="full"
            >
              <GridItem
                position="relative"
                _before={{
                  position: "absolute",
                  content: '""',
                  width: { md: "2px" },
                  height: "100%",
                  top: "0",
                  right: "0",
                  background: "#DFDFDF",
                }}
              >
                <Text
                  width="25px"
                  marginTop="8px"
                  top="0"
                  right="0"
                  height="1"
                  position="absolute"
                  marginRight="10px"
                  borderTop={{ sm: "0", md: "1px solid black" }}
                ></Text>
                <Box
                  width="15px"
                  borderRadius="50px"
                  boxShadow={{
                    sm: "0",
                    md: "0px 0px 0px 5px rgba(209,44,65,0.3)",
                  }}
                  top="0"
                  right="-7px"
                  height="15px"
                  position="absolute"
                  backgroundColor={{ sm: "", md: "Red" }}
                ></Box>
                <Box paddingLeft={{ sm: "7px", md: "0" }}>
                  <Heading
                    as="h2"
                    textAlign={{ sm: "left", md: "right" }}
                    fontSize={{ sm: "30px", md: "36px", lg: "50px" }}
                    lineHeight="1"
                    width="90%"
                    marginTop={{ md: "-15px" }}
                    paddingBottom={{ sm: "10px", md: "20px" }}
                  >
                    Benefits
                  </Heading>

                  <UnorderedList
                    textAlign={{ sm: "left", md: "right" }}
                    paddingRight={{ md: "65px" }}
                    m="0px"
                    width={{ base: "90%", sm: "100%" }}
                    listStyleType="none"
                    color="black"
                    fontFamily="Poppins"
                    fontWeight="normal"
                  >
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex", md: "block" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Flexible working hours{" "}
                      <CheckIcon
                        marginRight={{ sm: "10px", md: "0" }}
                        marginLeft={{ md: "10px" }}
                        color="#D3D3D3"
                      />
                    </ListItem>
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex", md: "block" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Company events & social hours{" "}
                      <CheckIcon
                        marginRight={{ sm: "10px", md: "0" }}
                        marginLeft="2px"
                        color="#D3D3D3"
                      />
                    </ListItem>
                    <ListItem
                      padding="5px 0px"
                      flexDirection={{ sm: "row-reverse", md: "row" }}
                      display={{ sm: "flex", md: "block" }}
                      justifyContent={{ sm: "flex-end", md: "flex-start" }}
                    >
                      Gym and Studio access{" "}
                      <CheckIcon
                        marginRight={{ sm: "10px", md: "0" }}
                        marginLeft="2px"
                        color="#D3D3D3"
                      />
                    </ListItem>
                  </UnorderedList>
                </Box>
              </GridItem>
            </SimpleGrid>
          </Flex>
        </Container>
      </Box>
      {/*  ////////// */}
      <Commoncard
        backgroundColor="rgb(238, 238, 238)"
        width={{ sm: "90%", md: "60%" }}
        fontSizeHeading={{ sm: "30px", md: "50px" }}
        fontSize="20px"
        color="#585858"
        padding="20px 0px"
        textAlign="center"
        title="JOIN TEAM Perpetua"
        //subtitle={<Box>To apply, download and print the application below. Please fill out, scan, and email your completed application to <Link textDecoration='underline'> hello@perpetua.ie </Link> with the subject line:<Text as='b' color='black' fontWeight='1000'> Front Desk Rep.</Text></Box>}
        subtitle={
          <Box>
            To apply, download and print the application below. Please fill out,
            scan, and email your completed application to{" "}
            <Link href="mailto:hello@perptua.ie" textDecoration="underline">
              {" "}
              hello@perpetua.ie
            </Link>{" "}
            with the job title as subject line
          </Box>
        }
        buttontitle="Application"
        isButton={true}
      />
    </>
  );
}
export default Application;
