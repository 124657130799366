import React, { useEffect, useState } from "react";
import { Flex, Text, Link } from "@chakra-ui/react";
import { Link as navLink, useNavigate } from "react-router-dom";
import { CheckIcon } from "@chakra-ui/icons";
import axios from "axios";
import LoginRegisterWrapper from "../../component/Forum/LoginRegister/LoginRegisterWrapper";
import {
  FormButton,
  PasswordInput,
  TextInput,
} from "../../component/Forum/LoginRegister/Inputs";
import AuthUser from "../../services/Auth";
const ForumLogIn = () => {
  const navigate = useNavigate();
  const { token, setToken, setUser } = AuthUser();
  const [email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const CheckedToggler = () => {
    setIsChecked(!isChecked);
  };
  
  
  const submit = () => {
    if (!email || !Password || Password.length < 8) {
      if (!email) {
        setEmailError("The email field is required.");
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          setEmailError("Please enter a valid email address.");
        } else {
          setEmailError("");
        }
      }
      if (!Password) {
        setPasswordError("The password field is required.");
      } else if (Password.length < 8) {
        setPasswordError("Password must be at least 8 characters long.");
      } else {
        setPasswordError("");
      }
    } else {
      setIsLoading(true);
      axios
        .post(process.env.REACT_APP_FORUM_API_BASE_URL + "/login", {
          email: email,
          password: Password,
        })
        .then((response) => {
          console.log("-------response----", response);
          const user = response?.data?.user;
          const userToken = response?.data?._token;
          setToken(userToken);
          setUser(user);
          localStorage.setItem("rememberMe", JSON.stringify(isChecked));
          if (isChecked) {
            localStorage.setItem("email", email);
            localStorage.setItem("password", Password);
          } else {
            localStorage.setItem("email", "");
            localStorage.setItem("password", "");
          }
          
          setEmail("");
          setPassword("");
          navigate("/forum/feeds");
        })
        .catch((error) => {
          console.log("error", error);
          if (error?.response?.data?.errors) {
            if (error?.response?.data?.errors?.email) {
              setEmailError(error?.response?.data?.errors?.email[0]);
            } else {
              setEmailError("");
            }
            if (error?.response?.data?.errors?.password) {
              setPasswordError(error?.response?.data?.errors?.password[0]);
            } else {
              setPasswordError("");
            }

            setMessage("");
          } else {
            if (error?.response?.data?.message) {
              setMessage(error?.response?.data?.message);
            } else {
              setMessage("somthing went wrong");
            }
            setEmailError("");
            setPasswordError("");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // }
  };

  const _retrieveStorageData = () => {
    try {
      const remMe = JSON.parse(localStorage.getItem("rememberMe"));
      const storageEmail = localStorage.getItem("email");
      const storagePass = localStorage.getItem("password");

      if (remMe) {
        setEmail(storageEmail);
        setPassword(storagePass);
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/forum/feeds");
    } else {
      localStorage.setItem("forum_token", "");
      _retrieveStorageData();
    }
  }, []);
  return (
    <LoginRegisterWrapper name="Log In">
      <TextInput
        value={email}
        setValue={setEmail}
        name="Email"
        type="email"
        error={emailError}
        setError={setEmailError}
      />

      <PasswordInput
        value={Password}
        setValue={setPassword}
        error={PasswordError}
        setError={setPasswordError}
      />
      <Flex
        marginBottom={{ base: "8px", lg: "10px" }}
        marginTop="10px"
        justifyContent={"space-between"}
      >
        <Flex
          onClick={CheckedToggler}
          _hover={{ cursor: "pointer" }}
          alignItems={"center"}
        >
          <Flex
            backgroundColor={"white"}
            p="1"
            borderRadius={"5"}
            marginRight={"10px"}
          >
            <CheckIcon
              fontSize={{ base: "13px", md: "14px" }}
              color={isChecked ? "blackAlpha.700" : "white"}
            />
          </Flex>
          <Text
            fontFamily={"Poppins"}
            color="blackAlpha.700"
            fontSize={{ base: "13px", md: "14px" }}
          >
            Keep me logged in
          </Text>
        </Flex>
        <Link
          to="/forum/forgot-password"
          as={navLink}
          fontFamily={"Poppins"}
          color="blackAlpha.700"
          textAlign={"center"}
          marginLeft="10px"
          fontSize={{ base: "13px", md: "14px" }}
          _hover={{ textDecoration: "none", color: "Red" }}
        >
          Forget password?
        </Link>
      </Flex>
      <Text
        fontSize={{ base: "12px", md: "14px" }}
        fontFamily="Poppins"
        color="Red"
        margin={{ base: "2px 5px 8px", md: "2px 5px 10px" }}
        minH={{ base: "18px", md: "21px" }}
      >
        {message}
      </Text>
      <FormButton onClick={submit} name="Log in" isDisabled={isLoading} />

      <Flex justifyContent={"center"}>
        <Text
          fontFamily={"Poppins"}
          color="blackAlpha.700"
          fontSize={{ base: "13px", md: "14px" }}
          paddingRight={"5px"}
        >
          Don't have an account?
        </Text>
        <Link
          to="/forum/registration"
          as={navLink}
          color="blackAlpha.900"
          fontFamily={"Poppins"}
          fontSize={{ base: "13px", md: "14px" }}
          _hover={{ textDecoration: "none", color: "Red" }}
          textDecoration="underline"
        >
          Sign Up
        </Link>
      </Flex>
    </LoginRegisterWrapper>
  );
};

export default ForumLogIn;
