import React, { useRef, useState } from "react";
import { Box, Text, Flex, Input, Tooltip } from "@chakra-ui/react";
import * as Style from "./Modal.style";
import ImageResize from "./ImageResize";
interface type { setImage: Function, setCroppedImage: Function, setMessage: Function, width?: string & {}, image?: any, type?: String, update?: boolean, setUpdate?: Function }
const ImageBox = (props: type) => {
  const [drag, setDrag] = useState(false);
  const hiddenFileInput: any = useRef(null);
  const handleDragOver = (event: { preventDefault: Function }) => {
    setDrag(true);
    event.preventDefault();
  };

  const handleDrop = async (event: { preventDefault: Function, dataTransfer: { files: Array<File> } }) => {
    event.preventDefault();
    setDrag(false);
    if (event.dataTransfer.files.length > 0) {

      if (props?.type == "profile") {
        const img = await ImageResize(event.dataTransfer.files[0], 600, "file");
        props?.setImage(img);
        props?.setCroppedImage(img);
      }
      else {
        props?.setImage(event.dataTransfer.files[0]);
        props?.setUpdate && props?.setUpdate(false)
      }
    }
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const maxWidth = props?.width ? props?.width : "38%";
  return (
    <Box
      position="relative"
      maxHeight="304px"
      width={{ base: "100%", md: maxWidth }}
      paddingBottom={{ base: "20px", md: 0 }}
    >
      <Style.ImageUploadBox
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onMouseOut={() => setDrag(false)}
        cursor={props?.image ? "default" : "pointer"}
        onClick={!props?.image && handleClick}
      >
        <Input
          ref={hiddenFileInput}
          type="file"
          display="none"
          accept="image/*"
          onChange={async (e: any) => {
            if (e?.target?.files.length > 0) {
              if (props?.type == "profile") {
                const img = await ImageResize(e?.target?.files[0], 500, "file");
                props?.setImage(img);
                props?.setCroppedImage(img);
              }
              else {
                props?.setImage(e?.target?.files[0]);
                props?.setUpdate && props?.setUpdate(false)
              }
              props?.setMessage("");
            }
          }}
        ></Input>

        {props?.image ? (
          <Flex width="100%" justifyContent="center">
            <Box
              backgroundImage={props?.update ? props?.image : URL.createObjectURL(props?.image)}
              backgroundPosition="center"
              width={props?.type == "profile" ? "260px" : "100%"}
              height="260px"
              borderRadius={props?.type == "profile" ? "50%" : "0"}
              backgroundSize={
                props?.type == "profile" || props?.type == "cover"
                  ? "cover"
                  : "contain"
              }
              backgroundRepeat="no-repeat"
            ></Box>
          </Flex>
        ) : (
          <Flex
            height="200px"
            justifyContent="center"
            alignItems={{ base: "start", md: "center" }}
            paddingTop={{ base: "20px", md: "0" }}
            fontSize="80px"
            className="bi bi-cloud-upload"
            color={drag ? "#ccc" : "#777"}
          />
        )}
        <Box
          bottom="5px"
          position="absolute"
          width="100%"
          marginLeft="-15px"
          textAlign="center"
        >
          {!props?.image && (
            <Box marginBottom="35px">
              <Text fontFamily="poppins" color={drag ? "#ccc" : "#777"}>
                Drag and drop image here
              </Text>
              <Text fontFamily="poppins" color={drag ? "#ccc" : "#777"}>
                or
              </Text>
              <Text
                fontFamily="poppins"
                textDecoration="underline"
                color={drag ? "#777" : "#ccc"}
              >
                Choose from gallery
              </Text>
            </Box>
          )}
        </Box>
      </Style.ImageUploadBox>
      {props?.image && (
        <Box top="15px" marginLeft="-15px" position="absolute" right="15px">
          <Tooltip label="Clear image" fontSize="16px">
            <Flex
              alignItems="center"
              justifyContent="center"
              width="30px"
              height="30px"
              fontSize="18px"
              mb="10px"
              color="#ccc"
              cursor="pointer"
              className="bi bi-trash3"
              textAlign="center"
              backgroundColor="#111"
              onClick={() => {
                props?.setImage("");
                if (props?.type == "profile") {
                  props?.setCroppedImage("");
                }
              }}
            ></Flex>
          </Tooltip>
          <Tooltip label="Choose another image" fontSize="16px">
            <Flex
              alignItems="center"
              justifyContent="center"
              width="30px"
              height="30px"
              fontSize="18px"
              mb="10px"
              color="#ccc"
              cursor="pointer"
              className="bi bi-pencil-square"
              textAlign="center"
              backgroundColor="#111"
              onClick={handleClick}
            ></Flex>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default ImageBox;
