import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import WindmillLocation from "../component/WindmillLocation";


const WindmillLocationPage = () => {
  return (
    <MainWrapper>
      <WindmillLocation />
    </MainWrapper>

  );
};

export default WindmillLocationPage;
