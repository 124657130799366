import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import PrivateHire from "../component/PrivateHire";


const PrivateHirePage = () => {
  return (
    <MainWrapper>
      <PrivateHire />
    </MainWrapper>

  );
};

export default PrivateHirePage;
