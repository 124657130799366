import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Flex,
  useDisclosure,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import * as Style from "./ForumProfile.style";
import LeftSideMenu from "../../component/Forum/LeftSideMenu";
import Posts from "../../component/Forum/Posts";
import CreatePost from "../../component/Forum/CreatePost";
import { useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../services/Auth";

const FeedCategories = () => {
  const navigate = useNavigate();
  const { api } = AuthUser();
  const { slug: categorySlug } = useParams();
  const [user] = useState();
  const [selectedCategory, setSelectedCategory] = useState(categorySlug);
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [loader, setLoader] = useState(true);
  const getPosts = (slug) => {
    if (slug) {
      setSelectedCategory(slug);
    } else {
      setSelectedCategory("");
    }
    api
      .get("/feeds/all", {
        params: { category_slug: slug },
      })
      .then((res) => {
        if (res?.data?.feeds) {
          setPosts(res?.data?.feeds);
        } else {
          setPosts([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const getCategories = async () => {
    api
      .get("/feeds/categories")
      .then((res) => {
        if (res?.data?.success) {
          setCategories(res?.data?.categories);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const token = localStorage.getItem("forum_token");
    if (token) {
      getCategories();
      if (categorySlug) {
        if (categorySlug == "all") {
          getPosts();
        } else {
          getPosts(categorySlug);
        }
      }
    } else {
      navigate("/forum/login");
    }
  }, [categorySlug]);

  return (
    <Style.forumProfilebg>
      <Container
        maxW={{ sm: "Container.sm", md: "container.xl", xl: "100%" }}
        padding={{ xl: "0px 30px 50px" }}
      >
        <Flex direction={{ sm: "column", md: "row" }}>
          <LeftSideMenu isOpen={isOpen} onClose={onClose} />
          {loader ? (
            <Flex
              justifyContent="center"
              align="center"
              width="100%"
              bg={"#000"}
              minH="60vh"
            >
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="#333"
                color="#ccc"
                size="lg"
              />
            </Flex>
          ) : (
            <Box width={{ lg: "83%" }} borderLeft={{ lg: "1px solid #262626" }}>
              <Flex direction={{ sm: "column", md: "row" }}>
                <Box
                  width={{ md: "60%", lg: "70%" }}
                  padding={{
                    sm: "30px 0 0",
                    lg: "30px 20px",
                    xl: "30px 95px 0",
                  }}
                >
                  {/* <CreatePost getPosts={getPosts} onToggle={onToggle} /> */}
                  {posts?.map((item, index) => (
                    <Posts data={item} user={user} key={index} />
                  ))}
                  {posts.length <= 0 && (
                    <Heading textAlign="center" marginTop="50px">
                      No posts
                    </Heading>
                  )}
                </Box>
                <Style.ForumRightSideWrap>
                  {categories.length > 0 && (
                    <Box style={{ padding: "0px 0px 50px" }}>
                      <Style.rightSeeAllWrap margin="0 20px 15px">
                        <Style.rightPeople>
                          Categories for you
                        </Style.rightPeople>
                        <Style.rightSeeAll
                          onClick={() => {
                            navigate("/forum/feeds/category/all", {
                              replace: true,
                            });
                          }}
                          cursor="pointer"
                        >
                          Clear
                        </Style.rightSeeAll>
                      </Style.rightSeeAllWrap>
                      {categories?.map((items, index) => {
                        return (
                          <Flex
                            backgroundColor={
                              selectedCategory == items?.slug ? "#333" : ""
                            }
                            padding="10px 15px"
                            alignItems="center"
                            cursor="pointer"
                            _hover={{ backgroundColor: "#333" }}
                            onClick={() => {
                              navigate("/forum/feeds/category/" + items?.slug, {
                                replace: true,
                              });
                            }}
                            key={index}
                          >
                            <Style.hashIcon className="bi bi-hash" />
                            <Box>
                              <Style.rightPeople>
                                {items.title}
                              </Style.rightPeople>
                              {/* <Style.postTotal>{items.hashtag}</Style.postTotal> */}
                            </Box>
                          </Flex>
                        );
                      })}
                    </Box>
                  )}
                </Style.ForumRightSideWrap>
              </Flex>
            </Box>
          )}
        </Flex>
      </Container>
    </Style.forumProfilebg>
  );
};

export default FeedCategories;
