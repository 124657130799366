import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Sweat from "../component/Sweat";


const SweatPage = () => {
  return (
    <MainWrapper>
      <Sweat />
    </MainWrapper>

  );
};

export default SweatPage;
