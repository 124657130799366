import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Highrox from "../component/Highrox";


const HighroxPage = () => {
  return (
    <MainWrapper>
      <Highrox />
    </MainWrapper>

  );
};

export default HighroxPage;
