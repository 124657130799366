import React, { useState } from "react";
import { Box, Text, Flex, Center } from "@chakra-ui/react";
import axios from "axios";
import { FormButton, PasswordInput } from "./LoginRegister/Inputs";
function ChangePasswordSection(props) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const submit = () => {
    const token = localStorage.getItem("forum_token");
    if (
      !currentPassword ||
      !newPassword ||
      !confirmNewPassword ||
      newPassword !== confirmNewPassword
    ) {
      if (!currentPassword) {
        setCurrentPasswordError("The current password is required.");
      } else if (currentPassword?.length < 8) {
        setCurrentPasswordError(
          "The current password must be at least 8 characters long."
        );
      } else {
        setCurrentPasswordError("");
      }
      if (!newPassword) {
        setNewPasswordError("The new password is required.");
      } else if (newPassword?.length < 8) {
        setNewPasswordError(
          "The new password must be at least 8 characters long."
        );
      } else {
        setNewPasswordError("");
      }
      if (!confirmNewPassword) {
        setConfirmNewPasswordError("The confirm new password is required.");
      } else if (confirmNewPassword?.length < 8) {
        setConfirmNewPasswordError(
          "The confirm password must be at least 8 characters long."
        );
      } else if (newPassword !== confirmNewPassword) {
        setConfirmNewPasswordError(
          "The new password and confirm new password must match."
        );
      } else {
        setConfirmNewPasswordError("");
      }
      setMessage("");
    } else {
      setIsLoading(true);
      axios
        .post(
          process.env.REACT_APP_FORUM_API_BASE_URL + "/profile/change-password",
          {
            current_password: currentPassword,
            new_password: newPassword,
            password_confirmation: confirmNewPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            setMessage("Password changed successfully");
            setTimeout(() => {
              props.onClose();
              setCurrentPassword("");
              setNewPassword("");
              setConfirmNewPassword("");
              setCurrentPasswordError("");
              setNewPasswordError("");
              setConfirmNewPasswordError("");
              setMessage("");
            }, 1000);
          } else {
            setMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error?.response?.data?.errors);
          if (error?.response?.data?.errors) {
            if (error?.response?.data?.errors?.current_password) {
              setCurrentPasswordError(
                error?.response?.data?.errors?.current_password[0]
              );
            } else {
              setCurrentPasswordError("");
            }
            if (error?.response?.data?.errors?.new_password) {
              setNewPasswordError(
                error?.response?.data?.errors?.new_password[0]
              );
            } else {
              setNewPasswordError("");
            }

            if (error?.response?.data?.errors?.password_confirmation) {
              setConfirmNewPasswordError(
                error?.response?.data?.errors?.password_confirmation[0]
              );
            } else {
              setConfirmNewPasswordError("");
            }
            setMessage("");
          } else {
            if (error?.response?.data?.message) {
              setMessage(error?.response?.data?.message);
            } else {
              setMessage("somthing went wrong");
            }
            setCurrentPasswordError("");
            setNewPasswordError("");
            setConfirmNewPasswordError("");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <Box>
      <Flex
        flexDirection={{ sm: "column", md: "row" }}
        paddingTop={{ sm: "5px", md: "15px" }}
        marginRight="10px"
      >
        <Text
          color="#fff"
          fontFamily="Poppins"
          fontWeight="500"
          paddingBottom={{ sm: "8px", md: "8px" }}
          width={{ md: "20%" }}
          marginRight="10px"
        >
          Current Password
        </Text>
        <Box width="100%">
          <PasswordInput
            backgroundColor="#444"
            color="#fff"
            name="Current Password"
            value={currentPassword}
            setValue={setCurrentPassword}
            error={currentPasswordError}
            setError={setCurrentPasswordError}
          />
        </Box>
      </Flex>
      <Flex
        flexDirection={{ sm: "column", md: "row" }}
        paddingTop={{ sm: "5px", md: "15px" }}
        marginRight="10px"
      >
        <Text
          color="#fff"
          fontFamily="Poppins"
          fontWeight="500"
          paddingBottom={{ sm: "8px", md: "8px" }}
          width={{ md: "20%" }}
          marginRight="10px"
        >
          New Password
        </Text>
        <Box width="100%">
          <PasswordInput
            backgroundColor="#444"
            color="#fff"
            name="New Password"
            value={newPassword}
            setValue={setNewPassword}
            error={newPasswordError}
            setError={setNewPasswordError}
          />
        </Box>
      </Flex>
      <Flex
        flexDirection={{ sm: "column", md: "row" }}
        paddingTop={{ sm: "5px", md: "15px" }}
        marginRight="10px"
      >
        <Text
          color="#fff"
          fontFamily="Poppins"
          fontWeight="500"
          paddingBottom={{ sm: "8px", md: "8px" }}
          width={{ md: "20%" }}
          marginRight="10px"
        >
          Confirm Password
        </Text>
        <Box width="100%">
          <PasswordInput
            backgroundColor="#444"
            color="#fff"
            name="Confirm Password"
            value={confirmNewPassword}
            setValue={setConfirmNewPassword}
            error={confirmNewPasswordError}
            setError={setConfirmNewPasswordError}
          />
        </Box>
      </Flex>
      <Text
        fontSize="14px"
        fontFamily="Poppins"
        color={message == "Password changed successfully" ? "#5cb85c" : "Red"}
        textAlign={"center"}
        padding="0px 5px 5px"
        minH="26px"
      >
        {message}
      </Text>
      <Center>
        <FormButton
          onClick={submit}
          backgroundColor="Red"
          width="227px"
          name="Change"
          isDisabled={isLoading}
        />
      </Center>
    </Box>
  );
}

export default ChangePasswordSection;
