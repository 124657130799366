import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Facilitics from "../component/Facilitics";


const FaciliticsPage = () => {
  return (
    <MainWrapper>
      <Facilitics />
    </MainWrapper>

  );
};

export default FaciliticsPage;
