import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import BlogDetails from "../component/BlogDetails";


const BlogDetailsPage = () => {
  return (
    <MainWrapper>
      <BlogDetails />
    </MainWrapper>

  );
};

export default BlogDetailsPage;
