import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Ride from "../component/Ride";


const RidePage = () => {
  return (
    <MainWrapper>
      <Ride />
    </MainWrapper>

  );
};

export default RidePage;
