import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import EasterBunnyBundles from "../../component/LandingPage/EasterBunnyBundles";

const EasterBunnyBundlesPage = () => {
  return (
    <MainWrapper>
      <EasterBunnyBundles />
    </MainWrapper>
  );
};

export default EasterBunnyBundlesPage;
