import { Box, Container, Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";


type ConditionalWrapType = {
  refresh?: number | null
  children: JSX.Element;
};

export const MainWrapper = ({ refresh, children }: ConditionalWrapType): JSX.Element => {

  return (
    <>
      <Header refresh={refresh} />
      {children}
      <Footer />
    </>
  );
};
