import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  Heading,
  Show,
} from "@chakra-ui/react";
import * as Style from "./Posts.style";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import ReadMoreText from "./ReadMoreText";
import Comments from "./Comments";
import AuthUser from "../../services/Auth";
import LikeList from "./LikeList";
import CreatePostModal from "./CreatePostModal";
import SharePostModal from "./SharePostModal";
import DeleteModal from "./DeleteModal";
import { LikeUnlike } from "../../services/FeedService";
function Posts({ data, refresh }) {
  const { userId, Encrypt, api } = AuthUser();
  const { likeHandler, unLikeHandler } = LikeUnlike()
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation()
  const [isliked, setIsLiked] = useState(false);
  const [showLikeList, setShowLikeList] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [share, setShare] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showComment, setShowComment] = useState(false);
  useEffect(() => {
    console.log("post----------------", data);
    setCommentCount(data?.comment_count)
    setIsLiked(data?.is_like);
    setLikeCount(data?.like_count);

  }, [data]);
  const likeToggler = (id: number) => {
    setIsLiked(!isliked);
    if (isliked) {
      unLikeHandler(id);
      if (likeCount == 0) {
        setLikeCount(0);
      } else {
        setLikeCount(likeCount - 1);
      }
    } else {
      likeHandler(id);
      setLikeCount(likeCount + 1);
    }
  };
  const deletePost = () => {
    api
      .delete("/feeds/delete", { params: { feed_id: data.id } })
      .then((res) => {
        if (res?.data?.success) {
          setShowDelete(false);
          refresh();
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Share Post",
          text: "Check out this Post!",
          url: location?.pathname, // dynamic URL you want to share
        });
      } else {
        navigator.clipboard.writeText("forum.perpetua.ie" + location?.pathname)
        alert("Post link coppied successfully")
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };
  return (
    <>
      <Style.PostsBackground>
        <Style.PostsTitleWrap marginBottom="15px">
          <Flex>
            <Style.image
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/forum/profile/" + Encrypt(data?.user_id));
              }}
              alt="image"
              src={
                data?.user?.image
                  ? data?.user?.image
                  : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${data?.user?.name}`
              }
            />
            <Box style={{ paddingLeft: "10px" }}>
              <Style.PostsTitle
                _hover={{
                  cursor: "pointer",
                  textDecor: "underline",
                }}
                onClick={() => {
                  navigate("/forum/profile/" + Encrypt(data?.user_id));
                }}
              >
                {data?.user?.name}
              </Style.PostsTitle>
              <Style.Postsactive>
                {moment(new Date(data?.created_at)).fromNow()}{" "}
                <Text
                  color="Red"
                  cursor="pointer"
                  onClick={() =>
                    navigate("/forum/feeds/category/" + data?.category?.slug)
                  }
                  as="span"
                >
                  #{data?.category?.title}
                </Text>
              </Style.Postsactive>
            </Box>
          </Flex>
          {data?.user_id == userId && (
            <Menu>
              <MenuButton>
                <Style.threeDotWrap className="bi bi-three-dots" />
              </MenuButton>
              <MenuList
                zIndex="999"
                color="#fff"
                backgroundColor="#282828"
                borderColor="#777"
              >
                <MenuItem
                  cursor="pointer"
                  fontFamily="poppins"
                  onClick={() => {
                    onOpen();
                  }}
                  _focus={{ color: "#fff", backgroundColor: "#444" }}
                  _hover={{ color: "#fff", backgroundColor: "#444" }}
                  alignItems="center"
                  fontSize="14px"
                >
                  <Box className="bi bi-pencil-square" paddingRight="5px" />
                  Edit
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  cursor="pointer"
                  fontFamily="poppins"
                  onClick={() => {
                    setShowDelete(true);
                  }}
                  _focus={{ color: "#fff", backgroundColor: "#444" }}
                  _hover={{ color: "#fff", backgroundColor: "#444" }}
                  alignItems="center"
                  fontSize="14px"
                >
                  <Box className="bi bi-trash" paddingRight="5px" />
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Style.PostsTitleWrap>
        {data?.description && (
          <Box padding="0 20px 10px" fontSize="14px">
            <ReadMoreText maxlength={200}>{data?.description}</ReadMoreText>
          </Box>
        )}
        {data?.feed_image && (
          <Box
            // onClick={() => {
            //   navigate("/forum/feed/" + Encrypt(data?.id));
            // }}
            padding="0 20px 15px "
          >
            <Style.image1 src={data?.feed_image} alt="" />
          </Box>
        )}
        {data?.shared_feed && (
          <Box
            margin="0 20px 15px "
            border="1px solid #808080"
            borderRadius="8px"
          >
            {data?.shared_feed == "FEED_DELETED" ? (
              <Box
                width="100%"
                justifyContent="center"
                alignItems="center"
                padding="50px"
                textAlign="center"
                fontFamily="poppins"
              >
                <Box
                  className="bi bi-file-earmark-image-fill"
                  color="#ccc"
                  fontSize="60px"

                />
                <Heading color="#ccc">This content isn't available</Heading>
                <Text color="#ccc" fontSize="16px">
                  When this happens, it's usually because the owner only shared
                  it with a small group of a people or changed who can see it,
                  or it's been deleted.
                </Text>
              </Box>
            ) : (
              <>
                {data?.shared_feed?.feed_image && (
                  <Style.image1
                    // onClick={() => {
                    //   navigate("/forum/feed/" + Encrypt(data?.id));
                    // }}
                    borderTopLeftRadius="8px"
                    borderTopRightRadius="8px"
                    marginBottom="15px"
                    src={data?.shared_feed?.feed_image}
                    alt=""
                  />
                )}
                <Style.PostsTitleWrap marginBottom="10px">
                  <Flex>
                    <Style.image
                      _hover={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(
                          "/forum/profile/" +
                          Encrypt(data?.shared_feed?.user_id)
                        );
                      }}
                      alt="image"
                      src={
                        data?.shared_feed?.user?.image
                          ? data?.shared_feed?.user?.image
                          : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${data?.shared_feed?.user?.name}`
                      }
                    />
                    <Box style={{ paddingLeft: "10px" }}>
                      <Style.PostsTitle
                        _hover={{
                          cursor: "pointer",
                          textDecor: "underline",
                        }}
                        onClick={() => {
                          navigate(
                            "/forum/profile/" +
                            Encrypt(data?.shared_feed?.user_id)
                          );
                        }}
                      >
                        {data?.shared_feed?.user?.name}
                      </Style.PostsTitle>
                      <Style.Postsactive>
                        {moment(
                          new Date(data?.shared_feed?.created_at)
                        ).fromNow()}{" "}
                        <Text
                          color="Red"
                          cursor="pointer"
                          onClick={() =>
                            navigate(
                              "/forum/feeds/category/" +
                              data?.shared_feed?.category?.slug
                            )
                          }
                          as="span"
                        >
                          #{data?.shared_feed?.category?.title}
                        </Text>
                      </Style.Postsactive>
                    </Box>
                  </Flex>
                </Style.PostsTitleWrap>
                {data?.shared_feed?.description && (
                  <Box padding="0 20px 10px" fontSize="14px">
                    <ReadMoreText maxlength={200}>
                      {data?.shared_feed?.description}
                    </ReadMoreText>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
        {(likeCount > 0 || commentCount > 0) && <Flex padding="0 5px 10px" marginX="20px" alignItems="center" justifyContent="space-between">

          <Flex
            alignItems="center" minWidth="70px"
            _hover={{ cursor: likeCount == 0 ? "not-allowed" : "pointer" }}
            onClick={() => likeCount != 0 && setShowLikeList(true)}
          >
            <Style.heartIcon
              className={`bi bi-suit-heart-fill`}
            />
            <Style.favCount
              minWidth="30px"
              marginTop="2px"
            >
              {likeCount}
            </Style.favCount>
          </Flex>
          {commentCount > 0 && <Flex
            onClick={() => {
              setShowComment(!showComment);
            }}
            paddingLeft="20px"
            alignItems="center"
            cursor="pointer"
          >


            <Style.favCount>{commentCount} Comment{commentCount > 1 && "s"}</Style.favCount>
          </Flex>
          }
        </Flex>}
        <Box borderTop={data?.shared_feed && likeCount == 0 && commentCount == 0 ? "none" : "1px solid #808080"} marginX="20px">
          <Flex marginY="5px" marginX="auto" maxWidth="580px" alignItems="center" justifyContent="space-between">

            <Flex
              style={{ alignItems: "center", minWidth: "70px", padding: "5px 15px", }}
              transition="ease-in-out 0.2s"
              onClick={() => likeToggler(data?.id)}
              _hover={{ backgroundColor: "#383838", borderRadius: "5px", cursor: "pointer" }}
            >
              <Style.heartIcon
                color={isliked ? "#D12C41" : "#F0F0F0!important"}
                className={`bi bi-suit-heart${isliked ? "-fill" : ""}`}

              />
              <Show breakpoint='(min-width: 500px)'>   <Text
                marginTop="2px"
                paddingLeft={{ base: "5px", md: "10px" }}
                color={isliked ? "#D12C41" : "#F0F0F0"}
                fontFamily="Poppins"
                fontSize="16px"
                minWidth="30px"
              >
                Like
              </Text></Show>
            </Flex>
            <Flex
              onClick={() => {
                setShowComment(!showComment);
              }}
              style={{
                padding: "5px 15px",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              transition="ease-in-out 0.2s"
              _hover={{ backgroundColor: "#383838", borderRadius: "5px" }}
            >
              <Box className="bi bi-chat-left" marginTop="5px" color="#F0F0F0" fontSize="18px" />

              <Show breakpoint='(min-width: 500px)'> <Style.favCount>Comment</Style.favCount></Show>
            </Flex>

            {data?.shared_feed != "FEED_DELETED" && (
              <Menu>
                <MenuButton _hover={{ backgroundColor: "#383838", borderRadius: "5px" }}>
                  <Flex
                    onClick={() => {
                      setShowComment(!showComment);
                    }}
                    style={{
                      padding: "5px 15px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}

                  >
                    <Box className="bi bi-share" color="#fff" fontSize="20px" />

                    <Show breakpoint='(min-width: 500px)'>  <Style.favCount>Share</Style.favCount></Show>
                  </Flex>

                </MenuButton>
                <MenuList
                  zIndex="999"
                  color="#fff"
                  backgroundColor="#282828"
                  borderColor="#777"
                >
                  <MenuItem
                    cursor="pointer"
                    fontFamily="poppins"
                    onClick={() => setShare(true)}
                    _focus={{ color: "#fff", backgroundColor: "#444" }}
                    _hover={{ color: "#fff", backgroundColor: "#444" }}
                    alignItems="center"
                    fontSize="14px"
                  >
                    <Box className="bi bi-pencil-square" paddingRight="5px" />
                    Share to Feed
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    cursor="pointer"
                    fontFamily="poppins"
                    onClick={handleShare}
                    _focus={{ color: "#fff", backgroundColor: "#444" }}
                    _hover={{ color: "#fff", backgroundColor: "#444" }}
                    alignItems="center"
                    fontSize="14px"
                  >
                    <Box className="bi bi-link-45deg" paddingRight="5px" />
                    Share Link
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Box>
        {showComment && <> {data?.is_comment == 1 ? (
          <Comments postId={data?.id} commentCount={commentCount} setCommentCount={setCommentCount} />
        ) : (
          <Flex
            style={{
              justifyContent: "space-between",
              padding: "15px 20px",
              borderTop: "1px solid #808080",
              marginTop: "20px",
            }}
          >
            <Text
              textAlign="center"
              fontFamily="poppins"
              width="100%"
              color="#B9B9B9"
              fontSize="20px"
            >
              Comments not allowed
            </Text>
          </Flex>
        )}</>}
      </Style.PostsBackground >
      {isOpen && (
        <CreatePostModal
          data={data}
          isOpen={isOpen}
          onClose={onClose}
          isEdit={true}
          shared={data?.shared_feed ? true : false}
          postId={data.id}
          refresh={refresh}
        />
      )
      }
      {
        share && (
          <SharePostModal
            data={data}
            isOpen={share}
            onClose={() => {
              setShare(false);
            }}
            postId={data.id}
            refresh={refresh}
          />
        )
      }
      {
        showLikeList && (
          <LikeList
            postId={data?.id}
            isOpen={showLikeList}
            onClose={() => {
              setShowLikeList(false);
            }}
          />
        )
      }
      <DeleteModal
        title="Are you sure want to delete this post?"
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        deleteFunction={deletePost}
      />
    </>
  );
}

export default Posts;
