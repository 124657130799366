import { Flex, Box, Image, Button, Text, Input } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const PostsBackground = styled(Box)((props) => ({
  backgroundColor: "#282828",
  padding: "20px 0 10px",
  margin: "20px",
  borderRadius: "5px",
}));
export const PostsTitleWrap = styled(Flex)((props) => ({
  padding: "0px 20px",
  alignItems: "center",
  justifyContent: "space-between",
}));
export const threeDotWrap = styled(Flex)((props) => ({
  fontSize: "20px",
  color: "#fff",
  alignItems: "center",
  justifyContent: "center",
  width: "30px",
  height: "30px",
  borderRadius: "15px",

  ":hover": { cursor: "pointer" },
}));
export const image = styled(Image)((props) => ({
  width: "45px",
  objectFit: "cover",
  height: "45px",
  borderRadius: "23px",
}));
export const PostsTitle = styled(Text)((props) => ({
  fontSize: "14px",
  fontFamily: "Poppins",
  color: "#B9B9B9",
  fontWeight: "700",
}));
export const Postsactive = styled(Text)((props) => ({
  fontSize: "12px",
  wordWrap: "anywhere",
  fontFamily: "Poppins",
  color: "#F0F0F0",
}));
export const image2 = styled(Image)((props) => ({
  width: "35px",
  objectFit: "cover",
  height: "35px",
  borderRadius: "18px",
}));
export const AddBtn = styled(Button)((props) => ({
  width: "84px",
  borderRadius: "20px",
  fontSize: "16px",
  padding: "5px 20px",
  backgroundColor: "#D12C41",
  color: "#fff",
  ":hover": { backgroundColor: "#D12C41", color: "#fff" },
}));
export const CancelBtn = styled(Button)((props) => ({
  width: "84px",
  borderRadius: "20px",
  fontSize: "16px",
  padding: "5px 20px",
  marginRight: "10px",
  backgroundColor: "#fff",
  color: "#999",
  ":hover": { backgroundColor: "#fff", color: "#D12C41" },
}));
export const favCount = styled(Text)((props) => ({
  paddingLeft: "10px",
  color: "#F0F0F0",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
}));
export const linerow = styled(Box)((props) => ({
  borderTop: "1px solid #808080",
  margin: "0",
  padding: "5px 0",
}));

export const heartIcon = styled(Box)((props) => ({
  fontSize: "20px",
  color: "#D12C41",
  width: "30px",
  height: "30px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "3px",
}));
export const chatIcon = styled(Box)((props) => ({
  fontSize: "18px",
  color: "#888888",
  width: "30px",
  height: "30px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DCDCDC",
}));
export const shareIcon = styled(Box)((props) => ({
  fontSize: "18px",
  color: "#D12C41",
  width: "30px",
  height: "30px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
}));
export const sendIcon = styled(Box)((props) => ({
  color: "#DCDCDC",
  paddingRight: "10px",
  fontSize: "18px",
  [`@media ${device.tablet}`]: {
    paddingRight: "30px",
  },
}));
export const arrowUpIcon = styled(Box)((props) => ({
  color: "#DCDCDC",
  fontSize: "18px",
}));

export const moreComment = styled(Text)((props) => ({
  fontSize: "14px",
  fontFamily: "Poppins",
  color: "#B9B9B9",
  fontWeight: "500",
}));
export const image1 = styled(Image)((props) => ({
  width: "100%",
  objectFit: "fill",
  objectPosition: "center",
}));

export const AddComment = styled(Input)((props) => ({
  border: "0",
  backgroundColor: "#383838",
  borderRadius: "20px",
  fontSize: "16px",
  ":focus-visible": {
    border: "0!important",
  },
  color: "white",
  width: "100%",
  "::placeholder": {
    color: "#888888",
    padding: "0px !important",
  },
}));
export const chevronDownIcon = styled(Box)((props) => ({
  color: "#888888",
  fontSize: "16px",
}));
export const moreCommentWrap = styled(Flex)((props) => ({
  padding: "15px 20px 0",
  ":hover": { cursor: "pointer" },
  alignItems: "center",
}));
