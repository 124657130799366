import React from "react";
import { ForumWrapper } from "../../component/Forum/ForumWrapper/ForumWrapper";
import Gallery from "../../component/Forum/Gallery/Gallery";

const GalleryPage = () => {
  return (
    <ForumWrapper>

      <Gallery></Gallery>


    </ForumWrapper>
  );
};

export default GalleryPage;
