import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import ForumLogIn from "../../component/Forum/ForumLogIn";



const ForumLogInPage = () => {
  return (

    <MainWrapper>
      <ForumLogIn />
    </MainWrapper>

  );
};

export default ForumLogInPage;