import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Center,
  Button,
  Image,
} from "@chakra-ui/react";
import * as Style from "./Modal.style";
import AuthUser from "../../services/Auth";
import ImageResize from "./ImageResize";
import ImageBox from "./ImageBox";
import getCroppedImg from "./cropImage";
import Cropper from "react-easy-crop";
import DeleteModal from "./DeleteModal";
function ProfileModal(props) {
  const { setImage, apiFormData, user, api } = AuthUser();
  const [imagesrc, setimagesrc] = useState("");
  const [message, setMessage] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const img = await ImageResize(imagesrc, 500, "base64");
      const croppedImage = await getCroppedImg(
        img,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
      setShowCrop(false);
    } catch (e) {
      console.error(e);
    }
  };
  const submit = async () => {
    try {
      setIsLoading(true);
      const Image =
        croppedImage?.size > 150000
          ? await ImageResize(croppedImage, 300, "file")
          : croppedImage;
      var formData = new FormData();
      formData.append("image", Image);
      apiFormData
        .post("/profile/image-upload", formData)
        .then((res) => {
          if (res.data.success) {
            setMessage("Profile Image Updated successfully");
            setImage(URL.createObjectURL(imagesrc));
            setTimeout(() => {
              props?.refresh();
              props.onClose();
              setCroppedImage("");
              setimagesrc("");
              setMessage("");
            }, 1000);
          } else {
            setMessage(res.data.message[0]);
          }
        })
        .catch((err) => {
          setMessage(err.response.data.message[0]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteImage = () => {
    api
      .delete("/profile/image-remove")
      .then((res) => {
        if (res?.data?.success) {
          setShowDelete(false);
          setMessage(res?.data?.message);
          setImage("");
          setTimeout(() => {
            props?.refresh();
            props.onClose();
            setCroppedImage("");
            setimagesrc("");
            setMessage("");
          }, 1000);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={() => {
          props.onClose();
          setCroppedImage("");
          setimagesrc("");
          setMessage("");
        }}
        size={{ sm: "lg", md: "6xl" }}
      >
        <ModalOverlay />
        <Style.ModalForumContent>
          <Style.ModalForumBody>
            <Style.createWrap>
              <Box />
              <Text color="#fff" style={{ fontFamily: "Poppins" }}>
                {props.image ? "Change " : "Add "}Profile Image
              </Text>
              <Style.CancelBtn
                color="#fff!important"
                className="bi bi-x-lg"
                onClick={() => {
                  props.onClose();
                  setimagesrc("");
                  setMessage("");
                }}
              />
            </Style.createWrap>
            <Box
              justifyContent="center"
              padding={{ sm: "15px 15px", md: "15px 30px", lg: "40px" }}
            >
              <Flex position="relative" minH="305px" justifyContent="center">
                {imagesrc && showCrop ? (
                  <Cropper
                    image={URL.createObjectURL(imagesrc)}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                ) : (
                  <ImageBox
                    image={croppedImage ? croppedImage : imagesrc}
                    setImage={setimagesrc}
                    setCroppedImage={setCroppedImage}
                    setMessage={setMessage}
                    type="profile"
                    width="305px"
                  />
                )}
              </Flex>
              <Text
                fontSize="14px"
                fontFamily="Poppins"
                color={
                  message == "Profile Image Updated successfully"
                    ? "#5cb85c"
                    : "Red"
                }
                textAlign={"center"}
                padding="10px 5px 5px "
                minH="36px"
              >
                {message}
              </Text>
              <Center paddingTop="10px">
                {props?.image && (
                  <Button
                    onClick={() => setShowDelete(true)}
                    color="#000"
                    _hover={{ opacity: 0.7 }}
                    _focused={{ backgroundColor: "#fff" }}
                    fontFamily="Poppins"
                    cursor="pointer"
                    bgColor="#fff"
                    fontWeight="500"
                    fontSize="18px"
                    marginRight="10px"
                    paddingY={{ base: "22px", lg: "25px" }}
                    colorScheme="gray"
                  >
                    <Box className="bi bi-trash3"></Box>
                  </Button>
                )}
                {imagesrc && (
                  <Button
                    onClick={() => {
                      if (showCrop) {
                        showCroppedImage();
                        setShowCrop(false);
                      } else {
                        setShowCrop(true);
                      }
                    }}
                    color="#fff"
                    _hover={{ opacity: 0.7 }}
                    _focused={{ backgroundColor: "Red" }}
                    fontFamily="Poppins"
                    cursor="pointer"
                    bgColor="Red"
                    fontWeight="500"
                    fontSize="18px"
                    paddingY={{ base: "22px", lg: "25px" }}
                    width="100px"
                    marginRight="10px"
                    colorScheme="Red"
                    // isDisabled={}
                  >
                    <Box className="bi bi-crop" paddingRight="10px"></Box>
                    {showCrop ? "Done" : "Crop"}
                  </Button>
                )}
                <Button
                  onClick={submit}
                  color="#fff"
                  _hover={{ opacity: 0.7 }}
                  _focused={{ backgroundColor: "Red" }}
                  fontFamily="Poppins"
                  cursor="pointer"
                  bgColor="Red"
                  fontWeight="500"
                  fontSize="18px"
                  marginX={props?.image ? 0 : "10px"}
                  paddingY={{ base: "22px", lg: "25px" }}
                  width={props?.image ? "130px" : "150px"}
                  colorScheme="Red"
                  isDisabled={isLoading}
                >
                  Save
                </Button>
              </Center>
            </Box>
          </Style.ModalForumBody>
        </Style.ModalForumContent>
      </Modal>
      <DeleteModal
        title="Are you sure want to delete your profile image?"
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        deleteFunction={deleteImage}
      />
    </>
  );
}

export default ProfileModal;
