import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Rumble12Rounds from "../component/Rumble12Rounds";


const Rumble12RoundsPage = () => {
  return (
    <MainWrapper>
      <Rumble12Rounds />
    </MainWrapper>

  );
};

export default Rumble12RoundsPage;
