import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Contact from "../component/Contact";


const ContactPage = () => {
  return (
    <MainWrapper>
      <Contact />
    </MainWrapper>

  );
};

export default ContactPage;
