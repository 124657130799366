import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import TeamListing from "../component/TeamListing";


const TeamListingPage = () => {
  return (
    <MainWrapper>
      <TeamListing />
    </MainWrapper>

  );
};

export default TeamListingPage;
