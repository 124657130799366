import React, { useEffect } from "react";
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import {
  Box,
  Stack,
  Container,
  Flex,
  SimpleGrid,
  VStack,
  Link,
  Image,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import WhatsincludedCard from "./WhatsIncluded Card/WhatsincludedCard";
import Testimonial from "./Testimonial/Testimonial";
import PriceBox from "./Price Component/PriceBox";
import MarqueLoop from "./Marque Loop/MarqueLoop";
import ContactForm from "./Form/ContactForm";
import LinkButton from "./Button/LinkButton";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";

import VideoBanner from "./VideoBanner/VideoBanner";
import * as Style from "./Crossfit.style";
import LazyLoad from "react-lazy-load";

const metaTitle = "Ladies Only Gym Classes Dublin | Perpetua Fitness";
const metaDesc =
  "Ladies strength group in Dublin with Perpetua Fitness. A supportive environment to get stronger.  Includes two sessions per week for 4 weeks.";

function LadiesGroup() {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* <InnerBanner
            backgroundColor='black' 
            backgroundImage={{sm:"url(./images/ladies_m.jpg)", md:'url(./images/ladies_banner.jpg)'}} 
            title='Ladies Strength Group'
            issubtextpara={false} 
            isButton={true} 
            issubtitleRight={true} 
            subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane Facility</Box>} 
            isSubtext={true} 
            subtitle='A supportive environment to get stronger'
            width={{sm:"100%"}}
            height={{ sm:'450px', md:'500px', lg:'550px', xl:'767px'}}
            buttontitle='Enquire now'
            Link='/contact-us'
            ButtonModalmarginTop={{sm:'10px', md:'0'}}
            videoModal='https://www.youtube.com/embed/WeL4yfMoleY'
            ></InnerBanner> */}

      <VideoBanner
        videobanner="../video/ladis-page.mp4"
        subtitle="Support and fun ladies group training"
        title="Ladies Strength Group"
        subtitleRight={
          <Box display="inline-block">
            <Image
              src="../images/map-white.svg"
              display="inline-block"
              top="12px"
              position="relative"
            ></Image>
            Available at our Windmill Lane Facility
          </Box>
        }
        isButton={true}
        issubtextpara={false}
        buttontitle="Buy Now"
        margin="0px 0 0"
        Link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=102276"
        videoModal="https://www.youtube.com/embed/WeL4yfMoleY"
        targetBlank={true}
        modalButtontitle="View The Experience"
        issubtitleRight={true}
      />

      <Flex
        backgroundColor="black"
        padding={{ sm: "15px 0px 0px", md: "30px 0px 0px", xl: "55px 0px 0px" }}
      >
        <Container maxW="container.xl">
          <MarqueLoop></MarqueLoop>
        </Container>
      </Flex>

      <Flex
        backgroundColor="black"
        padding={{ sm: "50px 15px", md: "80px 20px", xl: "100px 0px" }}
      >
        <Container maxW="container.xl">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            textAlign={{ base: "left", lg: "left" }}
            alignItems="center"
          >
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                currency="€"
                priceValue="300"
                time="/16 Sessions"
                description={
                  <Box>
                    Our Ladies Small Group includes three sessions per week for
                    6 weeks. Email Loz at{" "}
                    <Link href="mailto:lorraine@perpetua.ie">
                      {" "}
                      lorraine@perpetua.ie{" "}
                    </Link>
                    to find out more info.
                  </Box>
                }
                buttonText="Enquire Now"
                link="/contact-us"
                buttonPading="0px"
                issubHeading={false}
                width={{ base: "100%", sm: "70%" }}
              ></PriceBox>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <Heading
                title="What's Included"
                variant="small"
                color="white"
                margin="0px 0px 30px"
                padding={{ sm: "0px 0px 0px 0px", md: "0px 0px 0px 31px" }}
                textAlign={{ sm: "center", ms: "left" }}
              ></Heading>
              <SimpleGrid
                columns={{ base: "1", lg: "2" }}
                spacing="0px"
                className="_whatsinclude_box_body"
              >
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="16 Group Sessions"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    Link="/contact-us"
                    alt="Ladies Only Gym Classes Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Online Program"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    Link="/contact-us"
                    alt="Ladies Only Gym Classes Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Inbody Analysis"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    Link="/contact-us"
                    alt="Ladies Only Gym Classes Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Supportive environment"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    Link="/contact-us"
                    alt="Ladies Only Gym Classes Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <Box
        padding={{ sm: "50px 0px", md: "100px 0px" }}
        backgroundColor="LightGrey"
      >
        <Container maxW="container.xl">
          <Heading
            variant="extralarge"
            title="WHY CHOOSE PERPETUA"
            margin={{ sm: "0px auto 50px", md: "0px auto 86px" }}
            width={{ base: "90%", md: "60%" }}
            textAlign="center"
            lineHeight="1"
            color="black"
          />
          <SimpleGrid
            columns={{ sm: "1", md: "3" }}
            spacing={5}
            textAlign="center"
            justifyContent="center"
          >
            <GridItem>
              <Heading title="01" color="Red" textAlign="center" />
              <Heading
                title="EXPERIENCED COACHES"
                color="black"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="We would love to meet you at our facility and show you around. We can discuss when the next group will be starting and any questions you may have regarding strength training."
                color="black"
                padding="15px 20px"
              />
            </GridItem>
            <GridItem>
              <Heading title="02" color="Red" textAlign="center" />
              <Heading
                title="COMFORTABLE & SAFE ENVIRONMENT"
                color="black"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment."
                padding="15px 20px"
                color="black"
              />
            </GridItem>
            <GridItem>
              <Heading title="03" color="Red" textAlign="center" />
              <Heading
                title="Friendly Community"
                color="black"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="We understand that coming into a gym with your baby can be hard so we make sure all classes are run at our quieter times so you feel comfortable with the surroundings and maybe your little one can have a nap.
                            "
                color="black"
                padding="15px 20px"
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Flex
        flexDirection="column"
        padding={{ sm: "50px 0px ", md: "90px 0px 90px" }}
        position="relative"
      >
        <Container maxW="container.xl">
          <Testimonial
            headingWidth={{ sm: "97%", md: "90%" }}
            iconUri="/images/comment-quote-outline.svg"
            isname={true}
            title="“I loved the group training. I learnt the proper technique of movements, I got stronger and it made me feel more comfortable in the gym. I also met new people who I can now call my friends.”
                    "
            name="- PPERPETUA CLIENT"
          />
        </Container>
      </Flex>

      {/* video section */}

      <Box
        backgroundColor="black"
        padding={{ sm: "60px 0px", md: "80px 0px" }}
        borderTop="1px solid #232323"
      >
        <Container maxW="container.xl">
          <Style.Wrapper>
            <Style.LeftBox textAlign={{ sm: "center", md: "left" }}>
              <Heading
                variant="medium"
                title="Ladies Group Training "
                lineHeight="1"
                color="white"
              />
            </Style.LeftBox>
            <Style.RightBox>
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box
                    as="iframe"
                    src="https://www.youtube.com/embed/Z3XO3tBZ6AI"
                    width={{ sm: "100%", md: "100%" }}
                    height="550px"
                  />
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title="Ladies Group Training at Perpetua"
                    lineHeight="1"
                    color="white"
                    marginBottom="5px"
                  />
                  {/* <Heading 
                                    variant="small" 
                                    title=' Flow of a class' 
                                    lineHeight='1'
                                    color='white'
                                    /> */}
                </Style.TextWrapper>
              </Style.VideoWrapper>

              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box
                    as="iframe"
                    src="https://www.youtube.com/embed/0uLLaFS2Rr4"
                    width={{ sm: "100%", md: "100%" }}
                    height="550px"
                  />
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title="Perpetua Womens Workshop"
                    lineHeight="1"
                    color="white"
                    marginBottom="5px"
                  />
                  {/* <Heading 
                                    variant="small" 
                                    title=' Flow of a class' 
                                    lineHeight='1'
                                    color='white'
                                    /> */}
                </Style.TextWrapper>
              </Style.VideoWrapper>
            </Style.RightBox>
          </Style.Wrapper>
        </Container>
      </Box>
      {/* faq */}

      <Flex p={{ sm: "50px 0px", md: "90px 0px" }}>
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.lg",
            lg: "container.lg",
            xl: "container.xl",
          }}
        >
          <Stack direction={{ sm: "column", md: "row" }} spacing="24px">
            <Box
              width={{
                sm: "100%",
                md: "50%",
              }} /*position={{ base: 'relative', md: 'sticky' }} top={{ sm: '0', md: '100px' }} height={{sm:'auto',md:'150px' }} textAlign={{ sm: 'center', md: 'left' }} */
            >
              <Box
                paddingLeft="30px"
                position={"relative"}
                _after={{
                  position: "absolute",
                  content: '""',
                  width: "3px",
                  height: "100%",
                  top: "0",
                  left: "0",
                  background: "Red",
                }}
              >
                <Heading
                  title="FREQUENTLY ASKED QUESTIONS"
                  lineHeight="1"
                  padding="0px 0px 30px"
                  margin="0px auto"
                ></Heading>
                <Text
                  title="Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us."
                  width="80%"
                ></Text>
              </Box>
            </Box>
            <Box width={{ sm: "100%", md: "50%" }}>
              <Accordion
                defaultIndex={[0]}
                allowMultiple
                className="Accor_item _black_acording  white_acording"
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Who is suitable for this group?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="The group is available for females who are looking to learn how the techniques, build strength and gain more confidence in a gym setting."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="How many classes per week?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="There are 2 evening classes and one lunchtime session a week. If you can't make one session with
                                        other commitments, you can follow the program online. "
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="What should I bring to my session?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Wear light comfortable clothing & a water bottle."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Where are you located?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="These classes take place in our Windmill lane studio - 2 Windmill Lane, Dublin 2 ."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      {/*  */}

      <VStack
        backgroundColor="black"
        padding={{
          sm: "50px 0px",
          md: "50px 20px",
          lg: "70px 20px",
          xl: "96px 0px",
        }}
        borderBottom="1px solid #232323"
      >
        <Container
          display="flex"
          maxW="container.xl"
          flexDirection={{ base: "column", lg: "row" }}
          alignItems="top"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
        >
          <Box
            w={{ base: "100%", lg: "50%" }}
            textAlign={{ base: "center", lg: "left" }}
            marginBottom={{ base: "50px", lg: "0" }}
          >
            <Heading
              title="LOOKING FOR MORE INFORMATION"
              variant="extralarge"
              lineHeight="1"
              margin="0px 0px 40px"
              color="white"
            ></Heading>
            <Heading
              title="Chat with a member of the team "
              variant="smallmid"
              lineHeight="1"
              margin="0px 0px 30px"
              color="white"
            ></Heading>
            <Link
              href="/contact-us"
              textDecoration="none"
              display="inline-block"
              _hover={{ textDecoration: "none" }}
            >
              <LinkButton
                title="BOOK A CALL"
                Imguri="/images/red_arrow.svg"
                border="2px solid"
                borderColor="Red"
                padding="20px 25px"
                color="White"
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ base: "100%", lg: "50%" }}>
            <ContactForm pageName="LadiesGroup"></ContactForm>
          </Box>
        </Container>
      </VStack>
    </>
  );
}
export default LadiesGroup;
