import { useState } from "react";
import AuthUser from "./Auth";
import { useParams } from "react-router-dom";
import { parseFollowers, parseFollowing } from "../parser/parseProfile";
import type { RandomUserType } from "../type/Feed";
export const GetUser = () => {
    const { api, Decrypt, userId } = AuthUser()
    const [userProfile, setUserProfile] = useState<any>();
    const [loader, setLoader] = useState(true);
    const { id } = useParams()
    const fetchData = () => {
        let userid = !id ? userId : Decrypt(id)
        setLoader(true);
        api
            .get("/profile/single", {
                params: { user_id: userid },
            })
            .then((res) => {
                if (res.data.success) {
                    setUserProfile(res.data.data);
                }
            })
            .catch((err) => {
                console.log("user", err);
            })
            .finally(() => {
                setLoader(false);
            });
    }

    return { userProfile, loader, getUserDetails: fetchData };
};

export const Followers = () => {
    const { api, Decrypt } = AuthUser()
    const { id } = useParams()
    const [followingList, setFollowingList] = useState<Array<RandomUserType>>([]);
    const [followerList, setFollowerList] = useState<Array<RandomUserType>>([]);
    const [isFollowed, setIsFollowed] = useState(false)

    const createFollower = (id: number) => {
        api
            .post("/following/create", { to_user_id: id })
            .then((res) => {
                if (res?.data?.success) {
                    getFollowingList()
                    getFollowerList()
                }
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const removeFollower = (id: number) => {
        api
            .post("/following/remove", { to_user_id: id })
            .then((res) => {
                if (res?.data?.success) {
                    getFollowingList()
                    getFollowerList()
                }
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const CheckisFollowed = () => {
        api
            .get("/following/is-followed", {
                params: { to_user_id: Decrypt(id) },
            })
            .then((res) => {
                setIsFollowed(res?.data?.success);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getFollowingList = () => {
        api
            .get("/following/list", {
                params: { user_id: Decrypt(id) },
            })
            .then((res) => {
                if (res?.data?.data) {
                    const data = parseFollowing(res?.data?.data)
                    setFollowingList(data);
                } else {
                    setFollowingList([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getFollowerList = () => {
        api
            .get("/follower/list", {
                params: { user_id: Decrypt(id) },
            })
            .then((res) => {
                if (res?.data?.data) {
                    const data = parseFollowers(res?.data?.data);
                    setFollowerList(data);
                } else {
                    setFollowerList([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return { createFollower, removeFollower, getFollowingList, getFollowerList, CheckisFollowed, setIsFollowed, followingList, followerList, isFollowed }

};







