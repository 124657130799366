import React, { useEffect } from "react";
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import {
  Box,
  Stack,
  Container,
  Flex,
  SimpleGrid,
  VStack,
  Link,
  Image,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import WhatsincludedCard from "./WhatsIncluded Card/WhatsincludedCard";
import Testimonial from "./Testimonial/Testimonial";
import PriceBox from "./Price Component/PriceBox";
import MarqueLoop from "./Marque Loop/MarqueLoop";
import ContactForm from "./Form/ContactForm";
import LinkButton from "./Button/LinkButton";
// import WhyMembershipCard from '../component/WhyMemberShip/WhyMembershipCard';
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";
// import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from "./VideoBanner/VideoBanner";
import * as Style from "./Crossfit.style";

import BlogSection from "./Blog/BlogSection";
import Button from "./Button/CustomeButton";
import LazyLoad from "react-lazy-load";

const whyChoosePostNataData = [
  {
    id: "1",
    title: "CERTIFIED & EXPERIENCED COACHES",
    description:
      "Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.",
  },
  {
    id: "2",
    title: "COMFORTABLE & SAFE ENVIRONMENT",
    description:
      "Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment.",
  },
  {
    id: "3",
    title: "VARIETY OF TRAINING OPTIONS",
    description:
      "We have systems in place to make sure you are receiving the best service available. Each session will delivered online, you can track results, see your progress, communicate with your coach and receive daily accountability.",
  },
];

const WhatYouGetData = [
  {
    id: "1",
    title: "EXPERIENCED COACHING",
    description:
      "Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.",
    image: "/images/weight-icon.svg",
  },
  {
    id: "2",
    title: "PERSONAL PROGRAM",
    description:
      "You will receive your weekly program via an app so you can understand exactly what you are working towards, log your numbers and stats and see the progress you are making with the team.",
    image: "/images/Specialclasses.svg",
  },
  {
    id: "3",
    title: "COMFORTABLE & SAFE ENVIRONMENT",
    description:
      "Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment.",
    image: "/images/justgym-access.svg",
  },
];

const metaTitle = "Pregnancy Exercise Classes Dublin | Perpetua Fitness";
const metaDesc =
  "Pregnancy exercise classes in Dublin with Perpetua Fitness. Pre natal & post natal training. Train safely during and after pregnancy.";

function PostNatal(props) {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* <InnerBanner
        backgroundColor='black' 
        backgroundImage={{sm:'url(./images/pre_post_m.jpg)', md:'url(./images/pre-post-natal_bnner.jpg)'}} 
        title='PRE & POST
        NATAL TRAINING'
        issubtextpara={false} 
        isButton={true} 
        issubtitleRight={true} 
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane Facility</Box>} 
        isSubtext={true} 
        subtitle='TRAIN SAFELY DURING AND AFTER PREGNANCY'
        width={{sm:"100%"}}
        height={{ sm:'450px', md:'500px', lg:'550px', xl:'767px'}}
        buttontitle='Enquire now'
        Link='/contact-us'
        ButtonModalmarginTop={{sm:'10px', md:'0'}}
        videoModal='https://www.youtube.com/embed/-3cC0zZ6yt0'
        
      ></InnerBanner> */}

      <VideoBanner
        videobanner="../video/Website-banner.mp4"
        subtitle="PREGNANCY EXERCISE CLASSES DUBLIN"
        title="PRE & POST NATAL TRAINING"
        subtitleRight={
          <Box display="inline-block">
            <Image
              src="../images/map-white.svg"
              display="inline-block"
              top="12px"
              position="relative"
            ></Image>
            Available at our Windmill Lane Facility
          </Box>
        }
        isButton={true}
        issubtextpara={false}
        buttontitle="Enquire now"
        margin="0px 0 0"
        Link="/contact-us"
        videoModal="https://www.youtube.com/embed/-3cC0zZ6yt0"
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={true}
      />

      <Flex
        backgroundColor="black"
        padding={{ sm: "15px 0px 0px", md: "30px 0px 0px", xl: "55px 0px 0px" }}
      >
        <Container maxW="container.xl">
          <MarqueLoop></MarqueLoop>
        </Container>
      </Flex>
      <Flex
        backgroundColor="black"
        padding={{ sm: "50px 15px", md: "80px 20px", xl: "100px 0px" }}
      >
        <Container maxW="container.xl">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            textAlign={{ base: "left", lg: "left" }}
            alignItems="center"
          >
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                manheadind="Pre Natal-Group 150€/6 Weeks "
                time="/6 Weeks "
                description={
                  <Box>
                    We have a Pre-Natal Group that runs for a 6-week block that
                    involves 1 session per week.
                  </Box>
                }
                buttonText="Enquire Now"
                link="/contact-us"
                buttonPading="0px"
                issubHeading={false}
                width={{ base: "100%", sm: "70%" }}
                isHeading={false}
                isButton={false}
              ></PriceBox>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                currency="€"
                priceValue="Pre Natal-Group"
                time="150e/6 Weeks"
                description={
                  <Box>
                    We have a Post-Natal Group that runs for a 6-week block that
                    includes 2 sessions per week.
                  </Box>
                }
                buttonText="Enquire Now"
                link="/contact-us"
                buttonPading="0px"
                issubHeading={false}
                width={{ base: "100%", sm: "70%" }}
                isHeading={false}
                manheadind="Post Natal-Group 300€/6 weeks"
                isButton={false}
              ></PriceBox>
            </Box>
          </Stack>
          <Box
            w="100%"
            textAlign={{ sm: "left", md: "center", lg: "center" }}
            marginTop={{ lg: "50px", md: "50px", sm: "25px" }}
          >
            <Link href="/contact-us">
              <Button
                margin="30px 0px 0px"
                className="wow fadeInUp"
                title="Enquire Now"
              />
            </Link>
          </Box>
          <Box
            textAlign={{ sm: "left", md: "center", lg: "center" }}
            marginTop="30px"
          >
            <Text
              title={
                <Box>
                  Email Loz on{" "}
                  <Link
                    href="mailto:lorraine@perpetua.ie"
                    textDecoration="underline"
                    color="#d12c41"
                  >
                    lorraine@perpetua.ie
                  </Link>{" "}
                  find out more information on our available services.
                </Box>
              }
              color="#fff"
            ></Text>
          </Box>
        </Container>
      </Flex>

      {/* <Flex  backgroundColor='black' padding={{sm:'50px 15px',md:'80px 20px',xl:'100px 0px'}} borderTop='1px solid #2a2a2a'>
        <Container maxW='container.xl'>
          <Stack direction={{base:'column',md:'row'} } spacing='30px' textAlign={{base:'left', lg:'left'}} alignItems='center'>
              <Box w={{ base:'100%' ,lg:'50%'}} padding={{md:'0px 20px',lg:'0px 0px'}}>
                <PriceBox 
                  color='white'
                  currency='€'
                  priceValue='Pre Natal-Group'
                  time='150e/6 Weeks'
                  description={<Box>We have a Post-Natal Group that runs for a 6-week block that includes 2 sessions per week.</Box>}
                  buttonText='Enquire Now'
                  link='/contact-us'
                  buttonPading='0px'
                  issubHeading={false}
                  width={{base:'100%', sm:'70%'}}
                  isHeading={false}
                  manheadind='Post Natal-Group 300e/6 weeks'
                >
                </PriceBox>
              </Box>
              <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <Heading title='Services Available' variant='small' color='white' margin='0px 0px 30px' padding={{ sm: '0px 0px 0px 0px', md: '0px 0px 0px 31px' }} textAlign={{ sm: 'center', ms: 'left' }}></Heading>
              <SimpleGrid columns={{ base: '1', lg: '2' }} spacing='0px' className='_whatsinclude_box_body'>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    // Imguri='/images/gym.svg' 
                    title='Postpartum Group Training'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    Link='/contact-us'
                    alt="Pregnancy Exercise Classes Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg' 
                    title='Pre Natal Group Training '
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    Link='/contact-us'
                    alt="Pregnancy Exercise Classes Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg' 
                    title='1-1 Pre/Post Natal Personal Training'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    Link='/contact-us'
                    alt="Pregnancy Exercise Classes Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg' 
                    title='Pre & Post Online Coaching'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    Link='/contact-us'
                    alt="Pregnancy Exercise Classes Dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
          <Box textAlign='center' marginTop='30px'>
            <Text title={<Box>Email Loz on <Link href='mailto:lorraine@perpetua.ie' textDecoration='underline' color='#d12c41'>lorraine@perpetua.ie</Link> find out more information on our available services.</Box>} color='#fff'></Text>
          </Box>
        </Container>
      </Flex> */}

      {/* <WhyChoose title='WHY CHOOSE PERPETUA' data={whyChoosePostNataData}  padding={{sm:'60px 15px 0px' ,md:'70px 15px 60px'}} /> */}

      <Box
        padding={{ sm: "50px 0px", md: "100px 0px" }}
        backgroundColor="LightGrey"
      >
        <Container maxW="container.xl">
          <Heading
            variant="extralarge"
            title="WHY CHOOSE PERPETUA"
            margin={{ sm: "0px auto 50px", md: "0px auto 86px" }}
            width={{ base: "90%", md: "60%" }}
            textAlign="center"
            lineHeight="1"
            color="black"
          />
          <SimpleGrid
            columns={{ sm: "1", md: "3" }}
            spacing={5}
            textAlign="center"
            justifyContent="center"
          >
            <GridItem>
              <Heading title="01" color="Red" textAlign="center" />
              <Heading
                title="EXPERIENCED COACHES"
                color="black"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.
                "
                color="black"
                padding="15px 20px"
              />
            </GridItem>
            <GridItem>
              <Heading title="02" color="Red" textAlign="center" />
              <Heading
                title="COMFORTABLE & SAFE ENVIRONMENT"
                color="black"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment."
                padding="15px 20px"
                color="black"
              />
            </GridItem>
            <GridItem>
              <Heading title="03" color="Red" textAlign="center" />
              <Heading
                title="Friendly Community"
                color="black"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="We understand that coming into a gym with your baby can be hard so we make sure all classes are run at our quieter times so you feel comfortable with the surroundings and maybe your little one can have a nap.
                "
                color="black"
                padding="15px 20px"
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      {/* <WhyChoose title='WHAT YOU GET' data={WhatYouGetData } padding={{sm:'10px 15px 10px ' ,md:'10px 15px 40px'}} /> */}

      {/* <Container maxW='container.xl' padding={{ sm: '60px 30px 0px', md: '80px 30px 10px' }}>
        <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='top' paddingBottom={{ sm: '20px', md: '70px' }}>
          <Box w={{ md: '50%' }} position={{ base: 'relative', md: 'sticky' }} top={{ base: '0', md: '100px' }} height={{ base: 'auto', md: '150px' }} textAlign={{ sm: 'center', md: 'left' }}>
            <Heading title='WHAT YOU GET' lineHeight='1'></Heading>
          </Box>
          <Box width={{ md: '50%', lg: '50%' }}>

            <SimpleGrid
              columns={{ sm: '1', lg: '2' }}
              spacing={8}

            >
              <WhyMembershipCard Imguri='/images/weight-icon.svg' title='EXPERIENCED COACHING' paragraph='Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.' />
              <WhyMembershipCard Imguri='/images/justgym-access.svg' title='COMFORTABLE & SAFE ENVIRONMENT' paragraph='Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment.' />
              <WhyMembershipCard Imguri='/images/Specialclasses.svg' title='PERSONAL PROGRAM ' paragraph='You will receive your weekly program via an app so you can understand exactly what you are working towards, log your numbers and stats and see the progress you are making with the team.' />
            </SimpleGrid>

          </Box>
        </Stack>
      </Container> */}

      <Flex
        flexDirection="column"
        padding={{ sm: "50px 0px", md: "90px 0px" }}
        position="relative"
        // _after={{position:'absolute',content:'""',width:'554px', height:'464px', top:'0',left:'0',background:'LightGrey',zIndex:'-1'}}
        // _before={{position:'absolute',content:'""',width:'554px', height:'464px', top:'auto', bottom:'0',right:'0',background:'LightGrey',zIndex:'-1',margin:'auto'}}
      >
        <Container maxW="container.xl">
          <Testimonial
            headingWidth={{ sm: "97%", md: "90%" }}
            iconUri="/images/comment-quote-outline.svg"
            isname={true}
            title="“Having a baby changed everything for me! I’m delighted with the information I received 
          during my pregnancy and Loz helped me so much on this journey.”"
            name="- PPERPETUA CLIENT"
          />
        </Container>
      </Flex>

      {/* <Flex 
        flexDirection={{base:'column',xl:'row' }}
        p={{sm:'50px 0px', xl:'120px 0px 100px'}} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width={{sm:'100%',md:'100%',lg:'100%',xl:'58%'}} backgroundColor='black' marginRight={{lg:'0px',xl:'-220px'}} padding={{sm:'50px 0px', md:'50px 0px'}}  position='relative' zIndex='1'  marginTop={{lg:'0px', xl:'-172px'}}>
            <SimpleGrid  spacing={5} w={{sm:'90%',md:'90%'}} m='0px auto' display='flex' flexDirection={{sm:'column', md:'row'}} alignItems='flex-start' >
              <Box padding='60px 0px 47px' position='relative' height='auto' width={{sm:'100%' ,md:'55%'}} 
              _after={{w:'65px', h:'50px',position:'absolute', content:'""',top:'0', left:'0', backgroundImage:'url(../images/left-quote.svg)'}}
              _before={{w:'65px', h:'50px',position:'absolute', content:'""',bottom:'0', right:'0', top:'auto', backgroundImage:'url(../images/right-quote.svg)' }}
              >
                  <Heading as='h3' title='This is exactly what I needed. The guidance and coaching in this amazing facility took my training to the next level. 
                  I am delighted within the results' color='white' variant='medium' lineHeight='1'></Heading>
              </Box>
              <Box padding={{sm:'30px 20px', md:'15px 20px',lg:'25px 30px',xl:'30px'}} border='2px solid' borderColor='Red' borderRadius='20px' w={{sm:'100%',md:'45%'}} marginLeft={{sm:'0px', lg:'0px',xl:'50px'}}>
              <PriceBox 
                  color='white'
                  currency='€'
                  priceValue='60'
                  time='/session'
                  isButton={false}
                  buttonPading='20px 25px'
                  issubHeading={true}
                  width={{base:'100%', sm:'70%'}}
                  isDescription={false}
                  subheading='WHAT DO YOU GET'
                  border='2px solid'
                  borderColor='Red'
                >
                </PriceBox>
                <UnorderedList p='0' m='40px 0px 0px'listStyleType='none' color='white' fontFamily='Poppins' fontWeight='300' >
                  <ListItem borderTop='1px solid #2E2E2E' padding='15px 0px' position='relative' paddingLeft="30px"
                    _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                   >
                    EXPERIENCED COACHING
                  </ListItem>

                  <ListItem borderTop='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                  _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                  >
                    PERSONAL PROGRAM
                  </ListItem>

                  <ListItem borderTop='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                  _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                  >
                   SAFE ENVIRONMENT
                  </ListItem>

                </UnorderedList>
                <Link href='contact-us' _hover={{textDecoration:'none'}}>
                    <LinkButton 
                     color='white' 
                     title='BOOK A CALL ' 
                     Imguri='/images/red_arrow.svg' 
                     padding='20px 25px' 
                     margin='30px 0px 0px' 
                     border='2px solid' 
                     borderColor='Red' 

                     ></LinkButton>
                </Link>
              </Box>
            </SimpleGrid>
        </Box>
        <Box 
        width={{base:'100%',xl:'54%' }}
        backgroundImage='url(./images/CF-page-bottom-bg.jpg)' 
        height={{lg:'400px', xl:'558px'}}
        backgroundRepeat='no-repeat'
        backgroundPosition='center'
        backgroundSize='cover'
        ></Box>

      </Flex> */}

      {/* <VideoSection heading='Pre/Post Natal' headingSub='Training at Perpetua' video1='https://youtube.com/embed/-3cC0zZ6yt0?si=Myn4-Rtkn6KUggUX' 
      video2='https://youtube.com/embed/V9AVPmPYwLE?si=AQaDgcJiFYqZntVg' smallHeading='Find out more' smallHeading2='Breathing Technique' midHeading='' midHeading2=''/> */}

      <Box
        backgroundColor="black"
        padding={{ sm: "60px 0px", md: "80px 0px" }}
        borderTop="1px solid #232323"
      >
        <Container maxW="container.xl">
          <Style.Wrapper>
            <Style.LeftBox textAlign={{ sm: "center", md: "left" }}>
              <Heading
                variant="medium"
                title="PRE/POST NATAL"
                lineHeight="1"
                color="white"
              />
              <Heading
                variant="medium"
                title="TRAINING AT PERPETUA"
                lineHeight="1"
                color="white"
              />
            </Style.LeftBox>
            <Style.RightBox>
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box
                    as="iframe"
                    src="https://youtube.com/embed/-3cC0zZ6yt0?si=Myn4-Rtkn6KUggUX"
                    width={{ sm: "100%", md: "100%" }}
                    height="550px"
                  />
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title="FIND OUT MORE"
                    lineHeight="1"
                    color="white"
                    marginBottom="5px"
                  />
                  {/* <Heading 
                  variant="small" 
                  title='Gavin' 
                  lineHeight='1'
                  color='white'
                  /> */}
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box
                    as="iframe"
                    src="https://youtube.com/embed/V9AVPmPYwLE?si=AQaDgcJiFYqZntVg"
                    width={{ sm: "100%", md: "100%" }}
                    height="550px"
                  />
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title="BREATHING TECHNIQUE"
                    lineHeight="1"
                    color="white"
                    marginBottom="5px"
                  />
                  {/* <Heading 
                  variant="small" 
                  title='Eoghan' 
                  lineHeight='1'
                  color='white'
                  /> */}
                </Style.TextWrapper>
              </Style.VideoWrapper>
              {/* <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/p6iaTOnEdnI' width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Safe Core Movements ' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper> */}
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box
                    as="iframe"
                    src="https://www.youtube.com/embed/ksFkWySYU3o"
                    width={{ sm: "100%", md: "100%" }}
                    height="550px"
                  />
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title="Post Natal Training at Perpetua Fitness"
                    lineHeight="1"
                    color="white"
                    marginBottom="5px"
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
            </Style.RightBox>
          </Style.Wrapper>
        </Container>
      </Box>

      <BlogSection catSlug="prepost-natal" />

      <Flex p={{ sm: "50px 0px", md: "90px 0px" }}>
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.lg",
            lg: "container.lg",
            xl: "container.xl",
          }}
        >
          <Stack direction={{ sm: "column", md: "row" }} spacing="24px">
            <Box
              width={{
                sm: "100%",
                md: "50%",
              }} /*position={{ base: 'relative', md: 'sticky' }} top={{ sm: '0', md: '100px' }} height={{sm:'auto',md:'150px' }} textAlign={{ sm: 'center', md: 'left' }} */
            >
              <Box
                paddingLeft="30px"
                position={"relative"}
                _after={{
                  position: "absolute",
                  content: '""',
                  width: "3px",
                  height: "100%",
                  top: "0",
                  left: "0",
                  background: "Red",
                }}
              >
                <Heading
                  title="FREQUENTLY ASKED QUESTIONS"
                  lineHeight="1"
                  padding="0px 0px 30px"
                  margin="0px auto"
                ></Heading>
                <Text
                  title="Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us."
                  width="80%"
                ></Text>
              </Box>
            </Box>
            <Box width={{ sm: "100%", md: "50%" }}>
              <Accordion
                defaultIndex={[0]}
                allowMultiple
                className="Accor_item _black_acording  white_acording"
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title=" Who Is This Suitable for Postnatal Training? "
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="1-2-1 Training and Post Natal Group Training is suitable once you have been cleared for exercise (postnatal generally 6 weeks post vaginal birth /12 weeks post caesarean birth)."
                      margin="0px 0px 20px"
                    ></Text>
                    <Text
                      title="​​Most feel comfortable from 2-3 months postnatal up until their baby is at pre-crawling stage and is suitable for moms with babies up to 12 months."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Have You Any Options For Prenatal? "
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Yes we offer both 1-2-1 personal training where you work closer with Loz, as well as our Prenatal small group training."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Can I exercise if I’m pregnant and haven’t exercised before? "
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Yes, absolutely. We will start you slow and build it up."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="What Should I Bring To My Session?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title={
                        <Box>
                          For you:
                          <br />
                          Wear light comfortable clothing & a water bottle
                          <br />
                          For your little one:
                          <br />
                          Anything you generally need and maybe a little
                          blanket/toy that can keep them occupied for some time.
                        </Box>
                      }
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Where Are You Located?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title=" 2 WindMill Lane, Dublin 2"
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="What If I’m Unsure What Suits Me Best?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Have a consultation with Loz, grab a coffee or arrange a call to help figure out which suits you best."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <VStack
        backgroundColor="black"
        padding={{
          sm: "50px 0px",
          md: "50px 20px",
          lg: "70px 20px",
          xl: "96px 0px",
        }}
        borderBottom="1px solid #232323"
      >
        <Container
          display="flex"
          maxW="container.xl"
          flexDirection={{ base: "column", lg: "row" }}
          alignItems="top"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
        >
          <Box
            w={{ base: "100%", lg: "50%" }}
            textAlign={{ base: "center", lg: "left" }}
            marginBottom={{ base: "50px", lg: "0" }}
          >
            <Heading
              title="LOOKING FOR MORE INFORMATION"
              variant="extralarge"
              lineHeight="1"
              margin="0px 0px 40px"
              color="white"
            ></Heading>
            <Heading
              title="Chat with a member of the team "
              variant="smallmid"
              lineHeight="1"
              margin="0px 0px 30px"
              color="white"
            ></Heading>
            <Link
              href="/contact-us"
              textDecoration="none"
              display="inline-block"
              _hover={{ textDecoration: "none" }}
            >
              <LinkButton
                title="BOOK A CALL"
                Imguri="/images/red_arrow.svg"
                border="2px solid"
                borderColor="Red"
                padding="20px 25px"
                color="White"
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ base: "100%", lg: "50%" }}>
            <ContactForm pageName="PostNatal"></ContactForm>
          </Box>
        </Container>
      </VStack>
    </>
  );
}

export default PostNatal;
