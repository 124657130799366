import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import RideFirsttimers from "../component/RideFirsttimers";


const RideFirsttimersPage = () => {
  return (
    <MainWrapper>
      <RideFirsttimers />
    </MainWrapper>

  );
};

export default RideFirsttimersPage;
