import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import Feeds from "../../component/Forum/Feeds";



const FeedPage = () => {
  return (
    
    <MainWrapper>
        <Feeds />
    </MainWrapper>

  );
};

export default FeedPage;
