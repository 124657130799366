import {
  Flex,
  Box,
  Link,
  Image,
  Button,
  Text,
  MenuButton,
  MenuItem,
  Tab,
} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const forumProfilebg = styled(Box)((props) => ({
  backgroundColor: "#000",
  borderTop: "1px solid #262626",
}));
export const forumProfileImageBg = styled(Flex)((props) => ({
  alignItems: "flex-end",
  justifyContent: "center",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "30vh",
  [`@media ${device.tablet}`]: {
    height: "192px",
  },
  [`@media ${device.tabletM}`]: {
    height: "250px",
  },
  [`@media ${device.tabletL}`]: {
    height: "290px",
  },
}));

export const profileImgBackground = styled(Box)((props) => ({
  border: "5px solid #fff",
  width: "128px",
  height: "128px",
  borderRadius: "100%",
  marginBottom: "-20px",
  position: "relative",
  [`@media ${device.tablet}`]: {
    width: "198px",
    height: "198px",
  },
}));
export const profileImg = styled(Image)((props) => ({
  width: "120px",
  height: "120px",
  borderRadius: "100%",
  objectFit: "cover",
  marginBottom: "-30px",
  [`@media ${device.tablet}`]: {
    width: "190px",
    height: "190px",
  },
}));
export const profileImgAdd = styled(Box)((props) => ({
  fontSize: "22px",
  position: "absolute",
  left: "40%",
  bottom: 0,
  backgroundColor: "#fff",
  padding: "5px",
  borderRadius: "15px",
  marginBottom: "-20px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}));

export const profileTitle = styled(Text)((props) => ({
  fontSize: "35px",
  color: "#fff",
  fontWeight: "700",
  textAlign: "center",
  marginTop: "40px",
  fontFamily: "Poppins",
}));
export const FamilyTitle = styled(Text)((props) => ({
  fontSize: "16px",
  color: "#fff",
  fontWeight: "500",
  fontFamily: "Poppins",
  paddingRight: "10px",
}));
export const FamilyTitleWrap = styled(Flex)((props) => ({
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
}));

export const profileTimelineWrap = styled(Flex)((props) => ({
  alignItems: "center",
  marginTop: "20px",
  borderTop: "1px solid #262626",
  padding: "20px 0",
  flexWrap: "wrap",
  [`@media ${device.tablet}`]: {
    flexDirection: "row",
  },
}));
export const TimelineTitle = styled(Text)((props) => ({
  fontSize: "16px",
  color: "#fff",
  fontWeight: "400",
  fontFamily: "Poppins",
  paddingRight: "20px",
}));
export const menuItemTitle = styled(MenuItem)((props) => ({
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "13px",
}));

export const scrollBox = styled(Box)((props) => ({
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
  overflowY: "scroll",
  maxHeight: "520px",
}));

export const ForumRightSideWrap = styled(Box)((props) => ({
  height: "100%",
  zIndex: "11",
  top: "90px",
  width: "100%",

  padding: "0px 0px 30px",
  [`@media ${device.tablet}`]: {
    width: "37%",
    position: "sticky",
    borderLeft: "1px solid #262626",
    paddingLeft: "30px",
    paddingTop: "30px",
  },
  [`@media ${device.tabletM}`]: {
    width: "30%",
    position: "sticky",
    paddingLeft: "30px",
    paddingTop: "30px",
  },
}));
export const friendsTitle1 = styled(Text)((props) => ({
  fontFamily: "Poppins",
  fontWeight: "300",
  fontSize: "12px",
  color: "#fff",
  [`@media ${device.tablet}`]: {
    // paddingRight: "50px"
  },
}));
export const friendsTitle = styled(Text)((props) => ({
  fontFamily: "Poppins",
  fontWeight: "300",
  fontSize: "12px",
  color: "#fff",
  textTransform: "capitalize",
  paddingLeft: "15px",
  [`@media ${device.tablet}`]: {
    paddingLeft: "10px",
  },
}));
export const FriendTitleWrap = styled(Flex)((props) => ({
  justifyContent: "space-between",
  [`@media ${device.tablet}`]: {
    paddingRight: "20px",
    //justifyContent: 'flex-start',
  },
}));
export const FriendGallery = styled(Image)((props) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "center",
  borderRadius: "12px",
  marginRight: "10px",
}));
// export const FriendGalleryWrap = styled(Flex)(props => ({
//     alignItems: 'center',
//     //justifyContent: 'center',
//     padding: '10px 0px',
//     flexWrap: "wrap",
//     [`@media ${device.tablet}`]: {
//         justifyContent: "flex-start"
//     },
// }))

export const rightPeople = styled(Text)((props) => ({
  fontSize: "14px",
  fontFamily: "Poppins",
  color: "#fff",
  fontWeight: "700",
}));
export const rightSeeAll = styled(Text)((props) => ({
  fontSize: "14px",
  marginLeft: "10px",
  fontFamily: "Poppins",
  color: "#D12C41",
  fontWeight: "300",
}));
export const rightSeeAllWrap = styled(Flex)((props) => ({
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0",
  [`@media ${device.tablet}`]: {
    padding: "0 10px 0 0px",
  },
}));

///// feeds page
export const image = styled(Image)((props) => ({
  width: "35px",
  height: "35px",
  objectFit: "cover",
  borderRadius: "18px",
}));
export const postTotal = styled(Text)((props) => ({
  fontSize: "13px",
  fontFamily: "Poppins",
  color: "#D12C41",
  fontWeight: "300",
}));
export const hashIcon = styled(Box)((props) => ({
  color: "#D12C41",
  fontSize: "18px",
}));
export const repeatIcon = styled(Box)((props) => ({
  color: "#fff",
  fontSize: "14px",
  marginLeft: "120px",
}));

export const recordFillIcon = styled(Box)((props) => ({
  position: "absolute",
  right: "-3px",
  bottom: "-8px",
  fontSize: "15px",
  color: "green",
}));

// Tab Style
export const TabTitle = styled(Tab)((props) => ({
  color: "#fff",
  fontSize: "16px",
  fontFamily: "Poppins",
  borderBottom: "3px solid",
}));
