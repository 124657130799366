import {
  Flex,
  Container,
  SimpleGrid,
  GridItem,
  Box,
  Link,
  Image,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Heading from "./Heading/Heading";
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import ClassCard from "./Classcard/ClassCard";
import AppDownload from "./AppDownload/AppDownload";
import Text from "./SubHeading/SubHeading";
// import{Link} from 'react-router-dom';
import Button from "./Button/CustomeButton";
import Testimonial from "./Testimonial/Testimonial";
// import CfProgram from '../component/CF Program/CfProgram';
import Accordian from "./Accordion/Accordian";
// import { Helmet } from 'react-helmet';
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";
import VideoSection from "./VideoSection/VideoSection";
import VideoBanner from "./VideoBanner/VideoBanner";
import BlogSection from "./Blog/BlogSection";

// import ClasscardV2 from '../component/Classcard/ClasscardV2';
// import * as Style from '../component/ClassListing/ClassListing.style';

const accoData = [
  {
    id: 1,
    title: "When should I arrive for class?",
    description:
      "We recommend arriving at the gym 15 minutes before class for check-in. This will allow time for our Front Desk staff to get you checked into class and show you around the facility.",
  },
  {
    id: 2,
    title: "What do I do when I get there?",
    description:
      "Say hello to whoever is at the front desk and mention that it is your first time. They’ll get you taken care of.",
  },
  {
    id: 3,
    title: "What if I'm running late?",
    description:
      "To ensure that your spot is not released to the waitlist, please arrive at least 5 minutes prior to the start of your class.",
  },
  {
    id: 3,
    title: "Good Vibes Zone",
    description:
      "We want you to bring your good vibes! We truly believe in making our gyms a space where members can check their stress at the door. In our gyms you’ll find a community built on sweat and support, all are welcome here. All we ask is that you show up, bring a smile, and get in a great workout. It’s that simple.",
  },
];
const accoData2 = [
  {
    id: 1,
    title: "What is a Tread and Shred class like?",
    description:
      "Our signature Tread&Shred class is a group fitness experience unmatched in Ireland. You’ll alternate between treadmill and floor rounds focused on strength, power, and endurance. You’ll torch calories and get stronger and faster every class.",
  },
  {
    id: 2,
    title: "I've never done this style of training before, can I keep up?",
    description:
      "Absolutely! Our workouts are beginner friendly and designed to allow all athletes to workout at their own pace. Our Coaches are trained to modify and make adjustments to the workout on the fly to meet you where you are.",
  },
  {
    id: 3,
    title: "What should I wear?",
    description:
      "Wear athletic clothes that are comfy for you to sweat and move in.",
  },
  {
    id: 3,
    title: "What shoes should I wear?",
    description:
      "Most athletes wear traditional cross-training shoes suitable for cardio & strength training.",
  },
  {
    id: 3,
    title: "Do you guys provide water?",
    description:
      "Each of our gyms are equipped with filtered water stations. Bring a reusable water bottle with you and fill up before class!",
  },
];
const accoData3 = [
  {
    id: 1,
    title: "Do you have showers?",
    description:
      "Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided.",
  },
  {
    id: 2,
    title: "That was fun! What's next?",
    description:
      "Awesome! Schedule your next workout through the Perpetua Fitness app. If you have any questions, please feel free to reach out to our team at any facility or through hello@perpetua.ie",
  },
];

const metaTitle = "Fitness Classes Dublin | Tread & Shred | Perpetua Fitness";
const metaDesc =
  "Fitness Classes in Dublin with Perpetua Fitness. Our signature Tread & Shred class is a group fitness experience unmatched in Ireland. Book a class.";

function SweatPage(props) {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* <InnerBanner 
        backgroundColor='black' 
        backgroundImage={{sm:'url(./images/sweat_m.jpg)', md:'url(./images/tread-banner.jpg)'}} 
        subtitle='SWEAT STUDIO'
        title='TREAD AND SHRED'
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image> Available at our Windmill Lane & Lennox Street Facilities</Box>}
        issubtextpara={false} 
        isButton={true} 
        issubtitleRight={true} 
        width={{sm:"100%" }}
        buttontitle='Book a class'
        Link='/pricing'
        ButtonModalmarginTop={{sm:'10px', md:'0'}}
        videoModal='https://www.youtube.com/embed/gRnSHXYk0wY'
        /> */}

      <VideoBanner
        videobanner="../video/T&S-banner.mp4"
        subtitle="FITNESS CLASS DUBLIN"
        title="TREAD AND SHRED"
        subtitleRight={
          <Box display="inline-block">
            <svg
              style={{
                display: "inline-block",
                top: "12px",
                position: "relative",
              }}
              display="inline-block"
              top="12px"
              position="relative"
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
            >
              <path
                d="M18.9993 10.2917C20.0492 10.2917 21.056 10.7087 21.7983 11.451C22.5406 12.1934 22.9577 13.2002 22.9577 14.25C22.9577 14.7698 22.8553 15.2845 22.6564 15.7648C22.4574 16.245 22.1659 16.6814 21.7983 17.049C21.4307 17.4165 20.9944 17.7081 20.5141 17.907C20.0339 18.1059 19.5192 18.2083 18.9993 18.2083C17.9495 18.2083 16.9427 17.7913 16.2004 17.049C15.4581 16.3066 15.041 15.2998 15.041 14.25C15.041 13.2002 15.4581 12.1934 16.2004 11.451C16.9427 10.7087 17.9495 10.2917 18.9993 10.2917ZM18.9993 3.16666C21.9388 3.16666 24.7579 4.33436 26.8364 6.41289C28.915 8.49142 30.0827 11.3105 30.0827 14.25C30.0827 22.5625 18.9993 34.8333 18.9993 34.8333C18.9993 34.8333 7.91602 22.5625 7.91602 14.25C7.91602 11.3105 9.08372 8.49142 11.1622 6.41289C13.2408 4.33436 16.0599 3.16666 18.9993 3.16666ZM18.9993 6.33332C16.8997 6.33332 14.8861 7.1674 13.4014 8.65206C11.9168 10.1367 11.0827 12.1504 11.0827 14.25C11.0827 15.8333 11.0827 19 18.9993 29.6242C26.916 19 26.916 15.8333 26.916 14.25C26.916 12.1504 26.0819 10.1367 24.5973 8.65206C23.1126 7.1674 21.099 6.33332 18.9993 6.33332Z"
                fill="white"
              />
            </svg>{" "}
            Available at our Windmill Lane & Lennox Street Facilities
          </Box>
        }
        isButton={true}
        issubtextpara={false}
        buttontitle="BOOK A CLASS"
        margin="0px 0 0"
        Link="/timetable"
        videoModal="https://www.youtube.com/embed/gRnSHXYk0wY"
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={true}
      />

      <Flex
        flexDirection="column"
        padding={{
          sm: "0",
          md: "50px 0px 60px",
          lg: "70px 20px 70px",
          xl: "80px 0px 80px",
        }}
        background="#000"
        marginBottom="100px"
      >
        <Container maxW="container.xl">
          <Heading
            as="h3"
            title="INTELLIGENT PROGRAMMING, WORLD CLASS COACHES, & THE PERFECT BEATS. OUR SIGNATURE TREAD & SHRED CLASS IS A GROUP 
            FITNESS EXPERIENCE UNMATCHED IN IRELAND. YOU’LL ALTERNATE BETWEEN TREADMILL AND FLOOR EXERCISES THAT ARE FOCUSED ON STRENGTH, POWER, AND ENDURANCE. YOU’LL BURN CALORIES AND GET STRONGER AND FASTER EVERY CLASS."
            variant="medium"
            textAlign="center"
            lineHeight="1"
            width={{ lg: "100%", xl: "100%" }}
            padding={{ sm: "40px 0px", md: "0px 0px 70px" }}
            className="wow animate fadeInUp"
            color="#fff"
          ></Heading>
        </Container>
        <Container maxW="container-fluid">
          <SimpleGrid
            columns={{ sm: "1", md: "2", lg: "2", xl: "3", xxl: "4" }}
            columnGap={4}
            rowGap={2}
            width="full"
            marginTop="50px"
            padding={{ sm: "0px", md: "0px 30px" }}
          >
            <GridItem
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.7s"
            >
              <ClassCard
                title="WARM UP"
                subtitle="Please arrive 15 minutes early and check in with our front desk. Our staff will introduce
                        you to the class coach. They will provide an introduction to how the class works and show you how to use our treadmills. We have 6-10 minutes set aside to start your session off with a dynamic warm-
                        up to prep your body for the activity to come, we will alternate between the treadmills and floor until your heart rate is up and your body is ready to go!"
                bg="url('/images/TreadAndShread/WarmUp.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
            <GridItem
              mt={{ sm: "40px", xl: "80px" }}
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.9s"
            >
              <ClassCard
                title="TREADMILL"
                subtitle="LACE UP YOUR TRAINERS AND GET READY TO WORK. YOUR COACH WILL GUIDE YOU THROUGH THE LIKES OF LUNG BUSTING SPRINTS, INCLINE INTERVALS AND ENERGY SAPPING SLED PUSHES. NOT A RUNNER? NO BOTHER. YOUR COACH WILL GUIDE YOU EVERY STEP OF THE WAY - BEGINNER, INTERMEDIATE, AND ADVANCED RUNNERS OUT THERE, WE HAVE YOU COVERED!"
                bg="url('/images/TreadAndShread/Treadmill.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
            <GridItem
              mt={{ sm: "0", lg: "0", xl: "160px" }}
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.9s"
            >
              <ClassCard
                title="FLOOR WORK"
                subtitle="Let’s get spicy. Our floor rounds incorporate a variety of movements and equipment, including boxes, trx ropes, and our signature torpedoes. Floor rounds are designed to maximise your strength and agility, complimenting the work you’re doing on the tread."
                bg="url('/images/TreadAndShread/Floorwork.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
            <GridItem
              mt={{ sm: "0", md: "40px", lg: "40px", xl: "0", xxl: "240px" }}
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.9s"
            >
              <ClassCard
                title="COOL DOWN & HIGH FIVES"
                subtitle="We have 5 minutes set aside to finish class with a cool-down to bring your heart rate back
                        to normal and stretch out your muscles. Afterward, you can shower on-site & grab a shake at our
                        Mojo Health Bar"
                bg="url('/images/TreadAndShread/Cooldown.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      {/* <Flex 
        p={{sm:'30px 0px', md:'50px 0px'}}
        backgroundColor='black'
        borderTop='1px solid #232323'
        borderBottom='1px solid #232323'
        >
            <Container maxW={"container.xl"}>
                <Box textAlign='left'>
                    <Heading 
                        marginBottom='50px' 
                        variant="medium"
                        title="Make Your Move"
                        className='wow animate fadeInUp' 
                        color='#fff'
                        >
                    </Heading>
                </Box>
                <Style.ClasslistingBody>
                    <ClasscardV2
                        width={{xl:'23%', lg:'22%',md:'46%',sm:'100%'}}
                        title='CROSSFIT'
                        subtitle='COACHING. COMMUNITY. PASSION'
                        imageUri='/images/crossfit_new.jpg'
                        link="/crossfit"
                        margin="0"
                        transform="uppercase"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                    <ClasscardV2
                        width={{xl:'23%', lg:'22%',md:'46%',sm:'100%'}}
                        title='STUDIOS'
                        subtitle='DUBLINS BEST BOUTIQUE STUDIO EXPERIENCE'
                        imageUri='/images/studio.jpg'
                        link="/studios"
                        margin={{lg:'60px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                    <ClasscardV2
                        width={{xl:'23%', lg:'22%',md:'46%',sm:'100%'}}
                        title='Personal Training '
                        subtitle='WE KNOW EXACTLY WHAT IT TAKES TO GET RESULTS'
                        imageUri='/images/personal_training.jpg'
                        link="/personal-training"
                        margin={{lg:'120px 0px 0px', md:'0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                    <ClasscardV2
                        width={{xl:'23%', lg:'22%',md:'46%',sm:'100%'}}
                        title='JUSTGYM'
                        subtitle='FULLY EQUIPPED GYM'
                        imageUri='/images/just_gym_new.jpg'
                        link="/justgym"
                        margin={{lg:'200px 0px 0px', md:'0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                    />
                </Style.ClasslistingBody>
            </Container>
      </Flex> */}

      <Flex
        flexDirection="column"
        padding={{ sm: "50px 0px ", md: "0px 0px 0px" }}
      >
        <Container maxW="container.xl">
          <SimpleGrid
            columns={{ sm: "1", md: "2" }}
            columnGap={10}
            rowGap={2}
            width="full"
            textAlign={{ sm: "center", md: "left" }}
          >
            <GridItem>
              <Heading
                as="h2"
                title="WHO IS TREAD AND SHRED FOR?"
                variant="extralarge"
                textAlign={{ sm: "center", md: "left" }}
                lineHeight="1"
                padding="0px 0px 20px"
              ></Heading>
            </GridItem>
            <GridItem>
              <Text
                as="p"
                fontFamily="poppins"
                title="Our signature Tread & Shred class is a group fitness experience unmatched in Ireland. It is tailored towards complete beginners as well as professional athletes.You’ll alternate between treadmill and floor exercises focused on strength, power, and endurance. You’ll burn calories and get stronger and faster every class."
                width="98%"
              ></Text>
              <Heading
                as="h4"
                title="WHO IS TREAD AND SHRED FOR?"
                variant="extrasmall"
                textAlign={{ sm: "center", md: "left" }}
                lineHeight="1"
                width="102%"
                padding={{ base: "30px 0px 15px", sm: "62px 0px 30px" }}
              ></Heading>
              <Link href="/pricing" _hover={{ textDecoration: "none" }}>
                <Button
                  title="Get Started"
                  className="wow fadeInUp"
                  color="black"
                />{" "}
              </Link>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>

      <Flex
        flexDirection="column"
        padding={{
          sm: "0 0 20px",
          md: "50px 0px",
          lg: "70px 0px",
          xl: "80px 0px",
        }}
        position="relative"
      >
        <Container maxW="container.xl">
          <Testimonial
            headingWidth={{ sm: "97%", md: "90%", lg: "87%", xl: "69%" }}
            iconUri="/images/comment-quote-outline.svg"
            isname={false}
            title="“THE COACHES ARE BOTH PROFESSIONAL AND WELL-TRAINED. I WOULD ABSOLUTELY RECOMMEND PERPETUA TO ANYONE, FROM A BEGINNER TO A EXPERIENCED GYM USER”"
          />
        </Container>
      </Flex>

      {/* <Container maxW='container.xl' padding={{sm:'50px 15px 0',xl:'95px 0px 60px'}} >
        <Flex flexDirection={{sm: "column", md: "row"}} >
          <Box w={{sm:"100%", md: "45%"}} h={{sm:"auto" , md: '40px'}} position={{sm:"relative" , md: 'sticky'}} top={{sm:"0" , md: '100px'}}  paddingBottom={{sm:"30" , md: '10px'}}>
            <Heading title='WHATS MY FIRST CLASS LOOK LIKE?' variant='extralarge' lineHeight='1'></Heading>
          </Box>
          <Box w={{sm:"100%", md: "55%"}} >
              <VStack spacing={2}align='stretch' width={"full"}>
                <Stack p='0px 0px 50px' borderBottom='1px solid #D9D9D9'>
                  <Heading as='h2' title='01' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title='ARRIVAL AND CHECK IN' variant='small' margin='0px 0px 30px'></Heading>
                  <Text as='p' title='Please arrive 15 minutes early and check in with our front desk. Our staff will introduce you to the class coach. They will provide an introduction to how the class works and show you how to use our treadmills.'></Text>
                </Stack>
                <Stack p='50px 0px' borderBottom='1px solid #D9D9D9'>
                  <Heading as='h2' title='02' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title='CLASS BREAKDOWN ' variant='medium' margin='0px 0px 30px' width='50%' lineHeight='1'></Heading>
                  <br></br>
                  <CfProgram 
                    count='[A]' 
                    title='WARM-UP' 
                  >
                  </CfProgram>
                  <Text as='p' title='We have 6-10 minutes set aside to start your session off with a dynamic warm-up to prep your body for the activity to come, we will alternate between the treadmills and floor until your heart rate is up and your body is ready to go!'></Text>
                  <br></br>
                  <CfProgram 
                    count='[B]' 
                    countColor= "#000"
                    title=' THE WORKOUT' 
                  >
                  </CfProgram>
                  <br></br>
                  <Heading as='h3' title='TREADMILL' variant='small' margin='0px 0px 30px'lineHeight='1'></Heading>
                  <Text as='p' title='Lace up your trainers and get ready to work. Your coach will guide you through 
                    your running rounds on our state-of-the-art treadmills, cueing speed and incline intervals. Not a runner? No problem.
                    Your coach will give you a range of beginner, intermediate, and advanced speeds.'></Text>
                  <br></br>
                  <Heading as='h3' title='FLOOR WORK' variant='small' margin='0px 0px 30px'lineHeight='1'></Heading>
                  <Text as='p' title='Let’s get spicy. Our floor rounds incorporate a variety of movements and equipment, including boxes, trx ropes, 
                  and our signature torpedoes. Floor rounds are designed to maximise your strength and agility, complimenting the work you’re doing on the tread.'></Text>
                
                  <br></br>
                  <CfProgram 
                    count='[C]' 
                    title='COOL-DOWN' 
                  >
                  </CfProgram>
                  <Text as='p' title='We have 5 minutes set aside to finish class with a cool-down to bring your heart rate back to normal and stretch out your muscles.'></Text>
                  <br></br>
                </Stack>
                <Stack p='50px 0px'>
                  <Heading as='h2' title='03' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title='HIGH FIVES & DE-BRIEFS' variant='small' margin='0px 0px 30px'></Heading>
                  <Text as='p' title='Afterward, you can shower on-site & grab a shake at our Mojo Health Bar'></Text>
                </Stack>
              </VStack>
          </Box>
        </Flex>
      </Container> */}

      <Flex
        flexDirection={{ sm: "column", md: "row" }}
        padding={{ sm: "50px 0px ", md: "30px 0px 0" }}
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        justifyItems="center"
      >
        <Flex
          width={{ sm: "100%", md: "50%" }}
          backgroundColor="LightGrey"
          padding={{ sm: "45px 0 40px", md: "120px 0" }}
          position="relative"
          justifyContent="flex-end"
          /*_after={{position:'absolute',content:'""',width:'200px', height:'100%', top:'0',left:'100%',background:'LightGrey',zIndex:'-1', display:{sm:"none", md:"block"}}}
           */
        >
          {" "}
          <Box width={{ md: "100%", xl: "630px" }} padding="0 15px">
            <Heading
              title="Every class is coached by top professionals in the industry."
              variant="extralarge"
              lineHeight="1"
              margin="0px 0px 60px"
            ></Heading>
            <Text
              title="These aren’t your run of the mill coaches. These are professional coaches with 
                years of experience and dedicated to helping you get results."
              margin="0px 0px 20px"
            ></Text>
            <Text title="When you join a class at PERPETUA, you’re not just a number. Here, you are paying for guidance, accountability, and motivation from professionals who know how to get it done."></Text>
          </Box>
        </Flex>
        <Box
          width={{ sm: "100%", md: "50%" }}
          backgroundImage="url(./images/Sweatevery_class_new.webp)"
          height={{ sm: "300", md: "558px" }}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
        ></Box>
      </Flex>

      {/*   <Flex 
        flexDirection='row' 
        padding={{base:'150px 0px 0px ', sm:'310px 0px 0px' }} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width='50%' textAlign='right'>
            <Box 
            backgroundImage='url(./images/sweat-bg2.png)' 
            width='551px' height='671' 
            display='inline-block' 
            backgroundRepeat='no-repeat'
            backgroundPosition='center'
            backgroundSize='cover'
            position='relative'
            zIndex='1'
            top="-80px"
            >
            </Box>
        </Box>
        <Box width='58%' backgroundColor='LightGrey' marginLeft='-145px' padding='180px 300px 180px 200px' >
            <Heading title='EVERY CLASS IS A COACH-LED BY PROS' variant="extralarge" lineHeight='1' margin='0px 0px 60px'></Heading>
            <Text 
              title='These aren’t your run of the mill personal trainers. These are professional coaches with 
              years of experience and dedicated to helping you get results.'
              margin='0px 0px 20px'
            >
                  
            </Text>
            <Text title='When you join a class at PERPETUA, you’re not just a number. Here, you are paying for guidance, accountability, and motivation from professionals who know how to get it done.'></Text>
        </Box>

      </Flex> */}

      <VideoSection
        heading="Tread & Shred"
        headingSub="at Perpetua"
        video1="https://youtube.com/embed/PwZiFngkSFI?si=yL_ItKASuYwQNmk9"
        video2="https://youtube.com/embed/3mwRJOrRWzs?si=BrThLHFKeb2zvt5i"
        smallHeading="Find out more"
        smallHeading2="What our clients say"
        midHeading=""
        midHeading2=""
      />

      <Flex
        flexDirection="column"
        padding={{ sm: "20px 0px 50px", md: "100px 0px 100px" }}
        textAlign="center"
        justifyContent="center"
        justifyItems="center"
      >
        <Container maxW="container.xl">
          <Box>
            <Heading
              as="h2"
              title="ARE YOU READY?"
              variant="extralarge"
              textAlign="center"
              lineHeight="1"
              padding={{ base: "0px 0px 15px", sm: "0px 0px 30px" }}
            ></Heading>
            <Text
              title="Try 3 classes for €33. All fitness levels welcome "
              as="p"
            ></Text>
            <Link href="/pricing" _hover={{ textDecoration: "none" }}>
              <Button
                title="Get Started"
                className="wow fadeInUp"
                color="black"
                margin="40px 0px 0px "
              />{" "}
            </Link>
            <Box>
              <Heading
                as="h5"
                title="Download our app "
                variant="extrasmall"
                textAlign="center"
                lineHeight="1"
                padding={{ base: "15px 0px 0px", sm: "30px 0px 0px" }}
                display="inline-block"
              ></Heading>

              <Link
                href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
                target="_blank"
              >
                <Heading
                  as="h5"
                  title="Here"
                  variant="extrasmall"
                  textAlign="center"
                  lineHeight="1"
                  padding={{ base: "15px 0px 0px", sm: "30px 0px 0px" }}
                  display="inline-block"
                  margin="0px 0px 0px 5px"
                  color="Red"
                  textDecoration="underline"
                ></Heading>
              </Link>
            </Box>
          </Box>
        </Container>
      </Flex>

      <Box
        padding={{ sm: "35px 0px 30px", md: "80px 0px" }}
        background={"#eeeef1"}
      >
        <Container maxW="container.xl">
          <Flex
            margin="auto"
            textAlign={"center"}
            width={{ sm: "100%", md: "50%" }}
            flexDirection="column"
            paddingBottom="30px"
          >
            <Heading
              lineHeight={"1"}
              variant="large"
              title="FREQUENTLY ASKED QUESTIONS"
              marginBottom="30px"
            />
            <Box mb="40px">
              <Text title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie" />
            </Box>
          </Flex>

          <Flex flexDirection={{ sm: "column", md: "row" }}>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="BEFORE CLASS"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="DURING CLASS"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData2} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="AFTER CLASS"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData3} />
            </Box>
          </Flex>
        </Container>
      </Box>
      <BlogSection catSlug="tread-shred" />
      <AppDownload
        bg="url('/images/App-section-bg.webp')"
        heading="Your Daily Dose of Online Workouts Is Live"
        para="The Best Online Training Platform"
        imageUri="/images/iphone.webp"
        appstore="/images/app-store.png"
        googleplay="/images/google-play.png"
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
        alt="iphone - fitness classes dublin"
      />
    </>
  );
}

export default SweatPage;
