import React from "react";
import ContactusForm from "./Form/Form";
import { Box, Container, Flex } from "@chakra-ui/react";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import OurLocation from "./OurLocation/OurLocation";
import MetaTitle from "./MetaTitle";

function ContactUs() {
  return (
    <>
      <MetaTitle title="Contact Us | Perpetua Fitness" />
      <Container maxW="container.xl" p={{ sm: "50px 15px", md: "150px 15px" }}>
        <Flex
          flexDirection={{ sm: "column", md: "row" }}
          className="_form_body"
        >
          <Box
            w={{ sm: "100%", md: "50%" }}
            paddingRight={{ sm: "0px", md: "50px", lg: "150px" }}
          >
            <OurLocation></OurLocation>
          </Box>
          <Box
            w={{ sm: "100%", md: "50%" }}
            marginTop={{ sm: "40px", md: "0" }}
            position="sticky"
            top="100px"
            height={{ md: "650px", lg: "550px" }}
          >
            <Box
              border="2px solid #F4F3F3"
              p={{ sm: "20px" }}
              m="0px auto"
              textAlign="left"
            >
              <Heading
                title="Contact"
                variant="extralarge"
                margin="0px 0px 15px"
                lineHeight="0.8"
              ></Heading>
              <Text
                title="Looking for more info? Fill out the form below and a member from our team will reach out to you shortly."
                color="Deepgre"
                lineHeight="1.2"
                margin="0px"
              ></Text>
              <ContactusForm></ContactusForm>
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
}

export default ContactUs;
