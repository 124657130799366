import React from "react";
import {
  Box,
  Container,
  GridItem,
  SimpleGrid,
  Image,
  Button,
  VStack,
  Link,
} from "@chakra-ui/react";
import Text from "./SubHeading/SubHeading";
import { FiMail } from "react-icons/fi";

function TemporarilyLanding() {
  const handleScroll = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <Box>
      <Box
        alignItems="center"
        justifyContent="center"
        backgroundImage={{
          sm: "url(./images/small-view.jpg)",
          md: "url(./images/Landingpage2.jpg)",
        }}
        backgroundColor="black"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        height={{
          sm: "85vh",
          md: "550px",
          lg: "650px",
          xl: "767px",
          xxl: "935px",
        }}
        backgroundSize={"cover"}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Box position="absolute" bottom="0" zIndex="1">
          <Image src="/images/scroll-down.png" />
        </Box>
        <Box
          position="absolute"
          bottom="0"
          zIndex="1"
          marginBottom="82px"
          onClick={handleScroll}
          _hover={{ cursor: "pointer" }}
        >
          <Image src="/images/Arrow.png" />
        </Box>
      </Box>
      <Box margin={{ sm: "50px 10px 70px", md: "70px 0" }}>
        <Container maxW="container.xl">
          <Text
            title={
              <Box>
                The{" "}
                <span style={{ color: "black", fontWeight: "700" }}>
                  Grand Finale
                </span>{" "}
                Of The Summer Is Here
              </Box>
            }
            textAlign="center"
            margin="0 auto"
            lineHeight="1.4"
            width={{ base: "90%", md: "70%" }}
            fontSize={{ sm: "30px", md: "60px" }}
            fontWeight="275"
            padding={{ sm: "0 0 30px" }}
          />
          <SimpleGrid
            columns={{ sm: "1", md: "2" }}
            spacing={5}
            textAlign="center"
            justifyContent="center"
          >
            <GridItem alignSelf="center">
              <Text
                title={
                  <Box>
                    As The Temperature Drops, We’re Turning Up With The Heat
                    With The <span style={{ fontWeight: "700" }}>HOTTEST</span>{" "}
                    Party Of The Year!
                  </Box>
                }
                fontSize="19px"
                fontWeight="300"
                textAlign="left"
                lineHeight="1.4"
                textTransform="capitalize"
              />
              <Text
                title={
                  <Box>
                    <span
                      style={{ textTransform: "uppercase", fontWeight: "700" }}
                    >
                      {" "}
                      Our Summer Closing Party
                    </span>{" "}
                    is THE party you don’t want to miss. Join us for a 3-day
                    party between
                    <span style={{ fontWeight: "700" }}>
                      {" "}
                      25th - 27th August{" "}
                    </span>{" "}
                    with exclusive surprises from your favourite studio coaches,
                    live studio sessions and your favourite Perpetua & Prosecco.
                  </Box>
                }
                textAlign="left"
                fontSize="14px"
                fontWeight="400"
                margin="30px 0 0"
                lineHeight="1.6"
                textTransform="capitalize"
              />
            </GridItem>
            <GridItem
              className="summer-closing"
              marginLeft={{ md: "100px" }}
              marginTop={{ sm: "20px", md: "0" }}
            >
              <Image
                className="summerimage"
                src="/images/Union.png"
                height={{ sm: "265px", lg: "350px" }}
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>
      <Box margin={{ sm: "30px 0", md: "0 " }}>
        <SimpleGrid
          columns={{ sm: "1", md: "2" }}
          spacing={15}
          justifyContent="center"
          backgroundColor="black"
          margin={{ md: "50px 0" }}
        >
          <GridItem>
            <Image
              src="/images/temlanding.png"
              height={{ sm: "400px", md: "430px" }}
            />
          </GridItem>
          <GridItem
            alignSelf="center"
            marginLeft={{ md: "50px" }}
            padding={{ sm: "20px 15px 40px", md: "0" }}
          >
            <Text
              title="Expect exclusive surprises from your favourite studio coaches, and the ultimate Perpetua & Prosecco you don’t want to miss.⁠ Plus, we've got live sessions in the studio for a sweat you’ll never forget. "
              color="#fff"
              fontSize="16px"
              fontWeight="300"
              lineHeight="25px"
              width={{ md: "60%" }}
              textTransform="capitalize"
            />
            <Text
              title={
                <Box>
                  {" "}
                  Check Out The Full Event schedule across for all Closing Party
                  events on our social platforms{" "}
                  <span
                    style={{ color: "#E3384B", textDecoration: "underline" }}
                  >
                    {" "}
                    Here.
                  </span>
                </Box>
              }
              color="#fff"
              fontSize="16px"
              fontWeight="600"
              lineHeight="25px"
              margin="30px 0 0"
              width={{ md: "70%" }}
              textTransform="capitalize"
            />
          </GridItem>
        </SimpleGrid>
      </Box>
      <VStack padding="20px 0px 60px">
        <Container
          display="flex"
          maxW="container.xl"
          flexDirection={{ base: "column", lg: "row" }}
          alignItems="top"
        >
          <Box
            w={{ base: "100%", lg: "40%" }}
            textAlign={{ base: "center", lg: "left" }}
            marginBottom={{ base: "50px", lg: "0" }}
          >
            <Text
              title="Looking to host your own event at Perpetua Fitness? We have a team that will help bring your ideas to life "
              width={{ lg: "75%" }}
              fontSize="16px"
              fontWeight="400"
              lineHeight="25px"
              textTransform="capitalize"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent={{ sm: "center", lg: "normal" }}
              margin={{ sm: "20px 0", md: "50px 0 20px" }}
            >
              <FiMail color="#E3384B" />
              <Link href="mailto:hello@perpetua.ie">
                <Text
                  title="hello@perpetua.ie"
                  fontWeight="500"
                  textTransform="capitalize"
                  margin="0 5px "
                />
              </Link>
            </Box>
            <Box>
              <Link href="contact-us">
                <Button
                  minH="45px"
                  minW={{ sm: "100px", md: "130px", xl: "182px" }}
                  borderRadius="50px"
                  backgroundColor="#E3384B"
                  color="white"
                  fontFamily="Poppins"
                  fontSize="16px"
                >
                  Get In Touch
                </Button>
              </Link>
            </Box>
          </Box>
          <Box
            className="temlanding"
            w={{ base: "100%", lg: "60%" }}
            display={{ md: "flex" }}
            gap={{ sm: "30px" }}
            justifyContent={{ sm: "center", lg: "normal" }}
          >
            <Image
              src="/images/temlanding3.png"
              className="temlanding-img"
              height={{ sm: "220px", md: "180px", xl: "226px" }}
            />

            <Image
              src="/images/temlanding2.png"
              className="temlanding-img"
              height={{ sm: "220px", md: "180px", xl: "226px" }}
              style={{ marginTop: 30 }}
            />
          </Box>
        </Container>
      </VStack>
    </Box>
  );
}
export default TemporarilyLanding;
