import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import AboutUs from "../component/AboutUs";


const AboutUsPage = () => {
  return (
    <MainWrapper>
      <AboutUs />
    </MainWrapper>

  );
};

export default AboutUsPage;
