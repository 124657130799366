import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import InnerBanner from "../InnerBanner/InnerBanner";
import Text from "../SubHeading/SubHeading";
import Heading from "../Heading/Heading";
import MetaTitle from "../MetaTitle";
import PriceCard from "../Pricing/PricingCard";

const EasterBunnyBundles = () => {
  return (
    <>
      <MetaTitle title="Easter Bunny Bundles | Perpetua Fitness" />
      <InnerBanner
        backgroundColor="black"
        backgroundImage={{
          sm: "url(./images/easterBunnyBundlesSmall.webp)",
          md: "url(./images/easterBunnyBundles.webp)",
        }}
        isHeading={false}
        issubtextpara={false}
        isButton={false}
        isButton2={false}
        issubtitleRight={false}
        isSubtext={false}
        width={{ sm: "100%", md: "100%" }}
        height={{ sm: "450px", md: "500px", lg: "550px", xl: "767px" }}
      />
      <Box backgroundColor="#000" padding={{ sm: "50px 0", md: "80px 0" }}>
        <Container maxW="container.xl">
          <Text
            title="The Easter Bunny has arrived.. "
            color="#fff"
            fontSize="18px"
            textAlign="center"
          />
          <Heading
            title="Get Studio Credits for as low as €16! "
            variant="large"
            color="#fff"
            textAlign="center"
            padding="10px 0 0"
          />
        </Container>
      </Box>
      <Box
        padding={{ sm: "50px 0", md: "80px 50px 30px", xl: "80px 70px 30px" }}
      >
        <Container maxW="100%">
          <Heading
            title="Limited time offer"
            variant="large"
            textAlign="center"
            padding="0 0 20px"
          />
          <SimpleGrid
            columns={{ sm: "1", md: "2", xxl: "3" }}
            spacing={10}
            padding={{ md: "50px 0" }}
          >
            <PriceCard
              key="1"
              offer="€18 per credit "
              cardTitle=""
              currency="€108"
              planname="Easter Bunny Bundle 6 Pack"
              description="30 Day expiry"
              link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107913"
              targetBlank="true"
              buttonTitle="Buy Now"
            />
            <PriceCard
              key="2"
              offer="€17 per credit"
              cardTitle=""
              currency="€153"
              planname="Easter Bunny Bundle 9 Pack"
              description="45 Day expiry"
              link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107914"
              targetBlank="true"
              buttonTitle="Buy Now"
            />
            <PriceCard
              key="3"
              offer="€16 per credit "
              cardTitle=""
              currency="€192"
              planname="Easter Bunny Bundle 12 Pack"
              description="2 Month expiry"
              link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107915"
              targetBlank="true"
              buttonTitle="Buy Now"
            />
          </SimpleGrid>
        </Container>
      </Box>
      <Box backgroundColor="#F4F4F3">
        <Container
          maxW="container.xl"
          padding={{ sm: "50px 15px", md: "80px 20px" }}
        >
          <Heading title="Terms & Conditions: " variant="large" />
          <Text
            title="Expiry Dates from Date of Purchase: "
            fontSize="22px"
            fontWeight="700"
            padding={{ sm: "15px 0 0", md: "20px 0 0" }}
          />
          <UnorderedList padding={{ sm: "15px 0", md: "20px 0" }}>
            <ListItem fontFamily="Poppins" fontWeight="500" fontSize="18px">
              Easter Bunny Bundle 6 Pack - 1 month expiry.
            </ListItem>
            <ListItem
              fontFamily="Poppins"
              fontWeight="500"
              fontSize="18px"
              padding="10px 0"
            >
              Easter Bunny Bundle 9 Pack - 45 day expiry.
            </ListItem>
            <ListItem fontFamily="Poppins" fontWeight="500" fontSize="18px">
              Easter Bunny Bundle 12 Pack - 2 month expiry.{" "}
            </ListItem>
          </UnorderedList>
          <Text
            title="Credits activate as of purchase date. For example, if you purchase an Easter Bunny Bundle 6 Pack on April 1st , the expiry date is May 1st."
            fontSize="18px"
            padding="15px 0 0"
            fontWeight="500"
          />
          <Text
            title="Easter Bunny Bundle Credits valid to use in both Windmill Lane in Lennox Street in Tread & Shred, RIDE, Rumble & HYROX."
            fontSize="18px"
            padding="10px 0 0"
            fontWeight="500"
          />
          <Text
            title="Standard Studio Credit T&Cs apply. No pauses or extensions & credits are non-transferable."
            fontSize="18px"
            padding="10px 0 0"
            fontWeight="500"
          />
        </Container>
      </Box>
    </>
  );
};

export default EasterBunnyBundles;
