import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Rumble from "../component/Rumble";


const RumblePage = () => {
  return (
    <MainWrapper>
      <Rumble />
    </MainWrapper>

  );
};

export default RumblePage;
