import React, { useEffect, useState } from "react";
import {
  Flex,
  Image,
  Box,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  Menu,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import Text from "../SubHeading/SubHeading";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../services/Auth";
function ForumHeader(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setImage, api, user, userId, userImage: image, Encrypt } = AuthUser();
  const [userImage, setUserImage] = useState(image);
  useEffect(() => {
    api
      .get("/profile/single", {
        params: { user_id: userId },
      })
      .then((res) => {
        if (res?.data?.data?.intro?.profile_image) {
          setImage(res?.data?.data?.intro?.profile_image);
          setUserImage(res?.data?.data?.intro?.profile_image);
        } else {
          setImage("");
          setUserImage("");
        }
      })
      .catch((err) => {
        console.log("user", err);
      });
  }, [props?.refresh]);
  const navigate = useNavigate();
  return (
    <>
      <Box display={{ sm: "block", md: "block" }}>
        <Flex alignItems="center">
          <Menu autoSelect="false">
            <MenuButton minW="50px">
              <Image
                alt="image"
                src={
                  userImage
                    ? userImage
                    : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${user?.name}`
                }
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                marginLeft={{ lg: "20px" }}
              />
            </MenuButton>
            <MenuList color="#fff" backgroundColor="#282828" borderColor="#777">
              <MenuGroup>
                <MenuItem
                  cursor="pointer"
                  fontFamily="poppins"
                  onClick={() => {
                    navigate("/forum/profile/" + Encrypt(userId));
                  }}
                  _focus={{ color: "#fff", backgroundColor: "#444" }}
                  _hover={{ color: "#fff", backgroundColor: "#444" }}
                  alignItems="center"
                  padding="10px 0"
                >
                  <Image
                    alt="image"
                    src={
                      userImage
                        ? userImage
                        : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${user?.name}`
                    }
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "18px",
                      marginLeft: "10px",
                      objectFit: "cover",
                    }}
                  />
                  <Text
                    title={user?.name}
                    color="#fff"
                    fontSize="14px"
                    padding="0 0 0 10px"
                  />
                </MenuItem>
                <MenuItem
                  cursor="pointer"
                  _focus={{ color: "#fff", backgroundColor: "#444" }}
                  _hover={{ color: "#fff", backgroundColor: "#444" }}
                  fontFamily="Poppins"
                  fontSize="14px"
                  paddingY="10px"
                  icon={
                    <Box fontSize="14px" className="bi bi-box-arrow-right" />
                  }
                  onClick={onOpen}
                >
                  Log Out
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
          <Modal
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
          >
            <ModalOverlay />
            <ModalContent
              backgroundColor="#282828!important"
              boxShadow="0 0 20px rgba(255,255,255,0.2)"
              paddingY="10px"
            >
              <ModalHeader
                fontWeight="400"
                fontSize={{ base: "20px", md: "26px" }}
                textAlign="center"
                color="#fff"
              >
                Are you sure want to log out?
              </ModalHeader>
              <ModalFooter paddingBottom="20px" justifyContent="center">
                <Button
                  paddingX="30px"
                  colorScheme="gray"
                  fontWeight="400"
                  fontSize={{ base: "16px", md: "18px" }}
                  mr={3}
                  onClick={onClose}
                >
                  No
                </Button>
                <Button
                  paddingX="30px"
                  colorScheme="red"
                  fontSize={{ base: "16px", md: "18px" }}
                  fontWeight="400"
                  // variant="ghost"
                  onClick={() => {
                    onClose();
                    localStorage.setItem("forum_token", "");
                    localStorage.setItem("forum_user", "");
                    localStorage.setItem("forum_user_id", "");
                    localStorage.setItem("user_image", "");
                    navigate("/");
                  }}
                >
                  Yes
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      </Box>
    </>
  );
}

export default ForumHeader;
