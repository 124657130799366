import React from "react";
import { Box, Modal, ModalOverlay, ModalContent, ModalBody, Flex, Image } from "@chakra-ui/react";


const ImagePopup = ({ isOpen, onClose, data }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="lg"
        >
            <ModalOverlay />
            <ModalContent maxW='70%' >
                <ModalBody
                    backgroundColor="rgba(0,0,0, 0.2)"
                    padding={{ sm: "80px 0 0", md: "75px 0 0" }}
                >
                    <Flex
                        padding={{ sm: "0 20px" }}
                        justifyContent={{ lg: "center" }}
                        alignItems={{ lg: "center" }}
                        position='relative'
                    >
                        <Box
                            className="bi bi-x"
                            fontSize="26px"
                            cursor="pointer"
                            color="#fff"
                            position='absolute'
                            right='0'
                            top='-40px'
                            onClick={onClose}
                        />
                        <Image src={data ? data : "/images/facebookPopup.jpg"} />
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ImagePopup;