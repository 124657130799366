import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    SimpleGrid,
    Flex,
    useDisclosure,
    Heading,
    Spinner,
    Text,
    Button,
} from "@chakra-ui/react";
import * as Style from "./ForumProfile.style";
import LeftSideMenu from "./LeftSideMenu";
import { GetPosts, GetRandomusers, } from "../../services/FeedService";
import PostWrapper from "./PostWrapper";
import YourIntro from "./YourIntro";
import PeopleYouMayKnow from "./PeopleYouMayKnow";
import ProfileModal from "./ProfileModal";
import CoverModal from "./CoverModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FriendCard from "./FriendCard";
import { PostsBackground } from "./Posts.style";
import GalleryService from "../../services/GalleryService";
import ReadMoreText from "./ReadMoreText";
import AuthUser from "../../services/Auth";
import { GetUser, Followers } from "../../services/ProfileService";
import { UnFollowBtn, ConnectBtn } from "./PeopleYouMayKnow.style"
import Gallery from "./Gallery/Gallery";

const Profile = ({ refresh, setRefresh }) => {
    const navigate = useNavigate();
    const { id, } = useParams()
    const location = useLocation()
    const { user, Decrypt, } = AuthUser();
    const { userProfile, getUserDetails, loader, } = GetUser();
    const { data: gallery, fetchData: getGallery } = GalleryService();
    const { data: FeedList, fetchData: getFeeds } = GetPosts();
    const { isFollowed, followingList, followerList, getFollowerList, getFollowingList, CheckisFollowed, createFollower, removeFollower, setIsFollowed } = Followers()
    const { isOpen, onClose, onToggle } = useDisclosure();
    const [seeAllSuggestion, setSeeAllSuggestion] = useState(false);
    const [profileModalShow, setProfileModalShow] = useState(false);
    const [coverModalShow, setCoverModalShow] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const { friendSuggestion } = GetRandomusers();

    const seeAllSuggestionHandler = () => {
        setSeeAllSuggestion(!seeAllSuggestion);
    };

    console.log(location);

    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: "Share " + userProfile?.intro?.name + "'s Profile",
                    text: "Check out this Profile in perpetua forum.",
                    url: location?.pathname
                    ,
                });
            } else {
                navigator.clipboard.writeText("forum.perpetua.ie" + location?.pathname)
                alert(userProfile?.intro?.name + "'s Profile link coppied successfully")
            }
        } catch (error) {
            console.error("Error sharing:",);
        }
    };
    useEffect(() => {
        const token = localStorage.getItem("forum_token");
        if (token) {
            setTabIndex(0)
            getGallery()
            getFeeds()
            CheckisFollowed()
            getUserDetails()
        } else {
            navigate("/forum/login");
        }
    }, [id]);
    useEffect(() => {
        getFollowerList()
        getFollowingList()
    }, [tabIndex])

    return (
        <Style.forumProfilebg>
            <Container
                maxW={{ sm: "Container.sm", md: "container.xl", xl: "100%" }}
                padding={{ xl: "0px 30px 50px" }}
            >
                <Flex direction={{ sm: "column", md: "row" }}>
                    <LeftSideMenu isOpen={isOpen} onClose={onClose} />
                    <Box width={{ lg: "83%" }} borderLeft={{ lg: "1px solid #262626" }}>
                        {loader ? (
                            <Flex
                                justifyContent="center"
                                align="center"
                                width="100%"
                                bg={"#000"}
                                minH="60vh"
                            >
                                <Spinner
                                    thickness="2px"
                                    speed="0.65s"
                                    emptyColor="#333"
                                    color="#ccc"
                                    size="lg"
                                />
                            </Flex>
                        ) : (
                            <>
                                <Box
                                    margin={{
                                        md: "0 50px 10px 15px",
                                        lg: "0px 60px 20px",
                                        xl: "0 60px 20px 120px",
                                    }}
                                >
                                    <Style.forumProfileImageBg
                                        backgroundColor="#ccc"
                                        backgroundImage={
                                            userProfile?.intro?.cover_image
                                                ? userProfile?.intro?.cover_image
                                                : null
                                        }
                                        //height={{ sm: '30vh', md: '192px', lg: '250px', xl: '280px', xxl: '300px' }}
                                        className="inner_banner"
                                    >
                                        {user?.id == Decrypt(id) && (
                                            <Box
                                                style={{
                                                    position: "absolute",
                                                    right: "15px",
                                                    bottom: "15px",
                                                    backgroundColor: "#fff",
                                                    padding: "5px 10px",
                                                    borderRadius: "8px",
                                                    alignItems: "center",
                                                    fontFamily: "poppins",
                                                    justifyContent: "center",
                                                    display: "flex",
                                                }}
                                                _hover={{ cursor: "pointer" }}
                                                position="absolute"
                                                onClick={() => {
                                                    setCoverModalShow(true);
                                                }}
                                                className="bi bi-camera-fill"
                                            >
                                                <Text
                                                    as="span"
                                                    display={{ sm: "none", md: "block" }}
                                                    fontSize="14px"
                                                    paddingLeft="10px"
                                                >
                                                    {userProfile?.intro?.cover_image ? "Change " : "Add "}
                                                    Cover Image
                                                </Text>
                                            </Box>
                                        )}
                                        <Style.profileImgBackground>
                                            <Style.profileImg

                                                src={
                                                    userProfile?.intro?.profile_image
                                                        ? userProfile?.intro?.profile_image
                                                        : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${userProfile?.intro?.name}`
                                                }
                                                alt="image"
                                            />
                                            {user?.id == Decrypt(id) && (
                                                <Style.profileImgAdd
                                                    _hover={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setProfileModalShow(true);
                                                    }}
                                                    className="bi bi-camera-fill"
                                                />
                                            )}
                                        </Style.profileImgBackground>
                                    </Style.forumProfileImageBg>
                                    <ProfileModal
                                        isOpen={profileModalShow}
                                        image={
                                            userProfile?.intro?.profile_image &&
                                            userProfile?.intro?.profile_image
                                        }
                                        refresh={() => {
                                            setRefresh(refresh + 1)
                                            getUserDetails()
                                            getFeeds()

                                        }}
                                        onClose={() => setProfileModalShow(false)}
                                    />
                                    <CoverModal
                                        isOpen={coverModalShow}
                                        image={
                                            userProfile?.intro?.cover_image &&
                                            userProfile?.intro?.cover_image
                                        }
                                        refresh={() => { getUserDetails() }}
                                        onClose={() => setCoverModalShow(false)}
                                    />
                                    <Box>
                                        <Style.profileTitle position="relative">
                                            {userProfile?.intro?.name}
                                            <Flex position="absolute" right={0} top={0}>
                                                {user?.id != Decrypt(id) && <>
                                                    {isFollowed ? (
                                                        <UnFollowBtn
                                                            onClick={() => {
                                                                removeFollower(userProfile?.user?.id);
                                                                setIsFollowed(!isFollowed)

                                                            }}
                                                        >
                                                            Unfollow
                                                        </UnFollowBtn>
                                                    ) : (
                                                        <ConnectBtn
                                                            onClick={() => {
                                                                createFollower(userProfile?.user?.id)
                                                                setIsFollowed(!isFollowed)

                                                            }}
                                                        >
                                                            Follow
                                                        </ConnectBtn>
                                                    )}
                                                </>}
                                                <Button
                                                    onClick={handleShare}
                                                    color="#000"
                                                    _hover={{ opacity: 0.7 }}
                                                    fontFamily="Poppins"
                                                    cursor="pointer"
                                                    bgColor="#fff"
                                                    fontWeight="500"
                                                    fontSize="14px"
                                                    marginLeft="10px"

                                                    colorScheme="gray"
                                                >
                                                    <Box className="bi bi-send">
                                                        <Text
                                                            as="span"
                                                            display={{ sm: "none", md: "inline-block" }}
                                                            fontSize="14px"
                                                            paddingLeft="10px"
                                                        >
                                                            Share
                                                        </Text></Box>
                                                </Button>

                                            </Flex>
                                        </Style.profileTitle>
                                        <Style.FamilyTitleWrap>
                                            {userProfile?.intro?.intro && (
                                                <Box
                                                    fontWeight="500"
                                                    paddingX="10px"
                                                    fontSize="16px"
                                                    textAlign="center"
                                                    maxWidth="70%"
                                                >
                                                    <ReadMoreText maxlength={100}>
                                                        {userProfile?.intro?.intro}
                                                    </ReadMoreText>
                                                </Box>
                                            )}
                                        </Style.FamilyTitleWrap>
                                    </Box>
                                </Box>
                                <Flex direction={{ sm: "column", md: "row" }} position="relative">

                                    <Box
                                        width={{ md: "60%", lg: "70%" }}
                                    >
                                        <Flex
                                            borderBottom="2px solid #fff"
                                            paddingBottom="7px"
                                            marginLeft={{
                                                md: "15px",
                                                lg: "20px",
                                                xl: "120px",
                                            }}
                                            position="relative"
                                        >
                                            <Box
                                                onClick={() => {
                                                    setTabIndex(0)
                                                }}
                                            >
                                                <Text
                                                    style={{ color: tabIndex === 0 ? "#D12C41" : "#fff" }}
                                                    cursor="pointer"
                                                    fontFamily="Poppins"
                                                >
                                                    TimeLine
                                                </Text>
                                                <Box
                                                    position="absolute"
                                                    top="41px"
                                                    width="70px"
                                                    style={{
                                                        borderBottom: tabIndex === 0 ? "2px solid #D12C41" : "none",
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                onClick={() => {
                                                    setTabIndex(1)
                                                }}
                                                paddingLeft="30px"
                                            >
                                                <Text
                                                    style={{ color: tabIndex === 1 ? "#D12C41" : "#fff" }}
                                                    paddingBottom="10px"
                                                    cursor="pointer"
                                                    fontFamily="Poppins"
                                                >
                                                    Followers
                                                </Text>
                                                <Box
                                                    position="absolute"
                                                    top="41px"
                                                    width="70px"
                                                    style={{
                                                        borderBottom: tabIndex === 1 ? "2px solid #D12C41" : "none",
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                onClick={() => {
                                                    setTabIndex(2)
                                                }}
                                                paddingLeft="30px"
                                            >
                                                <Text
                                                    style={{ color: tabIndex === 2 ? "#D12C41" : "#fff" }}
                                                    paddingBottom="10px"
                                                    cursor="pointer"
                                                    fontFamily="Poppins"
                                                >
                                                    Following
                                                </Text>
                                                <Box
                                                    position="absolute"
                                                    top="41px"
                                                    width="70px"
                                                    style={{
                                                        borderBottom: tabIndex === 2 ? "2px solid #D12C41" : "none",
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                onClick={() => {
                                                    setTabIndex(3)
                                                }}
                                                paddingLeft="30px"
                                            >
                                                <Text
                                                    style={{ color: tabIndex === 3 ? "#D12C41" : "#fff" }}
                                                    cursor="pointer"
                                                    fontFamily="Poppins"
                                                >
                                                    Gallery
                                                </Text>
                                                <Box
                                                    position="absolute"
                                                    top="41px"
                                                    width="60px"
                                                    style={{
                                                        borderBottom: tabIndex === 3 ? "2px solid #D12C41" : "none",
                                                    }}
                                                />
                                            </Box>
                                        </Flex>

                                        <Box
                                            width="100%"
                                            padding={{
                                                sm: "30px 0 0",
                                                lg: "30px 40px 0 0",
                                                xl: "30px 95px 0",
                                            }}
                                        >
                                            {tabIndex === 0 && (
                                                <PostWrapper data={FeedList} showCreate={user?.id == Decrypt(id)} refresh={getFeeds} onToggle={onToggle} />
                                            )}

                                            {tabIndex === 1 && (
                                                <>
                                                    {followerList?.map((item, index: number) => (
                                                        <PostsBackground
                                                            maxWidth="600px!important"
                                                            padding="5px 40px!important"
                                                            key={index}
                                                        >
                                                            <PeopleYouMayKnow
                                                                data={item}
                                                                isShow={user?.id == Decrypt(id)}
                                                            />
                                                        </PostsBackground>
                                                    ))}
                                                    {followerList?.length <= 0 && (
                                                        <Heading textAlign="center" marginTop="50px">
                                                            No Followers Yet
                                                        </Heading>
                                                    )}
                                                </>
                                            )}
                                            {tabIndex === 2 && (
                                                <>
                                                    {followingList?.map((item, index: number) => (
                                                        <PostsBackground
                                                            maxWidth="600px!important"
                                                            padding="5px 40px!important"
                                                            key={index}
                                                        >
                                                            <PeopleYouMayKnow
                                                                data={item}
                                                                isShow={user?.id == Decrypt(id)}
                                                            />
                                                        </PostsBackground>
                                                    ))}
                                                    {followingList?.length <= 0 && (
                                                        <Heading textAlign="center" marginTop="50px">
                                                            No Followings Yet
                                                        </Heading>
                                                    )}
                                                </>
                                            )}
                                            {tabIndex === 3 && (
                                                <Gallery page="profile" />
                                            )}
                                        </Box>
                                    </Box>
                                    <Style.ForumRightSideWrap
                                        borderTop={{ md: "2px solid #fff" }}
                                        marginTop={{ md: "41px" }}
                                        paddingX={{ sm: "20px", md: "0" }}
                                    >
                                        <Box paddingTop={{ sm: "15px", md: "0" }}>
                                            <YourIntro
                                                isEdit={user?.id == Decrypt(id)}
                                                data={userProfile?.intro}
                                            />
                                        </Box>
                                        {gallery?.length > 0 && (
                                            <>
                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    paddingTop="20px"
                                                >
                                                    <Text
                                                        fontSize="15px"
                                                        fontFamily="Poppins"
                                                        color="#fff"
                                                        fontWeight="700"
                                                    >
                                                        Gallery
                                                    </Text>
                                                    <Style.rightSeeAll
                                                        cursor="pointer"
                                                        onClick={() =>
                                                            setTabIndex(3)
                                                        }
                                                    >
                                                        See all
                                                    </Style.rightSeeAll>
                                                </Flex>
                                                <SimpleGrid
                                                    columns={3}
                                                    spacing={2}
                                                    spacingX="auto"
                                                    paddingTop="20px"
                                                >
                                                    {gallery?.map((item, index) => {
                                                        return (
                                                            index < 6 && (
                                                                <FriendCard

                                                                    key={index}
                                                                    data={item}
                                                                />
                                                            )
                                                        );
                                                    })}
                                                </SimpleGrid>
                                            </>
                                        )}

                                        {(user?.id == Decrypt(id) && friendSuggestion.length > 0) && (
                                            <Box
                                                padding={{
                                                    sm: "20px 0px 50px",
                                                    md: "30px 0px 50px",
                                                }}
                                            >
                                                <Style.rightSeeAllWrap>
                                                    <Style.rightPeople>
                                                        People you may know
                                                    </Style.rightPeople>
                                                    {friendSuggestion?.length > 6 && (
                                                        <Style.rightSeeAll
                                                            cursor="pointer"
                                                            onClick={() => {
                                                                seeAllSuggestionHandler();
                                                            }}
                                                        >
                                                            {seeAllSuggestion ? "See less" : "See all"}
                                                        </Style.rightSeeAll>
                                                    )}
                                                </Style.rightSeeAllWrap>
                                                <Style.scrollBox>
                                                    {friendSuggestion?.map(
                                                        (item, index) =>
                                                            (index < 6 || seeAllSuggestion) && (
                                                                <PeopleYouMayKnow
                                                                    key={index}
                                                                    data={item}
                                                                    isShow={true}
                                                                />
                                                            )
                                                    )}
                                                </Style.scrollBox>
                                            </Box>
                                        )}
                                    </Style.ForumRightSideWrap>
                                </Flex></>)}
                    </Box>
                </Flex>
            </Container>
        </Style.forumProfilebg >
    )
}

export default Profile