import React from 'react';
import ReactWhatsapp from 'react-whatsapp';
import * as Style from './WhatsappIcon.style';
import Text from '../SubHeading/SubHeading';

const WhatsappIcon= () => {
    
    return (
        <>
        <Style.WhatsappIcon>
            <ReactWhatsapp number="+353 (86) 837 9903" message="Hello Perpetua">
                <Style.IconName >
                   <Text title='Connect with us' color='white' display={{sm:'none', md:'block'}} />
                    <Style.Icon src='/images/whatsapp.svg' alt='whatsapp-icon'></Style.Icon>
                </Style.IconName>
            </ReactWhatsapp>
        </Style.WhatsappIcon>
     </>
    );
}

export default WhatsappIcon;
