import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Pricing from "../component/Pricing";


const PricingPage = () => {
  return (
    <MainWrapper>
      <Pricing />
    </MainWrapper>

  );
};

export default PricingPage;
