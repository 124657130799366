import React from "react"
import { Box, useDisclosure } from "@chakra-ui/react";
import ImagePopup from "./ImagePopup";

const ImageCard = (props: any) => {

    const { isOpen, onToggle, onClose } = useDisclosure();

    return (
        <>
            <Box
                width={"100%"}
                height={{ sm: "200px", lg: "300px" }}
                backgroundImage={`url(${props.data.file_path})`}
                backgroundPosition="top"
                backgroundRepeat="no-repeat"
                backgroundSize={"cover"}
                borderRadius={"10px"}
                cursor='pointer'
                onClick={onToggle}
            />
            <ImagePopup isOpen={isOpen} onClose={onClose} data={props.data.file_path} />
        </>
    )
}

export default ImageCard;