import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  UnorderedList,
  ListItem,
  VStack,
  Flex,
  Stack,
  Link,
} from "@chakra-ui/react";
import MetaTitle from "./MetaTitle";
import VideoBanner from "./VideoBanner/VideoBanner";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import Testimonial from "./Testimonial/Testimonial";
import PriceBox from "./Price Component/PriceBox";
import WhatsincludedCard from "./WhatsIncluded Card/WhatsincludedCard";
import ContactForm from "./Form/ContactForm";
import LinkButton from "./Button/LinkButton";
import * as Style from "./Crossfit.style";
import Accordian from "./Accordion/Accordian";
import BlogSection from "./Blog/BlogSection";

const accordionData = [
  {
    id: 1,
    title: "Who is this for?",
    description:
      "Ideally golfers in this program will have previous training experience, though it is not essential .",
  },
  {
    id: 2,
    title: "How long are training sessions? ",
    description: "45mins - 1 hour.",
  },
  {
    id: 3,
    title:
      "What’s the difference between the Group Training and the Personal Training?",
    description:
      "Group training is a rigid 8-week structure with as many as 8 people in the group. Personal training is a bespoke plan tailored to the clients requirements.",
  },
];
const accordionData2 = [
  {
    id: 1,
    title: "Who is this for?",
    description: "All levels.",
  },
  {
    id: 2,
    title: "How long are personal training sessions?",
    description: "1 hour.",
  },
  {
    id: 3,
    title: "How many sessions do I need to do per week? ",
    description:
      "Varies person-to-person. Ideally twice. It can be discussed during consultation and roadmap phase.",
  },
];
const accordionData3 = [
  {
    id: 1,
    title: "Who is it for? ",
    description: "All levels. ",
  },
  {
    id: 2,
    title: "How long do I do my program for? ",
    description:
      "4-6 weeks. Frequency is very important in improving mobility, you should aim for every day or second day. Consider your efforts as compounding interest, rather than just a bolus amount after some time.",
  },
  {
    id: 3,
    title: "How do I retest to see if my handicap has improved? ",
    description:
      "We recommend that you do another assessment and you will receive a new mobility plan .",
  },
  {
    id: 4,
    title: "What is TPI?  ",
    description:
      "TPI (Titleist Performance Institute) is the world's leading educational organization dedicated to the study of how the human body functions in relation to the golf swing. For over twenty years, TPI has gathered the world’s largest technical database of the game’s top Tour professionals as well as every-day golfers. TPI collects 3D motion capture, force, pressure, launch monitor, strength, power, and movement data on every player that visits the TPI campus. Using this data, TPI discovered how a properly functioning body allows a player to swing a golf club in the most efficient way possible. Conversely, TPI determined how physical limitations in a player’s body can adversely affect the golf swing and potentially lead to injury. This relationship is what TPI calls The Body-Swing Connection and is the foundation of all professional Certification courses offered by TPI.  ",
  },
];

const whatWeProvideData = [
  {
    id: 1,
    title: "TPI Testing Day: ",
    description: "Weekend before the start of the program",
    description2: "Book individually, 30 minute slots",
  },
  {
    id: 2,
    title: "3 x 45 minute group sessions per week: ",
    description: "Mon & Wed 8pm, Sat 12:30. ",
    description2: "",
  },
  {
    id: 3,
    title: "Online Programming: ",
    description: "Daily mobility",
    description2: "Workouts in case they can’t make a session.  ",
  },
  {
    id: 4,
    title: "Access to JustGym throughout: ",
    description: "",
    description2: "",
  },
];

const GoalsMetrics = [
  {
    id: 1,
    title: "Faster swing speed  ",
    description: "Swing speed monitor - MPH ",
    description2: "",
  },
  {
    id: 2,
    title: "Improved mobility  ",
    description: "Mobility testing (TPI?) ",
    description2: "",
  },
  {
    id: 3,
    title: "Better Balance  ",
    description: "Single leg balance test ",
    description2: "",
  },
  {
    id: 4,
    title: "Increased strength ",
    description: "Testing TBD ",
    description2: "",
  },
  {
    id: 5,
    title: "Fitness/Conditioning  ",
    description: "Conditioning AMRAP compare week 1to week 8  ",
    description2: "Or FTP Testing? ",
  },
];

function Golf() {
  const metaDescription =
    "Elevate your golf game with specialised golf fitness training in Dublin at Perpetua. Our TPI certified programs are tailored to improve strength, swing speed, and mobility. Start your transformation today. ";
  return (
    <Box backgroundColor="#000">
      <MetaTitle
        title="Golf Fitness Training Dublin | Perpetua Fitness"
        content={metaDescription}
      />
      <VideoBanner
        videobanner="../video/golfVdeoBanner.mp4"
        title="GOLF FITNESS TRAINING"
        subtitle="Helping Golfers Improve Performance"
        subtextpara="Welcome to Perpetua's Golf Fitness Training in Dublin, where your passion for golf meets our expertise in fitness."
        isButton={true}
        issubtextpara={true}
        issubtitleRight={false}
        buttontitle="Book a consult"
        margin="0px 0 0"
        Link="/join-today"
        videoModal="https://youtube.com/embed/d9eNbr-Ll60"
        targetBlank={false}
        modalButtontitle="View The Experience"
      />
      {/*  */}
      <Flex backgroundColor="black" padding="80px 0px 0px">
        <Container maxW="container.xl">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            textAlign={{ base: "left", lg: "left" }}
            alignItems="center"
          >
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                currency="€"
                priceValue="From 450"
                time=""
                description="Arrange a call with our TPI certified coach Chris to discuss next steps"
                buttonText="Enquire Now"
                link="/join-today"
                buttonPading="0px"
                issubHeading={false}
                manheadind="8 Week Group Training"
                width={{ base: "100%", sm: "70%" }}
              ></PriceBox>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <Heading
                title="WHATS INCLUDED "
                variant="small"
                color="white"
                margin="0px 0px 30px"
                padding={{ sm: "0px 0px 0px 0px", md: "0px 0px 0px 31px" }}
                textAlign={{ sm: "center", ms: "left" }}
              ></Heading>
              <SimpleGrid
                columns={{ base: "1", lg: "2" }}
                spacing="0px"
                className="_whatsinclude_box_body"
              >
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="3 Sessions A Week"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Access To JustGYM"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Online Program"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Whatsapp Support"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>
      <Flex backgroundColor="black" padding="80px 0px 0px">
        <Container maxW="container.xl">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            textAlign={{ base: "left", lg: "left" }}
            alignItems="center"
          >
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                currency="€"
                priceValue="From 60"
                time="/Session"
                description="Arrange a call with our TPI certified coach Chris to discuss next steps"
                buttonText="Enquire Now"
                link="/join-today"
                buttonPading="0px"
                issubHeading={false}
                manheadind="Personal Training"
                width={{ base: "100%", sm: "70%" }}
              ></PriceBox>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <Heading
                title="WHATS INCLUDED "
                variant="small"
                color="white"
                margin="0px 0px 30px"
                padding={{ sm: "0px 0px 0px 0px", md: "0px 0px 0px 31px" }}
                textAlign={{ sm: "center", ms: "left" }}
              ></Heading>
              <SimpleGrid
                columns={{ base: "1", lg: "2" }}
                spacing="0px"
                className="_whatsinclude_box_body"
              >
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="1-1 or 2-1 Personal Training"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="60 Min Sessions"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Movement Assessment"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Online Program"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>
      <Flex backgroundColor="black" padding="80px 0px">
        <Container maxW="container.xl">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            textAlign={{ base: "left", lg: "left" }}
            alignItems="center"
          >
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                currency="€"
                priceValue="From 75"
                time="/Session"
                description="Arrange a call with our TPI certified coach Chris to discuss next steps"
                buttonText="Enquire Now"
                link="/join-today"
                buttonPading="0px"
                issubHeading={false}
                manheadind="TPI Assessment & Mobility Program"
                width={{ base: "100%", sm: "70%" }}
              ></PriceBox>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <Heading
                title="WHATS INCLUDED "
                variant="small"
                color="white"
                margin="0px 0px 30px"
                padding={{ sm: "0px 0px 0px 0px", md: "0px 0px 0px 31px" }}
                textAlign={{ sm: "center", ms: "left" }}
              ></Heading>
              <SimpleGrid
                columns={{ base: "1", lg: "2" }}
                spacing="0px"
                className="_whatsinclude_box_body"
              >
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="1-1 Session"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="60 Minutes"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Screening of 16 Movements"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    title="Mobility Program"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    alt="Personal Training Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>
      {/*  */}
      <Box
        padding={{
          sm: "60px 10px 20px",
          md: "80px 20px 20px",
          lg: "110px 0px 50px",
        }}
        backgroundColor="#fff"
      >
        <Container maxW="container.xl">
          <Testimonial
            headingWidth={{ sm: "97%", md: "90%" }}
            iconUri="/images/comment-quote-outline.svg"
            isname={true}
            title="“Since working with Chris, I move a lot better. I get less aches and pains and it’s letting me enjoy golf a lot more. The extra 15 yards from being stronger doesn’t hurt either. Getting assessed on mobility is a game changer and I only thought it was for the pros. I couldn’t recommend Chris enough.”"
            name="- Paul Kelly"
          />
        </Container>
      </Box>
      <Box
        backgroundColor="#fff"
        padding={{ sm: "40px 10px", md: "60px 20px" }}
      >
        <Container maxW={{ md: "container.lg", xl: "container.xl" }}>
          <Heading
            title="Golf x Perpetua Group Training "
            variant="large"
            lineHeight="0.9"
            padding="0 0 20px"
            textAlign="center"
          />
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            padding={{ md: "40px 0 0px" }}
          >
            <Box
              w={{ sm: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px" }}
            >
              <Heading title="Cost" variant="extrasmall" lineHeight="0.9" />
              <Heading
                title="€450 "
                variant="medium"
                lineHeight="0.9"
                padding="5px 0 20px"
                color="Red"
              />
              <Heading
                title="8 Week Training Program "
                variant="large"
                lineHeight="0.9"
                padding={{ md: "0 0 20px" }}
                width={{ md: "80%" }}
              />
            </Box>
            <Box
              w={{ sm: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <Heading title="What we provide: " variant="small"></Heading>
              <SimpleGrid columns={{ base: "1", lg: "2" }} spacing="0px">
                {whatWeProvideData.map((items, index) => {
                  return (
                    <Flex paddingTop="20px" key={index}>
                      <Heading
                        title={items.id}
                        className="strokeNumber"
                        margin={{
                          sm: "-5px 0 0",
                          lg: "-10px 0 0",
                          xl: "-15px 0 0",
                        }}
                      />
                      <Box paddingLeft="10px">
                        <Heading title={items.title} variant="extrasmall" />
                        <UnorderedList>
                          {items.description !== "" && (
                            <ListItem fontFamily="Poppins">
                              {items.description}
                            </ListItem>
                          )}
                          {items.description2 !== "" && (
                            <ListItem fontFamily="Poppins">
                              {items.description2}
                            </ListItem>
                          )}
                        </UnorderedList>
                      </Box>
                    </Flex>
                  );
                })}
              </SimpleGrid>
            </Box>
          </Stack>
          <Box
            backgroundColor="#fff"
            padding={{ sm: "40px 10px 0", md: "40px 20px 0px" }}
          >
            <Flex
              alignItems="center"
              width="100%"
              justifyContent="center"
              paddingBottom="20px"
            >
              <Box
                borderTop={{ md: "1px solid #ccc" }}
                width={{ md: "25%", xl: "30%" }}
              />
              <Heading
                title="Goals & Metrics"
                variant="large"
                lineHeight="0.9"
                padding={{ md: "0 30px" }}
              />
              <Box
                borderTop={{ md: "1px solid #ccc" }}
                width={{ md: "25%", xl: "30%" }}
              />
            </Flex>

            <SimpleGrid columns={{ sm: "1", md: "3" }} spacing="0px">
              {GoalsMetrics.map((items, index) => {
                return (
                  <Flex
                    padding={{ sm: "20px 0 0", md: "20px 30px" }}
                    key={index}
                  >
                    <Heading
                      title={items.id}
                      className="strokeNumber"
                      margin={{
                        sm: "-5px 0 0",
                        lg: "-10px 0 0",
                        xl: "-15px 0 0",
                      }}
                    />
                    <Box paddingLeft="10px">
                      <Heading title={items.title} variant="extrasmall" />
                      <UnorderedList>
                        {items.description !== "" && (
                          <ListItem fontFamily="Poppins">
                            {items.description}
                          </ListItem>
                        )}
                        {items.description2 !== "" && (
                          <ListItem fontFamily="Poppins">
                            {items.description2}
                          </ListItem>
                        )}
                      </UnorderedList>
                    </Box>
                  </Flex>
                );
              })}
            </SimpleGrid>
          </Box>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={{ lg: "30px" }}
            padding={{ sm: "50px 0 0", md: "40px 0 0px" }}
          >
            <Box
              w={{ sm: "100%", lg: "50%" }}
              padding={{ md: "20px 0 0", lg: "20px 0 0" }}
            >
              <Heading
                title="Program Structure: "
                variant="large"
                lineHeight="0.9"
                padding="0 0 20px"
                width={{ md: "80%" }}
              />
            </Box>
            <Box
              w={{ sm: "100%", lg: "50%" }}
              padding={{ sm: "20px 0 0 ", md: "0px 20px", lg: "0px" }}
              marginTop={{ sm: "-30px" }}
            >
              <SimpleGrid columns={{ base: "1", lg: "2" }} spacing="0px">
                <Box
                  borderBottom={{ lg: "1px solid #ccc" }}
                  padding={{ sm: "10px 0", lg: "20px" }}
                >
                  <Heading title="Week 1 & 2: " color="Red" variant="small" />
                  <Heading
                    title="Mobility, Unilateral Strength, Conditioning"
                    variant="smallheading"
                  />
                </Box>
                <Box
                  borderBottom={{ lg: "1px solid #ccc" }}
                  borderLeft={{ lg: "1px solid #ccc" }}
                  padding={{ sm: "10px 0", lg: "20px" }}
                >
                  <Heading
                    title="Week 3 & 4: "
                    color="Red"
                    variant="smallmid"
                  />
                  <Heading
                    title="Mobility, Bilateral Strength, Conditioning"
                    variant="smallheading"
                  />
                </Box>
                <Box padding={{ sm: "10px 0", lg: "20px" }}>
                  <Heading
                    title="Week 5 & 6: "
                    color="Red"
                    variant="smallmid"
                  />
                  <Heading
                    title="Mobility, Power Development, Conditioning"
                    variant="smallheading"
                  />
                </Box>
                <Box
                  borderLeft={{ lg: "1px solid #ccc" }}
                  padding={{ sm: "10px 0", lg: "20px" }}
                >
                  <Heading
                    title="Week 7 & 8: "
                    color="Red"
                    variant="smallmid"
                  />
                  <Heading
                    title="Mobility, Power and Speed Development, Conditioning"
                    variant="smallheading"
                  />
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Box>
      {/*  */}
      <Flex
        flexDirection={{ sm: "column-reverse", md: "row" }}
        padding={{ sm: "0px 0px", md: "80px 0px 0" }}
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        justifyItems="center"
        marginBottom={{ md: "80px" }}
      >
        <Flex
          width={{ sm: "100%", md: "60%" }}
          backgroundColor="LightGrey"
          padding={{ sm: "45px 0", md: "170px 0" }}
          justifyContent="flex-end"
        >
          <Box
            width={{ md: "100%", xl: "630px" }}
            padding={{ sm: "0 15px", xl: "0 120px 0 0" }}
          >
            <Heading
              title="Chris Loh"
              variant="extralarge"
              lineHeight="1"
              margin={{ sm: "0 0 30px", md: "0px 0px 60px" }}
            />
            <Text title="Chris is a head coach and personal trainer at Perpetua Fitness. He is TPI certified, a nutritionist and has a qualification is Sport Psychology. Playing off a low handicap and as a self-confessed golf swing nerd, Chris is aiming to provide a variety of training protocols to elevate your golf game and improve your overall health. " />
          </Box>
        </Flex>
        <Box
          width={{ sm: "100%", md: "40%" }}
          backgroundImage="url(./images/chrisLoh.jpg)"
          height={{ sm: "450px", md: "500px", lg: "558px" }}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
        ></Box>
      </Flex>
      {/*  */}
      <Box
        padding={{ sm: "0px 0px 30px", md: "80px 0px" }}
        background={"#eeeef1"}
      >
        <Container maxW="container.xl">
          <Flex
            margin="auto"
            textAlign={"center"}
            width={{ sm: "100%", md: "50%" }}
            flexDirection="column"
            paddingBottom="30px"
          >
            <Heading
              lineHeight={"1"}
              variant="large"
              title="FREQUENTLY ASKED QUESTIONS"
              marginBottom="30px"
            />
            <Box mb="40px">
              <Text title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie" />
            </Box>
          </Flex>

          <Flex flexDirection={{ sm: "column", md: "row" }}>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="Group Training Program"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accordionData} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="Personal Training"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accordionData2} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="TPI & Mobility Assessment "
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accordionData3} />
            </Box>
          </Flex>
        </Container>
      </Box>
      {/*Video section*/}
      <Box
        backgroundColor="black"
        padding={{ sm: "60px 0px 0", md: "80px 0px 0" }}
        borderTop="1px solid #232323"
      >
        <Container maxW="container.xl">
          <Style.Wrapper>
            <Style.LeftBox textAlign={{ sm: "center", md: "left" }}>
              <Heading
                variant="medium"
                title="Get started with Perpetua Golf Fitness Training"
                lineHeight="1"
                color="white"
              />
            </Style.LeftBox>
            <Style.RightBox>
              <Style.VideoWrapper>
                <Box
                  as="iframe"
                  src="https://www.youtube.com/embed/d9eNbr-Ll60"
                  width={{ sm: "100%", md: "100%" }}
                  height="550px"
                />
                <Style.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title="Perpetua Golf Personal Training"
                    lineHeight="1"
                    color="white"
                    marginBottom="5px"
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
            </Style.RightBox>
          </Style.Wrapper>
        </Container>
      </Box>
      <VStack
        backgroundColor="black"
        padding={{ sm: "40px 0 60px", md: "96px 0px" }}
        borderBottom="1px solid #232323"
      >
        <Container
          display="flex"
          maxW="container.xl"
          flexDirection={{ base: "column", lg: "row" }}
          alignItems="top"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
        >
          <Box
            w={{ base: "100%", lg: "50%" }}
            textAlign={{ base: "center", lg: "left" }}
            marginBottom={{ base: "50px", lg: "0" }}
          >
            <Heading
              title="LOOKING FOR MORE INFORMATION"
              variant="extralarge"
              lineHeight="1"
              margin="0px 0px 40px"
              color="white"
            ></Heading>
            <Heading
              title="Chat with a member of the team"
              variant="smallmid"
              lineHeight="1"
              margin="0px 0px 30px"
              color="white"
            ></Heading>
            <Link
              href="/contact-us"
              textDecoration="none"
              display="inline-block"
              _hover={{ textDecoration: "none" }}
            >
              <LinkButton
                title="BOOK A CALL"
                Imguri="/images/red_arrow.svg"
                border="2px solid"
                borderColor="Red"
                padding="20px 25px"
                color="White"
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ base: "100%", lg: "50%" }}>
            <ContactForm pageName="PersonalTraining"></ContactForm>
          </Box>
        </Container>
      </VStack>
      <Box backgroundColor="#fff">
        <BlogSection catSlug="personal-training" />
      </Box>
    </Box>
  );
}

export default Golf;
