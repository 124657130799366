import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Studios from "../component/Studios";


const StudiosPage = () => {
  return (
    <MainWrapper>
      <Studios />
    </MainWrapper>

  );
};

export default StudiosPage;
