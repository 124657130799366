import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import * as Style from "./Posts.style";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../services/Auth";
import ReadMoreText from "./ReadMoreText";
import moment from "moment";
import type { singleCommentType } from "../../type/Feed";
import DeleteModal from "./DeleteModal";


const SingleComment = ({
  item,
  index,
  commentList,
  setCommentList,
  isChild,
  children,
  getCommentList,
  commentCount, setCommentCount
}: singleCommentType) => {
  const navigate = useNavigate();
  const { api, userId, userImage, user, Encrypt } = AuthUser();
  const [comment, setComment] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [reply, setReply] = useState("");
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [Edit, setEdit] = useState(false);
  const CommentReply = (id: number) => {
    api
      .post("/comments/reply", { comment_id: id, comment: reply })
      .then((res: any) => {
        if (res?.data?.success) {
          setShowReplyBox(false);
          getCommentList();
          setReply("");
          setCommentCount(commentCount + 1)
        }

        console.log(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const EditComment = (id: number) => {
    api
      .post(`/comments/update/${id}`, { comment: comment })
      .then((res: any) => {
        if (res?.data?.success) {
          commentList[index].comment = comment;
          setEdit(false);
        }
        console.log(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const deleteComment = () => {
    api
      .post(`/comments/delete/${item?.id}`)
      .then((res: any) => {
        if (res?.data?.success) {
          const finalComment = commentList.filter((items) => items.id !== item?.id);
          setCommentList(finalComment);
        }
        setCommentCount(commentCount - 1)
        setShowDelete(false)
        console.log(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setComment(item?.comment);
  }, [item]);
  return (
    <>
      <Image
        width={isChild ? "28px" : "35px"}
        objectFit="cover"
        height={isChild ? "28px" : "35px"}
        borderRadius="18px"
        alt="image"
        src={
          item?.user?.profile_image
            ? item?.user?.profile_image
            : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${item?.user?.name}`
        }
        _hover={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/forum/profile/" + Encrypt(item?.user?.id));
        }}
      />
      <Box maxW={{ base: "80%", lg: "75%", xl: "80%" }} margin="0px 10px">
        <Flex
          onMouseOver={() => setShowEdit(true)}
          onMouseOut={() => setShowEdit(false)}
          width="100%"
        >
          <Box
            margin="0 10px 3px 0"
            padding="8px 15px"
            backgroundColor="#383838"
            borderRadius="20px"
            maxW="100%"
          >
            {Edit ? (
              <Flex>
                <Style.AddComment
                  width="300px!important"
                  Style={{
                    backgroundColor: "#444!important",
                  }}
                  onChange={(e: { target: { value: string } }) => setComment(e.target.value)}
                  value={comment}
                  placeholder="Add Comment..."
                />
                <Flex
                  fontSize="14px"
                  color="#fff"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{ cursor: "pointer" }}
                  className="bi bi-x-lg"
                  marginRight="10px"
                  onClick={() => {
                    setEdit(false);
                    setComment(item?.comment);
                  }}
                />
                <Flex
                  fontSize="14px"
                  color="#fff"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{ cursor: "pointer" }}
                  className="bi bi-check-lg"
                  onClick={() => {
                    EditComment(item?.id);
                  }}
                />
              </Flex>
            ) : (
              <>
                <Style.PostsTitle
                  _hover={{
                    cursor: "pointer",
                    textDecor: "underline",
                  }}
                  onClick={() => {
                    navigate("/forum/profile/" + Encrypt(item?.user?.id));
                  }}
                >
                  {item?.user?.name}
                </Style.PostsTitle>
                <Style.Postsactive id={index}>
                  {/* {isChild && (
                    <Text
                      color="#ccc"
                      display="inline-block"
                      marginRight="5px"
                      _hover={{
                        cursor: "pointer",
                        textDecor: "underline",
                      }}
                      onClick={() => {
                        navigate("/forum/profile/" + Encrypt(item?.user?.id));
                      }}
                    >
                      {parentName}
                    </Text>
                  )} */}
                  <ReadMoreText maxlength={60}>{item?.comment}</ReadMoreText>
                </Style.Postsactive>
              </>
            )}
          </Box>
          {!Edit && (
            <>
              {userId == item?.user?.id && (
                <Menu>
                  {showEdit ? (
                    <MenuButton>
                      <Flex
                        fontSize="14px"
                        color="#fff"
                        alignItems="center"
                        justifyContent="center"
                        _hover={{ cursor: "pointer" }}
                        className="bi bi-three-dots"
                      />
                    </MenuButton>
                  ) : (
                    <Box width="14px" />
                  )}
                  <MenuList
                    color="#fff"
                    backgroundColor="#282828"
                    borderColor="#777"
                    zIndex="999"
                  >
                    <MenuItem
                      cursor="pointer"
                      fontFamily="poppins"
                      _focus={{ color: "#fff", backgroundColor: "#444" }}
                      _hover={{ color: "#fff", backgroundColor: "#444" }}
                      alignItems="center"
                      fontSize="14px"
                      onClick={() => setEdit(true)}
                    >
                      Edit
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      cursor="pointer"
                      fontFamily="poppins"
                      _focus={{ color: "#fff", backgroundColor: "#444" }}
                      _hover={{ color: "#fff", backgroundColor: "#444" }}
                      alignItems="center"
                      fontSize="14px"
                      onClick={() => setShowDelete(true)}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </>
          )}
        </Flex>
        <Flex fontSize="12px" fontFamily="Poppins" color="#B9B9B9">
          <Text marginLeft="5px">
            {moment(new Date(item?.created_at)).fromNow()}{" "}
          </Text>
          {!isChild && (
            <Text
              _hover={{
                cursor: "pointer",
                textDecor: "underline",
              }}
              marginLeft="15px"
              onClick={() => setShowReplyBox(true)}
            >
              Reply
            </Text>
          )}
        </Flex>
        {showReplyBox && (
          <Flex alignItems="center" marginTop="5px">
            <Image
              width="28px"
              objectFit="cover"
              height="28px"
              borderRadius="18px"
              alt="image"
              src={
                userImage
                  ? userImage
                  : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${user?.name}`
              }
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/forum/profile/" + Encrypt(userId));
              }}
            />
            <Flex
              margin="0 10px 3px"
              padding="8px 15px"
              backgroundColor="#383838"
              borderRadius="20px"
              maxW="100%"
            >
              {/* <Text
                color="#fff"
                backgroundColor="Red"
                marginY="8px"
                fontSize="16px"
              >
                {item?.user?.name}
              </Text> */}
              <Style.AddComment
                width="300px!important"
                Style={{
                  backgroundColor: "#444!important",
                }}
                onChange={(e: { target: { value: string } }) => setReply(e.target.value)}
                value={reply}
                placeholder="Add reply..."
              />
              <Flex
                fontSize="14px"
                color="#fff"
                alignItems="center"
                justifyContent="center"
                _hover={{ cursor: "pointer" }}
                className="bi bi-x-lg"
                marginRight="10px"
                onClick={() => {
                  setShowReplyBox(false);
                }}
              />
              <Flex
                fontSize="14px"
                color="#fff"
                alignItems="center"
                justifyContent="center"
                _hover={{ cursor: "pointer" }}
                className="bi bi-check-lg"
                onClick={() => {
                  CommentReply(item?.id);
                }}
              />
            </Flex>
          </Flex>
        )}
        {children}
      </Box>
      <DeleteModal
        title={`Are you sure want to delete this ${isChild ? "reply" : "comment"}?`}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        deleteFunction={deleteComment}
      />
    </>
  );
};

export default SingleComment;
