import React, { useEffect } from "react";
import { SimpleGrid, Flex, Spinner, Text } from "@chakra-ui/react";
import GalleryService from "../../../services/GalleryService";
import ImageCard from "./ImageCard";
import { useParams } from "react-router-dom";
import AuthUser from "../../../services/Auth";
const Gallery = ({ page }: { page?: string }) => {
  const { data, loader, error, fetchData } = GalleryService();
  const { Decrypt, } = AuthUser();
  const { id } = useParams();
  useEffect(() => {
    console.log(Decrypt(id));

    fetchData(Decrypt(id))
  }, [id])

  return (
    <>
      {loader ? <Flex justifyContent='center' align="center" width="100%" bg={"#000"} minH='60vh'>
        <Spinner
          thickness='2px'
          speed='0.65s'
          emptyColor='#333'
          color='#ccc'
          size='lg'
        />
      </Flex> : <> {
        data.length > 0 ? <SimpleGrid columns={{ sm: 1, md: 2, lg: page == "profile" ? 2 : 3 }} spacing={5}>
          {data.map((item: any, index: number) => {
            return <ImageCard data={item} key={index} />
          })
          }
        </SimpleGrid> : <Text fontFamily='Poppins' color='#999' textAlign="center" marginTop="50px" fontSize='16px' >No image Found.</Text>
      }
      </>
      }
    </>

  )
}


export default Gallery;
