import React from "react";
import { Box, Container, Stack, HStack, Image } from "@chakra-ui/react";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import { Link } from "react-router-dom";
import Button from "./Button/CustomeButton";
import OwlCarouselComponent from "./owl_carousel/OwlCarousel";
import MetaTitle from "./MetaTitle";
import LocationCard from "./LocationCard/LocationCard";

function Location(props) {
  return (
    <>
      <MetaTitle title="Location | Perpetua Fitness" />
      <Container
        maxW={{ md: "container.md", xl: "container.xl" }}
        p={{ sm: "40px 15px", md: "150px 0px" }}
      >
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing="24px"
          className="LocationContent"
        >
          <Box width={{ sm: "100%", md: "50%" }}>
            <Heading
              title="2 Locations."
              as="small"
              variant="extralarge"
              lineHeight="1"
            ></Heading>
            <Heading
              title="Unlimited Variety."
              as="h1"
              variant="extralarge"
              margin="0px 0px 15px"
              lineHeight="1"
              width={{ sm: "100%", md: "45%" }}
            ></Heading>
            <Heading
              title="TRY 3 STUDIO CLASSES FOR €33"
              as="h6"
              variant="extrasmall"
              margin="0px 0px 15px"
              lineHeight="0.7"
            ></Heading>
          </Box>
          <Box width={{ sm: "100%", md: "50%" }}>
            <Text
              title="Enjoy the variety Perpetua Fitness has to offer with two locations. Perpetua Windmill offers CrossFit, JustGym, Hyrox, Studios and Personal Training. Perpetua Lennox offers Studios and Personal Training."
              lineHeight="1.5"
              margin="0px"
            ></Text>
            <Heading
              title="QUESTIONS"
              as="h3"
              variant="small"
              margin="40px 0px 15px"
              lineHeight="0.7"
            ></Heading>

            <Link to="/contact-us">
              <Button title="Contact" color="black" />
            </Link>
          </Box>
        </Stack>
      </Container>

      {/* <Box background={'black'}>
            <Container maxW="container.xl" p={{sm:'40px 20px', md:'150px 20px'}}>

                <Stack direction={{sm:'column', md:'row'}} className='LocationContent' justifyContent="space-between" >

                    <Box  width={{sm:"100%", md: "100%", lg:"30%"}} margin="0 0 30px">
                        <Heading title='PERPETUA' as='h2' variant='extralarge' lineHeight='1' color='white'></Heading>
                        <Heading title='2 Locations.' as='h2' variant='extralarge' margin='0px 0px 15px' lineHeight='1'color='white'></Heading>
                    </Box>

                    <Stack direction={{sm:'column', md:'row'}} width={{sm:"100%", md: "100%", lg:"70%"}} justifyContent="space-around">

                        <Box width={{sm:"100%", md: "50%"}} paddingBottom={{sm:'25px', md:"0"}} >
                            <Heading title='PERPETUA WINDMILL' as='h3' variant='medium' margin='0px 0px 30px' lineHeight='0.7' color='white'></Heading>
                            <Stack paddingTop='15px' gridRowGap={'10px'}>
                                <HStack spacing='10px' alignItems='center'>
                                    <Box as='span'paddingTop='5px'>
                                        <Image src='../images/map-white.svg'></Image>
                                    </Box>
                                    <Text as='span' 
                                    title='2 Windmill Lane, Dublin2' 
                                    width={{base:'100%'}} 
                                    fontFamily="'BlenderProBold', sans-serif"
                                    textTransform='inherit'
                                    fontSize='xs2'
                                    lineHeight='1.2'
                                    color='white'
                                    > </Text>
                                </HStack>
                                <HStack spacing='10px' alignItems='center'>
                                    <Box as='span'paddingTop='5px'>
                                        <Image src='../images/phone-white.svg'></Image>
                                    </Box>
                                    <Text as='span' 
                                    title='087 1234567' 
                                    width={{base:'100%'}} 
                                    fontFamily="'BlenderProBold', sans-serif"
                                    textTransform='inherit'
                                    fontSize='xs2'
                                    lineHeight='1.2'
                                    color='white'
                                    > </Text>
                                </HStack>

                                    <Link to='/contact-us' >
                                        <Button title='Contact' color='white' />
                                    </Link>

                            </Stack>
                        </Box>
                        <Box width={{sm:"100%", md: "50%"}} paddingTop={{sm:'25px', md:"0"}} >
                            <Heading title='PERPETUA LENNOX' as='h3' variant='medium' margin='0px 0px 30px' lineHeight='0.7' color='white'></Heading>
                            <Stack paddingTop='15px' gridRowGap={'10px'}>
                                <HStack spacing='10px' alignItems='center'>
                                    <Box as='span'paddingTop='5px'>
                                        <Image src='../images/map-white.svg'></Image>
                                    </Box>
                                    <Text as='span' 
                                    title='Lennox Street, Dublin 8' 
                                    width={{base:'100%'}} 
                                    fontFamily="'BlenderProBold', sans-serif"
                                    textTransform='inherit'
                                    fontSize='xs2'
                                    lineHeight='1.2'
                                    color='white'
                                    > </Text>
                                </HStack>
                                <HStack spacing='10px' alignItems='center'>
                                    <Box as='span'paddingTop='5px'>
                                        <Image src='../images/phone-white.svg'></Image>
                                    </Box>
                                    <Text as='span' 
                                    title='088 1234567' 
                                    width={{base:'100%'}} 
                                    fontFamily="'BlenderProBold', sans-serif"
                                    textTransform='inherit'
                                    fontSize='xs2'
                                    lineHeight='1.2'
                                    color='white'
                                    > </Text>
                                </HStack>
                                
                                    <Link to='/contact-us' >
                                        <Button title='Contact' color='white' />
                                    </Link>

                            </Stack>
                        </Box>
                    </Stack>
                        
                        
        
                    
                </Stack>
            </Container>
            
        </Box> */}

      <Box background={"black"}>
        <Container
          maxW="container.xl"
          p={{ sm: "40px 20px", md: "70px 20px", lg: "150px 20px" }}
        >
          <Stack
            direction={{ sm: "column", md: "column", lg: "row" }}
            className="LocationContent"
            justifyContent="space-between"
          >
            <Box
              width={{ sm: "100%", md: "100%", lg: "30%" }}
              margin="0 0 30px"
            >
              <Heading
                title="PERPETUA"
                as="h2"
                variant="extralarge"
                lineHeight="1"
                color="white"
              ></Heading>
              <Heading
                title="2 Locations."
                as="h2"
                variant="extralarge"
                margin="0px 0px 15px"
                lineHeight="1"
                color="white"
              ></Heading>
            </Box>

            <Stack
              direction={{ sm: "column", md: "column", lg: "row" }}
              width={{ sm: "100%", md: "100%", lg: "70%" }}
              justifyContent="space-around"
            >
              <Box
                width={{ sm: "100%", md: "50%" }}
                paddingBottom={{
                  sm: "25px",
                  md: "0",
                }} /* borderRight='1px solid' borderBottomColor={'#D9D9D9'} */
              >
                <LocationCard
                  title="PERPETUA WINDMILL"
                  location="2 Windmill Lane, Dublin2"
                  phone="087 1234567"
                  videolink="https://www.youtube.com/embed/2ukYI8KIwUk"
                />
              </Box>
              <Box
                width={{ sm: "100%", md: "50%" }}
                paddingTop={{ sm: "25px", md: "0" }}
              >
                <LocationCard
                  title="PERPETUA LENNOX"
                  location="Lennox Street, Dublin 8"
                  phone="088 1234567"
                  videolink="https://www.youtube.com/embed/wuxj2I_lYc0"
                />
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <OwlCarouselComponent />
    </>
  );
}

export default Location;
