import * as React from 'react'
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';
import Scrolltotop from './ScrollToTop';
import WhatsappIcon from './component/WhatsappIcon/WhatsappIcon';
import CookieConsent from "react-cookie-consent";
import {Router} from './component/Router'
class App extends React.Component {
  render() {

    return (
      <>
        
        <Scrolltotop />
        
        <Router></Router>

        <WhatsappIcon/>
   


        <CookieConsent
          location="bottom"
          buttonText="Allow cookies"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#e6374b", fontSize: "16px", fontFamily: " Poppins", alignItems: "center", justifyContent: "center" }}
          buttonStyle={{ background: "#000", color: "#fff", fontSize: "14px", padding: "15px 25px" }}
          expires={150}
        >
          Your experience on this site will be improved by allowing cookies.
        </CookieConsent>
      </>
    );
  }
}

export default App;


