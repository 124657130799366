import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import InBody from "../component/InBody";


const InBodyPage = () => {
  return (
    <MainWrapper>
      <InBody />
    </MainWrapper>

  );
};

export default InBodyPage;
