import React,{useState, useEffect} from 'react';
import {
    Box, 
    Container,
    Tabs,TabPanels,TabPanel,
    SimpleGrid,Button,
    TabList, Tab,Link
} from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import './price.css'
import PriceCard from './PricingCard';
import StudioPricingWraper from './StudioPricingWaper';
import { FaAngleDown } from "react-icons/fa";
import Text from '../SubHeading/SubHeading';


function PricingWraper(props) {

    const tabMenu = ["STUDIOS", "CROSSFIT", "JUST GYM", "PERSONAL TRAINING", "HYROX", "PRE/POST NATAL", "NUTRITION", "ONLINE PROGRAMMING","GIFT CARD","LADIES" ];

    const [tabIndex,setTabIndex] = useState(0);
    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const [priceTab, setPriceTab] = useState(isMobile ? false : true);

    const [showTabName, setShowTabName] = useState('All Classes');   
    
    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
        isMobile ? setPriceTab(false) : setPriceTab(true);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    const handelTabItem = () => {
        setPriceTab(!priceTab);       
    }
    
    const ShowTabClass = (items) => {
        setShowTabName(items);   

    } 

    return (
        <Box p={{sm:'50px 0px', md:'145px 0px'}}>
            <Container className='' maxW='container.xl' >
                <Tabs className='pricing_tab' onChange={(index) => setTabIndex(index)} variant='unstyled' pr={{sm:"10px"}}>
                    <Box className='-small-view-body'>
                        {isMobile && <Button onClick={handelTabItem} display='flex' alignItems='center' justifyContent='space-between' className='-small-dropdown-button'>
                                <Text as='span' title={showTabName}/>
                                <FaAngleDown/>
                            </Button> }
                        {priceTab ? <TabList className="ptList ptListALL" m={{sm:'0 0 30px', md:'0 0 70px'}} >
                            {tabMenu.map((item, index) => {                               
                                return (
                                    <Tab key={index} p="0" justifyContent={'flex-start'} onClick={() => ShowTabClass(item)} className={`tabBtn ${index === tabIndex ? "activeTab" : ""}`}>
                                        {item}
                                </Tab>
                        )})}
                        </TabList> : null}
                    </Box>

                    <TabPanels>
                        <TabPanel>
                            <StudioPricingWraper />
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid columns={{sm: "1", md:"2"}} spacing={10}>
                                {/* <PriceCard key="9" cardTitle="MEMBERSHIP" currency="€199" offer="Starting at" planname="UNLIMITED MEMBERSHIP" description="Unlimited access to CrossFit Classes and JustGym Area" buttonTitle='Enquire Now' link='/contact-us' /> */}
                                <PriceCard key="7" cardTitle="MEMBERSHIP" currency="€250" offer="Starting at" planname="Rolling Membership" description="Unlimited access to CrossFit & Speciality Classes, workshops, community events and full access to JustGym Area - 60 day notice period to stop the membership" buttonTitle='Enquire Now' link='/join-today' />
                                <PriceCard key="8" cardTitle="MEMBERSHIP" currency="€215" offer="Starting at" planname="12 Month Membership" description="Unlimited access to CrossFit & Speciality Classes, workshops, community events and full access to JustGym Area - 12 Month Contract - After 12 month contract is finished, 30 day's notice to end contract" buttonTitle='Enquire Now' link='/join-today' />
                                <PriceCard key="9" cardTitle="MEMBERSHIP" currency="€230" offer="Starting at" planname="6 Month Membership" description="Unlimited access to CrossFit & Speciality Classes, workshops, community events and full access to JustGym Area - 6 Month Contract - After 6 month contract is finished, 30 day's notice to end contract" buttonTitle='Enquire Now' link='/join-today' />
                                <PriceCard key="10" cardTitle="NEW MEMBER" currency="€215" offer="Starting at" planname="CROSSFIT ACADEMY" description="Our 4 Week CrossFit Academy course is the best introduction for new beginners to learn everything for classes" buttonTitle='Enquire Now' link='/join-today'/>
                                <PriceCard key="11" cardTitle="CLASS DROP IN" currency="€25" offer="Starting at" planname="1 DROP" description="Must have a minimum 6 months experience. Please enquire below to purchase drop in class"  buttonTitle='Enquire Now' link='/join-today'/>
                                <PriceCard key="12" cardTitle="PRIVATE TRAINING" currency="€70" offer="Starting at" planname="PERSONAL TRAINING" description="Want to work with a coach on some weaknesses or develop new skills, enquire below for a one hour session" buttonTitle='Enquire Now' link='/join-today' />
                            </SimpleGrid>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid columns={{sm: "1", md:"2"}} spacing={10}>
                                <PriceCard key="13" offer="" cardTitle="MEMBERSHIP" currency="€99" planname="JustGYM - 3 Months" description="Available at our Windmill location only. Our JustGym membership offers you
                                    unlimited access to our JustGym space.This JustGYM Membership is an initial 3 month contract from the start date; after that, you'll enjoy a rolling monthly membership where 30 days notice is required to cancel your membership. We also provide shampoo, conditioner, body wash, hair dryers &
                                    straighteners. You can avail of additional services in our studio such as online
                                    programming, personal training, studio classes and nutrition coaching. Please note,
                                    this membership is only valid in our Windmill Lane Location. You can arrange a free
                                    induction to the JustGYM area by contacting justgym@perpetua.ie" 
                                 buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=172' targetBlank='blank'/>
                                 
                                 <PriceCard key="14" offer="" cardTitle="MEMBERSHIP" currency="€89" planname="JustGYM - 6 Months" 
                                 description="Available at our Windmill location only. Our JustGym membership
                                    offers you unlimited access to our JustGym space. This JustGYM Membership is an initial 6 month contract from the start date; after that, you'll enjoy a rolling monthly membership where 30 days notice is required to cancel your membership.
                                    We also provide shampoo, conditioner, body wash, hair dryers &
                                    straighteners. You can avail of additional services in our studio such as online
                                    programming, personal training, studio classes and nutrition coaching. Please note,
                                    this membership is only valid in our Windmill Lane Location. You can arrange a free
                                    induction to the JustGYM area by contacting justgym@perpetua.ie" 
                                 buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=171' targetBlank='blank'/>
                                 
                                <PriceCard key="25" offer="" cardTitle="MEMBERSHIP" currency="€119" planname="JustGYM - Rolling" description="Available at our Windmill location only. Our JustGym membership
                                offers you unlimited access to our JustGym space. This membership agreement is
                                rolling and can be cancelled anytime. We have showers in both men’s and women’s
                                changing rooms. We also provide shampoo, conditioner, body wash, hair dryers &
                                straighteners. You can avail of additional services in our studio such as online
                                programming, personal training, studio classes and nutrition coaching. Please note,
                                this membership is only valid in our Windmill Lane Location. You can arrange a free
                                induction to the JustGYM area by contacting justgym@perpetua.ie"  
                                buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=170' targetBlank='blank'/>

                                <PriceCard key="26" offer="" cardTitle="MEMBERSHIP" currency="€20" planname="Drop in" description="This day pass which gives you full access to our JustGYM area. This space is only available
                                our Windmill Lane location. This pass does not give you access to any studio, Hyrox or CrossFit
                                classes."  
                                buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=105435' targetBlank='blank'/>

                                <PriceCard key="27" offer="" cardTitle="MEMBERSHIP" currency="€212" planname="JustGym + 8 Studio Credits – 3 Months" description="This membership offers all the benefits of the JustGym membership + 8 Studio
                                Credits a month to take your training to a whole new level! Studio credits can be
                                used at both locations - Windmill Lane & Lennox Street. Our JustGym space is
                                available at our Windmill location only and offers you unlimited access.
                                We have showers in both men’s and women’s changing rooms. We also provide
                                shampoo, conditioner, body wash, hair dryers & straighteners. You can avail of
                                additional services in our studio such as online programming, personal training,
                                studio classes and nutrition coaching. You can arrange a free induction to the
                                JustGYM area by contacting justgym@perpetua.ie"  
                                buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=174' targetBlank='blank'/>

                                <PriceCard key="27" offer="" cardTitle="MEMBERSHIP" currency="€199" planname="JustGym + 8 Studio Credits – 6 Months" description="This membership offers all the benefits of the JustGym membership + 8 Studio
                                Credits a month to take your training to a whole new level! Studio credits can be
                                used at both locations - Windmill Lane & Lennox Street. Our JustGym space is
                                available at our Windmill location only and offers you unlimited access.
                                We have showers in both men’s and women’s changing rooms. We also provide
                                shampoo, conditioner, body wash, hair dryers & straighteners. You can avail of
                                additional services in our studio such as online programming, personal training,
                                studio classes and nutrition coaching. You can arrange a free induction to the
                                JustGYM area by contacting justgym@perpetua.ie"  
                                buttonTitle='Buy Now' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=173' targetBlank='blank'/>

                            </SimpleGrid>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid columns={{sm: "1", md:"2"}} spacing={10}>
                                {/* <PriceCard key="15" offer="" cardTitle="" currency="€700" planname="10 SESSIONS" description="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered. "   buttonTitle='Enquire Now' link='/contact-us'/>
                                <PriceCard key="16" offer="" cardTitle="" currency="€975" planname="15 SESSIONS" description="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered."   buttonTitle='Enquire Now' link='/contact-us'/>
                                <PriceCard key="17" offer="" cardTitle="" currency="€1200" planname="20 SESSIONS" description="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered."  buttonTitle='Enquire Now' link='/contact-us'/> */}
                                <PriceCard key="15" offer="" cardTitle="" currency="Package1" planname="10 SESSIONS" description="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered. "   buttonTitle='Enquire Now' link='/join-today'/>
                                <PriceCard key="17" offer="" cardTitle="" currency="Package3" planname="20 SESSIONS" description="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered."  buttonTitle='Enquire Now' link='/join-today'/>
                                <PriceCard key="17" offer="" cardTitle="" currency="Monthly Package" planname="Small Group Personal Training" description="Get the best out of small group personal training where you will enjoy the group
atmosphere matched with the individual attention."  buttonTitle='Enquire Now' link='/join-today'/>
                            </SimpleGrid>
                        </TabPanel>   
                        <TabPanel>
                            <SimpleGrid columns={{sm: "1", md:"2"}} spacing={10}>
                                {/* <PriceCard key="18" offer="" cardTitle="" currency="€179" planname="MEMBERSHIP" description="Unlimited access to Hyrox Classes which take place 3 times a week and access to our Online Hyrox Program" buttonTitle='Enquire Now' link='/contact-us'/>
                                <PriceCard key="19" offer="" cardTitle="" currency="€23" planname="DROP IN" description="Join an Hyrox class and get the full experience how on to train for a Hyrox event" buttonTitle='Enquire Now' link='/contact-us' /> */}
                                <PriceCard key="18" offer="" cardTitle="" currency="€179" planname="MEMBERSHIP" description="• 6 HYROX classes a week
                                • JustGYM Access
                                • Access to Hyrox Program via TrainHeroic" buttonTitle='Buy Now' targetBlank="_blank" link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=158'/>

                                <PriceCard key="19" offer="" cardTitle="" currency="€23" planname="Studio Credit/Drop In" description="Join an Hyrox class and get the full experience how on to train for a Hyrox event" buttonTitle='BOOK NOW' link='/timetable' />
                            </SimpleGrid>
                        </TabPanel>  
                        <TabPanel>
                            <SimpleGrid columns={{sm: "1", md:"2"}} spacing={10}>
                                <PriceCard key="20" offer="" cardTitle="" currency="€300" planname="Post Natal 6 week Course" description="This 6 week course includes 2 sessions a week and is perfect for postpartum moms who want to ease back into exercise AND socialise with other mummys!" buttonTitle='Enquire Now' link='/contact-us'/>
                                <PriceCard key="21" offer="starting at" cardTitle="" currency="€70" planname="PT session" description="Perfect for pre or postnatal moms who want one-to-one in person coaching and super-personalised programming" buttonTitle='Enquire Now' link='/contact-us'/>
                                <PriceCard key="25" offer="" cardTitle="" currency="€150" planname="Pre Natal 6 week Course" description="This 6 week course includes 1 sessions a week and is perfect for soon to be moms who want to continue to exercise AND socialise with other mummys!" buttonTitle='Enquire Now' link='/contact-us'/>
                            </SimpleGrid>
                        </TabPanel> 
                        <TabPanel>
                            <SimpleGrid columns={{sm: "1", md:"2"}} spacing={10}>
                                <PriceCard key="22" offer="" cardTitle="" currency="€99" planname="8 week program" description="" buttonTitle='Buy Now' targetBlank="_blank" link='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107856'/>
                                <PriceCard key="23" offer="" cardTitle="" currency="€30" planname="Inbody Check" description="Receive a full In-Body check with our team along with detailed results. " buttonTitle='Enquire Now' link='/contact-us'/>
                            </SimpleGrid>
                        </TabPanel> 
                        <TabPanel>
                            <SimpleGrid columns={{sm: "1", md:"2"}} spacing={10}>
                                <PriceCard key="24" offer="" cardTitle="" currency="€30" planname="1 month" description="We have 4 online programs which you can follow. Each program has a 7 day free trial
period. Check out our page for more info on each program" buttonTitle='Find Out' link='https://perpetuatraining.com/'/>
                            </SimpleGrid>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid columns={{sm: "1", md:"1"}} spacing={10}>
                            
                                <Box margin="0px auto" width={{sm:'100%',md:'70%'}} background='#F4F4F3' border='2px solid #d12c41' padding={{sm:'30px', md:'50px' }} textAlign='center' position='relative'>
                                    <Text fontSize={{sm:"14px", md:"16px"}} as='p' title='Give the Gift of Fitness' margin="0 0 5px" className='tag'></Text>
                                    
                                    <Heading title='Gift Card' lineHeight='0.9'  variant='smallmid' margin='0px 0px 10px'/>
                                    <Text fontSize={{sm:"14px", md:"16px"}} color="#7D7D7D" as='p' title='Simply load a gift card with a custom amount and print it out or email it directly to the recipient. They can then redeem the credit with a product or service of their choice. Alternatively, you can purchase at Front of House.' colors='Grey' width={props.width} margin="0 0 5px"></Text>
                                    
                                    <Link href=' https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=42&giftCardID=106558' _hover="textDecoration:none" 
                                    isExternal='targetBlank' className='btn-new' >
                                        Buy Now
                                    </Link>
                                </Box>

                            </SimpleGrid>
                        </TabPanel>  
                        <TabPanel>
                            <SimpleGrid columns={{sm: "1", md:"2"}} spacing={10}>
                                <PriceCard key="25" offer="" cardTitle="" currency="€300" targetBlank="_blank" planname="LADIES GROUP" description=" Sign up to our 6 week course which has 3 sessions a week. The group is for females
                                    who are looking to learn how lift correctly, build strength and gain more confidence in a gym setting." buttonTitle='Find Out' link='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=102276'/>
                            </SimpleGrid>
                        </TabPanel>
                    </TabPanels>         
                </Tabs>      
            </Container>
        </Box>
    )
}

export default  PricingWraper;
