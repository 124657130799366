import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Application from "../component/Application";


const ApplicationPage = () => {
  return (
    <MainWrapper>
      <Application />
    </MainWrapper>

  );
};

export default ApplicationPage;
