import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Home from "../component/Home";


const HomePage = () => {
  return (
    <MainWrapper>
        <Home />
    </MainWrapper>

  );
};

export default HomePage;
