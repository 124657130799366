import React from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  Link,
} from "@chakra-ui/react";
import { Link as navLink } from "react-router-dom";
import {
  FormBox,
  FormFooter,
  FormLogo,
  LoginRegisterBox,
} from "./LoginRegister.style";
const LoginRegisterWrapper = (props) => {
  return (
    <Box
      borderTop="1px solid #262626"
      backgroundImage="url(../images/Justgym-banner.jpg)"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      minH="90vh"
      backgroundSize="cover"
    >
      <Container
        maxW={{ base: "Container.sm", md: "container.xl", xl: "100%" }}
        padding={{ xl: "30px" }}
      >
        <LoginRegisterBox spacing={{ lg: 20 }}>
          <FormBox>
            <FormLogo>
              <Link
                to="/"
                as={navLink}
                color={"black"}
                textAlign={"center"}
                fontFamily={"Poppins"}
                fontWeight={"600"}
                lineHeight="26px"
                fontSize={{ base: "30px", lg: "35px", xl: "40px" }}
                _hover={{ textDecoration: "none", color: "black" }}
              >
                PERPETUA
              </Link>
            </FormLogo>
            <Heading
              as={"h5"}
              fontWeight={"600"}
              textAlign={"center"}
              fontSize={{ base: "20px", lg: "22px", xl: "26px" }}
              fontFamily={"Poppins"}
              width="100%"
            >
              {props.name}
            </Heading>
            <Box marginY={"40px"}>{props.children}</Box>
            <FormFooter>
              <Link
                to="/terms-condition"
                as={navLink}
                color="blackAlpha.900"
                fontFamily={"Poppins"}
                fontSize={{ base: "13px", md: "14px" }}
                _hover={{ textDecoration: "none", color: "Red" }}
              >
                Terms & Conditions
              </Link>
              <Text as="span" marginX="5px">
                •
              </Text>
              <Link
                to="/privacy-policy"
                as={navLink}
                color="blackAlpha.900"
                fontFamily={"Poppins"}
                fontSize={{ base: "13px", md: "14px" }}
                _hover={{ textDecoration: "none", color: "Red" }}
              >
                Privacy Policy
              </Link>
            </FormFooter>
          </FormBox>
        </LoginRegisterBox>
      </Container>
    </Box>
  );
};

export default LoginRegisterWrapper;
