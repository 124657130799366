import type { Gallery } from "../type/Gallery";

export const parseGallery = (data: any): Gallery[] => {
  if (!data) return [];
  return data.map((item : any) => {
    return {
      id: item.id,
      file_path: item.file_path,
    } 
  })
};
