import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import CrossfitCoach from "../component/CrossfitCoach";


const CrossfitCoachPage = () => {
  return (
    <MainWrapper>
      <CrossfitCoach />
    </MainWrapper>

  );
};

export default CrossfitCoachPage;
