import { RandomUserType } from "../type/Feed";

export const parseRandomUser = (data: any): Array<RandomUserType> => {
    if (!data) return [];
    return data.map((item : any) => {
      return {
          id:item.id,
          is_followed:item.is_followed,
          profile_image:item.profile_image,
          name:item.name
      } 
    })
  };
  export const parseFollowers = (data: any): Array<RandomUserType> => {
    if (!data) return [];
    return data.map((item : any) => {
      return {
          id:item.from_user.id,
          is_followed:item.from_user.is_followed,
          profile_image:item.from_user.profile_image,
          name:item.from_user.name
      } 
    })
  };
  export const parseFollowing = (data: any): Array<RandomUserType> => {
    if (!data) return [];
    return data.map((item : any) => {
      return {
          id:item.user.id,
          is_followed:item.user.is_followed,
          profile_image:item.user.profile_image,
          name:item.user.name
      } 
    })
  };