import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Shop from "../component/Shop";


const ShopPage = () => {
  return (
    <MainWrapper>
      <Shop />
    </MainWrapper>

  );
};

export default ShopPage;
