import {
  Flex,
  Container,
  SimpleGrid,
  GridItem,
  Box,
  Stack,
  VStack,
  Link,
  Image,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Heading from "./Heading/Heading";
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import ClassCard from "./Classcard/ClassCard";
import AppDownload from "./AppDownload/AppDownload";
import Text from "./SubHeading/SubHeading";
import { NavLink } from "react-router-dom";
import Button from "./Button/CustomeButton";
import Testimonial from "./Testimonial/Testimonial";
import CfProgram from "./CF Program/CfProgram";
import Accordian from "./Accordion/Accordian";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";
import VideoSection from "./VideoSection/VideoSection";
import VideoBanner from "./VideoBanner/VideoBanner";
import BlogSection from "./Blog/BlogSection";

const accoData = [
  {
    id: 1,
    title: "When should I arrive for class?",
    description:
      "We recommend arriving at the gym 15 minutes before class for check-in. This will allow time for our Front Desk staff to get you checked into class and show you around the facility.",
  },
  {
    id: 2,
    title: "What do I do when I get there?",
    description:
      "Say hello to whoever is at the front desk and mention that it is your first time. They’ll get you taken care of.",
  },
  {
    id: 3,
    title: "What if I'm running late?",
    description:
      "To ensure that your spot is not released to the waitlist, please arrive at least 5 minutes prior to the start of your class.",
  },
  {
    id: 3,
    title: "Good Vibes Zone",
    description:
      "We want you to bring your good vibes! We truly believe in making our gyms a space where members can check their stress at the door. In our gyms you’ll find a community built on sweat and support, all are welcome here. All we ask is that you show up, bring a smile, and get in a great workout. It’s that simple.",
  },
];
const accoData2 = [
  {
    id: 1,
    title: "What is a RUMBLE BOXING class like?",
    description:
      "Each class you’ll be learning power moves from famous boxers, but you’ll need some determination, self-discipline, and effort to take on the challenge.",
  },
  {
    id: 2,
    title: "I've never done this style of training before, can I keep up?",
    description:
      "Absolutely! Our workouts are beginner friendly and designed to allow all athletes to workout at their own pace. Our Coaches are trained to modify and make adjustments to the workout on the fly to meet you where you are.",
  },
  {
    id: 3,
    title: "What should I wear?",
    description:
      "Wear athletic clothes that are comfy for you to sweat and move in. Boxing gloves are provided free of charge. You just have a set of boxing wraps, if you dont have a pair, you can purchase a pair before class",
  },
  {
    id: 4,
    title: "What shoes should I wear?",
    description:
      "Most athletes wear traditional cross-training shoes suitable for cardio & strength training. ",
  },
  {
    id: 5,
    title: "Do you guys provide water?",
    description:
      "Each of our gyms are equipped with filtered water stations. Bring a reusable water bottle with you and fill up before class!",
  },
];
const accoData3 = [
  {
    id: 1,
    title: "Do you have showers?",
    description:
      "Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided.",
  },
  {
    id: 2,
    title: "That was fun! What's next?",
    description:
      "Awesome! Schedule your next workout through the Perpetua Fitness app. If you have any questions, please feel free to reach out to our team at any facility or through hello@perpetua.ie",
  },
];

const metaTitle = "Boxing Class Dublin | Rumble | Perpetua Fitness";
const metaDesc =
  "Boxing classes in Dublin with Perpetua Fitness. Get ready to learn everything about boxing and have fun doing so. Take on the challenge.";

function Rumble(props) {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* <InnerBanner
        backgroundColor='black'
        backgroundImage={{ sm: 'url(./images/Rumble-banner-m.jpg)', md: 'url(./images/Rumble-banner.jpg)' }}
        subtitle='BOXING STUDIO'
        title='Rumble Boxing'
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image> Available at Lennox Street Facility</Box>}
        issubtextpara={false}
        isButton={true}
        issubtitleRight={true}
        width={{ sm: "100%" }}
        height={{ sm: '450px', md: '500px', lg: '550px', xl: '767px' }}
        buttontitle='BOOK A CLASS'
        Link='/pricing'
        ButtonModalmarginTop={{ sm: '10px', md: '0' }}
        videoModal='https://www.youtube.com/embed/Bhp_UPjiSpQ'
      /> */}

      <VideoBanner
        videobanner="../video/Rumble-banner.mp4"
        title="Rumble Boxing"
        subtitle="BOXING CLASS DUBLIN"
        isButton={true}
        issubtextpara={false}
        buttontitle="BOOK A CLASS"
        margin="0px 0 0"
        Link="/timetable"
        videoModal="https://www.youtube.com/embed/Bhp_UPjiSpQ"
        targetBlank={false}
        modalButtontitle="View The Experience"
        subtitleRight={
          <Box display="inline-block">
            <Image
              src="../images/map-white.svg"
              display="inline-block"
              top="12px"
              position="relative"
            ></Image>
            Available at Lennox Street Facility
          </Box>
        }
        issubtitleRight={true}
      />

      {/* <Flex flexDirection='column' padding={{ sm: '0', md: '50px 50px 60px', lg: '70px 50px 70px', xl: '80px 0px 80px' }}>
        <Container maxW='container.xl'>
          <Heading
            as="h3"
            title="EACH CLASS YOU’LL BE LEARNING POWER MOVES FROM FAMOUS BOXERS, BUT YOU’LL NEED SOME DETERMINATION, SELF-DISCIPLINE, AND EFFORT TO TAKE ON THE CHALLENGE."
            variant='medium'
            textAlign="center"
            lineHeight="1"
            width={{ lg: '100%', xl: '100%' }}
            padding={{ sm: '50px 0px', md: '0px 0px 70px' }}
            className='wow animate fadeInUp'
          >
          </Heading>

          <SimpleGrid
            columns={{ sm: '1', md: '2' }}
            columnGap={10}
            rowGap={2}
            width="full"
          >
            <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
              <ClassCard
                title='BAG WORK'
                subtitle='GET READY TO LEARN THE GROUNDWORK OF BOXING FROM YOUR STANCE AND FORM TO KILLER PUNCHING COMBOS, COMBINED WITH STRENGTH AND CONDITIONING BODYWEIGHT DRILLS SPECIFIC TO BOXING. EXPECT SKILLS, DRILLS AND COMBINATIONS, ALONG WITH SOME POWERFUL BODYWEIGHT MOVES.
                    '
                bg="url('/images/bagwork_new.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ base: '350px', sm: '450px', md: '450px', lg: '550px', xl: '652px' }}
              />
            </GridItem>
            <GridItem mt={{ base: '20px', sm: '40px', xl: '84px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='TREADMILL'
                subtitle='LACE UP YOUR TRAINERS AND GET READY TO WORK! YOU WILL BE GUIDED THROUGH RUNNING INTERVALS BY YOUR COACH ON OUR STATE-OF-THE-ART TREADMILLS. NOT A RUNNER? DON’T WORRY! YOUR COACH WILL PROVIDE YOU WITH A RANGE OF BEGINNER, INTERMEDIATE AND ADVANCED SPEEDS. THESE TREADMILL ROUNDS TO COMPLIMENT THE GRIT YOU NEED ON THE BAG.'
                bg="url('/images/treadmill_new.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ base: '350px', sm: '450px', md: '450px', lg: '550px', xl: '652px' }}
              />
            </GridItem>
          </SimpleGrid>

        </Container>
      </Flex> */}

      <Flex
        flexDirection="column"
        padding={{
          sm: "0",
          md: "50px 0px 60px",
          lg: "70px 20px 70px",
          xl: "80px 0px 80px",
        }}
        background="#000"
        marginBottom={{ md: "70px" }}
      >
        <Container maxW="container.xl">
          <Heading
            as="h3"
            title="EACH CLASS YOU’LL BE LEARNING POWER MOVES FROM FAMOUS BOXERS, BUT YOU’LL NEED SOME DETERMINATION, SELF-DISCIPLINE, AND EFFORT TO TAKE ON THE CHALLENGE."
            variant="medium"
            textAlign="center"
            lineHeight="1"
            width={{ lg: "100%", xl: "100%" }}
            padding={{ sm: "40px 0px", md: "0px 0px 70px" }}
            className="wow animate fadeInUp"
            color="#fff"
          ></Heading>
        </Container>
        <Container maxW="container-fluid">
          <SimpleGrid
            columns={{ sm: "1", md: "2", lg: "2", xl: "3", xxl: "4" }}
            columnGap={4}
            rowGap={2}
            width="full"
            marginTop="50px"
            padding={{ sm: "0px", md: "0px 30px" }}
          >
            <GridItem
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.7s"
            >
              <ClassCard
                title="WARM UP"
                subtitle="Please arrive 15 minutes early and check in with our front desk. Our staff will introduce
                  you to the class coach. They will provide an introduction to how the class works and show you how to use our treadmills. We have 6-10 minutes set aside to start your session off with a dynamic warm-
                  up to prep your body for the activity to come, we will alternate between the treadmills and floor until your heart rate is up and your body is ready to go!"
                bg="url('/images/Rumble/WarmUp.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
            <GridItem
              mt={{ sm: "40px", xl: "80px" }}
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.9s"
            >
              <ClassCard
                title="TREADMILL"
                subtitle="LACE UP YOUR TRAINERS AND GET READY TO WORK. YOUR COACH WILL GUIDE YOU THROUGH THE LIKES OF LUNG BUSTING SPRINTS, INCLINE INTERVALS AND ENERGY SAPPING SLED PUSHES. NOT A RUNNER? NO BOTHER. YOUR COACH WILL GUIDE YOU EVERY STEP OF THE WAY - BEGINNER, INTERMEDIATE, AND ADVANCED RUNNERS OUT THERE, WE HAVE YOU COVERED!"
                bg="url('/images/Rumble/Treadmill.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
            <GridItem
              mt={{ sm: "0", lg: "0", xl: "160px" }}
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.9s"
            >
              <ClassCard
                title="BAG WORK"
                subtitle="GET READY TO LEARN THE GROUNDWORK OF BOXING FROM YOUR STANCE AND FORM TO KILLER PUNCHING COMBOS, COMBINED WITH STRENGTH AND CONDITIONING BODYWEIGHT DRILLS SPECIFIC TO BOXING. EXPECT SKILLS, DRILLS AND COMBINATIONS, ALONG WITH SOME POWERFUL BODYWEIGHT MOVES.
                  "
                bg="url('/images/Rumble/BagWork.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
            <GridItem
              mt={{ sm: "0", md: "40px", lg: "40px", xl: "0", xxl: "240px" }}
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.9s"
            >
              <ClassCard
                title="COOL DOWN & HIGH FIVES"
                subtitle="We have 5 minutes set aside to finish class with a cool-down to bring your heart rate back
                  to normal and stretch out your muscles. Afterward, you can shower on-site & grab a shake at our
                  Mojo Health Bar"
                bg="url('/images/Rumble/Cooldown.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>

      <Flex
        flexDirection="column"
        padding={{
          sm: "20px 0 10px",
          md: "0px 50px 20px",
          lg: "0px 50px 30px",
          xl: "0px 0px 30px",
        }}
        marginBottom={{ md: "30px" }}
      >
        <Container maxW="container.xl">
          <SimpleGrid
            columns={{ sm: "1", md: "2" }}
            columnGap={10}
            rowGap={2}
            width="full"
          >
            <GridItem>
              <Heading
                as="h2"
                title="WHO IS RUMBLE BOXING FOR?"
                variant="extralarge"
                textAlign={{ sm: "center", md: "left" }}
                lineHeight="1"
                width={{ sm: "100%", md: "90%" }}
                padding={{ sm: "30px 0px", md: "0px 0px 146px" }}
              ></Heading>
            </GridItem>
            <GridItem
              marginBottom={{ sm: "40px", md: "0" }}
              textAlign={{ sm: "center", md: "left" }}
            >
              <Text
                as="p"
                fontFamily="poppins"
                title="Ireland’s newest boxing experience which will be unmatched. Our Box and Thread class is a group fitness experience where you’ll alternate between the treadmill and boxing which will focus on building a combination from a legendary boxer. Get ready to learn everything about boxing and have fun doing so.
                  "
                width="98%"
              ></Text>
              <Heading
                as="h4"
                title="Try 3 Classes for €33"
                variant="extrasmall"
                textAlign={{ sm: "center", md: "left" }}
                lineHeight="1"
                width="102%"
                padding={{ sm: "30px 0px 25px", md: "62px 0px 30px" }}
              ></Heading>
              <NavLink to="/contact-us" _hover={{ textDecoration: "none" }}>
                <Button
                  title="Get Started"
                  className="wow fadeInUp"
                  color="black"
                />{" "}
              </NavLink>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>

      <VideoSection
        heading="Rumble"
        headingSub="at Perpetua"
        video1="https://youtube.com/embed/8WN0iT6uigM?si=r8dK9X-jxLhaWHhP"
        video2="https://youtube.com/embed/8lFXBwKmfJc?si=G8z_rZWkORCvRy01"
        smallHeading="Find out more"
        smallHeading2="Meet the team"
        midHeading=""
        midHeading2=""
      />

      <Flex
        flexDirection="column"
        padding={{
          sm: "50px 0",
          md: "50px 0px",
          lg: "70px 0px",
          xl: "80px 0px",
        }}
        position="relative"
      >
        <Container maxW="container.xl">
          <Testimonial
            headingWidth={{ sm: "97%", md: "90%", lg: "87%", xl: "69%" }}
            iconUri="/images/comment-quote-outline.svg"
            isname={false}
            title="“THE COACHES ARE BOTH PROFESSIONAL AND WELL-TRAINED. I WOULD ABSOLUTELY RECOMMEND PERPETUA TO ANYONE, FROM A BEGINNER TO A EXPERIENCED GYM USER”"
          />
        </Container>
      </Flex>

      {/* <Container maxW='container.xl' padding={{ sm: '40px 20px', md: '20px 50px', lg: '30px 50px 80px', xl: '30px 0px 30px' }} >
        <Stack direction={{ sm: 'column', md: 'row', lg: 'row' }} spacing='30px'>
          <Box w={{ sm: '100%', md: '50%' }} h='40px' position={{ sm: 'relative', md: 'sticky' }} top={{ sm: 'inherit', md: '100px' }}>
            <Heading title='WHATS MY FIRST CLASS LOOK LIKE?' variant='extralarge' lineHeight='1'></Heading>
          </Box>
          <Box w={{ sm: '100%', md: '50%' }}>
            <VStack spacing={2} align='stretch' width={"full"}>
              <Stack p='0px 0px 50px' borderBottom='1px solid #D9D9D9'>
                <Heading as='h2' title='01' color='Red' lineHeight='1'></Heading>
                <Heading as='h3' title='- ARRIVAL AND CHECK IN' variant='small' margin='0px 0px 30px'></Heading>
                <Text as='p' title='Please arrive 15 minutes early and check in with our front desk. Our staff I will introduce you to the coach who is taking the class and they will provide an introduction to how the class works and show you how to use our treadmills.'></Text>
              </Stack>
              <Stack p='50px 0px' borderBottom='1px solid #D9D9D9'>
                <Heading as='h2' title='02' color='Red' lineHeight='1'></Heading>
                <Heading as='h3' title='CLASS BREAKDOWN ' variant='medium' margin='0px 0px 30px' width='50%' lineHeight='1'></Heading>
                <br></br>
                <CfProgram
                  count='[A.]'
                  title='WARM-UP'
                >
                </CfProgram>

                <Text as='p' title='We have 5-19 minutes set aside between all class times to give you time to share high fives and talk to your professional coach about nutrition, recovery, and anything else that will help you reach your goals faster!'></Text>
                <br></br>
                <CfProgram
                  count='[B.]'
                  title=' THE WORKOUT'
                >
                </CfProgram>
                <br></br>
                <Heading as='h3' title='TREADMILL - 2 + 3 THREE MINUTE ROUNDS' variant='small' margin='0px 0px 30px' lineHeight='1'></Heading>
                <Text as='p' title='Lace up your trainers and get ready to work. Your coach will guide you through your running rounds on
                   our state-of-the-art treadmills, cueing speed and incline intervals. Not a runner? 
                  No problem. Your coach will give you a range of beginner, intermediate, and advanced speeds.'></Text>
                <br></br>
                <Heading as='h3' title='BAG WORK - 2 + 3 THREE MINUTE ROUNDS' variant='small' margin='0px 0px 30px' lineHeight='1'></Heading>
                <Text as='p' title='Get your wrap and gloves on and get ready to work on a combination from a legendary boxer.
                   The coach will bring you through punches that you throw and also ask you to hit power and speed shots. There will be lots of variety in this work, 
                  however the main goal will be to improve your boxing technique and have fun doing so.'></Text>
              </Stack>
              <Stack p='50px 0px'>
                <Heading as='h2' title='03' color='Red' lineHeight='1'></Heading>
                <Heading as='h3' title='- HIGH FIVES & DE-BRIEFS' variant='small' margin='0px 0px 30px'></Heading>
                <Text as='p' title='We have 5-10 minutes set aside between all class times to give you time to share
high fives and talk to your professional coach about nutrition, recovery, and
anything else that will help you reach your goals faster!'></Text>
              </Stack>
            </VStack>
          </Box>
        </Stack>
      </Container> */}

      {/* <Flex 
        flexDirection='row' 
        padding={{sm:'30px 0px ',md:'110px 0px' }} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width={{sm:'100%',md:'58%'}} backgroundColor='LightGrey' marginRight={{ sm:'0px', md:'-50px',lg:'-100px', xl:'-145px'}} padding={{sm:'30px', md:'50px 100px 70px 50px', lg:'100px 180px 100px 50px',xl:'200px 300px 200px 200px'}} >
            <Heading title='EVERY CLASS IS A COACH-LED BY PROS' variant="extralarge" lineHeight='1' margin='0px 0px 60px'></Heading>
            <Text 
              title='These aren’t your run of the mill personal trainers. These are professional coaches with years of experience and dedicated to helping you get results.'
              margin='0px 0px 20px'
            >
                  
            </Text>
            <Text title='When you join a class at PERPETUA, you’re not just a number. Here, you are paying for
guidance, accountability, and motivation from professionals who know how to get it done.'></Text>
        </Box>
        <Box 
        width={{md:'350px',lg:'450px', xl:'50%' }}
        backgroundImage='url(./images/bg-4.jpg)' 
        height={{ md:'400px', lg:'450px',xl:'558px' }}
        backgroundRepeat='no-repeat'
        backgroundPosition='center'
        backgroundSize='cover'
        ></Box>

      </Flex>




      <Flex 
        flexDirection='row' 
        padding={{ md:'80px 0px 0px', xl:'10px 0px 0px' }} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width='50%' textAlign='right' display={{sm:'none',md:'inline-block'}}>
            <Box 
            backgroundImage='url(./images/bg-5.jpg)' 
            width={{md:'350px', lg:'450px', xl:'551px'}}
             height={{ md:'450px', lg:'550px',xl:'671px' }}
            display='inline-block' 
            backgroundRepeat='no-repeat'
            backgroundPosition='center'
            backgroundSize='cover'
            position='relative'
            zIndex='1'
            top="-80px"
            left={{md:'-80px', lg:'-30px',xl:'0px'}}
            >
            </Box>
        </Box>
        <Box width={{sm:'100%',md:'58%'}} backgroundColor='LightGrey' marginLeft={{sm:'0px',md:'-145px'}} padding={{sm:'30px',md:'60px 30px 60px 100px', lg:'100px 30px 100px 189px', xl:'180px 200px '}} >
            <Heading title='SINCE 2014' variant="extrasmall" lineHeight='1' margin='0px 0px 60px'></Heading>
            <Heading title='PERPETUA FITNESS IS THE HOME OF THE BEST FIRST EXPERIENCE IN IRELAND' variant="extralarge" lineHeight='1' margin='0px 0px 60px'></Heading>
            <Text title='You can expect the best experience from the moment you start your nutrition program. You will start with a consultation before receiving your 
            program and get getting weekly feedback from our coaches on what changes you need to make. '></Text>
        </Box>

      </Flex> */}

      <Flex
        flexDirection={{ sm: "column", md: "row" }}
        padding={{ sm: "0px 0px 50px", md: "30px 0px 0" }}
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        justifyItems="center"
      >
        <Flex
          width={{ sm: "100%", md: "50%" }}
          backgroundColor="LightGrey"
          padding={{ sm: "60px 0 35px", md: "50px 0" }}
          position="relative"
          justifyContent="flex-end"
          _after={{
            position: "absolute",
            content: '""',
            width: "200px",
            height: "100%",
            top: "0",
            left: "100%",
            background: "LightGrey",
            zIndex: "-1",
            display: { sm: "none", md: "block" },
          }}
        >
          {" "}
          <Box width={{ md: "100%", xl: "630px" }} padding="0 15px">
            <Heading
              title="EVERY CLASS IS A COACH-LED BY PROS"
              variant="extralarge"
              lineHeight="1"
              margin={{
                sm: "0px 0px 25px",
                md: "0px 0px 40px",
                lg: "0px 0px 60px",
              }}
            />
            <Text
              title="These aren’t your run of the mill boxing coaches. These are professional coaches with years of experience and dedicated to helping you get results."
              margin="0px 0px 20px"
            />
            <Text
              title="When you join a class at PERPETUA, you’re not just a number. Here, you are
paying for guidance, accountability, and motivation from professionals
who know how to get it done."
            />
          </Box>
        </Flex>
        <Box
          width={{ sm: "100%", md: "50%" }}
          backgroundImage="url(./images/ride_every_class.webp)"
          height={{ sm: "300", md: "558px" }}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
        ></Box>
      </Flex>

      {/* <Flex 
        flexDirection={{sm: 'column-reverse', md: "row"}}
        padding={{sm:'0', md:'70px 0px 0px', lg:'150px 0px 0px' }}
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width={{sm: '100%', md: "50%"}} textAlign='right'>
            <Box 
            backgroundImage='url(./images/bg-5.jpg)' 
            width={{md:"100%" , lg: '551px'}} height={{sm: "350px", md: '671'}} 
            display={{sm:"block", md: 'inline-block' }}
            backgroundRepeat='no-repeat'
            backgroundPosition='top'
            backgroundSize='cover'
            position='relative'
            zIndex='1'
            top={{sm: "0", md: "-80px"}}
            >
            </Box>
        </Box>
        <Box width={{sm: '100%', md: "58%"}} backgroundColor='LightGrey' marginLeft={{sm: "0", md: '-145px'}} padding={{sm: "30px 20px 30px", md: "70px 100px 60px 187px", xl:  '110px 100px 100px 190px'}}   >
          <Heading title='SINCE 2014' variant="extrasmall" lineHeight='1' margin={{sm:'0px 0px 25px', md:'0px 0px 40px', lg:'0px 0px 60px'}}></Heading>
          <Heading title='PERPETUA FITNESS IS THE HOME OF THE BEST FIRST EXPERIENCE IN IRELAND' variant="extralarge" lineHeight='1' margin={{sm:'0px 0px 25px', md:'0px 0px 40px', lg:'0px 0px 60px'}}></Heading>
          <Text title='You can expect the best experience from the moment you start your nutrition program. You will start with a consultation before receiving your 
            program and get getting weekly feedback from our coaches on what changes you need to make.'></Text>
            
        </Box>

      </Flex> */}

      <Flex
        flexDirection="column"
        padding={{ sm: "0px 0px 50px", md: "110px 0px 100px" }}
        textAlign="center"
        justifyContent="center"
        justifyItems="center"
      >
        <Container maxW="container.xl">
          <Box>
            <Heading
              as="h2"
              title="ARE YOU READY?"
              variant="extralarge"
              textAlign="center"
              lineHeight="1"
              padding={{ base: "0px 0px 15px", sm: "0px 0px 30px" }}
            ></Heading>
            <Text
              title="Try 3 classes for €33. All fitness levels welcome "
              as="p"
            ></Text>
            <NavLink to="/contact-us" _hover={{ textDecoration: "none" }}>
              <Button
                title="Get Started"
                className="wow fadeInUp"
                color="black"
                margin="40px 0px 0px "
              />{" "}
            </NavLink>
            <Box>
              <Heading
                as="h5"
                title="Download our app "
                variant="extrasmall"
                textAlign="center"
                lineHeight="1"
                padding={{ sm: "15px 0px 0px", md: "30px 0px 0px" }}
                display="inline-block"
              ></Heading>

              <Link
                href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
                target="_blank"
              >
                <Heading
                  as="h5"
                  title="Here"
                  variant="extrasmall"
                  textAlign="center"
                  lineHeight="1"
                  padding={{ sm: "15px 0px 0px", md: "30px 0px 0px" }}
                  display="inline-block"
                  margin="0px 0px 0px 5px"
                  color="Red"
                  textDecoration="underline"
                ></Heading>
              </Link>
            </Box>
          </Box>
        </Container>
      </Flex>

      {/* <TabComponent /> */}

      <Box
        padding={{ sm: "35px 0px 30px", md: "80px 0px" }}
        background={"#eeeef1"}
      >
        <Container maxW="container.xl">
          <Flex
            margin="auto"
            textAlign={"center"}
            width={{ sm: "100%", md: "50%" }}
            flexDirection="column"
            paddingBottom="30px"
          >
            <Heading
              lineHeight={"1"}
              variant="large"
              title="Frequently Asked Questions"
              marginBottom="30px"
            />
            <Box mb="40px">
              <Text title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie" />
            </Box>
          </Flex>

          <Flex flexDirection={{ sm: "column", md: "row" }}>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="BEFORE CLASS"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="DURING CLASS"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData2} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="AFTER CLASS"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData3} />
            </Box>
          </Flex>
        </Container>
      </Box>
      <BlogSection catSlug="rumble" />
      <AppDownload
        bg="url('/images/App-section-bg.webp')"
        heading="Your Daily Dose of Online Workouts Is Live"
        para="The Best Online Training Platform"
        imageUri="/images/iphone.webp"
        appstore="/images/app-store.png"
        googleplay="/images/google-play.png"
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
        alt="iphone - Boxing Class Dublin"
      />
    </>
  );
}

export default Rumble;
