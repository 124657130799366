import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
} from "@chakra-ui/react";
import * as Style from "./LeftSideMenu.style";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../services/Auth";

const SideMenuButtons = (props) => {
  return (
    <Style.iconWrap
      onClick={props?.onClick}
      backgroundColor={props?.backgroundColor}
      _hover={{ backgroundColor: "#333", cursor: "pointer" }}
    >
      <Box className={"bi bi-" + props.iconName} color="#D12C41" width="22px" />
      <Style.LeftMenuTitle>{props.name}</Style.LeftMenuTitle>
    </Style.iconWrap>
  );
};
const SideMenu = (props) => {
  const { userId, Encrypt, Decrypt } = AuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  return (
    <>
      <Style.LeftPersonal>Personal</Style.LeftPersonal>
      <SideMenuButtons
        name="Feeds"
        iconName="house-door"
        backgroundColor={location?.pathname == "/forum/feeds" ? "#333" : ""}
        onClick={() => navigate("/forum/feeds")}
      />
      <SideMenuButtons
        name="Profile"
        iconName="person"
        backgroundColor={
          location?.pathname.includes("/forum/profile") && userId == Decrypt(id)
            ? "#333"
            : ""
        }
        onClick={() => {
          navigate("/forum/profile/" + Encrypt(userId));
        }}
      />
      <SideMenuButtons
        name="Gallery"
        iconName="images"
        backgroundColor={
          location?.pathname.includes("/forum/gallery") && userId == Decrypt(id)
            ? "#333"
            : ""
        }
        onClick={() => navigate("/forum/gallery/" + Encrypt(userId))}
      />
      <Style.LeftlineRow />
      <Style.LeftPersonal>Settings</Style.LeftPersonal>
      <SideMenuButtons
        name="Account"
        backgroundColor={
          location?.pathname == "/forum/profile-edit" ? "#333" : ""
        }
        onClick={() => navigate(`/forum/profile-edit`)}
        iconName="gear-fill"
      />
      {/* <SideMenuButtons
        onClick={() => {
          onOpen();
        }}
        name="Log Out"
        iconName="box-arrow-in-right"
      /> */}
    </>
  );
};
function LeftSideMenu(props) {
  return (
    <>
      <Style.LeftlineColumn1>
        <Drawer isOpen={props.isOpen} placement="left" onClose={props.onClose}>
          <DrawerOverlay />
          <DrawerContent className="dark_bg_menu">
            <DrawerCloseButton marginTop="70px" onClick={props.onClose} />
            <DrawerBody marginTop="70px" alignItems="start!important">
              <Stack
                className="main_navigation mobileDropdown"
                color="Black"
                fontSize="xs2"
                alignItems="start!important"
              >
                <SideMenu />
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Style.LeftlineColumn1>
      <Style.LeftlineColumn>
        <SideMenu />
      </Style.LeftlineColumn>
    </>
  );
}

export default LeftSideMenu;
