import React, { useState } from "react";
import { MainWrapper } from "../../component/MainWrapper";
import Profile from "../../component/Forum/Profile";



const ProfilePage = () => {
  const [refresh, setRefresh] = useState(0)
  return (

    <MainWrapper refresh={refresh}>
      <Profile setRefresh={setRefresh} refresh={refresh} />
    </MainWrapper>

  );
};

export default ProfilePage;
