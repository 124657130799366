import { Flex, Image } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const WhatsappIcon = styled(Flex)(props => ({
    position: 'relative',
    flexDirection: 'row',
    background: '#D12C41',
    height: '45px',
    padding: '0px 10px',
    borderRadius: '30px',
    alignItems: 'center',
    fontFamily: 'Poppins',
    color: "white",
    justifyContent: 'center',
    position: 'fixed',
    zIndex: '99',
    top: 'auto',
    bottom: '30px',
    left: 'auto',
    right: '30px',

    "@media screen and (max-width:767px)": {
        width: '40px',
        height: '40px',
        right: '20px',
        bottom: '20px',
    },

    "button": {
        outline: 'none',
        border: '0',
        fontSize: '12px'
    }
}))

export const Icon = styled(Image)(props => ({
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    marginLeft: '4px',
    "@media screen and (max-width:767px)": {
        width: '25px',
        height: '25px',
        textAlign: 'center',
        margin: '0 auto',
        maxWidth: 'inherit'
    },
}))
export const IconName = styled(Flex)(props => ({
    margin: '0 auto',
    alignItems: 'center'
}))
