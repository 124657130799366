import React from 'react';
import {  Box, Heading, Text, Link as Href} from '@chakra-ui/react';
import Button from '../Button/CustomeButton';
import{Link} from 'react-router-dom';
function Commoncard(props){
    return(
        <>
            <Box
            backgroundColor={props.backgroundColor}
            backgroundImage={props.Image}
            margin={props.margin}
            padding='40px 0px'
            className={props.className}
            textAlign='center'
            >
                <Heading 
                fontSize={props.fontSizeHeading}
                color={props.colorHeading} 
                lineHeight={props.lineHeight}
                textTransform="uppercase"
                //p={props.p} 
                padding='0px'
                m={props.m}
                width={props.w}
                textAlign={props.textAlign}
                display={props.display}
                letterSpacing='normal'
                >{props.title}</Heading>
                <Text
                //as='p'
                fontSize={props.fontSize}
                color={props.color} 
                textTransform={props.textTransform}
                p={props.padding} 
                margin='0 auto'
                width={props.width}
                textAlign={props.textAlign}
                letterSpacing='normal'
                >{props.subtitle}</Text>
                <Heading padding='0px 0px 25px' fontSize='20px'>{props.subHeading}</Heading>
                {props.isButton && <Href href={props.buttonLink} ><Button margin={props.margin} _hover={{color:'white'}} color='black' title={props.buttontitle}/></Href>  }
            </Box>
        </>  
    )
}

export default Commoncard;