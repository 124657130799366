import {
  Flex,
  Container,
  SimpleGrid,
  GridItem,
  Box,
  Link,
  Image,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Heading from "./Heading/Heading";
import ClassCard from "./Classcard/ClassCard";
import AppDownload from "./AppDownload/AppDownload";
import Text from "./SubHeading/SubHeading";
import { NavLink } from "react-router-dom";
import Button from "./Button/CustomeButton";
import Testimonial from "./Testimonial/Testimonial";
import Accordian from "./Accordion/Accordian";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";
import VideoSection from "./VideoSection/VideoSection";
import VideoBanner from "./VideoBanner/VideoBanner";
import BlogSection from "./Blog/BlogSection";

const accoData = [
  {
    id: 1,
    title: "When should I arrive for class?",
    description:
      "We recommend arriving at the gym 15 minutes before class for check-in. This will allow time for our Front Desk staff to get you checked into class and show you around the facility.",
  },
  {
    id: 2,
    title: "What do I do when I get there?",
    description:
      "Say hello to whoever is at the front desk and mention that it is your first time. They’ll get you taken care of.",
  },
  {
    id: 3,
    title: "What if I'm running late?",
    description:
      "To ensure that your spot is not released to the waitlist, please arrive at least 5 minutes prior to the start of your class.",
  },
  {
    id: 4,
    title: "Good Vibes Zone",
    description:
      "We want you to bring your good vibes! We truly believe in making our gyms a space where members can check their stress at the door. In our gyms you’ll find a community built on sweat and support, all are welcome here. All we ask is that you show up, bring a smile, and get in a great workout. It’s that simple.",
  },
];
const accoData2 = [
  {
    id: 1,
    title: "What is a RUMBLE BOXING class like?",
    description:
      "Each class you’ll be learning power moves from famous boxers, but you’ll need some determination, self-discipline, and effort to take on the challenge.",
  },
  {
    id: 2,
    title: "I've never done this style of training before, can I keep up?",
    description:
      "Absolutely! Our workouts are beginner friendly and designed to allow all athletes to workout at their own pace. Our Coaches are trained to modify and make adjustments to the workout on the fly to meet you where you are.",
  },
  {
    id: 3,
    title: "What should I wear?",
    description:
      "Wear athletic clothes that are comfy for you to sweat and move in. Boxing gloves are provided free of charge. You just have a set of boxing wraps, if you dont have a pair, you can purchase a pair before class",
  },
  {
    id: 4,
    title: "What shoes should I wear?",
    description:
      "Most athletes wear traditional cross-training shoes suitable for cardio & strength training. ",
  },
  {
    id: 5,
    title: "Do you guys provide water?",
    description:
      "Each of our gyms are equipped with filtered water stations. Bring a reusable water bottle with you and fill up before class!",
  },
];
const accoData3 = [
  {
    id: 1,
    title: "Do you have showers?",
    description:
      "Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided.",
  },
  {
    id: 2,
    title: "That was fun! What's next?",
    description:
      "Awesome! Schedule your next workout through the Perpetua Fitness app. If you have any questions, please feel free to reach out to our team at any facility or through hello@perpetua.ie",
  },
];

const metaTitle = "Rumble 12Rounds | Perpetua Fitness";

const Rumble12Rounds = () => {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title={metaTitle} />
      <VideoBanner
        videobanner="../video/rumble12rounds.mp4"
        title="Rumble "
        subtitle="BOXING CLASS DUBLIN"
        SubHeading="12 Rounds"
        isSubHeading={true}
        isButton={true}
        issubtextpara={false}
        buttontitle="BOOK A CLASS"
        margin="0px 0 0"
        Link="/timetable"
        videoModal="https://www.youtube.com/embed/BUhlA-1hTSw"
        targetBlank={false}
        modalButtontitle="View The Experience"
        subtitleRight={
          <Box display="inline-block">
            <Image
              src="../images/map-white.svg"
              display="inline-block"
              top="12px"
              position="relative"
            ></Image>
            Available at Lennox Street Facility
          </Box>
        }
        issubtitleRight={true}
      />

      <Flex
        flexDirection="column"
        padding={{
          sm: "0",
          md: "50px 0px 60px",
          lg: "70px 20px 70px",
          xl: "80px 0px 80px",
        }}
        background="#000"
        marginBottom={{ md: "70px" }}
      >
        <Container maxW="container.xl">
          <Heading
            as="h3"
            title="OUR FIRST CLASS CONCEPT THAT FOCUSES SOLELY ON IMPROVING YOUR BOXING SKILLS – OUR 12
            ROUND CLASS IS A GROUP FITNESS EXPERIENCE WHERE YOU WILL GO THROUGH 12 ROUNDS OF
            SKILLS DRILLS ON OUR AQUA BAGS WITH THE GOAL OF BECOMING A BETTER BOXER"
            variant="medium"
            textAlign="center"
            lineHeight="1"
            width={{ lg: "100%", xl: "100%" }}
            padding={{ sm: "40px 0px", md: "0px 0px 70px" }}
            className="wow animate fadeInUp"
            color="#fff"
          ></Heading>
        </Container>
        <Container maxW="container-fluid">
          <SimpleGrid
            columns={{ sm: "1", md: "2", lg: "2", xl: "3", xxl: "3" }}
            columnGap={4}
            rowGap={2}
            width="full"
            marginTop={{ sm: "0px", md: "50px" }}
            padding={{ sm: "0px", md: "0px 30px" }}
          >
            <GridItem
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.7s"
            >
              <ClassCard
                title="WARM UP"
                subtitle="Please make sure to arrive 15 minutes before the class starts. One of our Front of House members is going to get you checked in and point you to the studio. Your Boxing Coach is going to invite you in the studio 5 minutes before the class starts to go over the very basics of boxing and to explain how everyone should be moving during the class. We have 6-8 minutes set aside for Shadowboxing Fundamentals to set your form and get your body warmed up before we put on the gloves and transition to bags!"
                bg="url('/images/Rumble/WarmUp.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
            {/* <GridItem mt={{ sm: '40px', xl: '80px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='TREADMILL'
                subtitle='LACE UP YOUR TRAINERS AND GET READY TO WORK. YOUR COACH WILL GUIDE YOU THROUGH THE LIKES OF LUNG BUSTING SPRINTS, INCLINE INTERVALS AND ENERGY SAPPING SLED PUSHES. NOT A RUNNER? NO BOTHER. YOUR COACH WILL GUIDE YOU EVERY STEP OF THE WAY - BEGINNER, INTERMEDIATE, AND ADVANCED RUNNERS OUT THERE, WE HAVE YOU COVERED!'
                bg="url('/images/Rumble/Treadmill.webp')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem> */}
            <GridItem
              mt={{ sm: "40px", xl: "80px" }}
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.9s"
            >
              <ClassCard
                title="BAG WORK"
                subtitle="Get ready to learn the groundwork of boxing from your stance and punching form, to killer knockout combinations all combined with boxing specific bodyweight strength and conditioning drills. Everyone is going to be learning on their individual Aqua Bags, glove hire is on us!"
                bg="url('/images/Rumble/BagWork.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
            <GridItem
              mt={{ sm: "40px", lg: "0", xl: "160px" }}
              className="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.9s"
            >
              <ClassCard
                title="COOL DOWN & HIGH FIVES"
                subtitle="We have 5 minutes set aside to finish the class with a cool-down to bring your heart rate back to normal and stretch your muscles afterwards. You can shower on-site & grab a shake at our Mojo Health Bar!"
                bg="url('/images/Rumble/Cooldown.webp')"
                isButton={false}
                marginBottom="30px"
                transform="uppercase"
                minHeight={{
                  sm: "450px",
                  md: "450px",
                  lg: "450px",
                  xl: "500px",
                  xxl: "600px",
                }}
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>

      <Flex
        flexDirection="column"
        padding={{
          sm: "20px 0 10px",
          md: "0px 50px 20px",
          lg: "0px 50px 30px",
          xl: "0px 0px 30px",
        }}
        marginBottom={{ md: "30px" }}
      >
        <Container maxW="container.xl">
          <SimpleGrid
            columns={{ sm: "1", md: "2" }}
            columnGap={10}
            rowGap={2}
            width="full"
          >
            <GridItem>
              <Heading
                as="h2"
                title="WHO IS RUMBLE BOXING FOR?"
                variant="extralarge"
                textAlign={{ sm: "center", md: "left" }}
                lineHeight="1"
                width={{ sm: "100%", md: "90%" }}
                padding={{ sm: "30px 0px", md: "0px 0px 146px" }}
              ></Heading>
            </GridItem>
            <GridItem
              marginBottom={{ sm: "40px", md: "0" }}
              textAlign={{ sm: "center", md: "left" }}
            >
              <Text
                as="p"
                fontFamily="poppins"
                title="Ireland’s newest boxing experience which will be unmatched. Our Box and Thread class is a group fitness experience where you’ll alternate between the treadmill and boxing which will focus on building a combination from a legendary boxer. Get ready to learn everything about boxing and have fun doing so.
                  "
                width="98%"
              ></Text>
              <Heading
                as="h4"
                title="Try 3 Classes for €33"
                variant="extrasmall"
                textAlign={{ sm: "center", md: "left" }}
                lineHeight="1"
                width="102%"
                padding={{ sm: "30px 0px 25px", md: "62px 0px 30px" }}
              ></Heading>
              <NavLink to="/contact-us" _hover={{ textDecoration: "none" }}>
                <Button
                  title="Get Started"
                  className="wow fadeInUp"
                  color="black"
                />{" "}
              </NavLink>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
      {/* Video section */}

      <VideoSection
        heading="Rumble"
        headingSub="at Perpetua"
        video1="https://www.youtube.com/embed/BUhlA-1hTSw"
        // video2='https://youtube.com/embed/8lFXBwKmfJc?si=G8z_rZWkORCvRy01'
        smallHeading="Perpetua Rumble Boxing - 12 Rounds"
        smallHeading2=""
        midHeading=""
        midHeading2=""
      />

      <Flex
        flexDirection="column"
        padding={{
          sm: "50px 0",
          md: "50px 0px",
          lg: "70px 0px",
          xl: "80px 0px",
        }}
        position="relative"
      >
        <Container maxW="container.xl">
          <Testimonial
            headingWidth={{ sm: "97%", md: "90%", lg: "87%", xl: "69%" }}
            iconUri="/images/comment-quote-outline.svg"
            isname={false}
            title="“THE COACHES ARE BOTH PROFESSIONAL AND WELL-TRAINED. I WOULD ABSOLUTELY RECOMMEND PERPETUA TO ANYONE, FROM A BEGINNER TO A EXPERIENCED GYM USER”"
          />
        </Container>
      </Flex>

      <Flex
        flexDirection={{ sm: "column", md: "row" }}
        padding={{ sm: "0px 0px 50px", md: "30px 0px 0" }}
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        justifyItems="center"
      >
        <Flex
          width={{ sm: "100%", md: "50%" }}
          backgroundColor="LightGrey"
          padding={{ sm: "60px 0 35px", md: "50px 0" }}
          position="relative"
          justifyContent="flex-end"
          _after={{
            position: "absolute",
            content: '""',
            width: "200px",
            height: "100%",
            top: "0",
            left: "100%",
            background: "LightGrey",
            zIndex: "-1",
            display: { sm: "none", md: "block" },
          }}
        >
          {" "}
          <Box width={{ md: "100%", xl: "630px" }} padding="0 15px">
            <Heading
              title="EVERY CLASS IS A COACH-LED BY PROS"
              variant="extralarge"
              lineHeight="1"
              margin={{
                sm: "0px 0px 25px",
                md: "0px 0px 40px",
                lg: "0px 0px 60px",
              }}
            />
            <Text
              title="These aren’t your run of the mill boxing coaches. These are professional coaches with years of experience and dedicated to helping you get results."
              margin="0px 0px 20px"
            />
            <Text
              title="When you join a class at PERPETUA, you’re not just a number. Here, you are
              paying for guidance, accountability, and motivation from professionals
              who know how to get it done."
            />
          </Box>
        </Flex>
        <Box
          width={{ sm: "100%", md: "50%" }}
          backgroundImage="url(./images/ride_every_class.webp)"
          height={{ sm: "300", md: "558px" }}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
        ></Box>
      </Flex>

      <Flex
        flexDirection="column"
        padding={{ sm: "0px 0px 50px", md: "110px 0px 100px" }}
        textAlign="center"
        justifyContent="center"
        justifyItems="center"
      >
        <Container maxW="container.xl">
          <Box>
            <Heading
              as="h2"
              title="ARE YOU READY?"
              variant="extralarge"
              textAlign="center"
              lineHeight="1"
              padding={{ base: "0px 0px 15px", sm: "0px 0px 30px" }}
            ></Heading>
            <Text
              title="Try 3 classes for €33. All fitness levels welcome "
              as="p"
            ></Text>
            <NavLink to="/contact-us" _hover={{ textDecoration: "none" }}>
              <Button
                title="Get Started"
                className="wow fadeInUp"
                color="black"
                margin="40px 0px 0px "
              />{" "}
            </NavLink>
            <Box>
              <Heading
                as="h5"
                title="Download our app "
                variant="extrasmall"
                textAlign="center"
                lineHeight="1"
                padding={{ sm: "15px 0px 0px", md: "30px 0px 0px" }}
                display="inline-block"
              ></Heading>

              <Link
                href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
                target="_blank"
              >
                <Heading
                  as="h5"
                  title="Here"
                  variant="extrasmall"
                  textAlign="center"
                  lineHeight="1"
                  padding={{ sm: "15px 0px 0px", md: "30px 0px 0px" }}
                  display="inline-block"
                  margin="0px 0px 0px 5px"
                  color="Red"
                  textDecoration="underline"
                ></Heading>
              </Link>
            </Box>
          </Box>
        </Container>
      </Flex>

      {/* <TabComponent /> */}

      <Box
        padding={{ sm: "35px 0px 30px", md: "80px 0px" }}
        background={"#eeeef1"}
      >
        <Container maxW="container.xl">
          <Flex
            margin="auto"
            textAlign={"center"}
            width={{ sm: "100%", md: "50%" }}
            flexDirection="column"
            paddingBottom="30px"
          >
            <Heading
              lineHeight={"1"}
              variant="large"
              title="Frequently Asked Questions"
              marginBottom="30px"
            />
            <Box mb="40px">
              <Text title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie" />
            </Box>
          </Flex>

          <Flex flexDirection={{ sm: "column", md: "row" }}>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="BEFORE CLASS"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="DURING CLASS"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData2} />
            </Box>
            <Box
              width={{ sm: "100%", md: "33.33%" }}
              paddingBottom={{ sm: "30px", md: 0 }}
            >
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="AFTER CLASS"
                marginBottom="20px"
                padding="0 15px"
              />
              <Accordian data={accoData3} />
            </Box>
          </Flex>
        </Container>
      </Box>
      <BlogSection catSlug="rumble" />
      <AppDownload
        bg="url('/images/App-section-bg.webp')"
        heading="Your Daily Dose of Online Workouts Is Live"
        para="The Best Online Training Platform"
        imageUri="/images/iphone.webp"
        appstore="/images/app-store.png"
        googleplay="/images/google-play.png"
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
        alt="iphone - Boxing Class Dublin"
      />
    </>
  );
};

export default Rumble12Rounds;
