import React from 'react';
import {Box,Image} from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import Text from'../SubHeading/SubHeading';
import {defaultTestimonialProps} from'./Testimonial.type';
import LazyLoad from "react-lazy-load";

function Testimonial(props) {
  return (
    <>
      {props.isicon || props.isfeedback || props.isname ? (   
        <Box
        justifyItems='center' 
        justifyContent='center' 
        textAlign='center' 
        boxShadow='0px 0px 100px rgba(0, 0, 0, 0.1)' 
        background={props.background ? props.background  :'white'}
        padding={{sm:'0px 0px',md:'60px 0px'}}
        >
            {props.isicon && <LazyLoad offset={300}><Image  alt='icon' src={props.iconUri} margin="0px auto 15px"/></LazyLoad>}
            {props.isfeedback &&<Heading 
            variant='medium' 
            title={props.title}
            width={props.headingWidth}
            margin='0px auto' 
            lineHeight='1.1'
            color={props.color}
            ></Heading>}
            {props.isname &&<Text fontFamily='BlenderProBold' color='Grey' title={props.name} textTransform='uppercase' fontSize='sm2'margin='20px 0px 0px'></Text>}
        </Box>
         ): null }
    </>
  )}
export default Testimonial;  
Testimonial.defaultProps = defaultTestimonialProps
