import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import ForgotPassword from "../../component/Forum/ForgotPassword";



const ForgotPasswordPage = () => {
    return (

        <MainWrapper>
            <ForgotPassword />
        </MainWrapper>

    );
};

export default ForgotPasswordPage;