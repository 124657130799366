import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Container,
  Center,
  Text as PText,
  Link,
  Spinner,
  Flex,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";

import * as Style from "./Style/Blog.style";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import LatestBlog from "./Blog/LatestBlog";
import MetaTitle from "./MetaTitle";

function BlogDetails() {
  const [blogDetails, setBlogDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();
  const [latestBlog, setLatestBlog] = useState([]);
  const [potCast, setPotCast] = useState([]);
  const [pageSlug, setPageSlug] = useState(null);

  const createPageSlug = (catDetails) => {
    const pageListData = {
      crossfit: "crossfit",
      hyrox: "hyrox",
      nutrition: "nutrition",
      "online-training": "online-programing",
      "personal-training": "personal-training",
      podcast: "podcast",
      "prepost-natal": "prepostnatal",
      ride: "rhythmride",
      rumble: "rumble",
      "tread-shred": "treadandshred",
    };

    if (pageListData.hasOwnProperty(catDetails.slug)) {
      setPageSlug(pageListData[catDetails.slug]);
    }
  };

  useEffect(() => {
    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/single",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        slug: slug,
      },
    })
      .then((response) => {
        createPageSlug(response.data.data[0].category);
        setBlogDetails(response.data.data[0]);
        const objectArray = Object.entries(response.data.data[0].podcast_link);
        setPotCast(objectArray);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    setIsLoading(true);
    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/latest",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        num_of_post: 3,
        except_slug: slug,
      },
    })
      .then((response) => {
        setLatestBlog(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  console.log("====================================");
  console.log(blogDetails);
  console.log("====================================");

  const videoEl = useRef(null);
  return (
    <>
      <MetaTitle
        title={blogDetails.title + " | Perpetua Fitness"}
        content={blogDetails.meta_description}
        metaTitle={blogDetails.meta_title}
      />
      <Box minH={{ sm: "100vh", md: "none" }}>
        {isLoading ? (
          <Flex justifyContent="center">
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#d12c41"
              size="lg"
            />
          </Flex>
        ) : (
          <>
            <Style.BlogHeading>
              <Container maxW="container.xl">
                <Heading
                  title={blogDetails.title}
                  width={{ sm: "100%", md: "70%" }}
                  margin="0px auto"
                  lineHeight="1.1"
                  as="h1"
                  cfSize={{ sm: "md", md: "md", lg: "lg", xl: "xl" }}
                ></Heading>
                {blogDetails.subtitle && (
                  <Heading
                    title={blogDetails.subtitle}
                    variant="smallmid"
                    cfSize={{ sm: "xs", md: "sm" }}
                    width={{ sm: "100%", md: "70%" }}
                    margin="25px auto 0px"
                    lineHeight="1.1"
                    as="h2"
                  ></Heading>
                )}
                <Center
                  display={{ sm: "none", md: "flex" }}
                  flexDirection="row"
                  marginTop="20px"
                >
                  <Text
                    title={moment(blogDetails.updated_at).format(
                      "MMMM Do YYYY"
                    )}
                    textTransform="uppercase"
                    fontSize={{ sm: "14px", md: "16px" }}
                    color="#D12C41"
                  />
                  <Text
                    title="|"
                    padding="0px 10px"
                    fontSize={{ sm: "14px", md: "16px" }}
                  />
                  {blogDetails.excerpt && (
                    <Link
                      href={
                        blogDetails.coach_ids
                          ? `/team/${blogDetails.coach_ids.slug}`
                          : ""
                      }
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {blogDetails.coach_ids && blogDetails.coach_ids.image && (
                        <Image
                          cursor="pointer"
                          src={blogDetails.coach_ids.image}
                          height="35px"
                          width="35px"
                          borderRadius="20px"
                        />
                      )}
                      <Text
                        title={blogDetails.excerpt}
                        padding="0 0 0 5px"
                        textTransform="capitalize"
                        fontSize={{ sm: "14px", md: "16px" }}
                      />
                    </Link>
                  )}
                </Center>
                <Center display={{ sm: "flex", md: "none" }} marginTop="10px">
                  <Text
                    title={moment(blogDetails.updated_at).format(
                      "MMMM Do YYYY"
                    )}
                    textTransform="uppercase"
                    fontSize={{ sm: "14px", md: "16px" }}
                    color="#D12C41"
                  />
                </Center>
                {blogDetails.excerpt && (
                  <Link
                    href={
                      blogDetails.coach_ids
                        ? `/team/${blogDetails.coach_ids.slug}`
                        : ""
                    }
                  >
                    <Center
                      marginTop="7px"
                      display={{ sm: "flex", md: "none" }}
                    >
                      {blogDetails.coach_ids && blogDetails.coach_ids.image && (
                        <Image
                          cursor="pointer"
                          src={blogDetails.coach_ids.image}
                          marginLeft="0px"
                          height="50px"
                          width="50px"
                          borderRadius="25px"
                        />
                      )}
                      <Text
                        title={blogDetails.excerpt}
                        padding="0 0 0 10px"
                        textTransform="capitalize"
                        fontSize={{ sm: "14px", md: "16px" }}
                      />
                    </Center>
                  </Link>
                )}
              </Container>
            </Style.BlogHeading>

            {blogDetails.podcast_link !== null && (
              <Style.BlogAppInfo>
                <Box
                  display="flex"
                  flexDirection="row"
                  /* flexWrap='wrap' */ alignItems="center"
                  textAlign="center"
                  padding={{ sm: "30px 15px 20px", md: "40px 15px" }}
                  justifyContent="center"
                >
                  {potCast.map((eachRow, i, arr) => (
                    <>
                      <Link href={eachRow[1].link} isExternal>
                        <Style.BlogAppImage
                          alt={eachRow[1].title}
                          src={eachRow[1].social_logo}
                          paddingBottom={{ sm: "15px", md: "20px", lg: "0" }}
                          paddingRight={{ sm: "0px", md: "20px" }}
                        />
                      </Link>
                      {arr.length - 1 > i && (
                        <PText
                          height={{ sm: "0px", md: "75px" }}
                          borderLeft={{ sm: "0px", md: "2px solid LightGrey" }}
                          title={i}
                        />
                      )}
                    </>
                  ))}
                </Box>
              </Style.BlogAppInfo>
            )}

            <Style.BlogInfo>
              <Container maxW="container.xl">
                {/* <SimpleGrid columns={{md:2, sm:1}} spacing={{md:10, sm:3}}>
                                <Box>
                                    <Heading
                                        title="goes content heading"
                                        variant="small"
                                        textTransform="uppercase"
                                        textAlign="center"
                                        padding={{ sm: '0px 120px 10px 0px', md: '0px 40px 0px 0px' }}
                                    />
                                </Box>
                                <Box>
                                    
                                </Box>
                            </SimpleGrid> */}

                <Box
                  className="htmlContent _blogdetails"
                  dangerouslySetInnerHTML={{ __html: blogDetails.body }}
                  textAlign="center"
                ></Box>
              </Container>

              {blogDetails.youtube_video_id && (
                <Flex
                  gap="15px"
                  padding="30px 0px"
                  alignItems="Center"
                  justifyContent="Center"
                  background="black"
                >
                  <Container maxW="container.xl">
                    <Heading
                      title="Related Video"
                      variant="large"
                      cfSize={{ sm: "md", md: "md", lg: "lg", xl: "xl2" }}
                      textTransform="uppercase"
                      textAlign="center"
                      padding={{
                        sm: "0px 120px 10px 0px",
                        md: "0px 40px 0px 0px",
                      }}
                      color="#fff"
                    />
                    <Flex
                      alignItems="Center"
                      justifyContent="Center"
                      gap="30px"
                      marginTop="30px"
                      flexWrap="wrap"
                    >
                      <Box width={{ md: "300px", sm: "100%" }}>
                        <Box
                          as="iframe"
                          src={blogDetails.youtube_video_id}
                          width={{ sm: "100%", md: "100%" }}
                          height="550px"
                        />
                      </Box>
                    </Flex>
                  </Container>
                </Flex>
              )}
            </Style.BlogInfo>

            {/* <PText width="90%" borderTop='1px solid LightGrey' padding="20px 0px" margin=" 0px auto" /> */}
            {/* <Box paddingBottom='25px'>
                        <Heading
                            title="GET CONNECTED:"
                            variant="small"
                            textTransform="uppercase"
                            textAlign="center"
                            padding={{ sm: '0px 120px 10px 0px', md: '0px 40px 0px 0px' }}
                        />
                        <Center>
                            <Style.BlogUnorderedList >
                                <Style.BlogListItems>
                                    Follow <Link textDecoration="underline" fontWeight="500"> MDV</Link>
                                </Style.BlogListItems>
                                <Style.BlogListItems>
                                    Follow <Link textDecoration="underline" fontWeight="500"> Max</Link>
                                </Style.BlogListItems>
                                <Style.BlogListItems>
                                    Follow <Link textDecoration="underline" fontWeight="500"> James</Link>
                                </Style.BlogListItems>
                                <Style.BlogListItems>
                                    Get your hands on <Link textDecoration="underline" fontWeight="500"> Perpetua</Link>
                                </Style.BlogListItems>
                            </Style.BlogUnorderedList>
                        </Center>
                    </Box> */}

            {latestBlog.status === true && (
              <LatestBlog
                title="Latest Blog"
                relatedPageSlug={pageSlug}
                latestBlogs={latestBlog.data}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default BlogDetails;
