import React, { useState } from "react";
import { Text } from "@chakra-ui/react";
import axios from "axios";
import LoginRegisterWrapper from "../../component/Forum/LoginRegister/LoginRegisterWrapper";
import { FormButton, TextInput } from "../../component/Forum/LoginRegister/Inputs";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = () => {
        if (!email) {
            setEmailError("The email field is required.");
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setEmailError("Please enter a valid email address.");
            } else {
                setIsLoading(true);
                try {
                    axios
                        .post(process.env.REACT_APP_FORUM_API_BASE_URL + "/forgot-password",
                            {
                                email: email,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                },
                            })
                        .then((response) => {
                            console.log("response--------", response);
                            if (response?.data?.success == false) {
                                setMessage(response.data.message);
                            } else {
                                setEmail("");
                                navigate("/forum/verify-otp", { state: { email: email } });
                            }
                        })
                } catch (error) {
                    console.error("Error:", error);
                    if (error?.response?.data?.message) {
                        setEmailError(error?.response?.data?.message);
                    } else {
                        setEmailError("");
                    }
                } finally {
                    setIsLoading(false);
                }
            }
        }
    }

    return (
        <LoginRegisterWrapper name="Forgot Password">
            <TextInput
                value={email}
                setValue={setEmail}
                name="Email"
                type="email"
                error={emailError}
                setError={setEmailError}
            />
            <Text
                fontSize={{ base: "12px", md: "14px" }}
                fontFamily="Poppins"
                color="Red"
                margin={{ base: "2px 5px 8px", md: "2px 5px 10px" }}
                minH={{ base: "18px", md: "21px" }}
            >
                {message}
            </Text>
            <FormButton onClick={fetchData} name="Send Otp" isDisabled={isLoading} />
        </LoginRegisterWrapper>
    )
}

export default ForgotPassword;