import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import JoinnowLanding from "../component/JoinnowLanding";


const JoinnowLandingPage = () => {
  return (
    <MainWrapper>
      <JoinnowLanding />
    </MainWrapper>

  );
};

export default JoinnowLandingPage;
