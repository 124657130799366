import { Flex, Image, useDisclosure } from "@chakra-ui/react";
import React from "react";
import * as Style from "./CreatePost.style";
import CreatePostModal from "./CreatePostModal";

function CreatePost({ onToggle, refresh }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Style.createIconWrap>
      <Style.createPostWrap>
        <Style.threeLine
          className="bi bi-filter-left"
          _hover={{ cursor: "pointer" }}
          display={{ lg: "none" }}
          onClick={onToggle}
        />
        <Flex alignItems="center">
          <Style.create>Create a post</Style.create>
          <Style.plusIcon onClick={onOpen} className="bi bi-plus" />
        </Flex>
      </Style.createPostWrap>
      <Flex alignItems="center">
        <Style.threeLine
          className="bi bi-filter-left"
          _hover={{ cursor: "pointer" }}
          display={{ sm: "none", md: "block", lg: "none" }}
          onClick={onToggle}
        />
        <Style.whatVideoWrap>
          <Style.whatVideobacground cursor="pointer" onClick={onOpen}>
            <Style.whatText> What do you have in mind ?</Style.whatText>
          </Style.whatVideobacground>
          <Image
            width="35px"
            height="35px"
            borderRadius="15px"
            marginLeft="10px"
            cursor="pointer"
            onClick={onOpen}
            src="/images/images.svg"
          />
        </Style.whatVideoWrap>
      </Flex>
      <Style.createPostWrap1>
        <Style.create>Create a post</Style.create>
        <Style.plusIcon onClick={onOpen} className="bi bi-plus" />
      </Style.createPostWrap1>
      {isOpen && (
        <CreatePostModal
          isOpen={isOpen}
          onClose={onClose}
          refresh={refresh}
        />
      )}
    </Style.createIconWrap>
  );
}

export default CreatePost;
