import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import FirstTimers from "../component/FirstTimers";


const FirstTimersPage = () => {
  return (
    <MainWrapper>
      <FirstTimers />
    </MainWrapper>

  );
};

export default FirstTimersPage;
