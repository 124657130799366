import React from 'react';
import { SimpleGrid, Box,Text, Flex } from '@chakra-ui/react';
import {ListItem, UnorderedList,} from '@chakra-ui/react'
import PriceCard from './PricingCard';
import PriceCard2 from './PricingCard2';
import Heading from '../Heading/Heading';


function StudioPricingWraper(){
    return(
        <>
            <SimpleGrid columns={{sm: "2", md:"2"}} spacing={{sm:'5',md:'10'}}>
                <PriceCard key="1004" offer="19.99 per class" cardTitle="" currency="€59.99" planname="STUDENT 3-PACK" description={<Box>30 Day expiry<br/>Must hold valid Student ID</Box>}  link="mailto:hello@perpetua.ie" targetBlank='true' buttonTitle='Enquire NOW' tagTitle='STUDENT ONLY' tagTitle2 ='STUDENT ONLY OFFER' border='2px solid #d12c41' />
                
                <PriceCard key="1" offer="best intro offer" cardTitle="" currency="€33" planname="3 FOR €33" description="10 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104433" targetBlank='true' buttonTitle='Buy Now' tagTitle='OPENING OFFER' border='2px solid #d12c41'/>

                <PriceCard key="2" offer="" cardTitle="" currency="€24" planname="1 STUDIO CREDIT" description="30 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104430" targetBlank='true' buttonTitle='BUY NOW'/>

                <PriceCard key="3" offer="€22 per class" cardTitle="" currency="€66" planname="3 STUDIO CREDITS" description="30 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107732" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard key="4" offer="€21 per class" cardTitle="" currency="€105" planname="5 STUDIO CREDITS" description="45 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104431" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard key="5" offer="€19 per class" cardTitle="" currency="€190" planname="10 STUDIO CREDITS" description="3 Month expiry"  link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104432" targetBlank='true' buttonTitle='Buy Now' tagTitle='MOST POPULAR' border='2px solid #d12c41' />

                <PriceCard key="6" offer="€18 per class" cardTitle="" currency="€270" planname="15 STUDIO CREDITS" description="6 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107733" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard key="7" offer="€17 per class" cardTitle="" currency="€340" planname="20 STUDIO CREDITS" description="9 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=105433" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard key="8" offer="€16 per class" cardTitle="" currency="€480" planname="30 STUDIO CREDITS" description="12 Month expiry"  link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107734" targetBlank='true' buttonTitle='Buy Now' />
            </SimpleGrid>
                
            <Box> <Heading title='STUDIO MEMBERSHIPS ' textAlign='center' padding='50px 0 ' /> </Box>

            <SimpleGrid columns={{sm: "2", md:"3",xl:"3", xxl:"4"}} spacing={10} margin='30px 0px'>
                <Box className='icon-box-content'>
                    <Box color='#D12C41' marginRight='5px' display='inline-flex' className='_icon' fontSize={{sm:"20px",md:"30px"}} ><i class="bi bi-tag-fill"></i></Box>   
                    <Text fontSize={{sm:"14px",md:"16px"}} color="#000" as='p' fontFamily='poppins' fontWeight='600' lineHeight='1.2'  textTransform='uppercase'>Members only Discounts & Events</Text>
                </Box>
                <Box className='icon-box-content'>
                    <Box color='#D12C41' marginRight='5px' display='inline-flex'  className='_icon' fontSize={{sm:"20px",md:"30px"}}><i class="bi bi-trophy"></i></Box>   
                    <Text  fontSize={{sm:"14px",md:"16px"}} color="#000" as='p' fontFamily='poppins' fontWeight='600' lineHeight='1.2'textTransform='uppercase'>Train For Less</Text>
                </Box>
                <Box className='icon-box-content' >
                    <Box color='#D12C41' marginRight='5px' display='inline-flex'  className='_icon' fontSize={{sm:"20px",md:"30px"}}><i class="bi bi-lightning-charge"></i></Box>   
                    <Text  fontSize={{sm:"14px",md:"16px"}} color="#000" as='p' fontFamily='poppins' fontWeight='600' lineHeight='1.2' textTransform='uppercase'>Train with a Buddy</Text>
                </Box>
                {/* <Box className='icon-box-content'>
                    <Box color='#D12C41' marginRight='5px' display='inline-flex'  className='_icon' fontSize={{sm:"20px",md:"30px"}}><i class="bi bi-heart-pulse-fill"></i></Box>   
                    <Text  fontSize={{sm:"14px",md:"16px"}} color="#000" as='p' fontFamily='poppins' fontWeight='600' lineHeight='1.2' textTransform='uppercase'>Perpetua Anywhere Access</Text>
                </Box> */}
                <Box className='icon-box-content'>
                    <Box color='#D12C41' marginRight='5px' display='inline-flex'  className='_icon' fontSize={{sm:"20px",md:"30px"}}><i class="bi bi-snow2"></i></Box>   
                    <Text  fontSize={{sm:"14px",md:"16px"}} color="#000" as='p' fontFamily='poppins' fontWeight='600' lineHeight='1.2'  textTransform='uppercase'>Freeze your membership</Text>
                </Box>
            </SimpleGrid>

            <Box>
                <Heading title='3 Month Commitment ' textAlign='center' padding='50px 0 ' variant='medium' color='#D12C41' /> 
                <SimpleGrid columns={{sm: "1", md:"2",xl:"2", xxl:"3"}} spacing={{sm:'5',md:'10'}}>
                    <PriceCard2 key="9" offer="€19.85 per class" cardTitle="" currency="€159" planname="STUDIO 8" credit="€21" guest="1" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&amp;stype=40&amp;prodid=151" targetBlank='true' buttonTitle='Buy Now' />

                    <PriceCard2 key="10" offer="€16.58 per class" cardTitle="" currency="€199" planname="STUDIO 12" credit="€19" guest="2"  link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&amp;stype=40&amp;prodid=153" targetBlank='true' buttonTitle='Buy Now' />

                    <PriceCard2 key="11" offer="€14.94 per class" cardTitle="" currency="€239" planname="STUDIO 16" credit="€17" guest="2" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&amp;stype=40&amp;prodid=154" targetBlank='true' buttonTitle='Buy Now' />

                </SimpleGrid>
            </Box>
            <Box marginTop='30px'>
                <Heading title='6 Month Commitment ' textAlign='center' padding='50px 0 ' variant='medium' color='#D12C41' /> 
                <SimpleGrid columns={{sm: "1", md:"2",xl:"2", xxl:"3"}} spacing={{sm:'5',md:'10'}}>
                    <PriceCard2 key="9" offer="€18 per class" cardTitle="" currency="€144" planname="STUDIO 8" credit="€20" guest="1" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=167" targetBlank='true' freeze='Freeze your membership'icon="bi bi-snow2" buttonTitle='Buy Now' />

                    <PriceCard2 key="10" offer="€17 per class" cardTitle="" currency="€186" planname="STUDIO 12" credit="€18" guest="2" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=168" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2" buttonTitle='Buy Now' />

                    <PriceCard2 key="11" offer="€13.87 per class" cardTitle="" currency="€222" planname="STUDIO 16" credit="€16" guest="2" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=169" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2"  buttonTitle='Buy Now' />

                </SimpleGrid>
            </Box>




            {/* <SimpleGrid columns={{sm: "2", md:"2"}} spacing={{sm:'5',md:'10'}}>
                <PriceCard key="1" offer="best intro offer" cardTitle="" currency="€33" planname="3 FOR €33" description="10 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104433" targetBlank='true' buttonTitle='BUY NOW' tagTitle='OPENING OFFER' border='2px solid #d12c41'/>

                <PriceCard key="2" offer="" cardTitle="" currency="€24" planname="1 STUDIO CREDIT" description="30 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104430" targetBlank='true' buttonTitle='BUY NOW'/>

                <PriceCard key="3" offer="€22 per class" cardTitle="" currency="€66" planname="3 STUDIO CREDITS" description="30 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107732" targetBlank='true' buttonTitle='BUY NOW' />

                <PriceCard key="4" offer="€21 per class" cardTitle="" currency="€105" planname="5 STUDIO CREDITS" description="45 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104431" targetBlank='true' buttonTitle='BUY NOW' />

                <PriceCard key="5" offer="€19 per class" cardTitle="" currency="€190" planname="10 STUDIO CREDITS" description="3 Month expiry"  link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104432" targetBlank='true' buttonTitle='BUY NOW' tagTitle='MOST POPULAR' border='2px solid #d12c41' />

                <PriceCard key="6" offer="€18 per class" cardTitle="" currency="€270" planname="15 STUDIO CREDITS" description="6 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107733" targetBlank='true' buttonTitle='BUY NOW' />

                <PriceCard key="7" offer="€17 per class" cardTitle="" currency="€340" planname="20 STUDIO CREDITS" description="9 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=105433" targetBlank='true' buttonTitle='BUY NOW' />

                <PriceCard key="8" offer="€16 per class" cardTitle="" currency="€480" planname="30 STUDIO CREDITS" description="12 Month expiry"  link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107734" targetBlank='true' buttonTitle='BUY NOW' />
            </SimpleGrid>
                
            <Box> <Heading title='STUDIO MEMBERSHIPS ' textAlign='center' padding='50px 0 ' /> </Box>
            <SimpleGrid columns={{sm: "2", md:"2"}} spacing={{sm:'5',md:'10'}}>
                <PriceCard key="9" offer="€19.85 per class" cardTitle="" currency="€159" planname="STUDIO 8" description="8 CREDITS TO USE EACH MONTH, 1 GUEST PASS PER MONTH, PERPETUA ANYWHERE SUBSCRIPTION, 5% OFF PERPETUA MERCH, PURCHASE ADD'L CREDITS FOR €21" link="https://clients.mindbodyonline.com/ASP/main_shop.asp?studioid=535079&tg=&vt=&lvl=&stype=40&view=&trn=0&page=&catid=&prodid=151&date=4%2f19%2f2023&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=0&loc=1" targetBlank='true' buttonTitle='BUY NOW' />

                <PriceCard key="10" offer="€16.58 per class" cardTitle="" currency="€199" planname="STUDIO 12" description="12 CREDITS TO USE EACH MONTH, 2 GUEST PASSES PER MONTH, PERPETUA ANYWHERE SUBSCRIPTION, 10% OFF PERPETUA MERCH, PURCHASE ADD'L CREDITS FOR €19" link="https://clients.mindbodyonline.com/ASP/main_shop.asp?studioid=535079&tg=&vt=&lvl=&stype=40&view=&trn=0&page=&catid=&prodid=153&date=4%2f19%2f2023&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=0&loc=1" targetBlank='true' buttonTitle='BUY NOW' />

                <PriceCard key="11" offer="€14.94 per class" cardTitle="" currency="€239" planname="STUDIO 16" description="16 CREDITS TO USE EACH MONTH, 2 GUEST PASSES PER MONTH, PERPETUA ANYWHERE SUBSCRIPTION, 10% OFF PERPETUA MERCH, PURCHASE ADD'L CREDITS FOR €17" link="https://clients.mindbodyonline.com/ASP/main_shop.asp?studioid=535079&tg=&vt=&lvl=&stype=40&view=&trn=0&page=&catid=&prodid=154&date=4%2f19%2f2023&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=0&loc=1" targetBlank='true' buttonTitle='BUY NOW' />


            </SimpleGrid> */}
        </>
    )
}
export default StudioPricingWraper;