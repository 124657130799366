import React, { useState } from "react";
import { Text } from "@chakra-ui/react";
import LoginRegisterWrapper from "../../component/Forum/LoginRegister/LoginRegisterWrapper";
import {
  FormButton,
  PasswordInput,
} from "../../component/Forum/LoginRegister/Inputs";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, otp } = location.state || {};
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submit = () => {
    if (!password || !confirmPassword || password !== confirmPassword) {
      if (!password) {
        setPasswordError("The password is required.");
      } else if (password?.length < 8) {
        setPasswordError("The password must be at least 8 characters long.");
      } else {
        setPasswordError("");
      }

      if (!confirmPassword) {
        setConfirmPasswordError("The confirm password is required.");
      } else if (confirmPassword?.length < 8) {
        setConfirmPasswordError(
          "The confirm password must be at least 8 characters long."
        );
      } else if (password !== confirmPassword) {
        setConfirmPasswordError(
          "The password and confirm password must match."
        );
      } else {
        setConfirmPasswordError("");
      }
    } else {
      setIsLoading(true);
      axios
        .post(
          process.env.REACT_APP_FORUM_API_BASE_URL + "/reset-password",
          {
            email: email,
            token: otp,
            password: password,
            password_confirmation: confirmPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response?.data?.success == true) {
            navigate("/forum/login");
          } else {
            setMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error?.response?.data?.message);
          if (error?.response?.data?.message) {
            if (error?.response?.data?.errors?.message) {
              setPasswordError(error?.response?.data?.errors?.message[2]);
            } else {
              setPasswordError("");
            }
            if (error?.response?.data?.errors?.message) {
              setConfirmPasswordError(
                error?.response?.data?.errors?.message[3]
              );
            } else {
              setConfirmPasswordError("");
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <LoginRegisterWrapper name="Forgot Password">
      <PasswordInput
        value={password}
        setValue={setPassword}
        error={passwordError}
        setError={setPasswordError}
      />
      <PasswordInput
        name="Current Password"
        value={confirmPassword}
        setValue={setConfirmPassword}
        error={confirmPasswordError}
        setError={setConfirmPasswordError}
      />
      <Text
        fontSize={{ base: "12px", md: "14px" }}
        fontFamily="Poppins"
        color="Red"
        margin={{ base: "2px 5px 8px", md: "2px 5px 10px" }}
        minH={{ base: "18px", md: "21px" }}
      >
        {message}
      </Text>
      <FormButton
        onClick={() => submit()}
        name="Submit"
        isDisabled={isLoading}
      />
    </LoginRegisterWrapper>
  );
};

export default ResetPassword;
