import React, { useEffect } from "react";
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import Heading from "./Heading/Heading";
import {
  Box,
  Stack,
  Container,
  Flex,
  SimpleGrid,
  VStack,
  Link,
  Image,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import Text from "./SubHeading/SubHeading";
import WhatsincludedCard from "./WhatsIncluded Card/WhatsincludedCard";
import Testimonial from "./Testimonial/Testimonial";
import PriceBox from "./Price Component/PriceBox";
import MarqueLoop from "./Marque Loop/MarqueLoop";
import CfProgram from "./CF Program/CfProgram";
import ContactForm from "./Form/ContactForm";
import LinkButton from "./Button/LinkButton";
import WhyMembershipCard from "./WhyMemberShip/WhyMembershipCard";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";
import * as Style from "./Crossfit.style";
import VideoCard from "./VideoCard/VideoCard";
import VideoBanner from "./VideoBanner/VideoBanner";
import LatestBlog from "./Blog/LatestBlog";
import BlogSection from "./Blog/BlogSection";
import LazyLoad from "react-lazy-load";

const metaTitle = "CrossFit Dublin | CrossFit Gym & Classes | Perpetua Fitness";
const metaDesc =
  "CrossFit gym & classes in Dublin with Perpetua Fitness. Providing the best coaching, programming and community since 2014. Over 60 classes per week.";

const latestBlogs = [
  {
    image: "/images/blog-box.jpg",
    catagori_name: "Fitness",
    date: "January 18th 2024",
    title: "Nutrition Challenge – Blog Post Week 2",
    slug: "latest",
  },
  {
    image: "/images/blog-box.jpg",
    catagori_name: "Fitness",
    date: "January 18th 2024",
    title: "Nutrition Challenge – Blog Post Week 2",
    slug: "latest",
  },
  {
    image: "/images/blog-box.jpg",
    catagori_name: "Fitness",
    date: "January 18th 2024",
    title: "Nutrition Challenge – Blog Post Week 2",
    slug: "latest",
  },
];

function CrossFitPage(props) {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* <InnerBanner
        backgroundColor='black'
        backgroundImage={{sm:'url(./images/crossfit_m.jpg)', md:'url(./images/newcrossfit-banner.jpg)'}}
        title='CROSSFIT DUBLIN '
        subtextpara={<Box>Providing the best coaching, programming <br/> and community since 2014 </Box>}
        issubtextpara={true}
        isButton={true}
        buttontitle='ENQUIRE NOW'
        issubtitleRight={true}
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image> Available at our Windmill Lane Facility</Box>}
        isSubtext={false}
        width={{ sm: "100%" }}
        height={{ sm: '450px', md: '500px', lg: '550px', xl: '767px' }}
        Link='/contact-us'
        margin='10px 0px 0px'
        ButtonModalmarginTop='10px'
        videoModal='https://www.youtube.com/embed/T-4WLpZYVBw'
      ></InnerBanner> */}

      <VideoBanner
        videobanner="../video/CF-banner.mp4"
        title="CROSSFIT DUBLIN"
        subtitle={
          <Box>
            Providing the best coaching, programming <br /> and community since
            2014{" "}
          </Box>
        }
        isButton={true}
        issubtextpara={false}
        buttontitle="Book a consult"
        margin="0px 0 0"
        Link="/join-today"
        videoModal="https://www.youtube.com/embed/t3x47LUlTog"
        targetBlank={false}
        modalButtontitle="View The Experience"
        subtitleRight={
          <Box display="inline-block">
            <Image
              src="../images/map-white.svg"
              display="inline-block"
              top="12px"
              position="relative"
            ></Image>
            Available at our Windmill Lane Facility
          </Box>
        }
        issubtitleRight={true}
      />

      <Flex backgroundColor="black" padding="55px 0px 0px">
        <Container maxW="container.xl">
          <MarqueLoop></MarqueLoop>
        </Container>
      </Flex>
      <Flex
        backgroundColor="black"
        padding={{ sm: "100px 0px 50px", md: "100px 0px" }}
      >
        <Container maxW="container.xl">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            textAlign={{ base: "left", lg: "left" }}
            alignItems="center"
          >
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                currency="€"
                priceValue="215"
                time="/mo"
                description="Arrange a call with our Head Coach to discuss our induction process  "
                buttonText="Book a consult"
                link="/join-today"
                issubHeading={false}
                width={{ base: "100%", sm: "70%" }}
                border="2px solid"
                borderColor="Red"
                buttonPading="20px 25px"
              ></PriceBox>
            </Box>
            <Box
              w={{ base: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <Heading
                title="WHATS INCLUDED "
                variant="small"
                color="white"
                margin="0px 0px 30px"
                padding={{ sm: "0px 0px 0px 0px", md: "0px 0px 0px 31px" }}
                textAlign={{ sm: "center", ms: "left" }}
              ></Heading>
              <SimpleGrid
                columns={{ base: "1", lg: "2" }}
                spacing="0px"
                className="_whatsinclude_box_body"
              >
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    // Imguri='/images/gym.svg'
                    title="UNLIMITED CROSSFIT CLASSES"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    Link="/contact-us"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg'
                    title="ACCESS TO THE JUST GYM AREA"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    Link="/contact-us"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg'
                    title="SPECIALITY CLASSES "
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    Link="/contact-us"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg'
                    title="OPEN GYM "
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    Link="/contact-us"
                  ></WhatsincludedCard>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>

      {/* <Box padding={{sm:'30px 0px 50px', lg:'70px 0px 100px',xl:'130px 0px 200px'}}>
        <Heading 
          variant="extralarge" 
          title='HOW DO I GET STARTED WITH CROSSFIT DUBLIN' 
          margin={{sm:'0px auto 50px' ,md:'0px auto 86px'}} 
          width={{base:'90%',md:'50%'}}
          textAlign='center'
          lineHeight='1'
          >

        </Heading>
        <Getstarted></Getstarted>
      </Box> */}

      <Box
        padding={{ sm: "0px 0px 80px", md: "20px 0px 100px" }}
        backgroundColor="black"
      >
        <Container maxW="container.xl">
          <Heading
            variant="extralarge"
            title="HOW DO I GET STARTED WITH CROSSFIT DUBLIN"
            margin={{ sm: "0px auto 50px", md: "0px auto 86px" }}
            width={{ base: "90%", md: "60%" }}
            textAlign="center"
            lineHeight="1"
            color="white"
          />
          <SimpleGrid
            columns={{ sm: "1", md: "3" }}
            spacing={5}
            textAlign="center"
            justifyContent="center"
          >
            <GridItem>
              <Heading title="01" color="Red" textAlign="center" />
              <Heading
                title="ARRANGE A CALL WITH OUR HEAD COACH"
                color="white"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="We would love to meet you at our facility or jump on a call to discuss our induction options. You will then understand the amazing process we have here so you learn everything correctly from the start."
                color="white"
                padding="15px 20px"
              />
            </GridItem>
            <GridItem>
              <Heading title="02" color="Red" textAlign="center" />
              <Heading
                title="JOIN OUR CROSSFIT ACADEMY"
                color="white"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="Prior to joining the regular classes, a new member would join our four week group academy. 
              We have morning or evening academies for you to choose from and these take place every 5 weeks. During these four weeks,
               you will learn all the movements and correct form/technique needed for classes.
              "
                padding="15px 20px"
                color="white"
              />
            </GridItem>
            <GridItem>
              <Heading title="03" color="Red" textAlign="center" />
              <Heading
                title="BEGIN A MEMBERSHIP "
                color="white"
                variant="small"
                padding={{ sm: "15px 20px", md: "15px 20px 55px" }}
              />
              <Text
                title="Once you have finished the induction process, our team will 
              set up a membership for you so you can get started straight away "
                color="white"
                padding="15px 20px"
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Box
        backgroundColor="black"
        padding={{ sm: "60px 0px", md: "80px 0px" }}
        borderTop="1px solid #232323"
      >
        <Container maxW="container.xl">
          <Style.Wrapper>
            <Style.LeftBox textAlign={{ sm: "center", md: "left" }}>
              <Heading
                variant="large"
                title="Is CrossFit Dublin"
                lineHeight="1"
                color="white"
              />
              <Heading
                variant="large"
                title="Right For You?"
                lineHeight="1"
                color="white"
              />
            </Style.LeftBox>
            <Style.RightBox>
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box
                    as="iframe"
                    src="https://www.youtube.com/embed/VEa155lSijU?si=yKd-bTZHrJK5y4Eq"
                    width={{ sm: "100%", md: "100%" }}
                    height="550px"
                  />
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title="Member Since 2019"
                    lineHeight="1"
                    color="white"
                    marginBottom="5px"
                  />
                  <Heading
                    variant="small"
                    title="Gavin"
                    lineHeight="1"
                    color="white"
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box
                    as="iframe"
                    src="https://www.youtube.com/embed/-g7zaD4HrF0?si=_j83dhSbH-Dd-OE6"
                    width={{ sm: "100%", md: "100%" }}
                    height="550px"
                  />
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title="Head Coach"
                    lineHeight="1"
                    color="white"
                    marginBottom="5px"
                  />
                  <Heading
                    variant="small"
                    title="Eoghan"
                    lineHeight="1"
                    color="white"
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box
                    as="iframe"
                    src="https://www.youtube.com/embed/t3x47LUlTog"
                    width={{ sm: "100%", md: "100%" }}
                    height="550px"
                  />
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading
                    variant="smallheading"
                    title="Coach Craig "
                    lineHeight="1"
                    color="white"
                    marginBottom="5px"
                  />
                  <Heading
                    variant="small"
                    title=" Flow of a class"
                    lineHeight="1"
                    color="white"
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
            </Style.RightBox>
          </Style.Wrapper>
        </Container>
      </Box>

      <Container
        maxW="container.xl"
        padding={{ sm: "60px 30px 0px", md: "80px 30px 0px" }}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing="30px"
          textAlign={{ base: "left", lg: "left" }}
          alignItems="top"
          paddingBottom={{ sm: "20px", md: "70px" }}
        >
          <Box
            w={{ lg: "50%" }}
            position={{ base: "relative", md: "sticky" }}
            top={{ base: "0", md: "100px" }}
            height={{ base: "auto", md: "150px" }}
            textAlign={{ sm: "center", md: "left" }}
          >
            <Heading
              title="WHATS INCLUDED IN OUR MEMBERSHIP"
              lineHeight="1"
            ></Heading>
          </Box>
          <Box width={{ md: "50%", lg: "50%" }}>
            <SimpleGrid columns={{ sm: "1", lg: "2" }} spacing={8}>
              <WhyMembershipCard
                Imguri="/images/check.png"
                title="UNLIMITED CROSSFIT CLASSES"
                paragraph="With a CrossFit membership you have unlimited access to all of our CrossFit classes. We have over 60 classes spread over 7 days a week which you can attend.  "
              />

              <WhyMembershipCard
                Imguri="/images/check.png"
                title="JUSTGYM ACCESS "
                paragraph="We have an amazing gym area which you can attend anytime of the day with a CrossFit membership. If you would like to do some extra training or maybe some mobility, then you full access to our state of the art JUSTGYM area"
              />
              <WhyMembershipCard
                Imguri="/images/check.png"
                title="SPECIALITY CLASSES "
                paragraph="We have Olympic lifting, running and mobility classes which are scheduled each week if a member would like to focus on this area. With a CrossFit membership, you also have full access to these classes."
              />
              <WhyMembershipCard
                Imguri="/images/check.png"
                title="COMMUNITY EVENTS/WORKSHOPS "
                paragraph="At CrossFit Dublin, we are always looking to grow our amazing community and create events throughout the year which provide an opportunity for everyone to use their fitness outside the gym. Join our community days like golfing, hiking, swimming and much more "
              />
            </SimpleGrid>
          </Box>
        </Stack>
      </Container>

      <Flex
        flexDirection="column"
        padding={{ sm: "40px 0px ", md: "20px 0px 50px" }}
        position="relative"
        /* _after={{position:'absolute',content:'""',width:'554px', height:'464px', top:'0',left:'0',background:'LightGrey',zIndex:'-1'}}
      _before={{position:'absolute',content:'""',width:'554px', height:'464px', top:'auto', bottom:'0',right:'0',background:'LightGrey',zIndex:'-1',margin:'auto'}} */
      >
        <Container maxW="container.xl">
          <Testimonial
            headingWidth={{ sm: "97%", md: "90%" }}
            iconUri="/images/comment-quote-outline.svg"
            isname={true}
            title="“100% CROSSFIT DUBLIN IS THE BEST GYM IN IRELAND. THE COACHING AND PROGRAMMING PROVIDED BY THE 
          DEDICATED TEAM IS EXACTLY WHAT THEY PROVIDE FOR EVERY SINGLE MEMBER”"
            name="- CROSSFIT DUBLIN MEMBER"
          />
        </Container>
      </Flex>

      <Container
        maxW="container.xl"
        p={{ sm: "30px 0px", md: "70px 30px 50px" }}
      >
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing="30px"
          textAlign={{ sm: "left", lg: "left" }}
          alignItems="top"
        >
          <Box
            w={{ sm: "100%", lg: "50%" }}
            position={{ base: "relative", md: "sticky" }}
            top={{ sm: "0", md: "100px" }}
            height={{ sm: "auto", md: "150px" }}
            className="cfBoxRes"
            textAlign={{ sm: "center", md: "left" }}
          >
            <Heading
              as="h2"
              title="OUR CROSSFIT PROGRAM  "
              variant="extralarge"
              margin="0px 0px 40px"
              lineHeight="1"
              width={{ sm: "100%", lg: "70%" }}
            ></Heading>
            {/* <Box 
                w={{sm:'300px',md:'400px',lg:'485px'}} 
                h={{sm:'300px',md:'400px',lg:'458px'}} 
                backgroundImage='url(../images/CF-program.jpg)' 
                position='relative' 
                zIndex='1'
                >

                </Box> */}
          </Box>
          <Box
            w={{ base: "100%", lg: "50%" }}
            paddingLeft="40px"
            paddingRight={{ sm: "40px", lg: "0px" }}
          >
            <Heading
              as="h5"
              title="WE KNOW THAT PROGRAMMING IS NOT ONE SIZE FITS ALL"
              variant="smallmid"
              margin="0px 0px 15px"
            ></Heading>
            <Text
              as="p"
              title="With years of experience and coaching thousands of members, the team at CrossFit Dublin have developed a program to suit all fitness levels. In each class, we will provide two programs for a member to follow depending on their experience.  "
            ></Text>

            <VStack spacing={2} align="stretch" width={"full"} marginTop="25px">
              <Stack p="0px 0px 50px" borderBottom="1px solid #D9D9D9">
                <CfProgram title="PERPETUA PEAK PROGRAM"></CfProgram>
                <Text
                  title="This program is designed for members who are new to class and still developing the barbell technique along with the correct form and have mastered some of the gymnastics movements. In this program, you will see more barbell work, skilled gymnastic movements and some heavier loads in the workouts."
                  margin="0px 0px 15px"
                  color="Grey"
                ></Text>
                <Text
                  title="This is where we aim to get all of our members to and if you are not at this level then you would love the BASE Program."
                  margin="0px 0px 15px"
                  color="Grey"
                ></Text>
              </Stack>
              <Stack p="50px 0px 50px">
                <CfProgram title="PERPETUA BASE PROGRAM"></CfProgram>
                <Text
                  title="This program is for members who are new to class and still are developing technique with moving a barbell and getting the basics of the gymnastics movements. 
                  In this program, you will see more dumbbell work, assisted gymnastic movements and some lighter loads in the workouts.  "
                  margin="0px 0px 15px"
                  color="Grey"
                ></Text>
                <Text
                  title="Once you feel comfortable with this program and would like to progress with your training, you would then follow the PEAK program.  "
                  margin="0px 0px 15px"
                  color="Grey"
                ></Text>
              </Stack>
            </VStack>
          </Box>
        </Stack>
      </Container>

      {/* <Flex 
        flexDirection={{sm:'column',lg:'row' }}
        p={{sm:'0 0px 30px', md:'0px 0px 195px'}} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width={{base:'100%',lg:'58%'}} backgroundColor='black' marginRight={{md:'0px',lg:'-220px'}} padding={{base:'100px 0px', xl:'135px 0px'}}  position='relative' zIndex='1'  marginTop={{md:'0px', lg:'-172px'}}>
            <SimpleGrid columns={{sm:'1',md:'2'}} spacing={5} w={{base:'80%',xl:'70%'}} m='0px auto' padding={{ base:'30px 20px',xl:'50px 60px'}} border='2px solid' borderColor='Red' borderRadius='20px'>
              <Box>
                <PriceBox 
                  color='white'
                  currency='€'
                  priceValue='199'
                  time='/mo'
                  buttonText='BOOK A CALL'
                  link='/contact-us'
                  buttonPading='20px 25px'
                  issubHeading={true}
                  width={{base:'100%', sm:'70%'}}
                  isDescription={false}
                  subheading='WHAT DO YOU GET'
                  border='2px solid'
                  borderColor='Red'
                >
                </PriceBox>
              </Box>
              <Box>
                <UnorderedList p='0' m='0'listStyleType='none' color='white' fontFamily='Poppins' fontWeight='300' >
                  <ListItem borderTop='1px solid #2E2E2E' padding='15px 0px' position='relative' paddingLeft="30px"
                    _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                   >
                    UNLIMITED CROSSFIT CLASSES  
                  </ListItem>

                  <ListItem borderTop='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                  _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                  >
                    ACCESS TO THE JUST GYM AREA  
                  </ListItem>

                  <ListItem borderTop='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                  _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                  >
                    SPECIALITY CLASSES  
                  </ListItem>

                  <ListItem borderTop='1px solid #2E2E2E'borderBottom='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                  _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                  >
                    OPEN GYM
                  </ListItem>

                </UnorderedList>
              </Box>
            </SimpleGrid>
        </Box>
        <Box 
        width={{base:'100%',lg:'54%' }}
        backgroundImage='url(./images/CF-page-bottom-bg.jpg)' 
        height={{base:'500px', xl:'558px'}}
        backgroundRepeat='no-repeat'
        backgroundPosition='center'
        backgroundSize='cover'
        ></Box>

      </Flex> */}
      {/*  */}

      <Flex p={{ sm: "0px 0px 70px", md: "20px 0px 90px" }}>
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.lg",
            lg: "container.lg",
            xl: "container.xl",
          }}
        >
          <Stack direction={{ sm: "column", md: "row" }} spacing="24px">
            <Box
              width={{
                sm: "100%",
                md: "50%",
              }} /*position={{ base: 'relative', md: 'sticky' }} top={{ sm: '0', md: '100px' }} height={{sm:'auto',md:'150px' }} textAlign={{ sm: 'center', md: 'left' }} */
            >
              <Box
                paddingLeft="30px"
                position={"relative"}
                _after={{
                  position: "absolute",
                  content: '""',
                  width: "3px",
                  height: "100%",
                  top: "0",
                  left: "0",
                  background: "Red",
                }}
              >
                <Heading
                  title="FREQUENTLY ASKED QUESTIONS"
                  lineHeight="1"
                  padding="0px 0px 30px"
                  margin="0px auto"
                ></Heading>
                <Text
                  title="Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us."
                  width="80%"
                ></Text>
              </Box>
            </Box>
            <Box width={{ sm: "100%", md: "50%" }}>
              <Accordion
                defaultIndex={[0]}
                allowMultiple
                className="Accor_item _black_acording  white_acording"
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title=" What is the CrossFit Academy?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="For New beginners, our 4 week CrossFit Academy course is the best intro for new beginners to learn everything for classes. "
                      margin="0px 0px 10px"
                    ></Text>
                    <Text
                      title="We schedule 3 sessions a week where we start with the basics before progressing each week so you develop the technique needed for classes. A new group starts every 5 to 6 weeks."
                      margin="0px 0px 20px"
                    />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="I'm an experienced CrossFitter, do I have to join the CrossFit Academy?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title={
                        <Box>
                          If you have done CrossFit for more than 6 months, you
                          can join classes and start a membership. Please email{" "}
                          <Link
                            href="mailto:crossfit@perpetua.ie"
                            textDecoration="underline"
                          >
                            crossfit@perpetua.ie
                          </Link>{" "}
                          to organise a class drop in and we can get set up a
                          membership for you.
                        </Box>
                      }
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Do you offer a free trial class?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="If you have over 6 months CrossFit experience, yes we can organise a trial class for you to experience the coaching and facility. If you haven't done CrossFit before, unfortunately we do not offer a trial class. You can organise a tour with our Head Coach to see the facility and find out more details about our start up process."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="What do I get for my monthly membership fee?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="You get unlimited access to CrossFit classes and also full access to the JustGym area."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="How many classes are on each day?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title=" We have 12-14 CrossFit classes a day Monday to Friday and weekends range from 3-4 classes a day. With your CrossFit membership, you get unlimited access to all CrossFit classes."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="How do I become a CrossFit member?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title={
                        <Box>
                          If you haven't done CrossFit before, simply email{" "}
                          <Link
                            href="mailto:crossfit@perpetua.ie"
                            textDecoration="underline"
                          >
                            crossfit@perpetua.ie
                          </Link>{" "}
                          to sign up for the CrossFit Academy.
                        </Box>
                      }
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="What are your hours of operation?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title=" WML: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm"
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title=" Do you have a place where I can securely leave my stuff while I work out?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="We have lockers in our men’s and women’s changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you’re in the facility."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Do you have showers?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)"
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      {/*  */}

      <BlogSection catSlug="crossfit" />

      <VStack
        backgroundColor="black"
        padding="96px 0px"
        borderBottom="1px solid #232323"
      >
        <Container
          display="flex"
          maxW="container.xl"
          flexDirection={{ sm: "column", md: "row" }}
          alignItems="top"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
        >
          <Box
            w={{ sm: "100%", md: "50%", lg: "40%" }}
            textAlign={{ base: "center", lg: "left" }}
            marginBottom={{ base: "50px", lg: "0" }}
          >
            <Heading
              title="LOOKING FOR MORE INFORMATION"
              variant="extralarge"
              lineHeight="1"
              margin="0px 0px 40px"
              color="white"
            ></Heading>
            <Heading
              title="Chat with our head coach "
              variant="smallmid"
              lineHeight="1"
              margin="0px 0px 30px"
              color="white"
            ></Heading>
            <Link href="contact-us" _hover={{ textDecoration: "none" }}>
              <LinkButton
                title="BOOK A CALL"
                Imguri="/images/red_arrow.svg"
                border="2px solid"
                borderColor="Red"
                padding="20px 25px"
                color="White"
                className="wow fadeInUp"
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ sm: "100%", md: "50%", lg: "60%" }}>
            <ContactForm pageName="CrossFit"></ContactForm>
          </Box>
        </Container>
      </VStack>
    </>
  );
}

export default CrossFitPage;
