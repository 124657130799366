import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import ThankYou from "../component/ThankYou";


const ThankYouPage = () => {
  return (
    <MainWrapper>
      <ThankYou />
    </MainWrapper>

  );
};

export default ThankYouPage;
