import React, {lazy, useRef, useState, useEffect, Suspense} from "react";
import { Box, Input, Stack, Flex, FormLabel, FormControl, Textarea, Select, Checkbox, FormErrorMessage } from '@chakra-ui/react'
import Text from "../SubHeading/SubHeading";
import Heading from "../Heading/Heading";
import Button from '../Button/CustomeButton';

import { Link } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas';
import './Cancellation.css';
import axios from "axios";
const ReCAPTCHA = lazy(()=>import('react-google-recaptcha')) ;


function CancellationForm() {

    const sigRef = useRef();
    const [signature, setSignature] = useState(null);
    const [successFrom, setsuccessFrom] = useState('');
    const [errorSignature, setErrorSignature] = useState('');
    const [btnDisable, setBtnDisable] = useState(false);

    const [isCaptcha, setIsCaptcha] = useState(null);
    const [errorCaptcha, setErrorCaptcha] = useState('');

    const getReCaptchaVal = (value) => {
        setIsCaptcha(value);
        setErrorCaptcha('');
    }

    const handleSignatureEnd = () => {
        setSignature(sigRef.current.toDataURL());
        setErrorSignature('');
    }

    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    }
    // checkbox value
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked(!checked);

    };
    useEffect(() => {
        contactForm.is_agree = checked;
    }, [checked]);

    // signature to image
    const [imageURL, setImageURL] = useState(null);
    const create = () => {
        const URL = sigRef.current.getTrimmedCanvas().toDataURL("image/png");
        contactForm.signature = URL;
    };


    // form


    const [contactForm, setContactForm] = useState({
        name: "",
        email: "",
        experience: "Good",
        received_coaching: "Good",
        followed_program: "Good",
        share_free_trial_offer: "",
        cause_canceling: "",
        feedback: "",
        is_agree: "",
        signature: ""
    });
    const handelInput = (e) => {
        console.log(e);
        const name = e.target.name;
        const value = e.target.value;

        setContactForm({ ...contactForm, [name]: value })
    }
    const handelSubmit = (e) => {
        e.preventDefault();
        setsuccessFrom('');
        setBtnDisable(true);

        create();
        if (signature === null) {
            setBtnDisable(false);
            setsuccessFrom('');
            setErrorSignature('Place your signature here');
            return false;
        }

        if (isCaptcha === null) {
            setBtnDisable(false);
            setsuccessFrom('');
            setErrorCaptcha('Please verify that you are not a robot.');
            return false;
        }

        

        axios.post(process.env.REACT_APP_ADMIN_API_BASE_URL + '/membership-cancellation', {
            name: contactForm.name,
            email: contactForm.email,
            experience: contactForm.experience,
            received_coaching: contactForm.received_coaching,
            followed_program: contactForm.followed_program,
            share_free_trial_offer: contactForm.share_free_trial_offer,
            cause_canceling: contactForm.cause_canceling,
            feedback: contactForm.feedback,
            is_agree: contactForm.is_agree,
            signature: contactForm.signature
        })
            .then((response) => {

                setBtnDisable(false);
                setsuccessFrom(response.data.success);
                window.grecaptcha.reset();
                contactForm.name = "";
                contactForm.email = "";
                contactForm.experience = "";
                contactForm.received_coaching = "";
                contactForm.followed_program = "";
                contactForm.share_free_trial_offer = "";
                contactForm.cause_canceling = "";
                contactForm.feedback = "";
                contactForm.is_agree = "";
                contactForm.signature = "";
            })
            .catch((error) => {
                setBtnDisable(false);
                setsuccessFrom('');
                window.grecaptcha.reset();
            });
    }

    return (
        <>
            <form onSubmit={handelSubmit} className="cpContact">
                <FormControl >
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            position='relative'
                            width='fit-content'
                            marginBottom='-34px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'
                        >Name <span style={{ color: "#d12c41" }}>*</span></FormLabel>
                        <Input isRequired _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' borderRadius='0px' color='black' fontSize='18px' margin='0px 0px 0px 0px' name="name" value={contactForm.name} onChange={handelInput} />

                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            position='relative'
                            width='fit-content'
                            marginBottom='-34px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'
                        >Email <span style={{ color: "#d12c41" }}>*</span></FormLabel>
                        <Input isRequired type='email' _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }} border='1px solid #000' height='50px' borderRadius='0px' color='black' fontSize='18px' margin='0px 0px 0px 0px' name="email" value={contactForm.email} onChange={handelInput} />
                        {/* {emailError && <FormErrorMessage>{emailError}</FormErrorMessage>} */}
                    </Stack>

                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            marginBottom='-11px'
                        >
                            Rate your CrossFit/ JustGym experience in Perpetua (1-10 Scale) </FormLabel>
                        <Select isRequired placeholder='Select option' borderRadius='0' height='50px' color='black' fontSize='18px' textTransform='uppercase' name="experience" value={contactForm.experience} onChange={handelInput}>
                            <option value='Very Poor' padding='8px 0px'>Very Poor</option>
                            <option value='Poor'>Poor</option>
                            <option value='Good'>Good</option>
                            <option value='Very Goodt'>Very Good</option>
                            <option value='Excellent'>Excellent</option>
                        </Select>
                        {/* {optionError && <FormErrorMessage>{optionError}</FormErrorMessage>} */}
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            marginBottom='-11px'
                        >
                            Rate the CrossFit/ JustGym coaching you received in Perpetua (1-10 Scale) </FormLabel>
                        <Select isRequired placeholder='Select option' borderRadius='0' height='50px' color='black' fontSize='18px' textTransform='uppercase' name="received_coaching" value={contactForm.received_coaching} onChange={handelInput}   >
                            <option value='Very Poor' padding='8px 0px'>Very Poor</option>
                            <option value='Poor'>Poor</option>
                            <option value='Good'>Good</option>
                            <option value='Very Good'>Very Good</option>
                            <option value='Excellent'>Excellent</option>

                        </Select>
                        {/* {optionError && <FormErrorMessage>{optionError}</FormErrorMessage>} */}
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            width='fit-content'
                            marginBottom='-11px'
                        >
                            Rate the CrossFit/ JustGym program you followed in Perpetua - (1-10 Scale) </FormLabel>
                        <Select isRequired placeholder='Select option' borderRadius='0' height='50px' color='black' fontSize='18px' textTransform='uppercase' name="followed_program" value={contactForm.followed_program} onChange={handelInput}   >
                            <option value='Very Poor' padding='8px 0px'>Very Poor</option>
                            <option value='Poor'>Poor</option>
                            <option value='Good'>Good</option>
                            <option value='Very Good'>Very Good</option>
                            <option value='Excellent'>Excellent</option>

                        </Select>
                        {/* {optionError && <FormErrorMessage>{optionError}</FormErrorMessage>} */}
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            position='relative'
                            width='fit-content'
                            marginBottom='-34px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'
                        >  Would you have any colleagues/friends/family you would like us to offer a free trial so they get the same
                            experience as you? <span style={{ color: "#d12c41" }}>*</span></FormLabel>
                        <Textarea isRequired
                            _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }}
                            height='80px' borderRadius='0px' border='1px solid #000' color='black' fontSize='18px' margin='0px' name="share_free_trial_offer" value={contactForm.share_free_trial_offer} onChange={handelInput}   ></Textarea>

                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            position='relative'
                            width='fit-content'
                            marginBottom='-34px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'
                        >Why are you canceling? <span style={{ color: "#d12c41" }}>*</span></FormLabel>
                        <Textarea isRequired
                            _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }}
                            height='90px' borderRadius='0px' border='1px solid #000' color='black' fontSize='18px' margin='0px' name="cause_canceling" value={contactForm.cause_canceling} onChange={handelInput}></Textarea>

                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <FormLabel
                            fontSize='18px'
                            textTransform='uppercase'
                            lineHeight='1'
                            position='relative'
                            width='fit-content'
                            marginBottom='-34px'
                            background='white'
                            zIndex='11'
                            left='12px'
                            padding='0px 5px'
                        >Is there any feedback you can provide us to improve our overall service? <span style={{ color: "#d12c41" }}>*</span> </FormLabel>
                        <Textarea isRequired
                            _placeholder={{ color: 'Grey', fontFamily: 'BlenderProBold , sans-serif', fontSize: '18px', textTransform: 'uppercase' }}
                            height='90px' borderRadius='0px' border='1px solid #000' color='black' fontSize='18px' margin='0px' name="feedback" value={contactForm.feedback} onChange={handelInput}></Textarea>

                    </Stack>
                    <Stack padding='30px 0'>
                        <Checkbox isRequired colorScheme='red' value={checked} onChange={handleChange} >
                            <Box display='flex' >
                                <Text fontSize='14px' title='Please tick this box to confirm I agree to our cancellation '></Text>

                                <Link to='/terms-condition'><Text padding='0 5px' title=' terms & conditions' fontSize='16px' color="#d12c41" textDecoration='underline' /> </Link>
                                <span style={{ color: "#d12c41", fontSize: '16px' }}> * </span>

                            </Box>
                        </Checkbox>
                    </Stack>
                    <Stack>
                        <FormLabel fontSize='18px' textTransform='uppercase' lineHeight='1' width='fit-content' >Electronic Signature :  <span style={{ color: "#d12c41", fontSize: '16px' }}> * </span> </FormLabel>
                        <SignatureCanvas penColor="black" canvasProps={{ className: 'signature' }} ref={sigRef} onEnd={handleSignatureEnd} />
                        <Flex >
                            <Box textAlign='center' width='50px' fontSize='14px' color='#fff' fontFamily='Poppins' padding='3px !important' background='#D12C41' border='none' borderRadius='4px' onClick={clearSignature}>Clear</Box>
                            <Box justifyContent="center" marginLeft='20px'> {errorSignature && <Text title={errorSignature} fontSize="16px" color="#D12C41" />}</Box>
                        </Flex>


                    </Stack>
                    <Stack direction={{ sm: 'column', md: 'column', lg: 'row' }} spacing={{ md: '25px', lg: '30px', xl: '50px' }} position='relative' marginTop='25px'>
                        <Suspense fallback={"Loading.."}>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={getReCaptchaVal} className='captcha' />
                        </Suspense>
                        <Box paddingTop={{ lg: '25px' }}>
                            <Button btnDisable={btnDisable} submit="submit" title='Contact' width='208px' color='black'></Button>
                        </Box>                        
                    </Stack>
                    {errorCaptcha &&  <Text title={errorCaptcha} fontSize="14px" color="red"/>}

                    <Box display='flex' padding='10px 0'>
                        <span style={{ color: "#d12c41" }}> * </span>
                        <Text title=' - These fields are mandatory' padding='0 5px' />
                    </Box>
                    {/* <Flex justifyContent="center" margin="30px 0 0"> {successFrom && <Text title={successFrom} fontSize="16px" color="green"/>}</Flex> */}
                    <Flex justifyContent="center" margin="30px 0 0"> {successFrom && <Text title={successFrom} fontSize="16px" color="green" />}</Flex>
                </FormControl>
            </form>

        </>
    )
}

export default CancellationForm;
