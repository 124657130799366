import React from "react";
import InnerBanner from "./InnerBanner/InnerBanner";
import MembershipCard from "./Membership/MembershipCard";
import MetaTitle from "./MetaTitle";
function Membership() {
  return (
    <>
      <MetaTitle title="Membership | Perpetua Fitness" />
      <InnerBanner
        backgroundColor="black"
        backgroundImage="url(./images/Crossfit-banner.jpg)"
        title="Membership"
        issubtextpara={false}
        isButton={false}
        issubtitleRight={false}
        isSubtext={false}
        width={{ sm: "100%" }}
        height={{ sm: "450px", md: "450px", lg: "500px", xl: "600px" }}
      ></InnerBanner>

      <MembershipCard />
    </>
  );
}

export default Membership;
