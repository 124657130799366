import { Flex, Box, Link, Image, Button, Text, MenuButton, MenuItem } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";


export const rightlineColumn = styled(Box)(props => ({
    padding: '30px 0px 0',
    position: "relative",
    height: "100%",
    [`@media ${device.tabletL}`]: {
        position: 'sticky',
        //width: '28%',
    },
    [`@media ${device.tablet}`]: {
        borderLeft: "1px solid #262626",
        padding: '30px 20px 0',
    },
}))

export const rightPeople = styled(Text)(props => ({
    fontSize: "15px",
    fontFamily: "Poppins",
    color: "#fff",
    fontWeight: "700",
}))
export const rightPeopleWrap = styled(Flex)(props => ({
    alignItems: 'center',
    justifyContent: 'space-between',
}))
export const IntroGallery = styled(Image)(props => ({
    width: '300px',
    height: '120px',
    borderRadius: "0px",
    marginRight: "10px",
    [`@media ${device.tablet}`]: {
        width: '180px',
        height: '120px',
    },
}))
export const friendsTitle = styled(Text)(props => ({
    fontFamily: "Poppins",
    fontWeight: "300",
    fontSize: "12px",
    color: "#fff"
}))

export const IntrobtnWrap = styled(Flex)(props => ({
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: '20px'
}))
export const Introbtnbg = styled(Box)(props => ({
    padding: "5px 8px",
    border: "1px solid #ccc",
    borderRadius: "15px",
    marginRight: "10px",
    marginTop: "10px",
    justifyContent: "center",
    alignItems: "center"
}))


