import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import ProfileEdit from "../../component/Forum/ProfileEdit";

const ProfileEditPage = () => {
  return (
    <MainWrapper>
      <ProfileEdit />
    </MainWrapper>
  );
};

export default ProfileEditPage;
