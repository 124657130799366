import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import RideToRhythmride from "../component/RideToRhythmride";


const RideToRhythmridePage = () => {
  return (
    <MainWrapper>
      <RideToRhythmride />
    </MainWrapper>

  );
};

export default RideToRhythmridePage;
