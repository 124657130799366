import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Flex,
  Text,
  Input,
  Center,
  Button,
  Textarea,
  Image,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import LeftSideMenu from "../../component/Forum/LeftSideMenu";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ChangePasswordSection from "../../component/Forum/ChangePasswordSection";
import { TextInput } from "../../component/Forum/LoginRegister/Inputs";
import ProfileModal from "../../component/Forum/ProfileModal";
import { useLocation } from "react-router-dom";

function ProfileEdit(props) {
  const location = useLocation();
  const { secondTab } = location?.state || {};
  const { isOpen, onToggle, onClose } = useDisclosure();

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDOB] = useState("");
  const [intro, setIntro] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [favouriteWorkout, setFavouriteWorkout] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [buttonName, setButtonName] = useState("Save");
  const [changePasswordtoggler, setChangePasswordtoggler] = useState(false);
  const [profileModalShow, setProfileModalShow] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [user, setUser] = useState("");
  const id = localStorage.getItem("forum_user_id");

  ///// tab Button
  const [tab1, setFirstTab] = useState(secondTab == true ? false : true);
  const [tab2, setSecondTab] = useState(secondTab == true ? true : false);
  const [tab3, setThirdTab] = useState(false);
  const firstTabHandler = () => {
    setFirstTab(true);
    setSecondTab(false);
    setThirdTab(false);
  };
  const secondTabHandler = () => {
    setFirstTab(false);
    setSecondTab(true);
    setThirdTab(false);
  };
  const ThirdTabHandler = () => {
    setFirstTab(false);
    setSecondTab(false);
    setThirdTab(true);
  };
  //// api fetch
  const getProfileData = () => {
    const user = JSON.parse(localStorage.getItem("forum_user"));
    const token = localStorage.getItem("forum_token");
    var bearer = "Bearer " + token;
    axios({
      method: "get",
      url: process.env.REACT_APP_FORUM_API_BASE_URL + "/profile/single",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: bearer,
      },
      params: {
        user_id: user?.id,
      },
    })
      .then((response) => {
        // console.log("user info....", response.data);
        // console.log(" info data.user.name....", response.data.data.user.name);
        setIsLoading(false);
        setUserProfile(response.data.data);
        setName(response.data.data.user.name);
        setPhone(response.data.data.user.phone);
        setEmail(response.data.data.user.email);
        const profileDetails = response.data.data.user.profile_details[0];
        setIntro(profileDetails.intro);
        setHeight(profileDetails.height);
        setWeight(profileDetails.weight);
        setFavouriteWorkout(profileDetails.favourite_workout);
        setDOB(profileDetails.dob);
        setAddress1(profileDetails.address_line_1);
        setAddress2(profileDetails.address_line_2);
        setCity(profileDetails.city);
        setState(profileDetails.state);
        setCountry(profileDetails.country);
        setZipCode(profileDetails.pin);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const profileDetailsUpdate = () => {
    if (!name || !phone) {
      if (!name) {
        setNameError("The name field is required.");
      } else {
        setNameError("");
      }
      if (!phone) {
        setPhoneError("The phone field is required.");
      } else {
        setPhoneError("");
      }
    } else {
      setButtonName("Wait");
      const token = localStorage.getItem("forum_token");
      var bearer = "Bearer " + token;
      axios
        .post(
          process.env.REACT_APP_FORUM_API_BASE_URL + "/profile/update",
          {
            name: name,
            phone: phone,
            dob: dob,
            intro: intro,
            height: height,
            weight: weight,
            favourite_workout: favouriteWorkout,
            address_line_1: address1,
            address_line_2: address2,
            city: city,
            state: state,
            country: country,
            pin: zipCode,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: bearer,
            },
          }
        )
        .then((response) => {
          //console.log("-------response----", response);
          setButtonName("Save");
          setMessage(response.data.message);
          setTimeout(function () {
            setMessage("");
          }, 2000);
          setNameError("");
          setPhoneError("");
        })
        .catch((error) => {
          setButtonName("Save");
          console.log("error", error.response);
          if (error?.response?.data?.errors) {
            if (error?.response?.data?.errors?.name) {
              setNameError(error?.response?.data?.errors?.name);
            } else {
              setNameError("");
            }
            if (error?.response?.data?.errors?.phone) {
              setPhoneError(error?.response?.data?.errors?.phone);
            } else {
              setPhoneError("");
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("forum_token");
    const User = JSON.parse(localStorage.getItem("forum_user"));
    console.log("user?.id", User?.id);
    if (token) {
      if (User) {
        setUser(User);
      }
      getProfileData();
    } else {
      navigate("/forum/login");
    }
  }, [navigate]);

  return (
    <>
      <Container
        maxW={{ sm: "Container.sm", md: "container.xl", xl: "100%" }}
        padding={{ xl: "0px 30px 50px" }}
        bg={"#000"}
        borderTop="1px solid #262626"
      >
        <Flex direction={{ sm: "column", md: "row" }}>
          <LeftSideMenu isOpen={isOpen} onClose={onClose} />

          <Box
            width={{ sm: "100%", lg: "83%" }}
            borderLeft={{ lg: "1px solid #262626" }}
            padding={{ sm: "50px 0px", md: "50px 30px", lg: "50px 95px" }}
          >
            {isLoading ? (
              <Flex
                justifyContent="center"
                align="center"
                width="100%"
                bg={"#000"}
                minH="60vh"
              >
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="#333"
                  color="#ccc"
                  size="lg"
                />
              </Flex>
            ) : (
              <Box
                border="1px solid #282828"
                borderRadius="10px"
                margin={{ lg: "0 auto" }}
                width={{ lg: "100%", xxl: "73%" }}
              >
                <Flex alignItems="center" padding="30px">
                  <Box
                    fontSize="22px"
                    color="white"
                    paddingRight="10px"
                    className="bi bi-filter-left"
                    _hover={{ cursor: "pointer" }}
                    display={{ lg: "none" }}
                    onClick={onToggle}
                  />
                  <Box position="relative">
                    <Image
                      src={
                        userProfile?.intro?.profile_image
                          ? userProfile?.intro?.profile_image
                          : `https://eu.ui-avatars.com/api/?background=random&size=150&name=${userProfile?.intro?.name}`
                      }
                      alt="image"
                      width={{ sm: "65px", md: "100px" }}
                      height={{ sm: "65px", md: "100px" }}
                      borderRadius="50px"
                      objectFit="cover"
                    />
                    {user?.id == id && (
                      <Box
                        className="bi bi-camera-fill"
                        cursor="pointer"
                        fontSize="16px"
                        padding="0px 5px"
                        zIndex="11"
                        right="0"
                        color="#000"
                        backgroundColor="#ccc"
                        position="absolute"
                        bottom="0"
                        borderRadius="25px"
                        onClick={() => {
                          setProfileModalShow(true);
                        }}
                      />
                    )}
                    <ProfileModal
                      isOpen={profileModalShow}
                      image={
                        userProfile?.intro?.profile_image &&
                        userProfile?.intro?.profile_image
                      }
                      refresh={() => getProfileData()}
                      onClose={() => setProfileModalShow(false)}
                    />
                  </Box>
                  <Box paddingLeft="20px">
                    <Text
                      fontFamily="Poppins"
                      fontSize={{ sm: "18px", md: "22px" }}
                      fontWeight="700"
                      color="#fff"
                    >
                      {name}
                    </Text>
                    <Text
                      fontFamily="Poppins"
                      fontSize={{ sm: "13px", md: "15px" }}
                      fontWeight="500"
                      color="#fff"
                    >
                      {email}
                    </Text>
                  </Box>
                </Flex>
                <Flex
                  borderBottom="2px solid #282828"
                  paddingBottom="7px"
                  position="relative"
                  paddingX="30px"
                >
                  <Box
                    onClick={() => {
                      firstTabHandler();
                    }}
                  >
                    <Text
                      style={{ color: tab1 ? "#D12C41" : "#fff" }}
                      cursor="pointer"
                      fontFamily="Poppins"
                    >
                      General
                    </Text>
                    <Box
                      position="absolute"
                      bottom="-2px"
                      width="70px"
                      style={{
                        borderBottom: tab1 ? "2px solid #D12C41" : "none",
                      }}
                    />
                  </Box>
                  <Box
                    onClick={() => {
                      secondTabHandler();
                    }}
                    paddingLeft="30px"
                  >
                    <Text
                      style={{ color: tab2 ? "#D12C41" : "#fff" }}
                      paddingBottom="10px"
                      cursor="pointer"
                      fontFamily="Poppins"
                    >
                      Intro
                    </Text>
                    <Box
                      position="absolute"
                      bottom="-2px"
                      width="45px"
                      style={{
                        borderBottom: tab2 ? "2px solid #D12C41" : "none",
                      }}
                    />
                  </Box>
                  <Box
                    onClick={() => {
                      ThirdTabHandler();
                    }}
                    paddingLeft="30px"
                  >
                    <Text
                      style={{ color: tab3 ? "#D12C41" : "#fff" }}
                      paddingBottom="10px"
                      cursor="pointer"
                      fontFamily="Poppins"
                    >
                      Change Password
                    </Text>
                    <Box
                      position="absolute"
                      bottom="-2px"
                      width={{ sm: "60px", md: "150px" }}
                      style={{
                        borderBottom: tab3 ? "2px solid #D12C41" : "none",
                      }}
                    />
                  </Box>
                </Flex>
                {tab1 && (
                  <Box
                    padding={{ sm: "30px 10px", md: "50px 40px", xl: "50px" }}
                    width="100%"
                  >
                    <Box position="relative">
                      <Flex
                        alignItems={{ md: "center" }}
                        flexDirection={{ sm: "column", md: "row" }}
                        paddingTop={{ sm: "15px", md: "20px" }}
                      >
                        <Text
                          color="#fff"
                          fontFamily="Poppins"
                          fontWeight="500"
                          paddingBottom="8px"
                          width={{ md: "20%" }}
                        >
                          Name
                        </Text>
                        <Box width="100%" marginRight="10px">
                          <TextInput
                            name="Name"
                            color="#fff"
                            setValue={setName}
                            value={name}
                            backgroundColor="#444"
                            error={nameError}
                            setError={setNameError}
                          />
                        </Box>
                      </Flex>
                    </Box>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "5px", md: "10px" }}
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        Email
                      </Text>
                      <TextInput
                        name="Email"
                        color="#fff"
                        value={email}
                        backgroundColor="#444"
                        disabled="true"
                      />
                    </Flex>
                    <Box position="relative">
                      <Flex
                        alignItems={{ md: "center" }}
                        flexDirection={{ sm: "column", md: "row" }}
                        paddingTop={{ sm: "5px", md: "25px" }}
                      >
                        <Text
                          color="#fff"
                          fontFamily="Poppins"
                          fontWeight="500"
                          paddingBottom="8px"
                          width={{ md: "20%" }}
                        >
                          Phone Number
                        </Text>
                        <Box width="100%" marginRight="10px">
                          <TextInput
                            name="Phone Number"
                            color="#fff"
                            type="number"
                            backgroundColor="#444"
                            value={phone}
                            setValue={setPhone}
                            error={phoneError}
                            setError={setPhoneError}
                          />
                        </Box>
                      </Flex>
                    </Box>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "5px", md: "10px" }}
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        DOB
                      </Text>
                      <TextInput
                        name="dob"
                        color="#fff"
                        className="date-input"
                        type="date"
                        backgroundColor="#444"
                        value={dob}
                        setValue={setDOB}
                      />
                    </Flex>
                    {message && (
                      <Text
                        paddingTop="30px"
                        fontFamily="Poppins"
                        color="#5cb85c"
                        fontWeight="500"
                        minH="54px"
                        textAlign="center"
                      >
                        {message}
                      </Text>
                    )}
                    <Center paddingTop="30px">
                      <Button
                        onClick={profileDetailsUpdate}
                        color="#fff"
                        _hover={{ opacity: 0.7 }}
                        fontFamily="Poppins"
                        cursor="pointer"
                        bgColor="Red"
                        fontWeight="500"
                        fontSize="18px"
                        paddingY={{ base: "22px", lg: "25px" }}
                        width="227px"
                        marginX="10px"
                        colorScheme="Red"
                        isDisabled={buttonName == "Wait" ? true : false}
                      >
                        {buttonName}
                      </Button>
                    </Center>
                  </Box>
                )}
                {tab2 && (
                  <Box
                    padding={{ sm: "30px 10px", md: "50px 40px", xl: "50px" }}
                    width="100%"
                  >
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "15px", md: "25px" }}
                      marginRight="10px"
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom={{ sm: "8px", md: "8px" }}
                        width={{ md: "20%" }}
                      >
                        About
                      </Text>
                      <Textarea
                        onChange={(e) => setIntro(e.target.value)}
                        value={intro}
                        padding="10px 15px 0 !important"
                        border="0px!important"
                        variant="unstyled"
                        _placeholder={{
                          padding: "5px 5px 0 !important",
                          color: "#fff",
                        }}
                        placeholder="Type Your Bio"
                        fontSize={{ base: "16px", xl: "18px" }}
                        color="#fff"
                        background="#444"
                        resize="none"
                        fontFamily="Poppins"
                        _focusVisible={{ border: "0px !important" }}
                        required
                      />
                    </Flex>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "15px", md: "25px" }}
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        Favourite Workout
                      </Text>
                      <TextInput
                        name="E.g. Running"
                        color="#fff"
                        backgroundColor="#444"
                        value={favouriteWorkout}
                        setValue={setFavouriteWorkout}
                      />
                    </Flex>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "5px", md: "25px" }}
                      marginRight="10px"
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        Height(cm)
                      </Text>
                      <Input
                        onChange={(e) => {
                          if (e.target.value.length <= 3) {
                            setHeight(e.target.value);
                          }
                        }}
                        value={height}
                        type="number"
                        color="#fff"
                        padding="13px 15px!important"
                        border="0px!important"
                        variant="unstyled"
                        placeholder="00 cm"
                        fontSize="16px"
                        fontFamily="Poppins"
                        background="#444"
                        _focusVisible={{
                          border: "0px solid #787878 !important",
                        }}
                        _placeholder={{
                          color: "#fff",
                          padding: "0px !important",
                        }}
                        required
                      />
                    </Flex>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "15px", md: "25px" }}
                      marginRight="10px"
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        Weight(lb)
                      </Text>
                      <Input
                        onChange={(e) => {
                          if (e.target.value.length <= 3) {
                            setWeight(e.target.value);
                          }
                        }}
                        value={weight}
                        padding="13px 15px!important"
                        border="0px!important"
                        variant="unstyled"
                        placeholder="00 lb"
                        type="number"
                        fontSize={{ base: "16px", xl: "18px" }}
                        color="#fff"
                        fontFamily="Poppins"
                        background="#444"
                        _focusVisible={{
                          border: "0px solid #787878 !important",
                        }}
                        _placeholder={{
                          color: "#fff",
                          padding: "0px !important",
                        }}
                        required
                      />
                    </Flex>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "15px", md: "25px" }}
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        Address 1
                      </Text>
                      <TextInput
                        name="Address 1"
                        color="#fff"
                        type="text"
                        backgroundColor="#444"
                        value={address1}
                        setValue={setAddress1}
                      />
                    </Flex>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "5px", md: "25px" }}
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        Address 2
                      </Text>
                      <TextInput
                        name="Address 2"
                        color="#fff"
                        backgroundColor="#444"
                        value={address2}
                        setValue={setAddress2}
                      />
                    </Flex>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "5px", md: "25px" }}
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        City
                      </Text>
                      <TextInput
                        name="City"
                        color="#fff"
                        backgroundColor="#444"
                        value={city}
                        setValue={setCity}
                      />
                    </Flex>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "5px", md: "25px" }}
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        State
                      </Text>
                      <TextInput
                        name="State"
                        color="#fff"
                        backgroundColor="#444"
                        value={state}
                        setValue={setState}
                      />
                    </Flex>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "5px", md: "25px" }}
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        Country
                      </Text>
                      <TextInput
                        name="Country"
                        color="#fff"
                        backgroundColor="#444"
                        value={country}
                        setValue={setCountry}
                      />
                    </Flex>
                    <Flex
                      alignItems={{ md: "center" }}
                      flexDirection={{ sm: "column", md: "row" }}
                      paddingTop={{ sm: "5px", md: "25px" }}
                      marginRight="10px"
                    >
                      <Text
                        color="#fff"
                        fontFamily="Poppins"
                        fontWeight="500"
                        paddingBottom="8px"
                        width={{ md: "20%" }}
                      >
                        Zip Code
                      </Text>
                      <Input
                        onChange={(e) => {
                          if (e.target.value.length <= 8) {
                            setZipCode(e.target.value);
                          }
                        }}
                        value={zipCode}
                        padding="13px 15px!important"
                        border="0px!important"
                        variant="unstyled"
                        placeholder="Zip Code"
                        fontSize={{ base: "16px", xl: "18px" }}
                        color="#fff"
                        type="number"
                        fontFamily="Poppins"
                        background="#444"
                        _focusVisible={{
                          border: "0px solid #787878 !important",
                        }}
                        _placeholder={{
                          color: "#fff",
                          padding: "0px !important",
                        }}
                        required
                      />
                    </Flex>

                    {message && (
                      <Text
                        paddingTop="30px"
                        fontFamily="Poppins"
                        color="#5cb85c"
                        fontWeight="500"
                        minH="54px"
                        textAlign="center"
                      >
                        {message}
                      </Text>
                    )}
                    <Center paddingTop="30px">
                      <Button
                        onClick={profileDetailsUpdate}
                        color="#fff"
                        _hover={{ opacity: 0.7 }}
                        fontFamily="Poppins"
                        cursor="pointer"
                        bgColor="Red"
                        fontWeight="500"
                        fontSize="18px"
                        paddingY={{ base: "22px", lg: "25px" }}
                        width="227px"
                        marginX="10px"
                        colorScheme="Red"
                        isDisabled={buttonName == "Wait" ? true : false}
                      >
                        {buttonName}
                      </Button>
                    </Center>
                  </Box>
                )}
                {tab3 && (
                  <Box
                    padding={{ sm: "30px 10px", md: "50px 40px", xl: "50px" }}
                  >
                    <ChangePasswordSection />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Flex>
      </Container>
    </>
  );
}

export default ProfileEdit;
