import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import OTPVerification from "../../component/Forum/OTPVerification";



const OTPVerificationPage = () => {
  return (

    <MainWrapper>
      <OTPVerification />
    </MainWrapper>

  );
};

export default OTPVerificationPage;