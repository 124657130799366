import { Stack, Link } from "@chakra-ui/react";
import React from "react";
import MenuList from "./MenuList";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Button from "../Button/CustomeButton";
function Navigation({ isDrawerOpen, closeDrawer, user }) {
  return (
    <>
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={closeDrawer}>
        <DrawerOverlay />
        <DrawerContent className="dark_bg_menu">
          <DrawerCloseButton onClick={closeDrawer} />
          <DrawerBody>
            <Stack
              className="main_navigation mobileDropdown"
              color={"Black"}
              spacing={0}
              align="center"
              justify={["center", "space-between", "flex-start", "flex-start"]}
              direction={["column", "row", "row", "row"]}
              fontSize="xs2"
              display={{ base: "block", md: "block", lg: "none" }}
            >
              <MenuList closeDrawer={closeDrawer} />
              <Link
                href="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
                isExternal="targetBlank"
                textDecoration="none"
                _hover="textDecoration:none"
                marginTop="24px"
                className="_small-menu"
              >
                <Button className="Download_APP" title="Download APP" />
              </Link>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Stack
        className="main_navigation deskMenu"
        color={"white"}
        spacing={0}
        align="center"
        justify={["center", "space-between", "flex-start", "flex-start"]}
        direction={["column", "row", "row", "row"]}
        fontSize="xs2"
        display={{ base: "none", md: "none", lg: "block" }}
      >
        <MenuList />
      </Stack>
    </>
  );
}

export default Navigation;
