import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import FrontOfHouse from "../component/FrontOfHouse";


const FrontOfHousePage = () => {
  return (
    <MainWrapper>
      <FrontOfHouse />
    </MainWrapper>

  );
};

export default FrontOfHousePage;
