import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import OnlinePrograming from "../component/OnlinePrograming";


const OnlineProgramingPage = () => {
  return (
    <MainWrapper>
      <OnlinePrograming />
    </MainWrapper>

  );
};

export default OnlineProgramingPage;
