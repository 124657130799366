import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import WorkforPerpetua from "../component/WorkforPerpetua";


const WorkforPerpetuaPage = () => {
  return (
    <MainWrapper>
      <WorkforPerpetua />
    </MainWrapper>

  );
};

export default WorkforPerpetuaPage;
