import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import BlogListing from "../component/BlogListing";


const BlogListingPage = () => {
  return (
    <MainWrapper>
      <BlogListing />
    </MainWrapper>

  );
};

export default BlogListingPage;
