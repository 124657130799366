import {
  Flex,
  Box,
  Link,
  Image,
  Button,
  Text,
  ModalContent,
  ModalBody,
  MenuButton,
  MenuItem,
  Textarea,
  ModalFooter,
} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const image4 = styled(Image)((props) => ({
  width: "35px",
  height: "35px",
  borderRadius: "15px",
  marginLeft: "10px",
  cursor: "pointer",
}));

export const ModalForumContent = styled(ModalContent)((props) => ({
  alignSelf: "center",
  justifyContent: "center",
  display: "flex",
}));
export const ModalForumBody = styled(ModalBody)((props) => ({
  margin: "0",
  padding: "0",
  alignSelf: "center",
  borderRadius: "10px",
  width: "90%",
  backgroundColor: "#282828",
  [`@media ${device.tablet}`]: {
    maxWidth: "750px",
  },
}));
export const ModalForumFooter = styled(ModalFooter)((props) => ({
  margin: "0",
  padding: "0",
  alignSelf: "center",
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
  width: "90%",
  backgroundColor: "#282828",
  [`@media ${device.tablet}`]: {
    maxWidth: "750px",
  },
}));
export const createWrap = styled(Flex)((props) => ({
  padding: "8px 20px",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #ccc",
  [`@media ${device.tablet}`]: {
    padding: "15px 20px",
  },
}));
export const modalIconBg = styled(Flex)((props) => ({
  backgroundColor: "#fff",
  // width: '80px',
  // height: '35px',
  padding: "10px 15px",
  borderRadius: "8px",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "5px",
  cursor: "pointer",
}));

export const modalIconImg = styled(Box)((props) => ({
  fontSize: "13px",
  color: "#D12C41",
}));

export const modalIconTitle = styled(Text)((props) => ({
  color: "#D12C41",
  paddingLeft: "5px",
  textTransform: "capitalize",
  fontFamily: "Poppins",
  fontSize: "13px",
}));
export const CancelBtn = styled(Box)((props) => ({
  fontSize: "18px",
  color: "#000",
  cursor: "pointer",
}));

export const whatDoTextArea = styled(Textarea)((props) => ({
  height: "160px",
  fontSize: "18px",
  backgroundColor: "#444",
  color: "#fff!important",
  border: "none",
  fontFamily: "Poppins",
  cursor: "pointer",
  "::placeholder": {
    color: "#fff",
    fontSize: "18px",
    padding: "0",
    fontFamily: "Poppins",
  },
  ":focus-visible": {
    boxShadow: "none",
  },
}));
export const createBtn = styled(Button)((props) => ({
  fontSize: "14px",
  fontWeight: "500",
  color: "#fff",
  borderRadius: "5px",
  height: "40px",
  marginLeft: "auto",
  width: "120px",
  fontFamily: "Poppins",
  backgroundColor: "#D12C41",
  ":hover": {
    backgroundColor: "#D12C41",
  },
}));
export const everyoneBtn = styled(MenuButton)((props) => ({
  fontWeight: "500",
  fontSize: "14px",
  color: "grey",
  borderRadius: "20px",
  height: "30px",
  fontFamily: "Poppins",
  backgroundColor: "rgb(241 245 249)",
}));
export const BtnWrap = styled(Flex)((props) => ({
  paddingTop: "20px",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const ImageUploadBox = styled(Box)((props) => ({
  padding: "15px",
  width: "100%",
  height: "100%",
  position: "relative",
  maxHeight: "310px",
  "background-image": `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23777777FF' stroke-width='3' stroke-dasharray='16%2c 24%2c 12%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
}));

export const HiddenScrollBox = styled(Box)((props) => ({
  overflowY: "scroll",
  justifyContent: "center",
  padding: 0,
  ":-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none" /* IE and Edge */,
  "scrollbar-width": "none",
}));
