import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Mojo from "../component/Mojo";


const MojoPage = () => {
  return (
    <MainWrapper>
      <Mojo />
    </MainWrapper>

  );
};

export default MojoPage;
