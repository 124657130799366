import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Nutrition from "../component/Nutrition";


const NutritionPage = () => {
  return (
    <MainWrapper>
      <Nutrition />
    </MainWrapper>

  );
};

export default NutritionPage;
