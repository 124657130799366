import React, { useEffect, useState } from "react";
// import{Link} from 'react-router-dom';
import {
  Flex,
  Heading,
  Wrap,
  WrapItem,
  VStack,
  IconButton,
  useDisclosure,
  Link,
  Box,
} from "@chakra-ui/react";
import Navigation from "../Menu/Navigation";
import Button from "../Button/CustomeButton";
import { FiMenu } from "react-icons/fi";
import { defaultHeaderProps } from "./Header.type";
import ForumHeader from "./ForumHeader";
import { useNavigate } from "react-router-dom";

function Header(props) {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [user, setUser] = useState();
  const [userImage, setUserImage] = useState();
  const navigate = useNavigate();
  const headerHeight = document.getElementById("mainHeader");
  const token = localStorage.getItem("forum_token");
  useEffect(() => {
    const user = localStorage.getItem("forum_user");
    const image = localStorage.getItem("user_image");
    if (token) {
      if (user) {
        setUser(JSON.parse(user));
      }

      setUserImage(image ? image : null);
    }
  }, [navigate]);
  return (
    <Box paddingTop={headerHeight?.offsetHeight} backgroundColor="#000">
      <Flex
        bg={"black"}
        alignContent="space-between"
        width={"full"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingStart={{ sm: "10px", md: "50px" }}
        paddingEnd={{ sm: "5px", md: "50px" }}
        position="fixed"
        top="0"
        zIndex="1111"
      >
        {props.isLogo || props.isNavigation || props.isButton ? (
          <Wrap width="100%" id="mainHeader" className="headerWrap -hader">
            {props.isLogo && (
              <WrapItem
                maxW={{ base: "100%", md: "100%", lg: "15%", xl: "10%" }}
                flex="1 1 auto"
                textAlign={{ lg: "center", xl: "left" }}
                height={{ base: "70px", md: "70px", lg: "90px" }}
                alignItems={"center"}
                justifyContent={"left"}
                className="logo-section"
              >
                <Heading
                  className="_logo"
                  as={"h1"}
                  color={"white"}
                  fontSize={{ sm: "xs", md: "md", lg: "lg", xl: "lg" }}
                >
                  <Link href="/" _hover="textDecoration:none">
                    PERPETUA
                  </Link>
                </Heading>
              </WrapItem>
            )}

            <WrapItem
              maxW={{ base: "100%", md: "100%", lg: "83%", xl: "87%" }}
              flex="1 1 auto"
              textAlign={{ lg: "center", xl: "left" }}
              height={{ base: "70px", md: "70px", lg: "90px" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {props.isNavigation && (
                <VStack
                  align={{ md: "left" }}
                  width={{ sm: "50%", md: "92%" }}
                  paddingLeft="41px"
                >
                  <Navigation isDrawerOpen={isOpen} closeDrawer={onClose} />
                </VStack>
              )}
              {props.isButton && (
                <VStack align={{ md: "left" }} flexDirection="row">
                  <Link
                    href={props.link}
                    isExternal={props.targetBlank}
                    textDecoration="none"
                    _hover="textDecoration:none"
                  >
                    <Button className="Download_APP" title="Download APP" />
                  </Link>
                  <IconButton
                    icon={<FiMenu display={"inline-block"} fontSize="20px" />}
                    className="_togelbuttn"
                    // maxW="20px"
                    height="35px"
                    display={{
                      base: "inline-block",
                      md: "inline-block",
                      lg: "none",
                    }}
                    textAlign="center"
                    padding={"0"}
                    margin="0"
                    onClick={onToggle}
                  />
                </VStack>
              )}
              {token && (
                <ForumHeader
                  user={user}
                  image={userImage}
                  refresh={props?.refresh}
                />
              )}
            </WrapItem>
          </Wrap>
        ) : null}
      </Flex>
    </Box>
  );
}

export default Header;
Header.defaultProps = defaultHeaderProps;
