import * as React from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "../pages/AboutUsPage";
import CrossFitPage from "../pages/CrossFitPage";
import SweatPage from "../pages/SweatPage";
import JustGym from "../pages/JustGymPage";
import PersonalTraining from "../pages/PersonalTrainingPage";
import PostNatal from "../pages/PostNatalPage";
import WorkforPerpetua from "../pages/WorkforPerpetuaPage";
import ContactUs from "../pages/ContactPage";
import OnlinePrograming from "../pages/OnlineProgramingPage";
import Nutrition from "../pages/NutritionPage";
import Rumble from "../pages/RumblePage";
import Location from "../pages/LocationPage";
import RidePage from "../pages/RidePage";
import BlogListing from "../pages/BlogListingPage";
import TimeTable from "../pages/TimeTablePage";
import Pricing from "../pages/PricingPage";
import Studios from "../pages/StudiosPage";
import Mojo from "../pages/MojoPage";
import Application from "../pages/ApplicationPage";
import Highrox from "../pages/HighroxPage";
import TermsConditions from "../pages/TermsConditionsPage";
import Corporate from "../pages/CorporatePage";
import Membership from "../pages/MembershipPage";
import BlogDetails from "../pages/BlogDetailsPage";
import PrivacyPolicy from "../pages/PrivacyPolicyPage";
import TeamListing from "../pages/TeamListingPage";
import Podcast from "../pages/PodcastPage";
import Page404 from "../pages/Page404Page";
import Shop from "../pages/ShopPage";
import TeamDetails from "../pages/TeamDetailsPage";
import FrontOfHouse from "../pages/FrontOfHousePage";
import Facilitics from "../pages/FaciliticsPage";
import CrossfitCoach from "../pages/CrossfitCoachPage";
import LadiesGroup from "../pages/LadiesGroupPage";
import PrivateHire from "../pages/PrivateHirePage";
import ResidentialServices from "../pages/ResidentialServicesPage";
import InBody from "../pages/InBodyPage";
import SignUp from "../pages/SignUpPage";
import Iconic from "../pages/IconicPage";
import Cancellation from "../pages/CancellationPage";
import TemporarilyLanding from "../pages/TemporarilyLandingPage";
import ThankYou from "../pages/ThankYouPage";
import TreadandshredLandingpage from "../pages/TreadandshredLandingPage";
import RideFirsttimersLandingpage from "../pages/RideFirsttimersPage";
import RumbleLandingPage from "../pages/RumbleLandingPage";
import WindmillLocation from "../pages/WindmillLocationPage";
import LennoxstreetLocation from "../pages/LennoxstreetLocationPage";
import LandingPage from "../pages/LandingPage";
import UnbrokinLandingpage from "../pages/LandingPage/UnbrokinLandingPage";
import Ride45Page from "../pages/Ride45Page";
import JoinnowLandingPage from "../pages/JoinnowLandingPage";
import ResolutionLandingpage from "../pages/LandingPage/ResolutionPacksPage";
import HealthBar from "../pages/HealthBarPage";
import RideToRhythmride from "../pages/RideToRhythmridePage";
import Career from "../pages/CareerPage";
import ValentinesTakeover from "../pages/LandingPage/ValentinesTakeoverPage";
import UnbrokenPoweredByWhoop from "../pages/LandingPage/UnbrokenPoweredByWhoopPage";
import ProfilePage from "../pages/Forum/ProfilePage";
import Feeds from "../pages/Forum/FeedPage";
import FeedCategories from "../pages/Forum/FeedCategoriesPage"
import GalleryPage from "../pages/Forum/GalleryPage";
import ForumLogIn from "../pages/Forum/ForumLogInPage";
import ForumRegister from "../pages/Forum/ForumRegisterPage";
import ProfileEdit from "../pages/Forum/ProfileEditPage";

import HomePage from "../pages/HomePage";

import ForgotPassword from "../pages/Forum/ForgotPasswordPage";
import OTPVerification from "../pages/Forum/OTPVerificationPage";
import ResetPassword from "../pages/Forum/ResetPasswordPage";
import FirstTimers from "../pages/FirstTimersPage";
import Rumble12Rounds from "../pages/Rumble12RoundsPage";
import Golf from "../pages/GolfPage";
import OurStudios from "../pages/OurStudiosPage";
import StudioClassDescriptions from "../pages/StudioClassDescriptionsPage";
import NewStudios from "../pages/NewStudiosPage";
import EasterBunnyBundles from "../pages/LandingPage/EasterBunnyBundlesPage";
// import SingleFeedPage from "../pages/Forum/SingleFeedPage";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/treadandshred" element={<SweatPage />} />
      <Route path="/crossfit-dublin" element={<CrossFitPage />} />
      <Route path="/luxury-gym-dublin-city-centre" element={<JustGym />} />
      <Route path="/personal-training-dublin" element={<PersonalTraining />} />
      <Route path="/pregnancy-exercise-dublin" element={<PostNatal />} />
      <Route path="/work-for-perpetua" element={<WorkforPerpetua />} />
      <Route path="/online-gym-programming" element={<OnlinePrograming />} />
      <Route path="/nutrition-coaching-dublin" element={<Nutrition />} />
      <Route path="/location" element={<Location />} />
      <Route path="/rumble-boxing" element={<Rumble />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/rhythmride" element={<RidePage />} />
      <Route path="/ride45" element={<Ride45Page />} />
      <Route path="/blog" element={<BlogListing />} />
      <Route path="/blog/category/:category_id" element={<BlogListing />} />
      <Route path="/blog/search" element={<BlogListing />} />
      <Route path="/timetable" element={<TimeTable />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/studios" element={<Studios />} />
      <Route path="/mojo" element={<Mojo />} />
      <Route path="/application" element={<Application />} />
      <Route path="/hyrox-dublin" element={<Highrox />} />
      <Route path="/terms-condition" element={<TermsConditions />} />
      <Route path="/corporate-wellness" element={<Corporate />} />
      <Route path="/membership" element={<Membership />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/blog/:slug" element={<BlogDetails />} />
      <Route path="/podcast" element={<Podcast />} />
      <Route path="*" element={<Page404 />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/team" element={<TeamListing />} />
      <Route path="/team/:slug" element={<TeamDetails />} />
      <Route path="/font-of-house" element={<FrontOfHouse />} />
      <Route path="/facilitics" element={<Facilitics />} />
      <Route path="/crossfit-coach" element={<CrossfitCoach />} />
      <Route path="/ladies-gym-dublin" element={<LadiesGroup />} />
      <Route path="/private-gym-hire-dublin" element={<PrivateHire />} />
      <Route path="/residential-services" element={<ResidentialServices />} />
      <Route path="/inbody" element={<InBody />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/iconic" element={<Iconic />} />
      <Route path="/cancellation" element={<Cancellation />} />
      <Route path="/summer-party" element={<TemporarilyLanding />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route
        path="/treadandshred-first-timers"
        element={<TreadandshredLandingpage />}
      />
      <Route
        path="/ride-first-timers"
        element={<RideFirsttimersLandingpage />}
      />
      <Route path="/rumble-first-timers" element={<RumbleLandingPage />} />
      <Route path="/windmill-lane" element={<WindmillLocation />} />
      <Route path="/lennox-street" element={<LennoxstreetLocation />} />
      <Route path="/join-the-guest-list" element={<LandingPage />} />
      <Route
        path="/unbroken-powered-by-lululemon"
        element={<UnbrokinLandingpage />}
      />
      <Route path="/join-today" element={<JoinnowLandingPage />} />
      <Route path="/resolution-packs" element={<ResolutionLandingpage />} />
      <Route path="/ride" element={<RideToRhythmride />} />
      <Route path="/what-we-offer/health-bar" element={<HealthBar />} />
      <Route path="/careers" element={<Career />} />
      <Route path="/valentines-takeover" element={<ValentinesTakeover />} />
      <Route
        path="/unbroken-powered-by-whoop"
        element={<UnbrokenPoweredByWhoop />}
      />
      <Route
        path="/unbroken-powered-by-whoop"
        element={<UnbrokenPoweredByWhoop />}
      />
      <Route path="/first-timers" element={<FirstTimers />} />
      <Route path="/rumble-12-rounds" element={<Rumble12Rounds />} />
      <Route path="/golf-fitness-training-dublin" element={<Golf />} />
      <Route path="/our-studios" element={<OurStudios />} />
      <Route
        path="/studio-class-descriptions"
        element={<StudioClassDescriptions />}
      />
      <Route path="/easter-bunny-bundles" element={<EasterBunnyBundles />} />
      <Route path="/fitness-classes-dublin" element={<NewStudios />} />

      {/* Forum */}
      <Route path="/forum/feeds" element={<Feeds />} />
      <Route path="/forum/gallery/:id" element={<GalleryPage />} />
      <Route path="/forum/feeds/category/:slug" element={<FeedCategories />} />
      <Route path="/forum/login" element={<ForumLogIn />} />
      <Route path="/forum/registration" element={<ForumRegister />} />
      <Route path="/forum/profile/:id" element={<ProfilePage />} />
      {/* <Route path="/forum/feed/:id" element={<SingleFeedPage />} /> */}
      <Route path="/forum/profile-edit" element={<ProfileEdit />} />
      <Route path="/forum/forgot-password" element={<ForgotPassword />} />
      <Route path="/forum/verify-otp" element={<OTPVerification />} />
      <Route path="/forum/reset-password" element={<ResetPassword />} />
    </Routes>
  );
};
