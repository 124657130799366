import React, { useEffect, useState } from "react";
import { Flex, Text, Link, Box } from "@chakra-ui/react";
import { Link as navLink, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginRegisterWrapper from "../../component/Forum/LoginRegister/LoginRegisterWrapper";
import {
  FormButton,
  PasswordInput,
  TextInput,
} from "../../component/Forum/LoginRegister/Inputs";
const ForumRegister = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [ConfirmPasswordError, setConfirmPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const submit = () => {
    if (
      !name ||
      !email ||
      !phone ||
      !Password ||
      !ConfirmPassword ||
      Password !== ConfirmPassword
    ) {
      if (!name) {
        setNameError("The name field is required.");
      } else {
        setNameError("");
      }
      if (!email) {
        setEmailError("The email field is required.");
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          setEmailError("Please enter a valid email address.");
        } else {
          setEmailError("");
        }
      }
      if (!phone) {
        setPhoneError("The phone field is required.");
      } else {
        setPhoneError("");
      }
      if (!Password) {
        setPasswordError("The password field is required.");
      } else if (Password.length < 8) {
        setPasswordError("Password must be at least 8 characters long.");
      } else {
        setPasswordError("");
      }
      if (!ConfirmPassword) {
        setConfirmPasswordError("The confirm password field is required.");
      } else {
        setConfirmPasswordError("");
      }
      if (Password !== ConfirmPassword) {
        setConfirmPasswordError("The confirm password not matched.");
      }
    } else {
      setIsLoading(true);
      console.log("submit");
      axios
        .post(
          process.env.REACT_APP_FORUM_API_BASE_URL + "/register",
          {
            name: name,
            email: email,
            phone: phone,
            password: Password,
            password_confirmation: ConfirmPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log("-------response----", response);
          const user = response.data.user;
          localStorage.setItem("forum_user_id", user.id);
          localStorage.setItem("forum_user", JSON.stringify(user));
          localStorage.setItem("forum_token", response.data._token);
          setName("");
          setEmail("");
          setPhone("");
          setPassword("");
          setConfirmPassword("");
          navigate("/forum/feeds");
        })
        .catch((error) => {
          console.log("error", error);
          if (error?.response?.data?.error) {
            if (error?.response?.data?.error?.name) {
              setNameError(error?.response?.data?.error.name[0]);
            } else {
              setNameError("");
            }
            if (error?.response?.data?.error?.email) {
              setEmailError(error?.response?.data?.error?.email[0]);
            } else {
              setEmailError("");
            }
            if (error?.response?.data?.error?.phone) {
              setPhoneError(error?.response?.data?.error?.phone[0]);
            } else {
              setPhoneError("");
            }
            if (error?.response?.data?.error?.password) {
              setPasswordError(error?.response?.data?.error?.password[0]);
            } else {
              setPasswordError("");
            }
            if (error?.response?.data?.error?.password_confirmation) {
              setConfirmPasswordError(
                error?.response?.data?.error?.password_confirmation[0]
              );
            } else {
              setConfirmPasswordError("");
            }
            setMessage("");
          } else {
            if (error?.response?.data?.message) {
              setMessage(error?.response?.data?.message);
            } else {
              setMessage("somthing went wrong");
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // }
  };
  useEffect(() => {
    const token = localStorage.getItem("forum_token");
    if (token) {
      navigate("/forum/feeds");
    } else {
      localStorage.setItem("forum_token", "");
    }
  }, []);
  return (
    <LoginRegisterWrapper name="Sign Up">
      <TextInput
        setValue={setName}
        name="Name"
        error={nameError}
        setError={setNameError}
      />

      <TextInput
        setValue={setEmail}
        name="Email"
        type="email"
        error={emailError}
        setError={setEmailError}
      />
      <TextInput
        setValue={setPhone}
        name="Phone"
        type="number"
        error={phoneError}
        setError={setPhoneError}
      />
      <PasswordInput
        setValue={setPassword}
        error={PasswordError}
        setError={setPasswordError}
      />
      <PasswordInput
        setValue={setConfirmPassword}
        name="Confirm Password"
        error={ConfirmPasswordError ? ConfirmPasswordError : message}
        setError={setConfirmPasswordError}
      />
      <FormButton onClick={submit} name="Sign Up" isDisabled={isLoading} />
      <Flex justifyContent={"center"}>
        <Text
          fontFamily={"Poppins"}
          color="blackAlpha.700"
          fontSize={{ base: "13px", md: "14px" }}
          paddingRight={"5px"}
        >
          Already have an account?
        </Text>
        <Link
          to="/forum/login"
          as={navLink}
          color="blackAlpha.900"
          fontFamily={"Poppins"}
          fontSize={{ base: "13px", md: "14px" }}
          _hover={{ textDecoration: "none", color: "Red" }}
          textDecoration="underline"
        >
          Login
        </Link>
      </Flex>
    </LoginRegisterWrapper>
  );
};

export default ForumRegister;
