import React, { useEffect } from "react";
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import Heading from "./Heading/Heading";
// import { Box, Stack, Container, Flex, SimpleGrid, VStack, ListItem, UnorderedList, Link, Image, GridItem, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import {
  Box,
  Stack,
  Container,
  Flex,
  SimpleGrid,
  VStack,
  Link,
  Image,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import Text from "./SubHeading/SubHeading";
import WhatsincludedCard from "./WhatsIncluded Card/WhatsincludedCard";
import Testimonial from "./Testimonial/Testimonial";
import PriceBox from "./Price Component/PriceBox";
import MarqueLoop from "./Marque Loop/MarqueLoop";
import CfProgram from "./CF Program/CfProgram";
import ContactForm from "./Form/ContactForm";
import LinkButton from "./Button/LinkButton";
import Button from "./Button/CustomeButton";
// import{Link} from 'react-router-dom';
import WhyMembershipCard from "./WhyMemberShip/WhyMembershipCard";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";
import VideoSection from "./VideoSection/VideoSection";
import VideoBanner from "./VideoBanner/VideoBanner";

const metaTitle =
  "Luxury Gym Dublin | State of the Art Facility | Perpetua Fitness";
const metaDesc =
  "JustGym at Perpetua Fitness. State of the art training facility in Dublin. We are open 7 days a week. You can train anytime you like. Book a consult.";

function JustGym(props) {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  const marqueItem = [
    "Community",
    "Facility",
    "Access",
    "Programs",
    "Friendly",
    "JustGym",
  ];

  // const getStartedData = [
  //   {
  //     id: '1',
  //     title: 'POP INTO OUR FACILITY',
  //     description: 'We would love to meet you at our facility and show you around our facility. We can set up your membership for you or you can purchase a membership by downloading the Perpetua Fitness app',
  //   },
  //   {
  //     id: '2',
  //     title: 'SET UP THE INTRODUCTION',
  //     description: 'Prior to starting your first session, a member of Perpetua can bring you through all of the equipment in the JustGym so you can understand how to use everything correctly.'
  //   },
  //   {
  //     id: '3',
  //     title: 'FOLLOW OUR ONLINE TRAINING PROGRAMS',
  //     description: 'Next Steps? Follow one of our online training programs which you can get a 14 day free trial to before commiting to the program. These programs are designed by our Head Coach Eoghan McGregor and are suitable for all fitness levels.',
  //   },
  // ]

  // const whatsIncludedData = [
  //   {
  //     id: '1',
  //     title: 'UNLIMITED JUSTGYM ACCESS',
  //     description: 'Train anytime you like.',
  //     image: '/images/weight-icon.svg'

  //   },
  //   {
  //     id: '2',
  //     title: '14 DAY FREE TRIAL FOR ONLINE PROGRAMMING',
  //     description: 'Follow one of our online training programs which you can get a 14 day free trial to before commiting to the program.',
  //     image: '/images/justgym-access.svg'
  //   },
  //   {
  //     id: '3',
  //     title: 'EQUIPMENT & FACILITY WALK THROUGH',
  //     description: 'Prior to starting your first session, a member of Perpetua can bring you through all of the equipment in the JustGym so you can understand how to use everything correctly.',
  //     image: '/images/Specialclasses.svg'
  //   },
  //   {
  //     id: '4',
  //     title: 'SHOWER AND TOWEL SERVICES ',
  //     description: ' If you want to work on your mobility, running technique or olympic lifting skills, we have you covered with our speciality classes which happen throughout the week.',
  //     image: '/images/justgym-access.svg'
  //   }
  // ]

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* <InnerBanner
        backgroundColor='black'
        backgroundImage={{ sm: 'url(./images/justgym_m.jpg)', md: 'url(./images/Justgym-banner.jpg)' }}
        title='JUST GYM'
        subtitle="STATE OF THE ART TRAINING FACILITY"
        SubtextVariant='smallmid'
        issubtextpara={true}
        subtextpara='State of the art training facility'
        isButton={true}
        issubtitleRight={true}
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane Facility</Box>}
        isSubtext={false}
        buttontitle="GET STARTED"
        width={{ sm: "100%" }}
        height={{ sm: '450px', md: '500px', lg: '550px', xl: '767px' }}
        margin="10px 0 0"
        Link="/contact-us"
        ButtonModalmarginTop='10px'
        videoModal='https://www.youtube.com/embed/pBP-UGi7jxI'
      ></InnerBanner> */}

      <VideoBanner
        videobanner="../video/Justgym-banner.mp4"
        subtitle="LUXURY GYM DUBLIN CITY CENTRE"
        title="JUST GYM"
        subtitleRight={
          <Box display="inline-block">
            <Image
              src="../images/map-white.svg"
              display="inline-block"
              top="12px"
              position="relative"
            ></Image>
            Available at our Windmill Lane Facility
          </Box>
        }
        isButton={true}
        issubtextpara={false}
        buttontitle="Book a consult"
        margin="0px 0 0"
        Link="/join-today"
        videoModal="https://www.youtube.com/embed/pBP-UGi7jxI"
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={true}
      />

      <Flex backgroundColor="black" padding="55px 0px 0px">
        <Container maxW="container.xl">
          <MarqueLoop marqueItem={marqueItem}></MarqueLoop>
        </Container>
      </Flex>
      <Flex backgroundColor="black" padding="100px 0px 60px">
        <Container maxW="container.xl">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            textAlign={{ base: "left", lg: "left" }}
            alignItems="center"
          >
            <Box
              w={{ sm: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                currency="€"
                priceValue="89"
                time="/mo Full Access"
                description="Arrange a call with our Head of JustGym to get our induction to our equipment"
                buttonText="BUY NOW"
                link="/pricing"
                issubHeading={false}
                width={{ base: "100%", sm: "70%" }}
                border="2px solid"
                borderColor="Red"
                buttonPading="20px 25px"
                manheadind="Starting"
              ></PriceBox>
            </Box>
            <Box
              w={{ sm: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <Heading
                title="WHATS INCLUDED "
                variant="small"
                color="white"
                margin="0px 0px 30px"
                padding={{ sm: "0px 0px 0px 0px", md: "0px 0px 0px 31px" }}
                textAlign={{ sm: "center", ms: "left" }}
              ></Heading>
              <SimpleGrid
                columns={{ base: "1", lg: "2" }}
                spacing="0px"
                className="_whatsinclude_box_body"
              >
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    Imguri="/images/gym.svg"
                    title="FULL ACCESS TO THE JUST GYM AREA"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    Imguri="/images/gym.svg"
                    title="PRIVATE INTRODUCTION TO THE GYM"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    Imguri="/images/gym.svg"
                    title="FREE 14 DAY TRIAL TO OUR ONLINE PROGRAMMING"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    Imguri="/images/gym.svg"
                    title="SHOWER AND TOWEL SERVICES"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="30px"
            textAlign={{ base: "left", lg: "left" }}
            alignItems="center"
            marginTop={{ sm: "50px", md: "50px" }}
            borderTop="1px solid  rgba(255,255,255,0.15)"
            paddingTop="50px"
          >
            <Box
              w={{ sm: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <PriceBox
                color="white"
                manheadind="Starting"
                currency="€"
                priceValue="199"
                time=" /mo JustGYM+8 Studio Credits"
                description="Arrange a call with our Head of JustGYM to get an induction to our equipment"
                buttonText="BUY NOW"
                link="/pricing"
                issubHeading={false}
                width={{ base: "100%", sm: "70%" }}
                border="2px solid"
                borderColor="Red"
                buttonPading="20px 25px"
              ></PriceBox>
            </Box>
            <Box
              w={{ sm: "100%", lg: "50%" }}
              padding={{ md: "0px 20px", lg: "0px 0px" }}
            >
              <Heading
                title="WHATS INCLUDED "
                variant="small"
                color="white"
                margin="0px 0px 30px"
                padding={{ sm: "0px 0px 0px 0px", md: "0px 0px 0px 31px" }}
                textAlign={{ sm: "center", ms: "left" }}
              ></Heading>
              <SimpleGrid
                columns={{ base: "1", lg: "2" }}
                spacing="0px"
                className="_whatsinclude_box_body"
              >
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    Imguri="/images/gym.svg"
                    title="FULL ACCESS TO THE JUST GYM AREA"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    Imguri="/images/gym.svg"
                    title="PRIVATE INTRODUCTION TO THE GYM"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    Imguri="/images/gym.svg"
                    title="Shower and Towel Services"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    alt="Luxury Gym Dublin- arrow icon"
                  ></WhatsincludedCard>
                </Box>
                <Box
                  className="_whatsinclude_box"
                  padding={{ base: "15px 10px", xl: "20px" }}
                >
                  <WhatsincludedCard
                    Imguri="/images/gym.svg"
                    title="8 Studio Credits per month"
                    headingColor="white"
                    buttonColor="white"
                    ButtonMargin="0px 0px 0px auto"
                    padding="0px"
                    spacing={props.padding}
                    alt="Luxury Gym Dublin - arrow icon"
                  ></WhatsincludedCard>
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>
      {/* 
      <Container maxW='container.xl'>
        <Box padding={{base:'50px 0px', lg:'70px 0px 100px',xl:'130px 0px 200px'}}>
          <Heading 
            variant="extralarge" 
            title='Get Started With Perpetua' 
            margin={{sm:'0px auto 10px' ,md:'0px auto 86px'}} 
            width={{base:'90%',md:'70%'}}
            textAlign='center'
            lineHeight='1'
            >

          </Heading>
          <GetstartedCardfullWidth 
            position='absolute' 
            textAlign='right' 
            cardwidth={{base:'90%', md:'80%'}}
            right={{base:'-35px',md:'-50px'}}  
            cardposition="relative"
            minHeight='auto'
            Number='1'
            Heading='POP INTO OUR FACILITY'
            Paragraph='We would love to meet you at our facility and show you around our facility. 
            We can set up your membership for you or you can purchase a membership by downloading the Perpetua Fitness app'
            padding={{base:'20px 50px 20px 20px', md:'50px 140px 50px 40px'}}
            iconBg='Red'
            iconOutline={{base:'0px 0px 0px 6px rgba(209,44,65,0.3)', md:'0px 0px 0px 12px rgba(209,44,65,0.3)'}}
            cardMargin={'50px auto 0px 0px'}
            top='0'
            bottom='0'
            IconMargin='auto'
            >
          </GetstartedCardfullWidth>
          <GetstartedCardfullWidth 
            position='absolute' 
            textAlign='left' 
            cardwidth={{base:'90%', md:'80%'}} 
            left={{base:'-35px',md:'-50px'}}  
            cardposition="relative"
            minHeight='auto'
            Number='2'
            Heading='SET UP THE INTRODUCTION'
            Paragraph='Prior to starting your first session, a member of Perpetua can bring you through all of the equipment in the JustGym so 
            you can understand how to use everything correctly.'
            padding={{base:'20px 40px 20px 50px', md:'50px 40px 50px 140px'}}
            iconBg='Red'
            iconOutline={{base:'0px 0px 0px 6px rgba(209,44,65,0.3)', md:'0px 0px 0px 12px rgba(209,44,65,0.3)'}}
            cardMargin='50px 0px 0px auto'
            IconMargin='auto'
            top='0'
            bottom='0'
            >
          </GetstartedCardfullWidth>
          <GetstartedCardfullWidth 
            position='absolute' 
            textAlign='right' 
            cardwidth={{base:'90%', md:'80%'}} 
            right={{base:'-35px',md:'-50px'}} 
            cardposition="relative"
            minHeight='auto'
            Number='3'
            Heading='FOLLOW OUR ONLINE TRAINING PROGRAMS'
            Paragraph='Next Steps? Follow one of our online training programs which you can get a 14 day 
            free trial to before commiting to the program. These programs are designed by our Head Coach Eoghan McGregor and are suitable for all fitness levels.'
            padding={{base:'20px 50px 20px 20px', md:'50px 140px 50px 40px'}}
            iconBg='Red'
            iconOutline={{base:'0px 0px 0px 6px rgba(209,44,65,0.3)', md:'0px 0px 0px 12px rgba(209,44,65,0.3)'}}
            cardMargin='50px auto 0px 0px'
            IconMargin='auto'
            top='0'
            bottom='0'
            >
          </GetstartedCardfullWidth>
        </Box>
      </Container> */}

      {/* <WhyChoose title='GET STARTED WITH PERPETUA' data={getStartedData} padding={{sm:'60px 15px 20px' ,md:'70px 15px 60px'}}/> */}
      {/* <WhyChoose title='WHATS INCLUDED IN OUR MEMBERSHIP' data={whatsIncludedData} padding={{sm:'10px 15px 20px ' ,md:'10px 15px 40px'}} />      */}
      {/* <WhyMembership title='WHATS INCLUDED IN OUR MEMBERSHIP' data={whatsIncludedData} padding={{ sm: '60px 30px 0px', md: '80px 30px 0px' }} /> */}
      {/*  */}
      <Box
        padding={{ sm: "0px 0px 50px", md: "30px 0px 100px" }}
        backgroundColor="black"
      >
        <Container maxW="container.xl">
          <Heading
            variant="extralarge"
            title="How to get started"
            margin={{ sm: "0px auto 50px", md: "0px auto 86px" }}
            width={{ base: "90%", md: "60%" }}
            textAlign="center"
            lineHeight="1"
            color="white"
          />
          <SimpleGrid
            columns={{ sm: "1", md: "3" }}
            spacing={5}
            textAlign="center"
            justifyContent="center"
          >
            <GridItem>
              <Heading title="01" color="Red" textAlign="center" />
              <Heading
                title="POP INTO OUR FACILITY"
                color="white"
                variant="small"
                padding={{ sm: "15px 20px", md: "15px 20px 55px" }}
              />
              <Text
                title="We would love to meet you at our Windmill facility and show you around our facility. We can then set up your membership or you can purchase a membership by downloading the Perpetua Fitness App."
                color="white"
                padding="15px 20px"
              />
            </GridItem>
            <GridItem>
              <Heading title="02" color="Red" textAlign="center" />
              <Heading
                title="SET UP THE INTRODUCTION"
                color="white"
                variant="small"
                padding={{ sm: "15px 20px", md: "15px 20px 55px" }}
              />
              <Text
                title="Prior to starting your first session, a member of Perpetua can bring you through all of the equipment in the JustGym so you can understand how to use everything correctly."
                padding="15px 20px"
                color="white"
              />
            </GridItem>
            <GridItem>
              <Heading title="03" color="Red" textAlign="center" />
              <Heading
                title="FOLLOW OUR ONLINE TRAINING PROGRAMS"
                color="white"
                variant="small"
                padding="15px 20px"
              />
              <Text
                title="Next Steps? Follow one of our online training programs where you can get a 14 day free trial before commiting to the program. These programs are designed by our Head Coach Eoghan McGregor and are suitable for all fitness levels."
                color="white"
                padding="15px 20px"
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Container
        maxW="container.xl"
        padding={{ sm: "60px 30px 0px", md: "80px 30px 0px" }}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing="30px"
          textAlign={{ base: "left", lg: "left" }}
          alignItems="top"
          paddingBottom={{ sm: "40px", md: "80px" }}
        >
          <Box
            w={{ lg: "50%" }}
            position={{ base: "relative", md: "sticky" }}
            top={{ base: "0", md: "100px" }}
            height={{ base: "auto", md: "150px" }}
            textAlign={{ sm: "center", md: "left" }}
          >
            <Heading
              title="WHATS INCLUDED IN OUR MEMBERSHIP"
              lineHeight="1"
            ></Heading>
          </Box>
          <Box width={{ md: "50%", lg: "50%" }}>
            <SimpleGrid columns={{ sm: "1", lg: "2" }} spacing={8}>
              <WhyMembershipCard
                Imguri="/images/check.png"
                title="UNLIMITED JUSTGYM ACCESS"
                paragraph="We are open 7 days a week and with your JustGym membership you can train anytime you like. Get ready to enjoy your training session and experience a facility which has everything you need to achieve your goals
"
              />
              <WhyMembershipCard
                Imguri="/images/check.png"
                title="14 DAY FREE TRIAL FOR ONLINE PROGRAMMING "
                paragraph="With years of experience and coaching thousands of members, the team at CrossFit Dublin have developed programs to suit all fitness levels. You can start any of the programs with a 14 day free trial.
"
              />
              <WhyMembershipCard
                Imguri="/images/check.png"
                title="EQUIPMENT & FACILITY WALK THROUGH "
                paragraph="Prior to starting your first session, a member of Perpetua can bring you through all of the equipment in the JustGym so you can understand how to use everything correctly."
              />
              <WhyMembershipCard
                Imguri="/images/check.png"
                title="SHOWER AND TOWEL SERVICES "
                paragraph="We have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)
"
              />
            </SimpleGrid>
          </Box>
        </Stack>
      </Container>

      <VideoSection
        heading="A Premium"
        headingSub="training experience"
        video1="https://youtube.com/embed/T4iPSAYkivo?si=grUvWuNR1y17YwMV"
        video2="https://youtube.com/embed/pBP-UGi7jxI?si=lIWQERQEXDm1CfUm"
        smallHeading="Take a tour"
        smallHeading2="Whats Included"
        midHeading=""
        midHeading2=""
      />

      {/*  */}
      <Flex
        flexDirection="column"
        padding={{ sm: "10px 0px ", md: "20px 0px" }}
        marginTop="50px"
        position="relative"
        /* _after={{position:'absolute',content:'""',width:'554px', height:'464px', top:'0',left:'0',background:'LightGrey',zIndex:'-1'}}
      _before={{position:'absolute',content:'""',width:'554px', height:'464px', top:'auto', bottom:'0',right:'0',background:'LightGrey',zIndex:'-1',margin:'auto'}} */
      >
        <Container maxW="container.xl">
          <Testimonial
            headingWidth={{ sm: "97%", md: "90%" }}
            iconUri="/images/comment-quote-outline.svg"
            isname={true}
            title="“THE JUSTGYM FACILITY HAS EVERYTHING I NEED TO GET A GREAT SESSION IN. I ALSO FOUND THE INITIAL CONSULTATION VERY HELPFUL SO I UNDERSTOOD HOW TO USE ALL THE MACHINES”"
            name="- JUSTGYM MEMBER"
          />
        </Container>
      </Flex>

      <Container
        maxW={{
          sm: "Container.sm",
          md: "container.md",
          lg: "container.lg",
          xl: "container.xl",
        }}
        p={{ sm: "50px 15px 30px", md: "90px 15px 70px" }}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing="30px"
          textAlign={{ base: "left", lg: "left" }}
          alignItems="top"
        >
          <Box
            w={{ base: "100%", lg: "50%" }}
            position={{ base: "relative", md: "sticky" }}
            top={{ base: "0", md: "100px" }}
            height={{ base: "auto", md: "522px", lg: "580px" }}
          >
            <Heading
              as="h2"
              title="OUR ONLINE PROGRAMS"
              variant="extralarge"
              margin="0px 0px 40px"
              lineHeight="1"
              width={{ sm: "100%", md: "80%" }}
            ></Heading>
            {/* <Box 
                w={{sm:'100%',md:'400px',lg:'485px'}} 
                h={{sm:'300px',md:'400px',lg:'458px'}} 
                backgroundImage='url(../images/CF-program.jpg)' 
                position='relative' 
                backgroundRepeat='no-repeat'
                zIndex='1'
                border={{sm:'8px solid white',md:'14px solid white'}}
                >

                </Box> */}
          </Box>
          <Box
            w={{ base: "100%", lg: "50%" }}
            padding={{ sm: "0", lg: "0px 40px" }}
          >
            <Heading
              as="h5"
              title="WE KNOW THAT PROGRAMMING IS NOT ONE SIZE FITS ALL"
              variant="smallmid"
              margin="0px 0px 15px"
            ></Heading>
            <Text
              as="p"
              title="With years of experience and coaching thousands of members, the team at CrossFit Dublin have developed 
              programs to suit all fitness levels. You can start any of the programs with a 14 day free trial."
            ></Text>

            <VStack spacing={2} align="stretch" width={"full"} marginTop="25px">
              <Stack p="0px 0px 50px" borderBottom="1px solid #D9D9D9">
                <CfProgram title="Perpetua Strength & Conditioning"></CfProgram>
                <Text
                  title="Perpetua Strength & Conditioning is our signature program which has helped 1000’s of members. This program is designed for individuals who have previous experience in compound lifting and conditioning.
                 This is for people who enjoy a mixture of strength and cardio training, and need to keep their training window to 60 min or less. In our 60 path, we structure your workout from warmup to cool down to allow you to be as productive as possible. The training itself will look like classic Crossfit. A balance of strength work, skill progressions, mixed modal testers while avoiding heavy Olympic Lifting. "
                  margin="0px 0px 15px"
                  color="Grey"
                ></Text>
              </Stack>

              <Stack p="50px 0px 50px" borderBottom="1px solid #D9D9D9">
                <CfProgram title="Perpetua Endurance"></CfProgram>
                <Text
                  title="Our endurance days in Perpetua Fitness are one of our most popular days with members and now we are delivering this to individuals online. "
                  margin="0px 0px 15px"
                  color="Grey"
                ></Text>
                <Text
                  title="This program is for people who want to develop a broad endurance base across multiple mono-structural modalities. Following this path should provide the preparation and confidence necessary to be able to jump into any conditioning event/workout."
                  margin="0px 0px 15px"
                  color="Grey"
                ></Text>
              </Stack>

              <Stack p="50px 0px 50px">
                <CfProgram title="Perpetua Build"></CfProgram>
                <Text
                  title="Our endurance days in Perpetua Fitness are one of our most popular days with members and now we are delivering this to individuals online. "
                  margin="0px 0px 15px"
                  color="Grey"
                ></Text>
                <Text
                  title="Not every training day needs high intensity so this is where our Perpetua Build program can be perfect for you. Perpetua Build is 
                  a functional strength and full-body bodybuilding program only available at Perpetua Fitness! Workouts trade out intensity for a little more burn and aesthetics. Each day will consist of functional strength
                   followed by a bodybuilding session. The goal of Perpetua Build is simple – all gains, zero stress"
                  margin="0px 0px 15px"
                  color="Grey"
                ></Text>
              </Stack>
            </VStack>
          </Box>
        </Stack>
      </Container>

      <Flex
        flexDirection="column"
        padding={{ sm: "40px 0px 45px", md: "100px 0px 100px" }}
        textAlign="center"
        justifyContent="center"
        justifyItems="center"
        background="#eee"
      >
        <Container maxW="container.xl">
          <Box>
            <Heading
              as="h2"
              title="14 day free trial"
              variant="extralarge"
              textAlign="center"
              lineHeight="1"
              padding={{ base: "0px 0px 15px", sm: "0px 0px 30px" }}
            ></Heading>
            <Text
              title="Trial our online programs for free for 14 days"
              as="p"
            ></Text>
            <Link
              href="online-gym-programming"
              _hover={{ textDecoration: "none" }}
            >
              <Button
                title="Get Started"
                color="black"
                margin="40px 0px 0px"
                className="wow animate fadeInUp"
              />{" "}
            </Link>
            {/* <Box >
              <Heading 
                as="h5" 
                title="Down our app "
                variant='extrasmall'
                textAlign="center"
                lineHeight="1"
                padding={{base:'15px 0px 0px', sm:'30px 0px 0px' }}
                display='inline-block'
              >
                
              </Heading>

              <Link to={props.Link} >
                <Heading 
                as="h5" 
                title="Here"
                variant='extrasmall'
                textAlign="center"
                lineHeight="1"
                padding={{base:'15px 0px 0px', sm:'30px 0px 0px' }}
                display='inline-block'
                margin='0px 0px 0px 5px'
                color='Red'
                textDecoration='underline'
              ></Heading>
              </Link>
              </Box> */}
          </Box>
        </Container>
      </Flex>

      {/* <Flex 
        flexDirection={{sm:'column',lg:'row' }}
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
        backgroundColor='black' padding={{sm:'50px 0px', md:'60px 0px'}} 
        marginBottom={{sm:'50px', md:'195px'}}
      >
        <Container maxW='container.xl'>
          <Box position='relative' zIndex='1'>
              <SimpleGrid columns={{sm:'1',md:'2'}} spacing={5} >
                <Box padding='60px 0px ' position='relative' height={{base:'auto',md:'250px', lg:'250px', xl:'250px'}}
                _after={{w:'65px', h:'50px',position:'absolute', content:'""',top:'0', left:'0', backgroundImage:'url(../images/left-quote.svg)'}}
                _before={{w:'65px', h:'50px',position:'absolute', content:'""',bottom:'0', right:'0', top:'auto', backgroundImage:'url(../images/right-quote.svg)' }}
                >
                    <Heading as='h3' title='The facility has everything needed to train and get the results I want' color='white' variant='medium' lineHeight='1'></Heading>
                </Box>
                <Box padding={{ base:'30px 20px',xl:'50px 60px'}} border='2px solid' borderColor='Red' borderRadius='20px' >
                <PriceBox 
                    color='white'
                    currency='€'
                    priceValue='199'
                    time='/mo'
                    isButton={false}
                    link='/about'
                    buttonPading='20px 25px'
                    issubHeading={true}
                    width={{base:'100%', sm:'70%'}}
                    isDescription={false}
                    subheading='WHAT DO YOU GET'
                    border='2px solid'
                    borderColor='Red'
                  >
                  </PriceBox>
                  <UnorderedList p='0' m='40px 0px 0px'listStyleType='none' color='white' fontFamily='Poppins' fontWeight='300' >
                    <ListItem borderTop='1px solid #2E2E2E' padding='15px 0px' position='relative' paddingLeft="30px"
                      _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                    >
                      UNLIMITED JUSTGYM ACCESS  
                    </ListItem>

                    <ListItem borderTop='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                    _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                    >
                      FACILITY INTRODUCTION  
                    </ListItem>

                    <ListItem borderTop='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                    _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                    >
                      14 DAY FREE TRIAL FOR ONLINE PROGRAMMING  
                    </ListItem>

                    <ListItem borderTop='1px solid #2E2E2E'borderBottom='1px solid #2E2E2E' padding='10px 0px'position='relative'paddingLeft="30px"
                    _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'21px', left:'0', background:'Red', borderRadius:'50px'}}
                    >
                    SHOWER AND TOWEL SERVICES
                    </ListItem>

                  </UnorderedList>
                  <Link href='/contact-us' _hover={{textDecoration:'none'}}>
                      <LinkButton 
                      color='white' 
                      title='BOOK A CALL ' 
                      Imguri='/images/red_arrow.svg' 
                      padding='20px 25px' 
                      margin='30px 0px 0px' 
                      border='2px solid' 
                      borderColor='Red' 

                      ></LinkButton>
                  </Link>
                </Box>
              </SimpleGrid>
          </Box>
        </Container>
      </Flex> */}
      {/*  */}
      <Flex p={{ sm: "50px 0px", md: "80px 0px 90px" }}>
        <Container
          maxW={{
            sm: "Container.sm",
            md: "container.lg",
            lg: "container.lg",
            xl: "container.xl",
          }}
        >
          <Stack direction={{ sm: "column", md: "row" }} spacing="24px">
            <Box width={{ sm: "100%", md: "50%" }}>
              <Box
                paddingLeft="30px"
                position={"relative"}
                _after={{
                  position: "absolute",
                  content: '""',
                  width: "3px",
                  height: "100%",
                  top: "0",
                  left: "0",
                  background: "Red",
                }}
              >
                <Heading
                  title="FREQUENTLY ASKED QUESTIONS"
                  lineHeight="1"
                  padding="0px 0px 30px"
                  margin="0px auto"
                ></Heading>
                <Text
                  title="Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us."
                  width="80%"
                ></Text>
              </Box>
            </Box>
            <Box width={{ sm: "100%", md: "50%" }}>
              <Accordion
                defaultIndex={[0]}
                allowMultiple
                className="Accor_item _black_acording  white_acording"
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="If I have a studio pack can I also use the JustGYM area?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="Studio packs are for use in our studios only. But on a JustGYM+ membership, you also receive 8 credits per month that you can use!"
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="What length of memberships do you offer for JustGYM?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="We offer JustGYM Memberships for an initial 3/6-month contract from the start date; after that, you will enjoy a rolling monthly membership where 30 days notice is required to cancel your membership. We also offer a rolling membership which you can cancel anytime."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="What do I get for my monthly membership fee?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title=" You get access to our JustGYM space anytime Perpetua is open! Additionally, new JustGYM members get a complimentary consultation with a trainer who will help you put together a program."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="How do I become a JustGYM member?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title={
                        <Box>
                          Please pop out JustGYM team an email to get started
                          <Link href="mailto:justgym@perpetua.ie">
                            {" "}
                            justgym@perpetua.ie
                          </Link>
                        </Box>
                      }
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="What are your hours of operation?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="WML: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm"
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title="Do you have a place I can securely leave my stuff while I work out?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title="We have lockers in our men’s and women’s changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you’re in the facility."
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Heading
                          title=" Do you have showers?"
                          variant="extrasmall"
                        ></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      title=" Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)"
                      margin="0px 0px 20px"
                    ></Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      {/*  */}

      <VStack
        backgroundColor="black"
        padding={{ sm: "50px 0px", md: "96px 0px" }}
        borderBottom="1px solid #232323"
      >
        <Container
          display="flex"
          maxW="container.xl"
          flexDirection={{ base: "column", md: "row" }}
          alignItems="top"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
        >
          <Box
            w={{ sm: "100%", md: "50%", lg: "40%" }}
            textAlign={{ base: "center", lg: "left" }}
            marginBottom={{ base: "50px", lg: "0" }}
          >
            <Heading
              title="LOOKING FOR MORE INFORMATION"
              variant="extralarge"
              lineHeight="1"
              margin="0px 0px 40px"
              color="white"
            ></Heading>
            <Heading
              title="Chat with a member of the team "
              variant="smallmid"
              lineHeight="1"
              margin="0px 0px 30px"
              color="white"
            ></Heading>
            <Link
              href="/join-today"
              textDecoration="none"
              display="inline-block"
              _hover={{ textDecoration: "none" }}
            >
              <LinkButton
                title="Enquire Now"
                Imguri="/images/red_arrow.svg"
                border="2px solid"
                borderColor="Red"
                padding="20px 25px"
                color="White"
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ sm: "100%", md: "50%", lg: "60%" }}>
            <ContactForm pageName="JustGym"></ContactForm>
          </Box>
        </Container>
      </VStack>
    </>
  );
}

export default JustGym;
