import React, { useEffect } from "react";
import InnerBanner from "./InnerBanner/InnerBanner";
import TeamFilter from "./TeamFilter/TabFilter";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";

const TeamListing = (props) => {
  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, []);

  return (
    <>
      <MetaTitle title="Team | Perpetua Fitness" />
      <InnerBanner
        backgroundColor="black"
        backgroundImage={{
          sm: "url(./images/Rumble-banner-m.jpg)",
          md: "url(./images/Rumble-banner.jpg)",
        }}
        subtitle={false}
        title="OUR TEAM "
        subtextpara="PROFESSIONAL COACHES WITH YEARS OF EXPERIENCE"
        issubtextpara={true}
        isButton={false}
        issubtitleRight={false}
        width={{ base: "100%", sm: "100%", md: "50%" }}
        height={{ sm: "300px", md: "450px", lg: "500px", xl: "767px" }}
      />

      <TeamFilter></TeamFilter>
    </>
  );
};

export default TeamListing;
