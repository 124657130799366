import React from "react";
import { MainWrapper } from "../../component/MainWrapper";
import ResolutionPacks from "../../component/LandingPage/ResolutionPacks";

const ResolutionPacksPage = () => {
  return (
    <MainWrapper>
      <ResolutionPacks />
    </MainWrapper>
  );
};

export default ResolutionPacksPage;
