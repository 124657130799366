import {
  Box,
  Modal,
  ModalOverlay,
  Flex,
  Text,
  Spinner,
} from "@chakra-ui/react";
import * as Style from "./Posts.style";
import * as Style1 from "./Modal.style";
import PeopleYouMayKnow from "./PeopleYouMayKnow";
import React from "react";

import { GetLikeList } from "../../services/FeedService";
const LikeList = ({ isOpen, onClose, postId }) => {
  const { likeList, loader } = GetLikeList(postId)
  console.log(likeList);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size={{ sm: "lg" }}
    //maxHeight="85vh"
    >
      <ModalOverlay />
      <Style1.ModalForumContent>
        <Style1.ModalForumBody>
          <Style1.createWrap>
            <Text
              display="flex"
              alignItems="center"
              style={{ fontFamily: "Poppins" }}
            >
              <Style.heartIcon className={`bi bi-suit-heart-fill`} />{" "}
              <Text
                as="span"
                color="#fff"
                marginLeft="5px"
                fontFamily="poppins"
              >
                {likeList?.length}
              </Text>
            </Text>
            <Style1.CancelBtn
              color="#fff!important"
              className="bi bi-x-lg"
              onClick={() => {
                onClose();
              }}
            />
          </Style1.createWrap>
          <Style1.HiddenScrollBox
            maxHeight="60vh"
          >
            {loader ? (
              <Flex
                justifyContent="center"
                align="center"
                width="100%"
                bg="#282828"
                minH="40vh"
              >
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="#333"
                  color="#ccc"
                  size="lg"
                />
              </Flex>
            ) : (
              <Box minH="40vh" margin="20px">
                {likeList?.map((item, index) => (
                  <PeopleYouMayKnow
                    key={index}
                    data={item}
                    isShow={false}
                  />
                ))}
              </Box>
            )}
          </Style1.HiddenScrollBox>
        </Style1.ModalForumBody>
      </Style1.ModalForumContent>
    </Modal>
  );
};

export default LikeList;
