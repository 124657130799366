import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

export const InputBox = (props) => {
  return (
    <Box
      backgroundColor={
        props?.backgroundColor ? props?.backgroundColor : "white"
      }
      padding={{ base: "5px 10px" }}
      borderRadius="8px"
      width='100%'
    >
      {props.children}
    </Box>
  );
};

export const TextInput = (props) => {
  return (
    <>
      <InputBox backgroundColor={props?.backgroundColor}>
        <Input
          onChange={(e) => {
            props.setValue(e.target.value);
            props?.setError("");
          }}
          value={props.value}
          variant="unstyled"
          padding="8px 5px !important"
          fontSize={{ base: "16px", xl: "18px" }}
          fontFamily={"Poppins"}
          type={props.type}
          border="0px!important"
          placeholder={props.name}
          color={props?.color}
          required
          _focusVisible={{ border: "0px!important" }}
          _placeholder={{ color: props?.color, padding: "0px !important" }}
          width={props.width}
          className={props.className}
          disabled={props?.disabled}
        />
      </InputBox>
      <Text
        fontSize={{ base: "12px", md: "14px" }}
        fontFamily="Poppins"
        color="Red"
        margin={{ base: "2px 5px 10px", md: "2px 5px 15px" }}
      >
        {props?.error}
      </Text>
    </>
  );
};
export const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordToggler = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <InputBox backgroundColor={props.backgroundColor}>
        <InputGroup>
          <Input
            onChange={(e) => {
              props.setValue(e.target.value);
              props?.setError("");
            }}
            value={props.value}
            variant="unstyled"
            padding="8px 5px!important"
            border="0px!important"
            required
            color={props?.color}
            fontSize={{ base: "16px", xl: "18px" }}
            type={showPassword ? "text" : "password"}
            placeholder={props.name ? props.name : "Password"}
            _focusVisible={{ border: "0px!important" }}
            _placeholder={{ color: props?.color , padding: "0px !important"}}
            fontFamily={"Poppins"}
          />
          <InputRightElement
            _hover={{ cursor: "pointer" }}
            onClick={passwordToggler}
          >
            {showPassword ? (
              <ViewIcon
                fontSize={{ base: "20px", xl: "22px" }}
                color="gray.300"
              />
            ) : (
              <ViewOffIcon
                fontSize={{ base: "20px", xl: "22px" }}
                color="gray.300"
              />
            )}
          </InputRightElement>
        </InputGroup>
      </InputBox>
      <Text
        fontSize={{ base: "12px", md: "14px" }}
        fontFamily="Poppins"
        color="Red"
        margin={{ base: "2px 5px 10px", md: "2px 5px 15px" }}
      >
        {props?.error}
      </Text>
    </>
  );
};
export const FormButton = (props) => {
  return (
    <Button
      fontFamily={"Poppins"}
      width={props?.width ? props?.width : "100%"}
      paddingY={{ base: "22px", lg: "25px" }}
      fontSize={{ base: "16px", lg: "18px" }}
      marginBottom={"10px"}
      colorScheme={
        props?.backgroundColor ? props?.backgroundColor : "blackAlpha"
      }
      backgroundColor={
        props?.backgroundColor ? props?.backgroundColor : "black"
      }
      onClick={props.onClick}
      isDisabled={props.isDisabled}
    >
      {props.isDisabled ? "wait" : props.name}
    </Button>
  );
};
