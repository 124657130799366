import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Container,
  Image,
  Link,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import InnerBanner from "./InnerBanner/InnerBanner";
import ClassCard from "./Classcard/ClassCard";
import Heading from "./Heading/Heading";
import Text from "./SubHeading/SubHeading";
import AppDownload from "./AppDownload/AppDownload";
import PodcastEpisode from "./Podcast/PodcastEpisode";
import * as Style from "./Style/Podcast.style";
import axios from "axios";
import MetaTitle from "./MetaTitle";
import WOW from "wowjs";
import BlogSection from "./Blog/BlogSection";

function Podcast() {
  const [podList, setPodList] = useState([]);
  const [podDetails, setPodDetails] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isListed, setIsListed] = useState(true);
  const [potCast, setPotCast] = useState([]);

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();

    setIsLoading(true);
    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/categorylandingpage",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        slug: "podcast",
      },
    })
      .then((response) => {
        setPodDetails(response.data.data[0]);
        const objectArray = Object.entries(response.data.data[0].social_links);
        setPotCast(objectArray);
        setGalleryImages(response.data.data[0].images);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    axios({
      method: "get",
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + "/blogs/latest",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        num_of_post: 3,
        cat_slug: "podcast",
      },
    })
      .then((response) => {
        setPodList(response.data.data);
        setIsListed(false);
      })
      .catch((error) => {
        console.log(error);
        setIsListed(true);
      });
  }, []);

  return isLoading ? (
    <Container minHeight={"100vh"} maxW={"100%"} bg={"black"}></Container>
  ) : (
    <>
      <MetaTitle title="Podcast | Perpetua Fitness" />

      <InnerBanner
        backgroundColor="black"
        // backgroundImage={{sm:'url(./images/podcast_m.jpg)' , md:podDetails.banner_image}}
        backgroundImage={{ md: podDetails.banner_image }}
        title={podDetails.banner_title}
        subtextpara={podDetails.banner_subtitle}
        issubtextpara={true}
        isButton={false}
        issubtitleRight={false}
        isSubtext={false}
        width={{ sm: "90%", md: "70%", lg: "80%", xl: "125%" }}
        height={{ sm: "450px", md: "500px", lg: "550px", xl: "767px" }}
      ></InnerBanner>

      <Style.PodcastOurClass>
        <Container maxW="container.xl">
          <Heading
            title={podDetails.page_title}
            textAlign="center"
            cfSize={{ sm: "40px", md: "60px" }}
          />
          <Style.PodcastOurClassSocial>
            <Text title="NEVER MISS AN EPISODE" margin="0px 0px 30px" />

            <Style.PodcastOurClassSocialContent>
              {potCast.map((eachRow) => (
                <Link href={eachRow[1].link} isExternal>
                  {" "}
                  <Image src={eachRow[1].social_icon} width="40px" />{" "}
                </Link>
              ))}
            </Style.PodcastOurClassSocialContent>
          </Style.PodcastOurClassSocial>
          {/* <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        spacing={6}
                        padding={{ sm: '10px 0px', md: '30px 10px' }}
                    >

                        <GridItem>
                            <Box>
                                <Heading title={podDetails.heading}
                                    variant='medium'
                                    lineHeight='0.8'
                                    width='100%'
                                    padding='10px 0px 20px' />
                                {/* <Box className='htmlContent' dangerouslySetInnerHTML={{ __html: podDetails.description }}></Box> */}
          {/*   </Box>
                        </GridItem> 

                        <GridItem position='relative'>
                            <Style.PodcastOurClassSocial>
                                <Text title='NEVER MISS AN EPISODE' margin='0px 0px 30px' />

                                <Style.PodcastOurClassSocialContent>
                                    {potCast.map((eachRow) =>
                                        <Link href={eachRow[1].link} isExternal> <Image src={eachRow[1].social_icon} width='40px' /> </Link>
                                    )}

                                </Style.PodcastOurClassSocialContent>
                            </Style.PodcastOurClassSocial>
                        </GridItem>
                    </SimpleGrid>*/}
        </Container>
      </Style.PodcastOurClass>

      {/* Podcast episode */}

      {isListed ? (
        <Flex justifyContent="center">
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#d12c41"
            size="lg"
          />
        </Flex>
      ) : (
        <PodcastEpisode title="EXPLORE ALL PODCASTS" podListItem={podList} />
      )}

      {/* <Style.PodcastImageInfor>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        columnGap={10}
                        rowGap={6}
                        width="full"
                    >

                        {galleryImages.map((eachRow) =>
                            <GridItem>
                                <ClassCard
                                    isHeading={false}
                                    isSubheading={false}
                                    isButton={false}
                                    bg={eachRow.image}
                                    minHeight={{ sm: '375px', md: '375px' }}
                                />
                            </GridItem>
                        )}

                    </SimpleGrid>
                </Container>
            </Style.PodcastImageInfor> */}

      {/* app store */}
      <BlogSection catSlug="podcast" />
      <AppDownload
        bg="url('/images/App-section-bg.webp')"
        heading="Your Daily Dose of Online Workouts Is Live"
        para="Perpetua Online Programming"
        imageUri="/images/iphone.webp"
        appstore="/images/app-store.png"
        googleplay="/images/google-play.png"
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
      />
    </>
  );
}
export default Podcast;
