import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import TimeTable from "../component/TimeTable";


const TimeTablePage = () => {
  return (
    <MainWrapper>
      <TimeTable />
    </MainWrapper>

  );
};

export default TimeTablePage;
