import React from "react";
import { Box, Flex, Container } from "@chakra-ui/react";
import ThankyouCard from "./ThankyouCard/ThankyouCard";
import { Helmet } from "react-helmet";

const ThankYou = () => {
  return (
    <>
      <Helmet
        script={[
          {
            innerHTML: `fbq('track', 'Purchase', {
              value: 100,
              currency: 'EUR'
            });`,
          },
          {
            innerHTML: `gtag('event', 'conversion', {
              'send_to': 'AW-413038608/x3fACI_co_8YEJDw-cQB',
              'value': 1.0,
              'currency': 'EUR',
              'transaction_id': ''
              });`,
          },
        ]}
      />

      <Box
        backgroundImage={{
          sm: "url(./images/Crossfit-banner.jpg)",
          md: "url(./images/Crossfit-banner.jpg)",
        }}
      >
        <Container
          maxW={{ md: "container.lg", xl: "container.xl" }}
          textAlign="center"
        >
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            padding={{ sm: "100px 0px" }}
          >
            <ThankyouCard
              heading="Thank You"
              description="For being a valuable member of Perpetua Fitness"
              buttontext="Go To Home"
            ></ThankyouCard>
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default ThankYou;
