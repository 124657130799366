import React from "react";
import { MainWrapper } from "../component/MainWrapper";
import Location from "../component/Location";


const LocationPage = () => {
  return (
    <MainWrapper>
      <Location />
    </MainWrapper>

  );
};

export default LocationPage;
