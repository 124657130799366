import { Text } from "@chakra-ui/react";
import React, { useState } from "react";
interface textType { maxlength: number, children: string; }

const ReadMoreText = ({ maxlength, children }: textType): JSX.Element => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Text fontFamily="Poppins" color="#fff">
      {isReadMore ? text?.slice(0, maxlength) : text}
      {text?.length > maxlength && (
        <span
          onClick={toggleReadMore}
          style={{ color: "rgb(192, 192, 192)", cursor: "pointer" }}
        >
          {isReadMore ? "...read more" : " show less"}
        </span>
      )}
    </Text>
  );
};

export default ReadMoreText;
